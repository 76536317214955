<div class="log-wrapper">
    <div class="log">
        <div class="log-header">
            <span class="log-label" [innerHTML]="entry.typeLabel | highlight: queryText"></span>
            <span *ngFor="let tag of entry.taggedEntities" ngbTooltip="Click to view {{tag.type.toLowerCase()}}"
                [openDelay]="400"
                class="tooltip-interactionj" [hidden]="dialogMode">
                    <button class="btn btn-link sf-btn-link badge-link" (click)="goToTag(tag, entry)">
                        <b class="badge badge{{tag.type}}" [innerHTML]="tag.label | highlight: queryText"></b>
                    </button>
                </span>

            <small class="float-end">
                <span *ngIf="entry.performedBy">
                    by
                    <button class="btn btn-link sf-btn-link white-button" (click)="goToUser(entry.performedBy, 'contact')"
                            ngbTooltip="View User">
                        <b [innerHTML]="entry.performedBy | highlight: queryText"></b>
                    </button>
                </span>
                <span *ngIf="entry.ipAddress">
                    {{entry.ipAddress ? '(' + entry.ipAddress + ')' : ''}}
                </span>
                <span *ngIf="entry.inBehalfOf"> as <b [innerHTML]="entry.inBehalfOf | highlight: queryText"></b></span>
                <span> on </span>
                <span [innerHTML]="entry.date | highlight: queryText"></span>
            </small>
        </div>

        <div *ngIf="entry.details" class="row" style="word-wrap: break-word">
            <div class="log-details col-md-10" [ngSwitch]="entry.details.detailsType">
                <div *ngSwitchCase="'assignmentsChange'">
                    The following roles were {{entry.details.rolesAdded ? "added to " : "removed from "}}
                    <button class="btn btn-link sf-btn-link"
                            (click)="goToUser(entry.details.userID, 'contact')">
                        <b [innerHTML]="entry.details.userID | highlight: queryText"></b>
                    </button>
                    at
                    <b [innerHTML]="entry.details.organizationName | highlight: queryText"></b>
                    <button class="btn btn-link sf-btn-link"
                            (click)="goToOrganization(entry.details.organizationID, 'manager')">
                        (<b [innerHTML]="entry.details.organizationID | highlight: queryText"></b>)
                    </button>
                    :
                    <ul>
                        <li *ngFor="let role of entry.details.changedRoles">
                            <span [innerHTML]="role.roleName | highlight: queryText"></span>
                            <button *ngIf="role.roleID" class="btn btn-link sf-btn-link"
                                    (click)="goToRoleWithOrg(role.roleID, entry.details.organizationID)">
                                (<b [innerHTML]="role.roleID | highlight: queryText"></b>)
                            </button>
                        </li>
                    </ul>
                </div>

                <div *ngSwitchCase="'userArchived'">
                    {{entry.details.archivedUser.user.contact.name.first}} {{entry.details.archivedUser.user.contact.name.last}} <b>
                    ({{entry.details.archivedUser.user.id}})
                    </b> was archived and removed from {{entry.details.archivedOrgTotal}} {{entry.details.archivedOrgTotal > 1 ? "organizations" : "organization"}}.
                    <button class="btn btn-link sf-btn-link"
                            (click)="goToArchivedUserDialog(entry.details)">
                        <b>Click here </b>
                    </button>
                    to view the {{entry.details.archivedOrgTotal > 1 ? "organizations" : "organization"}} and
                    associated {{entry.details.archivedOrgTotal > 1 || entry.details.changedRolesTotal > 1 ? "roles that were removed" : "role that was removed"}}.
                </div>

                <div *ngSwitchCase="'userInvitation'">
                    <div>
                        Invite sent to <em><span [innerHTML]="entry.details.sentToEmail | highlight: queryText"></span></em>
                        by <b><span [innerHTML]="entry.details.sentByName | highlight: queryText"></span></b>
                        <button class="btn btn-link sf-btn-link"
                                (click)="goToUser(entry.details.sentByID, 'contact')">
                            (<b [innerHTML]="entry.details.sentByID | highlight: queryText"></b>)
                        </button>
                    </div>
                    <div *ngIf="!entry.details.organizations"> for
                        <b><span [innerHTML]="entry.details.organizationName | highlight: queryText"></span></b>
                        <button class="btn btn-link sf-btn-link"
                                (click)="goToOrganization(entry.details.organizationID, 'manager')">
                            (<b [innerHTML]="entry.details.organizationID | highlight: queryText"></b>)
                        </button>
                    </div>
                    <div *ngIf="!!entry.details.organizations"> for organization(s):
                        <ul>
                            <li *ngFor="let organization of entry.details.organizations">
                                <b><span [innerHTML]="organization.organizationName | highlight: queryText"></span></b>
                                <button class="btn btn-link sf-btn-link"
                                        (click)="goToOrganization(organization.organizationID, 'manager')">
                                    (<b [innerHTML]="organization.organizationID | highlight: queryText"></b>)
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div>with the following roles:</div>
                    <ul>
                        <li *ngFor="let role of entry.details.roles">
                            <span [innerHTML]="role.roleName | highlight: queryText"></span>
                            <span *ngIf="role.roleID">
								<button class="btn btn-link sf-btn-link"
                                        (click)="goToRoleWithOrg(role.roleID, entry.details.organizationID)">
                                    (<b [innerHTML]="role.roleID | highlight: queryText"></b>)
                                </button>)
                            </span>
                        </li>
                    </ul>
                </div>

                <div *ngSwitchCase="'serviceInvitation'">
                    <div>
                        <span>Invitation sent to </span>
                        <button *ngIf="entry.details.sentToUser" class="btn btn-link sf-btn-link"
                                (click)="goToUser(entry.details.sentToUser, 'manager')">
                            <b [innerHTML]="entry.details.sentToUser | highlight: queryText"></b>
                        </button>
                        <span *ngIf="entry.details.sentToName">{{entry.details.sentToName}}</span>
                        <span> at </span>
                        <b><span [innerHTML]="entry.details.organizationName | highlight: queryText"></span></b>
                        <button class="btn btn-link sf-btn-link"
                                (click)="goToOrganization(entry.details.organizationID, 'services')">
                            (<b [innerHTML]="entry.details.organizationID | highlight: queryText"></b>)
                        </button>
                    </div>
                    <div>For the <em><span [innerHTML]="entry.details.serviceID | highlight: queryText"></span></em>
                        service
                    </div>
                </div>

                <div *ngSwitchCase="'organizationInvitation'">
                    <div>
                        <span>Invitation sent to </span>
                        <b><span [innerHTML]="entry.details.firstName | highlight: queryText"></span>&nbsp;<span
                            [innerHTML]="entry.details.lastName | highlight: queryText"></span></b>
                    </div>
                    <div>
                        <span>at </span>
                        <b><span [innerHTML]="entry.details.organizationName | highlight: queryText"></span></b>
                    </div>
                    <div>
                        <span>at </span>
                        <b><span [innerHTML]="entry.details.emailAddress | highlight: queryText"></span></b>
                    </div>
                    <div>For the <b><span [innerHTML]="entry.details.serviceID | highlight: queryText"></span></b>
                        service
                    </div>
                    <div>as a <b><span [innerHTML]="entry.details.organizationType | highlight: queryText"></span></b>
                        organization.
                    </div>
                    <div *ngIf="entry.details.parentOrgName">
                        <span>Parent organization: {{entry.details.parentOrgName}}</span>
                    </div>
                </div>

                <div *ngSwitchCase="'entitlementsChange'">
                    The following services were {{entry.details.activityType}} for
                    <b><span [innerHTML]="entry.details.organizationName | highlight: queryText"></span></b>
                    <span> (</span>
                    <button class="btn btn-link sf-btn-link"
                            (click)="goToOrganization(entry.details.organizationID, 'services')">
                        <b [innerHTML]="entry.details.organizationID | highlight: queryText"></b>
                    </button>
                    <span>):</span>
                    <ul>
                        <li *ngFor="let service of entry.details.entitlementsChanged"><span
                            [innerHTML]="service | highlight: queryText"></span></li>
                    </ul>
                    <div *ngIf="entry.details.reason">
                        <b *ngIf="!entry.details.reason.startsWith('Reason:')">Reason: </b>
                        <span>{{entry.details.reason}}</span>
                    </div>
                    <div *ngIf="entry.details.notifiedUsers">
                        <b>Email sent to: </b>
                        <span>{{entry.details.notifiedUsers}}</span>
                    </div>
                </div>

                <div *ngSwitchCase="'apiSubscriptionsChange'">
                    The following API integrations were {{entry.details.activityType}}
                    for <b><span [innerHTML]="entry.details.organizationName | highlight: queryText"></span></b>
                    <button class="btn btn-link sf-btn-link"
                            (click)="goToOrganization(entry.details.organizationID, 'services')">
                        (<b [innerHTML]="entry.details.organizationID | highlight: queryText"></b>)
                    </button>
                    <span *ngIf="entry.details.reason"> due to {{entry.details.reason}}</span>:
                    <ul>
                        <li>{{entry.details.apiKey}}</li>
                    </ul>
                </div>

                <div *ngSwitchCase="'treeModification'">
                    The organization hierarchy for <b>
                    <span [innerHTML]="entry.details.rootName | highlight: queryText"></span></b>
                    <button class="btn btn-link sf-btn-link"
                            (click)="goToOrganization(entry.details.rootID, 'hierarchy')">
                        <b [innerHTML]="entry.details.rootID | highlight: queryText"></b>
                    </button>
                    was modified:
                    <ul>
                        <li *ngFor="let mod of entry.details.changes">
                            <b><span [innerHTML]="mod.organizationName | highlight: queryText"></span></b>
                            <button class="btn btn-link sf-btn-link"
                                    (click)="goToOrganization(mod.organizationID, 'hierarchy')">
                                (<b [innerHTML]="mod.organizationID | highlight: queryText"></b>)
                            </button>
                            <span *ngIf="!mod.parentID">
                                    was {{mod.wasAdded ? "added" : "removed"}} as a root organization
                                </span>
                            <span *ngIf="mod.parentID">
                                was {{mod.wasAdded ? "added to" : "removed from"}}
                                <b><span [innerHTML]="mod.parentName | highlight: queryText"></span></b>
                                <button class="btn btn-link sf-btn-link"
                                        (click)="goToOrganization(mod.parentID, 'hierarchy')">
                                    (<b [innerHTML]="mod.parentID | highlight: queryText"></b>)
                                </button>
                            </span>
                        </li>
                    </ul>
                </div>

                <div *ngSwitchCase="'organizationArchived'">
                    <b>{{entry.details.organizationName}}</b> archived<br/>
                    Archive Reason: {{entry.details.archivedReasonName}}<br/>
                    <span *ngIf="entry.details.details">
                            Details: <span [innerHTML]="entry.details.details"></span>
                        </span>
                </div>

                <div *ngSwitchCase="'diffChange'">
                    <b [innerHTML]="entry.details.comment | highlight: queryText"></b>
                    <ul>
                        <li *ngFor="let change of entry.details.changes">
                            <span [innerHTML]="change | highlight: queryText"></span>
                        </li>
                    </ul>
                </div>

                <!-- 'default' must display HTML -->
                <div *ngSwitchCase="'default'">
                    <b [innerHTML]="entry.details.comment"></b>
                </div>

                <div *ngSwitchDefault><em>No Details Specified</em></div>
            </div>

            <div class="col-md-2">
                <!--
                <a class="float-end view-raw" ng-click="expanded = !(!!expanded)">
                    <span class="fa-stack">
                        <fa-icon [icon]="['far', 'circle']" stackItemSize="2x"></fa-icon>
                        <fa-icon [icon]="['far', 'code']" [inverse]="true" stackItemSize="1x"></fa-icon>
                    </span>
                </a>
                -->
            </div>
        </div>

    </div>
</div>
