import { dayjs } from "@sf/common";

import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from "@angular/core";
import {
    Contract,
    ContractExtended,
    ContractFee,
    ContractFeeType,
    ContractService,
    ContractType,
    Organization,
    OrganizationEntitlement,
    Product,
    ProductBasic,
    TokenService,
    User,
    UserorgActivationService,
    UserOrgService
} from "@sf/userorg/common";
import { ActivatedRoute, Router } from "@angular/router";
import {
    GrowlService,
    SessionService,
    SfValidators,
    SpinnerService
} from "@sf/common";
import {
    AdminLicenseUtilityInterface,
    EnrollmentCodeSelectDialogComponent,
    LicenseUtilityService,
    OrganizationServicesCoreService
} from "@sf/userorg/main";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { first } from "rxjs/operators";
import { SubmitterOrganizationService } from "@sf/submitter/common";

interface SetupThing extends Contract {
    licenseFee?: number;
    renewalFee?: number;
    documentFee?: any; // could have tiers

    agreementUrl?: string;
    agreementMissing?: boolean;
    agreementLoading?: boolean;
}

// prettier-ignore
@Component({
    selector: "sf-license-document-builder",
    templateUrl: "./license-document-builder.component.html",
    styleUrls: [
        "./license-document-builder.component.scss",
        "../../styles/license-styles.scss"
    ]
})
export class LicenseDocumentBuilderComponent implements OnInit, OnChanges {
    @Input() orgID: string;
    @Input() adminLicenceUtility: AdminLicenseUtilityInterface;

    /** Private Variables **/
    licenseType = ContractType.DOCUMENT_BUILDER;
    product: ProductBasic = {
        productID: "submitter_signing",
        label: "Document Builder"
    };
    contractTemplate: any = null;

    isSuperUser = false;
    setup: SetupThing;
    msa: SetupThing = null;
    editMode = false;
    userCanEdit = false;
    originalValues: SetupThing;
    isProcessing = false;
    loaded = false;
    isSuspended = false;
    licenseExpired = false;
    salesPerson: User = null;
    warning: string = null;
    formattedExpirationDate = "";
    formattedExecutedDate = "";
    formattedPaidDate = "";
    expirationDayjs: dayjs.Dayjs;
    currentInvite: any = null; // set in licenseUtilityService
    currentInviteMessage: string = null;
    missingTemplate = false;
    pendingContract: ContractExtended = null;
    futureContract: ContractExtended = null;
    selectableBillingCycles: any[];
    selectedBillingCycle: any;
    displayableBillingCycle: string;
    selectablePaymentTerms: any[];
    selectedPaymentTerm: any;
    displayablePaymentTerms: string;
    fieldsNotDefined: boolean = false;
    maxDate: dayjs.Dayjs = null;
    minDate: dayjs.Dayjs = null;
    isLicenseEditOverridden: boolean = false;
    opportunityID: string = null;
    enrollmentCodeName: string;
    showPricingTiers = false;
    hasSalesforceEditPermission: boolean = false;
    entitlement: OrganizationEntitlement = null;
    paymentConfig: any = {      // relevant org payment config settings
        'Templates and E-Sign': {
            bulk_release_base_fee: {
                label: ""
            },
            bulk_release_per_ref_fee: {
                label: ""
            }
        }
    };

    constructor(
            private _route: ActivatedRoute,
            private router: Router,
            private sessionService: SessionService,
            private growlService: GrowlService,
            private contractService: ContractService,
            private spinnerService: SpinnerService,
            private activationService: UserorgActivationService,
            private licenseService: LicenseUtilityService,
            private tokenService: TokenService,
            private userorgService: UserOrgService,
            private modalService: NgbModal,
            private coreService: OrganizationServicesCoreService,
            private submitterOrgService: SubmitterOrganizationService
    ) {}

    ngOnInit(): void {
        this.isSuperUser = this.sessionService.isSuperUser();
        this.hasSalesforceEditPermission = this.sessionService.hasPermission("admin_salesforce_id_edit", "SIMPFL")
                && sf.liveConfig.SalesforceIntegrationSettings.isTalendServiceEnabled;


        this.selectableBillingCycles = this.contractService.getSelectableBillingCycles(this.product.productID);
        this.selectablePaymentTerms = this.contractService.getSelectablePaymentTerms();
        if (this.isSuperUser) {
            this.userCanEdit = this.sessionService.hasPermission("admin_license_edit", "SIMPFL");
            this.contractService.checkLicenseTemplate("MSA")
                .subscribe((response: boolean) => {
                    this.missingTemplate = !response;
                });
        }

        this.contractService.getLicenseOverrideSetting()
            .subscribe((overrideEnabled: boolean) => {
                this.isLicenseEditOverridden = overrideEnabled;
                if (!this.isLicenseEditOverridden) {
                    this.maxDate = dayjs().add(1, "year");
                    this.minDate = dayjs().add(1, "day").startOf("day");
                }
            });

        this.reload();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.orgID && !changes.orgID.firstChange) {
            this.orgID = changes.orgID.currentValue;
            this.reload();
        }
    }

    reload() {
        this.showPricingTiers = false;
        this.warning = null;
        this.editMode = false;
        this.loaded = false;
        this.contractTemplate = null;
        this.msa = null;
        this.enrollmentCodeName = null;

        this.opportunityID = null;
        this.userorgService.getAllOrganizationEntitlements(this.orgID)
            .subscribe((entitlements: OrganizationEntitlement[]) => {
                if (entitlements) {
                    entitlements.forEach((entitlement) => {
                        if (entitlement.product == Product.SUBMITTER_SIGNING) {
                            this.entitlement = entitlement;
                            this.isSuspended = entitlement.status == "SUSPENDED";
                            if (this.isSuspended) {
                                let disabledReason = this.coreService.getFriendlyDisabledReason(entitlement.entitlementStatusReason);
                                this.warning = "Service is suspended";
                                if (disabledReason) {
                                    this.warning += " due to " + disabledReason;
                                }
                            }
                            this.opportunityID = entitlement.salesforceOpportunityID;
                        }
                    });
                }
            });

        this.contractService.findContract(this.orgID, this.licenseType)
            .subscribe((response: Contract) => {
                this.useContract(response);
                if (response) {
                    if (response.contractID) {
                        this.licenseService.refreshDocumentIcon(this.orgID, response);
                    }
                    if (response.enrollmentCode) {
                        this.licenseService.getEnrollmentCodeName(response.enrollmentCode)
                            .then((name: string) => {
                                this.enrollmentCodeName = name;
                            });
                    }
                }
                this.loaded = true;
            });
        this.contractService.getPendingContract(this.orgID, this.licenseType)
            .subscribe((response: Contract) => {
                this.pendingContract = response as ContractExtended;
                this.contractService.getFutureContract(this.orgID, this.licenseType)
                    .subscribe((response: Contract) => {
                        this.futureContract = response as ContractExtended;

                        //if contract is both pending and future, only want the pending
                        if (!!this.pendingContract && !!this.futureContract && this.pendingContract.contractID == this.futureContract.contractID) {
                            this.futureContract = null;
                        }
                    });
            });
        this.contractService.findContract(this.orgID, "MSA")
            .subscribe((response: Contract) => {
                if (response && response.contractID) {
                    this.msa = response;
                    this.licenseService.refreshDocumentIcon(this.orgID, response);
                }
            });

        // get the salesperson name
        this.userorgService.getOrganizationSalesRep(this.orgID, this.product.productID)
            .pipe(first())
            .subscribe((seller: User) => {
                if (seller) {
                    if (!seller.name) {
                        // This probably means that no salesperson is assigned
                        seller = null;
                    } else {
                        this.userorgService.buildUserFullName(seller);
                    }
                }
                this.salesPerson = seller;
            });

        // unique to doc builder - payment settings
        this.reloadPaymentConfigSettings();

        if (this.isSuperUser) {
            this.licenseService.lookForInvite(this, this.orgID, this.product.productID);
        }
    }

    reloadPaymentConfigSettings() {
        this.submitterOrgService.getSubmitterOrganizationConfigPreparationWithAllValues(this.orgID)
            .subscribe((configs: any) => {
                this.paymentConfig = configs;
            });
    }

    showSalesPerson() {
        this.licenseService.showSalesPerson(this.salesPerson);
    }

    showSignerDetails(contract: ContractExtended) {
        this.licenseService.showSignerDetails(this.currentInvite, contract, this.orgID, this.product.label);
    }

    useContract(contract: Contract) {
        if (contract) {
            this.setup = contract;
            this.originalValues = this.userorgService.cloneObj(contract);

            // pull fees out of array for easier UI
            this.setup.licenseFee = 0;
            this.setup.renewalFee = 0;
            //this.setup.documentFee = 0;
            if (contract.contractFees) {
                Object.values(contract.contractFees).forEach((fee: ContractFee) => {
                    if (fee.feeType == "DOCUMENT_BUILDER_LICENSE_FEE") {
                        this.setup.licenseFee =
                            fee.contractFeeTiers.length > 0
                                ? fee.contractFeeTiers[0].feeAmount
                                : null;
                        this.setup.licenseFee = this.licenseService.roundOff(
                            this.setup.licenseFee,
                            2
                        );
                    } else if (fee.feeType == "DOCUMENT_BUILDER_RENEWAL_FEE") {
                        this.setup.renewalFee =
                            fee.contractFeeTiers.length > 0
                                ? fee.contractFeeTiers[0].feeAmount
                                : null;
                        this.setup.renewalFee = this.licenseService.roundOff(
                            this.setup.renewalFee,
                            2
                        );
                    } else if (fee.feeType == "DOCUMENT_BUILDER_DOCUMENT_FEE") {
                        // 	this.setup.documentFee = fee.contractFeeTiers.length > 0 ? fee.contractFeeTiers[0].feeAmount : null;
                        this.setup.contractFees.DOCUMENT_BUILDER_DOCUMENT_FEE.contractFeeTiers[0].feeAmount =
                            this.licenseService.roundOff(
                                this.setup.contractFees
                                    .DOCUMENT_BUILDER_DOCUMENT_FEE
                                    .contractFeeTiers[0].feeAmount,
                                2
                            );
                    }
                });
            } else {
                // contract fees are too dang complicated
                this.licenseService.dummyTheDumbFees(this.setup, [
                    ContractFeeType.DOCUMENT_BUILDER_LICENSE_FEE,
                    ContractFeeType.DOCUMENT_BUILDER_RENEWAL_FEE,
                    ContractFeeType.DOCUMENT_BUILDER_FILE_TRANSFER_FEE
                ]);
            }

            this.originalValues.licenseFee = this.setup.licenseFee;
            this.originalValues.renewalFee = this.setup.renewalFee;
            this.originalValues.documentFee = this.userorgService.cloneObj(
                this.setup.contractFees
                    ? this.setup.contractFees.DOCUMENT_BUILDER_DOCUMENT_FEE
                    : undefined
            );
            this.originalValues.freeSubmissions = this.setup.freeSubmissions;

            this.formattedExpirationDate = this.licenseService.formatDate(
                contract.expirationDate
            );
            this.formattedExecutedDate = this.licenseService.formatDate(
                contract.executedDate
            );
            this.formattedPaidDate = this.licenseService.formatDate(
                contract.paidDate
            );
            this.expirationDayjs = dayjs(contract.expirationDate);

            this.licenseExpired = this.licenseService.isDateBeforeToday(
                this.formattedExpirationDate
            );

            this.selectedBillingCycle = this.selectableBillingCycles.find((cycle) => {
                return cycle.id == contract.billingCycle;
            });
            this.displayableBillingCycle = this.selectedBillingCycle
                ? this.selectedBillingCycle.label
                : "Unknown";
            if (!this.selectedBillingCycle) {
                this.fieldsNotDefined = true;
                this.selectedBillingCycle = this.selectableBillingCycles[0]; //default to DAILY
                this.setup.billingCycle = this.selectedBillingCycle.id;
            }

            this.selectedPaymentTerm = this.selectablePaymentTerms.find((term) => {
                return term.id == contract.paymentTerms;
            });
            this.displayablePaymentTerms = this.selectedPaymentTerm
                ? this.selectedPaymentTerm.label
                : "Unknown";
            if (!this.selectedPaymentTerm) {
                this.fieldsNotDefined = true;
                this.selectedPaymentTerm = this.selectablePaymentTerms[0]; //default to NET1
                this.setup.paymentTerms = this.selectedPaymentTerm.id;
            }
        } else {
            this.setup = <SetupThing>{
                renewalFee: 0,
                licenseFee: 0,
                contractFees: null,
                freeSubmissions: 0,
                contractType: this.licenseType
            };
            // contract fees are too dang complicated
            this.licenseService.dummyTheDumbFees(this.setup, [
                ContractFeeType.DOCUMENT_BUILDER_LICENSE_FEE,
                ContractFeeType.DOCUMENT_BUILDER_RENEWAL_FEE,
                ContractFeeType.DOCUMENT_BUILDER_FILE_TRANSFER_FEE
            ]);
            this.originalValues = this.setup;
        }
    }

    beginEdit() {
        if (!this.userCanEdit) {
            return;
        }
        if (this.pendingContract) {
            this.growlService.error("There is already a license that is pending approval. " +
                    "If you want to make changes, you'll need to cancel the pending license.");
            return;
        }
        if (this.futureContract) {
            this.growlService.error("There is already a pending future license. If you want to make changes, you'll need to cancel the future license first.");
            return;
        }
        if (this.isSuspended) {
            this.growlService.error("This service is suspended. If you want to make changes, you'll need to unsuspend the service.");
            return;
        }

        this.userorgService.getOrganization(this.orgID)
            .pipe(first())
            .subscribe((org: Organization) => {
                if (!org || !org.enabled) {
                    this.growlService.error("This organization is disabled, so the license can't be edited.");
                    return;
                }
                this.editMode = true;
                if (!this.setup.expirationDate) {
                    this.setup.expirationDate = this.licenseService.getNextYearDateYMD();
                }
            });
    }

    selectBillingCycle($event: any) {
        if ($event && $event.$selection) {
            let newCycle: any = $event.$selection;
            this.selectedBillingCycle = newCycle;
            this.setup.billingCycle = newCycle.id;
            this.displayableBillingCycle = this.selectedBillingCycle.label;
        }
    }

    selectPaymentTerm($event: any) {
        if ($event && $event.$selection) {
            let newTerm: any = $event.$selection;
            this.selectedPaymentTerm = newTerm;
            this.setup.paymentTerms = newTerm.id;
            this.displayablePaymentTerms = this.selectedPaymentTerm.label;
        }
    }

    expirationDateChanged(newDate: dayjs.Dayjs) {
        this.setup.expirationDate = this.licenseService.formatDayjs(newDate);
    }

    validateInputs() {
        this.warning = null;

        // validate data
        if (typeof this.setup.licenseFee == "undefined" || this.setup.licenseFee == null) {
            this.warning = "License Fee is required";
        }
        if (typeof this.setup.renewalFee == "undefined" || this.setup.renewalFee == null) {
            this.warning = "Renewal Fee is required";
        }
        if (typeof this.setup.contractFees.DOCUMENT_BUILDER_DOCUMENT_FEE == "undefined" ||
                this.setup.contractFees.DOCUMENT_BUILDER_DOCUMENT_FEE == null ||
                this.setup.contractFees.DOCUMENT_BUILDER_DOCUMENT_FEE.contractFeeTiers.length <= 0) {
            this.warning += "Document Fee Pricing Tiers are required";
        }

        if (this.warning) {
            return;
        }

        if (!SfValidators.isPositiveIntegerString(this.setup.freeSubmissions)) {
            this.warning = "Free Documents must be a positive whole number or zero";
        }
        if (this.setup.contractFees.DOCUMENT_BUILDER_DOCUMENT_FEE.contractFeeTiers[0].feeAmount === 0 ||
                !SfValidators.isPositiveFloatString(
                        this.setup.contractFees.DOCUMENT_BUILDER_DOCUMENT_FEE.contractFeeTiers[0].feeAmount)) {
            this.warning += "Document Fee must be a valid number";
        }
        if (!SfValidators.isPositiveFloatString(this.setup.renewalFee)) {
            this.warning += "Renewal Fee must be a valid number";
        }
        if (!SfValidators.isPositiveFloatString(this.setup.licenseFee)) {
            this.warning = "License Fee must be a valid number";
        }

        if (!this.setup.expirationDate) {
            this.warning = "License Expiration Date is required";
        } else if (this.sessionService.getEnv() == "PROD") {
            this.expirationDayjs = dayjs(this.setup.expirationDate);
            let now = dayjs();
            if (this.expirationDayjs.isBefore(now)) {
                this.warning = "License Expiration Date cannot be in the past";
                return;
            }
        }
        if (this.maxDate && !dayjs(this.setup.expirationDate).isBefore(this.maxDate)) {
            this.warning = "License Expiration Date cannot be more than a year from today";
            return;
        }

        if (!this.setup.billingCycle) {
            this.warning = "License Billing Cycle is required";
            return;
        }

        if (!this.setup.paymentTerms) {
            this.warning = "License Payment Terms is required";
            return;
        }

        // round off to 2 decimal places
        this.setup.licenseFee = this.licenseService.roundOff(this.setup.licenseFee, 2);
        this.setup.renewalFee = this.licenseService.roundOff(this.setup.renewalFee, 2);
        //this.setup.documentFee = this.licenseService.roundOff(this.setup.documentFee, 2);
        this.setup.contractFees.DOCUMENT_BUILDER_DOCUMENT_FEE.contractFeeTiers[0].feeAmount =
                this.licenseService.roundOff(
                        this.setup.contractFees.DOCUMENT_BUILDER_DOCUMENT_FEE.contractFeeTiers[0].feeAmount, 2);

        if (!SfValidators.testFee(this.setup.licenseFee)) {
            this.warning = "Invalid License Fee format";
            return;
        }
        if (!SfValidators.testFee(this.setup.renewalFee)) {
            this.warning = "Invalid Renewal Fee format";
            return;
        }
        if (!SfValidators.testFee(this.setup.contractFees.DOCUMENT_BUILDER_DOCUMENT_FEE.contractFeeTiers[0].feeAmount)) {
            this.warning = "Invalid Document Fee format";
            return;
        }

        if (this.hasSalesforceEditPermission && this.opportunityID && this.opportunityID.length != 18) {
            this.warning = "Salesforce Opportunity ID must be 18 characters";
            return;
        }
    }

    cancelSend(isFutureContract?: boolean) {
        if (isFutureContract && !!this.futureContract) {
            this.licenseService.confirmCancel(this.product.label, this.actualCancelFuture.bind(this), this.futureContract, true);
        } else if (!!this.pendingContract) {
            this.licenseService.confirmCancel(this.product.label, this.actualCancelSend.bind(this), this.pendingContract, false);
        }
    }

    actualCancelSend() {
        this.isProcessing = true;
        this.contractService.cancelContractApprovalRequest(this.orgID, this.setup.contractType)
            .subscribe((result: any) => {
                this.reload();
                this.processingFinished();
            }, () => {
                this.processingFinished();
            });
    }

    actualCancelFuture(): void {
        this.isProcessing = true;
        this.contractService.cancelFutureContract(this.orgID, this.futureContract.contractType, this.futureContract.contractID)
                .subscribe((success: boolean) => {
                    if (success) {
                        this.growlService.success("Future license successfully removed.");
                    }
                    this.reload();
                    this.processingFinished();
                }, () => {
                    this.processingFinished();
                });
    }

    sendContract(requiredSend: boolean) {
        this.licenseService.sendContract(requiredSend, this.addendumPresave.bind(this), this.setup, this.originalValues, this.orgID, () => {
            this.reload();
        });
    }

    checkForPromptToSendContract(): boolean {
        this.formattedExpirationDate = this.licenseService.formatDate(this.setup.expirationDate);
        //var formattedOriginalExpirationDate = this.licenseService.formatDate(this.originalValues.expirationDate);
        this.licenseExpired = this.licenseService.isDateBeforeToday(this.formattedExpirationDate);

        // prompt user to approve if contract was previously signed and fees changed
        if (this.licenseService.numbersDifferent(this.setup.licenseFee, this.originalValues.licenseFee) ||
                this.licenseService.numbersDifferent(this.setup.renewalFee, this.originalValues.renewalFee) ||
                this.licenseService.numbersDifferent(
                        this.setup.contractFees.DOCUMENT_BUILDER_DOCUMENT_FEE.contractFeeTiers[0].feeAmount,
                        this.originalValues.documentFee.contractFeeTiers[0].feeAmount) ||
                (this.selectedBillingCycle.id != this.originalValues.billingCycle && !this.fieldsNotDefined) ||
                (this.selectedPaymentTerm.id != this.originalValues.paymentTerms && !this.fieldsNotDefined)) {
            // if we are here, then fees have changed, so prompt
            this.sendContract(true);
            return true;
        }
        return false;
    }

    save() {
        if (!this.userCanEdit) {
            return;
        }

        this.validateInputs();
        if (this.warning) {
            return;
        }

        this.licenseService.licenseFeeChangeCheck(this.setup.licenseFee, this.originalValues.licenseFee)
            .then((goOn: boolean) => {
                if (goOn) {
                    let prompted = this.checkForPromptToSendContract();
                    if (!prompted) {
                        this.doTheSave();
                    }
                }
            });
    }

    addendumPresave() {
        this.setup.contractFees.DOCUMENT_BUILDER_LICENSE_FEE.contractFeeTiers[0].feeAmount =
            this.setup.licenseFee;
        this.setup.contractFees.DOCUMENT_BUILDER_RENEWAL_FEE.contractFeeTiers[0].feeAmount =
            this.setup.renewalFee;
        this.setup.contractFees.DOCUMENT_BUILDER_FILE_TRANSFER_FEE.contractFeeTiers[0].feeAmount = 0;
    }

    // unique to doc builder
    saveDocBuilderConfig() {
        this.submitterOrgService.setSubmitterOrganizationConfig(this.orgID, this.paymentConfig)
            .subscribe(() => {
                this.reloadPaymentConfigSettings();
            });
    }

    doTheSave() {
        this.addendumPresave();
        this.saveDocBuilderConfig();

        this.isProcessing = true;
        this.contractService.saveContract(this.orgID, this.setup).subscribe(
            () => {
                this.growlService.success("License details saved.");
                this.reload();
                this.processingFinished();
            },
            () => {
                this.processingFinished();
            }
        );
        if (this.opportunityID != this.entitlement.salesforceOpportunityID) {
            this.entitlement.salesforceOpportunityID = this.opportunityID;
            this.userorgService.updateOrganizationEntitlement(this.orgID, this.entitlement)
                    .subscribe((result: Organization) => {
                        this.growlService.success("Salesforce Opportunity ID saved.");
                    });
        }
    }

    showUpload() {
        if (this.pendingContract) {
            this.growlService.error("There is already a license that is pending approval. " +
                    "If you want to make changes, you'll need to cancel the pending license.");
            return;
        }
        if (this.futureContract) {
            this.growlService.error("There is already a pending future license. If you want to make changes, you'll need to cancel the future license first.");
            return;
        }
        if (this.isSuspended) {
            this.growlService.error("This service is suspended. If you want to make changes, you'll need to unsuspend the service.");
            return;
        }
        if (this.msa) {
            this.adminLicenceUtility.uploadAgreement(this.orgID, this.product, this.afterUpload.bind(this));
        } else {
            this.adminLicenceUtility.uploadMSA(this.orgID, () => {
                this.adminLicenceUtility.uploadAgreement(this.orgID, this.product, this.afterUpload.bind(this));
            });
        }
    }

    afterUpload() {
        this.reload();
    }

    showUploadMSA() {
        this.adminLicenceUtility.uploadMSA(this.orgID, this.afterUpload.bind(this));
    }

    /*
    // These fee structures are way too complicated
    ensureFeeStructure() {
        if (!this.setup.contractFees) {
            this.setup.contractFees = <any>{};
        }
        if (!this.setup.contractFees.DOCUMENT_BUILDER_LICENSE_FEE) {
            this.setup.contractFees.DOCUMENT_BUILDER_LICENSE_FEE = {
                contractFeeTiers: [{ tierThreshold: 0, feeAmount: 0 }],
                thresholdResetPeriod:
                    ContractFeeThresholdResetPeriod.INDEFINITE,
                feeType: ContractFeeType.DOCUMENT_BUILDER_LICENSE_FEE
            };
        }
        if (!this.setup.contractFees.DOCUMENT_BUILDER_RENEWAL_FEE) {
            this.setup.contractFees.DOCUMENT_BUILDER_RENEWAL_FEE = {
                contractFeeTiers: [{ tierThreshold: 0, feeAmount: 0 }],
                thresholdResetPeriod:
                    ContractFeeThresholdResetPeriod.INDEFINITE,
                feeType: ContractFeeType.DOCUMENT_BUILDER_RENEWAL_FEE
            };
        }
        if (!this.setup.contractFees.DOCUMENT_BUILDER_DOCUMENT_FEE) {
            this.setup.contractFees.DOCUMENT_BUILDER_DOCUMENT_FEE = {
                contractFeeTiers: [{ tierThreshold: 0, feeAmount: 0 }],
                thresholdResetPeriod:
                    ContractFeeThresholdResetPeriod.INDEFINITE,
                feeType: ContractFeeType.DOCUMENT_BUILDER_DOCUMENT_FEE
            };
        }
    }
    */

    showInvite() {
        this.spinnerService.startSpinner();
        this.userorgService.getOrganization(this.orgID)
            .pipe(first())
            .subscribe((organization: Organization) => {
                this.spinnerService.stopSpinner();
                this.licenseService.showInvite(organization, this.product);
            }, () => {
                this.spinnerService.stopSpinner();
            }, () => {
                this.spinnerService.stopSpinner();
            });
    }

    cancelInvite() {
        this.licenseService.confirmCancelInvite(this.product.label, this.actualCancelInvite.bind(this));
    }

    actualCancelInvite() {
        this.tokenService.cancelServiceInvitation(this.orgID, this.product.productID)
            .subscribe(() => {
                this.currentInvite = null;
                this.adminLicenceUtility.refreshAfterActivate(this.orgID);
                this.growlService.success("Invitation canceled.");
            });
    }

    processingFinished() {
        setTimeout(() => {
            this.isProcessing = false;
        });
    }

    goToEnrollmentCode() {
        this.router.navigateByUrl(
                "/admin/sales/contract-pricing-templates?enrollmentCode=" + this.setup.enrollmentCode);
    }

    removeEnrollmentCode() {
        this.setup.enrollmentCode = null;
    }

    showEnrollmentCodes() {
        const modalRef = this.modalService.open(EnrollmentCodeSelectDialogComponent, {
            backdrop: "static",
            windowClass: "enrollment-code-select"
        });

        const modalInstance = modalRef.componentInstance;
        modalInstance.contractType = this.licenseType;

        modalRef.result.then((template: any) => {
            if (template) {
                this.contractTemplate = template;
                this.setup.enrollmentCode = template.registrationCode;
                this.setup.licenseFee =
                        template.contractTemplateFees.DOCUMENT_BUILDER_LICENSE_FEE.contractFeeTiers[0].feeAmount;
                this.setup.renewalFee =
                        template.contractTemplateFees.DOCUMENT_BUILDER_RENEWAL_FEE.contractFeeTiers[0].feeAmount;
                this.setup.contractFees.DOCUMENT_BUILDER_DOCUMENT_FEE =
                        template.contractTemplateFees.DOCUMENT_BUILDER_DOCUMENT_FEE;
                this.setup.freeSubmissions = template.freeSubmissions;

                this.enrollmentCodeName = template.templateName;
                if (template.billingCycleType) {
                    this.selectedBillingCycle = this.selectableBillingCycles.find((cycle) => {
                        return cycle.id == template.billingCycleType;
                    });
                    this.setup.billingCycle = this.selectedBillingCycle.id;
                } else {
                    //default to DAILY
                    this.selectedBillingCycle = this.selectableBillingCycles[0];
                    this.setup.billingCycle = this.selectedBillingCycle.id;
                }
                this.displayableBillingCycle = this.selectedBillingCycle.label;

                if (template.paymentTerms) {
                    this.selectedPaymentTerm = this.selectablePaymentTerms.find((term) => {
                        return term.id == template.paymentTerms;
                    });
                    this.setup.paymentTerms = this.selectedPaymentTerm.id;
                } else {
                    //default to NET1
                    this.selectedPaymentTerm = this.selectablePaymentTerms[0];
                    this.setup.paymentTerms = this.selectedPaymentTerm.id;
                }
                this.displayablePaymentTerms = this.selectedPaymentTerm.label;
            }
        }, () => {
            // error
        });
    }

    updateContractFee(updatedContractFee: any) {
        this.setup.contractFees.DOCUMENT_BUILDER_DOCUMENT_FEE = updatedContractFee;
    }

    removeTiers() {
        this.showPricingTiers = false;
    }

    showLicenseDocuments() {
        this.licenseService.showLicenseDocuments(this.router, this.orgID);
    }

    goToSalesforce() {
        this.licenseService.goToSalesforce(this.opportunityID);
    }
}
