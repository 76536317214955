import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from "@angular/core";
import {
    GrowlService,
    SelectableItem,
    SfValidators,
    SortUtilitiesService
} from "@sf/common";
import { dayjs } from "@sf/common";
import { SessionService } from "@sf/common";
import { ActivityLogEntry } from "../activity-log-entry/activity-log-entry.component";
import {
    ActivityLogService,
    ActivitySubscriptionService
} from "@sf/userorg/common";
import { Subject, Subscription } from "rxjs";
import { sampleTime, takeUntil } from "rxjs/operators";

interface SelectedType {
    timeOption: SelectableItem;
    before: dayjs.Dayjs;
    after: dayjs.Dayjs;
    typeOptions: string[]; // just the string identifiers
}

interface ActivityLogType {
    id: string;
    label: string;
    entityTypes: string[];
}

interface UpdateMessage {
    data: any;
    method: string;
    path: string;
    type: string;
}

// prettier-ignore
@Component({
    selector: "sf-activity-log",
    templateUrl: "./activity-log.component.html",
    styleUrls: ["./activity-log.component.scss"]
})
export class ActivityLogComponent implements OnInit, OnChanges, OnDestroy {
    @Input() entityType: string;        // "organization" or "user" (or blank)
    @Input() objectKey: string;         // org ID or user ID
    @Input() initialTimeOption: string; // any of the 'option' values from _buildTimeOptions function
    @Input() initialTypeOptions: string[]; // any of the activity log type options

    /** Private Variables **/
    private _onDestroy$: Subject<void> = new Subject<void>();
    updater: Subscription = null;
    searchDelay: number = null;
    filterDelay: number = null;
    canToggleSearch = false;    // feature disabled
    searchNotFilter = false;

    /** Public Variables **/
    timeOptions: SelectableItem[] = null;
    loaded = false;
    afterHour: number| string = null;
    afterMinute: number | string = null;
    beforeHour: number | string = null;
    beforeMinute: number | string = null;
    logs: ActivityLogEntry[] = [];
    filteredLogs: ActivityLogEntry[] = [];
    selected: SelectedType = {
        timeOption: null,
        before: null,
        after: null,
        typeOptions: null
    };
    filterQuery = {
        text: ""
    };
    searchQuery = {
        text: ""
    };
    performedBy = {
        text: <string>null
    };
    environment = "";
    typeOptions: SelectableItem[] = null;
    selectedLive = 0;

    constructor(
            private sessionService: SessionService,
            private activityLogService: ActivityLogService,
            private growlService: GrowlService,
            private activitySubscriptionService: ActivitySubscriptionService
    ) {}

    ngOnInit() {
        if (this.objectKey) {
            this.searchQuery.text = this.objectKey;
            this.canToggleSearch = false;
        }

        this.timeOptions = this._buildTimeOptions();

        if (this.initialTimeOption) {
            this.selected.timeOption = this.timeOptions.find((item) => {
                return item.option == this.initialTimeOption;
            });
        } else {
            this.selected.timeOption = this.timeOptions[0];
        }
        if (this.initialTypeOptions) {
            this.selected.typeOptions = this.initialTypeOptions;
        }

        this.computeTimeFromOption(this.selected.timeOption);

        this.activityLogService.getActivityTypes()
            .subscribe((types: ActivityLogType[]) => {
                types = this.filterEventTypes(types);
                this.typeOptions = types.map((type) => {
                    return {
                        option: type.id,
                        label: type.label
                    };
                });
                this.typeOptions = this.typeOptions.sort((a, b) => {
                    return SortUtilitiesService.stringSortCompareInsensitive(a.label, b.label);
                });
            });

        this.environment = this.sessionService.getEnv();

        this._scheduleLogRequest();
        this.subscribeToChanges();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.objectKey && (changes.objectKey.currentValue != changes.objectKey.previousValue)) {
            this.canToggleSearch = false;
            this._scheduleLogRequest();
            this.subscribeToChanges();
        }
    }

    ngOnDestroy(): void {
        if (this.searchDelay) {
            clearTimeout(this.searchDelay);
        }
        if (this.filterDelay) {
            clearTimeout(this.filterDelay);
        }
        this._onDestroy$.next();
    }

    subscribeToChanges() {
        if (this.updater) {
            // new user or organization
            this.updater.unsubscribe();
        }
        this.updater = this.activitySubscriptionService.subscribeToUpdates(this.objectKey)
           .pipe(
               takeUntil(this._onDestroy$),
               sampleTime(2000)
           )
           .subscribe((msg: UpdateMessage) => {
               if (this.selectedLive) {
                   if (!this.objectKey || !msg.data || (msg.data.id == this.objectKey)) {
                       this._scheduleLogRequest();
                   }
               }
           });
    }

    selectLive() {
        this._scheduleLogRequest();
    }

    filterEventTypes(entityTypes: ActivityLogType[]): ActivityLogType[] {
        if (!this.entityType) {
            return entityTypes;
        }
        let filteredEventTypes: ActivityLogType[] = [];
        entityTypes.forEach((option) => {
            option.entityTypes.forEach((type) => {
                if (type == this.entityType) {
                    filteredEventTypes.push(option);
                }
            });
        });
        return filteredEventTypes;
    }

    computeTimeFromOption(item: SelectableItem) {
        let afterDayjs: dayjs.Dayjs = null;
        switch (item.option) {
            case "tenminutesago":
                afterDayjs = dayjs().subtract(10, "minutes");
                break;
            case "hourago":
                afterDayjs = dayjs().subtract(1, "hours");
                break;
            case "dayago":
                afterDayjs = dayjs().subtract(1, "days");
                break;
            case "weekago":
                afterDayjs = dayjs().subtract(1, "weeks");
                break;
            case "monthago":
                afterDayjs = dayjs().subtract(1, "months");
                break;
            case "alltime":
                afterDayjs = dayjs().subtract(30, "years");
                break;
            default:
                afterDayjs = dayjs();
                break;
        }

        this.selected.after = afterDayjs;
        this.selected.before = null;    // dayjs();

        this.afterHour = afterDayjs.get("hour");
        this.afterMinute = afterDayjs.get("minute");
        this.afterHour = this.prependZero(this.afterHour);
        this.afterMinute = this.prependZero(this.afterMinute);
    }

    prependZero(num: number | string): string {
        if (num === null || typeof num == 'undefined') {
            return null;
        }
        if (Number(num) < 10) {
            num = "0" + Number(num);
        }
        return <string> num;
    }

    _buildTimeOptions(): SelectableItem[] {
        return [
            { label: "Past 10 Minutes", option: "tenminutesago" },
            { label: "Past Hour", option: "hourago" },
            { label: "Past Day", option: "dayago" },
            { label: "Past Week", option: "weekago" },
            { label: "Past Month", option: "monthago" },
            { label: "All Time", option: "alltime" },
            { label: "Custom Date Range", option: "CUSTOM" }
        ];
    }

    // called when user changes selection for Log Type
    typeChanged(event: any) {
        if (event && event.hasOwnProperty("$selection")) {
            if (event.$isSelectionChanged) {
                let selection: SelectableItem[] = event.$selection;
                this.selected.typeOptions = [];
                if (selection) {
                    selection.forEach((opt) => {
                        this.selected.typeOptions.push(opt.option);
                    });
                }

                this._scheduleLogRequest();
            }
        }
    }

    // called when user changes selection for the date range
    timeRangeChanged(event: any) {
        if (event && event.hasOwnProperty("$selection")) {
            let selection: SelectableItem = event.$selection;
            this.selected.timeOption = selection;
            if (selection) {
                if (selection.option != "CUSTOM") {
                    this.computeTimeFromOption(selection);
                    this._scheduleLogRequest();
                } else {
                    if (this.selected.before == null || typeof this.selected.before == "undefined") {
                        let now = dayjs();
                        this.selected.before = now;
                        this.beforeHour = now.get("hour");
                        this.beforeMinute = now.get("minute");
                        this.beforeHour = this.prependZero(this.beforeHour);
                        this.beforeMinute = this.prependZero(this.beforeMinute);
                    }
                }
            }
        }
    }

    /*
     * We have to do this because the date picker seems to want to give us
     * Universal time instead of Local time.
     * If they fix that bug, then this should still work.
     */
    ensureLocalDate(date: dayjs.Dayjs): dayjs.Dayjs {
        if (!date) {
            return null;
        }
        let month = date.month();
        let day = date.date();
        let hour = date.hour();
        date = date.local().month(month).date(day).hour(hour);
        return date;
    }

    // called when user changes selection for After Date
    customAfterDateChanged(selectedDate: dayjs.Dayjs) {
        this.selected.after = this.ensureLocalDate(selectedDate);
        this._scheduleLogRequest();
    }

    // called when user changes selection for Before Date
    customBeforeDateChanged(selectedDate: dayjs.Dayjs) {
        this.selected.before = this.ensureLocalDate(selectedDate);
        this._scheduleLogRequest();
    }

    // called when user changes selection for Time
    customTimeChanged() {
        if (this.afterHour != null && typeof this.afterHour != "undefined") {
            if (!SfValidators.isPositiveIntegerString(this.afterHour)) {
                this.afterHour = "0";
                this.growlService.error("Please enter a valid hour between 0 and 23");
                return;
            }
            if (Number(this.afterHour) > 23) {
                this.afterHour = "0";
                this.growlService.error("Please enter a valid hour between 0 and 23");
            }
        }
        if (this.beforeHour != null && typeof this.beforeHour != "undefined") {
            if (!SfValidators.isPositiveIntegerString(this.beforeHour)) {
                this.beforeHour = "0";
                this.growlService.error("Please enter a valid hour between 0 and 23");
                return;
            }
            if (Number(this.beforeHour) > 23) {
                this.beforeHour = "0";
                this.growlService.error("Please enter a valid hour between 0 and 23");
            }
        }
        if (this.afterMinute != null && typeof this.afterMinute != "undefined") {
            if (!SfValidators.isPositiveIntegerString(this.afterMinute)) {
                this.afterMinute = "0";
                this.growlService.error("Please enter a valid minute between 0 and 59");
                return;
            }
            if (Number(this.afterMinute) > 59) {
                this.afterMinute = "0";
                this.growlService.error("Please enter a valid minute between 0 and 59");
            }
        }
        if (this.beforeMinute != null && typeof this.beforeMinute != "undefined") {
            if (!SfValidators.isPositiveIntegerString(this.beforeMinute)) {
                this.beforeMinute = "0";
                this.growlService.error("Please enter a valid minute between 0 and 59");
                return;
            }
            if (Number(this.beforeMinute) > 59) {
                this.beforeMinute = "0";
                this.growlService.error("Please enter a valid minute between 0 and 59");
            }
        }

        this.afterHour = this.prependZero(this.afterHour);
        this.afterMinute = this.prependZero(this.afterMinute);
        this.beforeHour = this.prependZero(this.beforeHour);
        this.beforeMinute = this.prependZero(this.beforeMinute);

        this._scheduleLogRequest();
    }

    toggleFilterSearch() {
        this.searchNotFilter = !this.searchNotFilter;
        if (this.searchNotFilter) {
            this.searchQuery.text = this.filterQuery.text;
            this.filterQuery.text = "";
            this.objectKey = this.searchQuery.text;
            if (this.updater) {
                // stop with 'live' option
                this.updater.unsubscribe();
                this.updater = null;
            }
        } else {
            this.filterQuery.text = this.searchQuery.text;
            this.searchQuery.text = "";
            this.objectKey = null;
        }
        this._scheduleLogRequest();
    }

    clearSearch() {
        this.searchQuery.text = "";
        this.doSearchSearch();
    }

    doSearchFilter() {
        if (this.filterDelay) {
            clearTimeout(this.filterDelay);
            // kill active scheduled filter
        }
        this.filteredLogs = [];
        this.filterDelay = window.setTimeout(() => {
            this.filterDelay = null;
            this.loaded = false;
            this.doFilter();
        }, 300);
    }

    clearFilter() {
        this.filterQuery.text = "";
        this.doSearchFilter();
    }

    doFilter() {
        this.filteredLogs = [];

        if (this.logs && this.logs.length) {
            let filterText: string = null;
            if (this.filterQuery.text) {
                filterText = (this.filterQuery.text as string).toLowerCase();
            }

            for (let i = 0; i < this.logs.length; i++) {
                let logEntry: any = this.logs[i];
                if (this.filterByKeys(logEntry, filterText)) {
                    this.filteredLogs.push(logEntry);
                }
            }
        }

        this.loaded = true;
    }

    filterByKeys(logEntry: any, queryText: string): boolean {
        if (!queryText) {
            // if no query text, then it passes
            return true;
        }
        let keys = Object.keys(logEntry);
        let matched: boolean = keys.some((key) => {
            let thisValue = logEntry[key];
            if (thisValue && typeof thisValue == "string") {
                return thisValue.toLowerCase().includes(queryText);
            }
            if (typeof thisValue == "object") {
                return this.filterByKeys(thisValue, queryText);
            }
            return false;
        });
        return matched;
    }

    doSearchSearch() {
        this.objectKey = this.searchQuery.text;
        this._scheduleLogRequest();
    }

    clearPerformedByFilter() {
        this.performedBy.text = "";
        this.doSearchSearch();
    }

    _scheduleLogRequest() {
        if (this.searchDelay) {
            clearTimeout(this.searchDelay);
            // kill active scheduled search
        }
        this.searchDelay = window.setTimeout(() => {
            this.searchDelay = null;
            this.loaded = false;
            this._requestLogs();
        }, 350);
    }

    _requestLogs() {
        if (this.selected.timeOption.option == "CUSTOM") {
            let hour: number;
            let minute: number;

            if (this.selected.after) {
                hour = Number(this.afterHour);
                minute = Number(this.afterMinute);
                this.selected.after = this.selected.after.set("hour", hour);
                this.selected.after = this.selected.after.set("minute", minute);
            }
            if (this.selected.before) {
                hour = Number(this.beforeHour);
                minute = Number(this.beforeMinute);
                this.selected.before = this.selected.before.set("hour", hour);
                this.selected.before = this.selected.before.set("minute", minute);
            }
        }

        let afterParam = this.selected.after;
        let beforeParam = this.selected.before;
        if (beforeParam == null || typeof beforeParam == "undefined") {
            beforeParam = dayjs();
        }

        let afterMillis = this._extractDate(afterParam);
        let beforeMillis = this._extractDate(beforeParam);

        let options = {
            idQuery: this.objectKey ? this.objectKey : "",
            performedByQuery: this.performedBy.text ? this.performedBy.text : "",
            after: afterMillis,
            before: beforeMillis,
            types: this.selected.typeOptions
        };

        // starting search
        this.activityLogService.getLogs(options).subscribe((logs: any) => {
            this._refreshLogs(logs);
        });
    }

    _refreshLogs(results: any) {
        if (results.length > 1000) {
            results = results.slice(0, 1000);
        }
        results.forEach((log: any) => {
            if (log.date) {
                let dayjsus = dayjs(log.date);
                let formattedDate = dayjsus.format("MMM D, YYYY [at] h:mm a");
                log.date = formattedDate;
            }

            if (log.details && log.details.comment) {
                log.details.comment = this.cleanHtml(log.details.comment);
                // convert newlines to line breaks
                log.details.comment = log.details.comment.replace(
                    /(?:\r\n|\r|\n)/g,
                    "<br/>"
                );
            }

            log.taggedEntities.forEach((tag: any) => {
                if (!tag.type) {
                    tag.type = "Unknown ID";
                } else if (tag.type == "TOKEN") {
                    tag.label = "Token";
                } else {
                    tag.label = tag.id;
                }
            });
        });
        this.logs = results;
        this.doFilter();
        this.loaded = true;
    }

    cleanHtml(before: string): string {
        let after: string = before;
        // these have bitten us
        after = after.replace(/<h1>/g, "&lt;h1&gt;");
        after = after.replace(/<\/h1>/g, "&lt;/h1&gt;");
        after = after.replace(/<h2>/g, "&lt;h2&gt;");
        after = after.replace(/<\/h2>/g, "&lt;/h2&gt;");
        after = after.replace(/<h3>/g, "&lt;h3&gt;");
        after = after.replace(/<\/h3>/g, "&lt;/h3&gt;");
        after = after.replace(/<i>/g, "&lt;i&gt;");
        after = after.replace(/<\/i>/g, "&lt;/i&gt;");
        return after;
    }

    _extractDate(datetime: dayjs.Dayjs): number {
        let timestamp: number = datetime.valueOf();
        return timestamp;
    }

    adjustColumnWidths() {

    }
}
