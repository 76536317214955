<div class="sf-unmask">
    <sf-modal-header title="Move Documents"></sf-modal-header>
    <form [formGroup]="moveForm" class="modal-body">
        <div>
            <div>Destination</div>
            <sf-select [options]="locations" labelBy="name" trackBy="id" [isSingleSelect]="true" formControlName="location"
                       placeholder="Select location..." [hasFilter]="true" [withFooter]="showFooter" disableBy="disabled" titleBy="title"
                       (footerActionLeft)="manageFolders()" [footerActionLeftIcon]="['far', 'folder-open']" footerActionLeftLabel=" Manage Folders">
            </sf-select>
            <br/>
            <div>Comments</div>
            <textarea formControlName="comment" class="form-control sf-mask" [style.resize]="'none'" placeholder="Provide a reason for moving document"></textarea>
            <div *ngIf="inRONEvent" class="mt-2">Signatures will be removed from any documents that have been signed.</div>
        </div>
    </form>
    <sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>
</div>
