import { Component, Input, OnInit } from "@angular/core";
import { ModalButton } from "@sf/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "sf-submitter-banking-export-copy-from-dialog",
    templateUrl: "./submitter-banking-export-copy-from-dialog.component.html",
    styleUrls: ["./submitter-banking-export-copy-from-dialog.component.scss"]
})
export class SubmitterBankingExportCopyFromDialogComponent implements OnInit {
    @Input()
    orgID: string;

    title: string;
    primary: ModalButton;
    secondary: ModalButton;
    selectedOrg: string;
    submitterOrgServices = ["submitter"];

    constructor(private _activeModal: NgbActiveModal) {}

    ngOnInit(): void {
        this.title = "Copy Settings from Organization";
        this.primary = {
            text: "Copy",
            disabled: true,
            callback: this.copy.bind(this)
        };
        this.secondary = {
            text: "Cancel",
            callback: this.cancel.bind(this)
        };
    }

    copy() {
        this._activeModal.close(this.selectedOrg);
    }

    cancel() {
        this._activeModal.close();
    }

    selectOrg(selectedOrg: string) {
        this.selectedOrg = selectedOrg;
        this.primary.disabled = selectedOrg === this.orgID;
    }
}
