import { Component, Input, OnInit, Optional } from "@angular/core";
import { ModalButton } from "@sf/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ArchivedUserDetails } from "../../components/activity-log-entry/activity-log-entry.component";

@Component({
    selector: "sf-archived-user-dialog",
    templateUrl: "./archived-user-dialog.component.html",
    styleUrls: ["./archived-user-dialog.component.scss"]
})
export class ArchivedUserDialogComponent implements OnInit {
    @Input()
    userId: string;
    @Input()
    archivedUserDetails: ArchivedUserDetails[];
    @Input()
    title: string;

    /** Public Variables **/
    queryText = "";
    primary: ModalButton;

    constructor(private activeModal: NgbActiveModal) {}

    ngOnInit() {
        // Modal buttons
        this.primary = {
            text: "Close",
            disabled: false,
            callback: this.abort.bind(this)
        };
    }

    goToOrganization(orgID: string) {
        this.abort();
        let newUrl = "/sf/ui/admin/organization/" + orgID + "/config/users";
        window.open(newUrl, "_blank");
    }

    goToRoleWithOrg(id: string, orgID: string) {
        this.abort();
        let newUrl = "/sf/ui/admin/organization/" + orgID + "/roles/" + id;
        window.open(newUrl, "_blank");
    }

    abort() {
        this.activeModal.close(false);
    }
}
