import {
    AfterViewInit,
    ApplicationRef,
    ComponentFactory,
    ComponentFactoryResolver,
    Directive,
    ElementRef,
    EventEmitter,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2
} from "@angular/core";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { Plugins, Sortable, SortableSortedEvent } from "@shopify/draggable";
import { faBars } from "@fortawesome/pro-solid-svg-icons";
import { cloneDeep } from "@sf/common";

@Directive({
    selector: "table[sfSortableTable]"
})
export class SortableTableDirective
    implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
    @Input("sfSortableTable") model: any[];
    @Output() sfSortableTableChange: EventEmitter<any> = new EventEmitter();
    @Output() sortEvent: EventEmitter<any> = new EventEmitter();

    private _factory: ComponentFactory<FaIconComponent>;
    private _clonedArray: any[] = null;
    private _changes: MutationObserver;

    constructor(
        private _el: ElementRef,
        private _componentFactoryResolver: ComponentFactoryResolver,
        private _injector: Injector,
        private _app: ApplicationRef,
        private _renderer: Renderer2
    ) {}

    ngOnInit() {
        this._factory =
            this._componentFactoryResolver.resolveComponentFactory(
                FaIconComponent
            );

        this._changes = new MutationObserver(() => {
            let rows = this._el.nativeElement.querySelectorAll("tbody tr");
            for (let row of rows) {
                this._createNewDragHandle(row);
            }
        });

        this._changes.observe(this._el.nativeElement.querySelector("tbody"), {
            childList: true
        });
    }

    ngOnChanges() {
        this._clonedArray = cloneDeep(this.model);
    }

    ngOnDestroy() {
        this._changes.disconnect();
    }

    ngAfterViewInit() {
        this._renderer.addClass(this._el.nativeElement, "sf-sortable-table");

        const sortable = new Sortable(
            this._el.nativeElement.querySelector("tbody"),
            {
                draggable: "tr:not(.disable-drag)",
                handle: ".drag-handle",
                sortAnimation: {
                    duration: 200,
                    easingFunction: "ease-in-out"
                },
                plugins: [Plugins.SortAnimation],
                mirror: {
                    constrainDimensions: true,
                    xAxis: false
                }
            }
        );

        sortable.on("sortable:stop", (sortedEvent: SortableSortedEvent) => {
            if (this.model) {
                this._clonedArray = cloneDeep(this.model);
                let row = this._clonedArray[sortedEvent.oldIndex];
                this._clonedArray.splice(sortedEvent.oldIndex, 1);
                this._clonedArray.splice(sortedEvent.newIndex, 0, row);
            }

            this.sfSortableTableChange.emit(this._clonedArray);

            this.sortEvent.emit({
                array: this._clonedArray,
                old: sortedEvent.oldIndex,
                new: sortedEvent.newIndex
            });
        });

        let headerRow = this._el.nativeElement.querySelector("thead tr");
        let gap = document.createElement("th");
        gap.innerText = " ";
        headerRow?.prepend(gap);
    }

    private _createNewDragHandle(row: HTMLElement) {
        if (!row.querySelector(".drag-handle")) {
            let dragCol = document.createElement("td");
            dragCol.style.cursor = "grab";
            dragCol.style.width = "35px";
            row?.prepend(dragCol);

            const ref = this._factory.create(this._injector, [], dragCol);
            ref.instance.icon = faBars;
            ref.instance.render();
            this._app.attachView(ref.hostView);
            dragCol.classList.add("drag-handle");
        }
    }
}
