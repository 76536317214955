import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class TooltipConfig {
    autoClose: boolean = true;
    closeDelay: number = 0;
    disableTooltip: boolean = false;
    openDelay: number = 0;
    placement: Placement = "auto";
    triggers: string = "hover focus";
    container: "body" | "" | null;
}
export const placementNames = [
    "auto",
    "top",
    "bottom",
    "start",
    "left",
    "end",
    "right",
    "top-start",
    "top-left",
    "top-end",
    "top-right",
    "bottom-start",
    "bottom-left",
    "bottom-end",
    "bottom-right",
    "start-top",
    "left-top",
    "start-bottom",
    "left-bottom",
    "end-top",
    "right-top",
    "end-bottom",
    "right-bottom"
] as const;
export type Placement = (typeof placementNames)[number];

export const autoReplacement = [
    "top",
    "bottom",
    "start",
    "end",
    "top-start",
    "top-end",
    "bottom-start",
    "bottom-end",
    "start-top",
    "start-bottom",
    "end-top",
    "end-bottom"
] as const;

export const TriggerAliases: any = {
    hover: ["mouseenter", "mouseleave"],
    focus: ["focusin", "focusout"]
};
