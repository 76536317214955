<sf-modal-header title="Invitation Token Details"></sf-modal-header>
<div class="modal-body">
    <div class="table-holder">
        <table class="table">
            <tbody>
            <tr *ngFor="let attribute of details">
                <td>{{attribute.id}}</td>
                <td>{{attribute.value}}</td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>