<sf-modal-header [title]="title" [helpLink]="helpLink"></sf-modal-header>
<div class="modal-body">
    <ul class="column-list-box" cdkDropList (cdkDropListDropped)="drop($event)">
        <ng-container
            *ngFor="let column of allColumns; trackBy: 'id' | trackByProperty"
        >
            <li
                *ngIf="column.selected"
                class="column-row"
                cdkDrag
                cdkDragBoundary=".column-list-box"
            >
                <fa-icon
                    class="reorder-indicator"
                    [icon]="['far', 'arrows-alt-v']"
                ></fa-icon>
                <input
                    type="checkbox"
                    [(ngModel)]="column.selected"
                    (change)="toggleColumn(column)"
                    [name]="'column-draggable-' + column.id"
                    id="column-draggable-{{ column.id }}"
                />
                <label
                    class="reorder-indicator"
                    for="column-draggable-{{ column.id }}"
                >
                    {{ column.uiLabel }}
                    <span *ngIf="column.groupBy">(grouped)</span>
                </label>
                <fa-icon
                    class="info"
                    [icon]="['far', 'info-circle']"
                    [ngbTooltip]="column.toolTip"
                    placement="right"
                ></fa-icon>
                <span class="filler"></span>
                <input
                    class="form-control input-wide"
                    type="text"
                    *ngIf="column.selected"
                    [(ngModel)]="column.label"
                    [disabled]="!column.labelEditable"
                    (change)="validateLabel(column)"
                />
            </li>
            <li *ngIf="!column.selected" class="column-row not-sortable">
                <span class="icon-filler"></span>
                <input
                    type="checkbox"
                    [(ngModel)]="column.selected"
                    (change)="toggleColumn(column)"
                    [name]="'column-' + column.id"
                    id="column-{{ column.id }}"
                />
                <label for="column-{{ column.id }}">{{ column.uiLabel }}</label>
                <fa-icon
                    class="info"
                    [icon]="['far', 'info-circle']"
                    [ngbTooltip]="column.toolTip"
                    placement="right"
                ></fa-icon>
            </li>
        </ng-container>
    </ul>
</div>
<sf-modal-footer
    [primary]="primary"
    [secondary]="secondary"
    [tertiary]="tertiary"
    [quaternary]="quaternary"
    [quinary]="quinary"
></sf-modal-footer>
