import { Injectable, Injector } from "@angular/core";
import { concat, Observable, of } from "rxjs";
import { Router } from "@angular/router";
import {
    Auth,
    AuthService,
    dayjs,
    ExternalPageService,
    ICONS,
    LiveConfigService,
    MetricLoggerService,
    PersistedMemoryService,
    RpcClientService,
    SessionService,
    Socket2Service,
    SocketService
} from "@sf/common";
import { filter, switchMap, take } from "rxjs/operators";
import { FaConfig, FaIconLibrary } from "@fortawesome/angular-fontawesome";
import { SIGN_EVENT_STORAGE_KEY, SignEventStorage } from "@sf/tagging-shared";
import { environment } from "../../environments/environment";
import { SupportBannerService } from "@sf/admin/common";
import log from "loglevel";
import * as bowser from "bowser";
import { faBan, IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { LicenseManager } from "@ag-grid-enterprise/core";

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class AppBootstrapService {
    private _isAuthenticated = false;
    private authenticatedAtLeastOnce = false;
    //private intervalID: ReturnType<typeof setInterval> | null = null;

    constructor(
            private _router: Router,
            private _metricLogger: MetricLoggerService,
            private _rpcClient: RpcClientService,
            private _externalPageService: ExternalPageService,
            private _auth: AuthService,
            private _socket: SocketService,
            private _socket2: Socket2Service,
            private _persistedMemoryService: PersistedMemoryService,
            private _sessionService: SessionService,
            private _iconLibrary: FaIconLibrary,
            private _iconConfig: FaConfig,
            private _liveConfigService: LiveConfigService,
            private _injector: Injector
    ) {
        this._iconConfig.defaultPrefix = "far";
        this._iconConfig.fallbackIcon = faBan;
        this._iconLibrary.addIcons(...Object.values(ICONS));
    }

    bootstrap(auth: Auth): Observable<boolean> {
        this._isAuthenticated = !!auth.data.authenticated;
        return this.observeIfLoggedIn("BOOTSTRAP").pipe(
                switchMap(() => {
                    this._setGlobals();
                    this.setSessionGlobals(auth);

                    ModuleRegistry.registerModules([
                        ClientSideRowModelModule,
                        ClipboardModule,
                        RangeSelectionModule,
                        RowGroupingModule,
                        SetFilterModule
                    ]);

                    LicenseManager.setLicenseKey("Using_this_AG_Grid_Enterprise_key_( AG-049184 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Intercontinental Exchange, Inc. )_is_granted_a_( Multiple Applications )_Developer_License_for_( 106 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_( 21 )_Production_Environments___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 16 October 2024 )____[v2]_MTcyOTAzMzIwMDAwMA==5e7b99009226ba315c30912e3a6749a7");

                    if (this._liveConfigService.get("FalconSettings.enableFullStory")) {
                        this.initFullStory();
                    }

                    if (this._liveConfigService.get("FalconSettings.enableReadableGridHeaders")) {
                        document.body.classList.add("enable-readable-grid-headers");
                    }

                    sf.metricLog = this._metricLogger;

                    this._auth.auth.subscribe((authMessage) => {
                        this._isAuthenticated = !!authMessage.data.authenticated;
                        this.observeIfLoggedIn("SOCKET");
                        // check for session change
                        if (authMessage.session) {
                            let currentSessionID = this._sessionService.getSessionID();
                            if (currentSessionID && authMessage.session.sessionId != currentSessionID) {
                                this.setSessionGlobals(authMessage);
                            }
                        }
                    });

                    return of(true);
                })
        );
    }

    bootstrapAfterLogin(auth: Auth): Observable<boolean> {
        this._isAuthenticated = !!auth.data.authenticated;
        return this.observeIfLoggedIn("BOOTAFTER").pipe(switchMap(() => {
            this._setGlobals();
            this.setSessionGlobals(auth);

            return of(true);
        }));
    }

    authenticate(): Observable<Auth> {
        // Impossible to comprehend code here
        return concat(this._socket.makeConnection().pipe(
                filter((value) => value === true),
                take(1)
            ),
            this._auth.auth
        ).pipe(
            filter((event) => typeof event !== "boolean"),
            take(1)
        ) as Observable<Auth>;
    }

    setSessionGlobals(auth: Auth) {
        if (auth.session) {
            sf.session = auth.session;
            this._sessionService.setSession(auth.session);
            sf.liveConfig = auth.liveConfig;
            sf.falconSettings = auth.falconSettings;
            if (this._sessionService.isSuperUser() || this._sessionService.isLoggedInAs()) {
                this._injector.get(SupportBannerService);
            }
        } else {
            sf.session = null;
            sf.liveConfig = {
                FalconSettings: {}
            };
            sf.falconSettings = {};
            this.authenticatedAtLeastOnce = false;
        }
    }

    isAuthenticated(): boolean {
        return this._isAuthenticated;
    }

    initFullStory(): void {
        if (require.resolve("@fullstory/browser")) {
            import("@fullstory/browser").then((FullStory) => {
                if (!FullStory.isInitialized()) {
                    FullStory.init({
                        orgId: "13NS5P",
                        devMode: !environment.production
                    });
                }
            });
        }
    }

    private _setGlobals(): void {
        const w = window as any;
        w.sf = {
            ...w.sf
        };

        sf.rpcClient = this._rpcClient;
        sf.router = this._router;
        w.dayjs = dayjs;
        w.socket2 = this._socket2;
        w.log = log;
        w.bowser = bowser;
    }

    private observeIfLoggedIn(context: string): Observable<boolean> {
        return of(this.checkIfLoggedIn(context));
    }

    private checkIfLoggedIn(context: string): boolean {
        if (this._isAuthenticated) {
            // user is logged in
            this.authenticatedAtLeastOnce = true;
            return true;
        }

        let loginPageBase = "/sf/ui/login";
        let nextUrl = "";
        const location = window.location;
        const pathname = location.pathname;

        if (this._externalPageService.isCurrentPageExternal()) {
            return true;    // not logged in, but ok
        }

        if (location.search === "?restricted=true" || location.search?.includes("isepc")) {
            // LS-12873 viewing the app in encompass  don't redirect with a way to log back in.
            nextUrl = `/sf/up/login?err=restrictedExpired&app=Encompass${location.hash ?? ""}`;
        } else if (pathname.includes("sign-event")) {
            if (pathname.includes("closing")) {
                window.opener?.postMessage({ message: "CLOSE_PAGE" }, window.origin);
                nextUrl = this._getESignEventRedirectUrl();
            } else if (pathname.includes("signing")) {
                window.close();
                // window.close doesn't work on iOS
                if (!window.closed) {
                    window.open(window.location.href, "_self").close();
                }
                return false;
            }
        }
        if (!nextUrl) {
            // send them to our login page
            let redirect = encodeURIComponent(
                    `${location.pathname.replace(/sf\//, "")}${location.search ?? ""}${location.hash ?? ""}`);
            nextUrl = `${loginPageBase}?fr=${redirect}`;
            let onLoginPageAlready = false;
            if (location && location.pathname) {
                if (location.pathname.indexOf("/login") > -1) {
                    onLoginPageAlready = true;
                }
            }
            if (!onLoginPageAlready && this.authenticatedAtLeastOnce) {
                nextUrl += "&err=expired";
                log.warn("Session expired by " + context);
            }
        }

        // if we get here, the user is in our app and not authenticated, so we need to send them somewhere
        // nextUrl should be set, and that's where we send them
        // unless they are already there
        if (!location.pathname || location.pathname.indexOf(nextUrl) < 0) {
            log.warn("app bootstrap redirect to: " + nextUrl + " from: " + location.pathname);
            location.assign(nextUrl);
        }
        return false;
    }

    private _getESignEventRedirectUrl(): string {
        const eventStorage: SignEventStorage = this._persistedMemoryService.get(SIGN_EVENT_STORAGE_KEY);
        if (eventStorage?.onSessionTimeoutUrl) {
            return eventStorage.onSessionTimeoutUrl;
        } else if (eventStorage?.participantToken) {
            return `/sf/ui/sign-event/external/sign-event-auth/${eventStorage.participantToken}`;
        } else {
            return null;
        }
    }
}
