import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TokenService } from "@sf/userorg/common";
import { ModalButton, SortUtilitiesService } from "@sf/common";
import { SessionService } from "@sf/common";
import { dayjs } from "@sf/common";

interface TokenDetail {
    id: string;
    value: string;
}

// prettier-ignore
@Component({
    selector: "sf-token-details-dialog",
    templateUrl: "./token-details-dialog.component.html",
    styleUrls: ["./token-details-dialog.component.scss"]
})
export class TokenDetailsDialogComponent implements OnInit {
    @Input()
    invitation: any;

    details: TokenDetail[] = [];

    primary: ModalButton;
    secondary: ModalButton = null;

    constructor(
        private activeModal: NgbActiveModal,
        private modalService: NgbModal,
        private tokenService: TokenService,
        private sessionService: SessionService
    ) {}

    ngOnInit() {
        // Modal buttons
        this.primary = {
            text: "Close",
            disabled: false,
            callback: this.close.bind(this)
        };

        if (this.invitation) {
            Object.keys(this.invitation).forEach((key) => {
                // roles: organizationID, organizationName, roleName, roleID
                if (key != "$$hashKey" && key != "date") {
                    let value = this.invitation[key];
                    if (
                        key == "createDate" ||
                        key == "expirationDate" ||
                        key == "verifiedDate"
                    ) {
                        let dayjsus = dayjs(value);
                        value = dayjsus.format("MMM D, YYYY [at] h:mm a");
                    } else if (key == "roles") {
                        let roles: any[] = value;
                        value = "";
                        roles.forEach((role) => {
                            if (value) {
                                value += ", ";
                            }
                            value +=
                                role.roleName + " in " + role.organizationID;
                        });
                    }
                    let attribute = {
                        id: key,
                        value: value
                    };
                    this.details.push(attribute);
                }
            });
            this.details = this.details.sort((a, b) => {
                return SortUtilitiesService.stringSortCompareInsensitive(a.id, b.id);
            });
        } else {
            this.details = [
                {
                    id: "Error:",
                    value: "Cannot get details for this token."
                }
            ];
        }
    }

    close() {
        this.activeModal.close(false);
    }
}
