<sf-modal-header [title]="modalTitle"></sf-modal-header>
<div class="modal-body import-payment-account-dialog-body sf-unmask">
    <div *ngIf="warning" class="d-flex flex-column input-error">
        <span>{{warning}}</span>
    </div>
    <div *ngIf="orgsLoading">
        <fa-icon
            [icon]="['fad', 'spinner']"
            [spin]="true"
            [fixedWidth]="true"
        ></fa-icon
        ><i>Loading organizations...</i>
    </div>
    <div *ngIf="!orgsLoading && !orgList">
        There are no organizations to import accounts from.
    </div>
    <div *ngIf="!orgsLoading && orgList" class="d-flex flex-column">
        <label class="mb-2">Select which organization(s) to import accounts from:</label>
        <sf-select
            id="orgSelector"
            [placeholder]="'Select...'"
            [isSingleSelect]="false"
            [selectedOptions]="selectedOrgIDs"
            [options]="orgList"
            trackBy="option"
            labelBy="label"
            [hasFilter]="true"
            (select)="handleOrgSelection($event)"
        ></sf-select>
        <div *ngIf="accountsLoading && !organizationAccounts">
            <fa-icon
                [icon]="['fad', 'spinner']"
                [spin]="true"
                [fixedWidth]="true"
            ></fa-icon>
            <i>Loading accounts...</i>
        </div>
        <div *ngIf="!accountsLoading && organizationAccounts" class="mt-4 all-accounts">
            <div *ngFor="let info of organizationAccounts" class="org-account">
                <label class="account-title">{{info.org.label}}</label>
                <hr class="hr-custom">
                <div *ngIf="!info.accounts.length" class="account-details">
                    <span>** no payment accounts **</span>
                </div>
                <div *ngIf="info.accounts.length">
                    <div *ngFor="let account of info.accounts; let even = even; let odd = odd" [ngClass]="{odd: odd, even: even}">
                        <div *ngIf="account.accountType != paymentAccountType.FROST_BANK ||
                            (account.accountType == paymentAccountType.FROST_BANK && allowFrostAccounts)" class="d-flex account-details sf-mask">
                            <div>
                                <input type="checkbox" class="me-3" (click)="handleAccountClick($event, account)"
                                        [disabled]="validatingAccount" [attr.aria-label]="'Select account ' + account.accountLabel"/>
                            </div>
                            <div>
                                <label class="account-label">{{account.accountLabel}}</label><br/>
                                <label>{{getAccountTypeDesc(account.paymentAccountType)}}</label><br/>
                                <div *ngIf="account.paymentAccountType == paymentAccountType.BANK" class="account-info">
                                    <label>a{{account.routingNumber}}a</label><br/>
                                    <label>{{account.accountNumber}}c</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<sf-modal-footer
    [primary]="primaryButton"
    [secondary]="secondaryButton"
></sf-modal-footer>
