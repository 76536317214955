import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    SecurityContext,
    ViewChild
} from "@angular/core";
import { Quill, QuillOptionsStatic } from "quill";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { deepMerge } from "../../helpers/object";

export interface CustomizeQuill {
    pickers?: CustomQuillItem[];
    buttons?: CustomQuillItem[];
}

export interface CustomQuillItem {
    name: string;
    label?: string;
    icon?: any;
    getDisplay?: any;
}

/*
    For a good example of customizing the Quill editor, check out:
    ui/sf/libs/lender/admin/src/lib/organization/pages/lender-email-templates/lender-email-templates.component.ts
 */

@Component({
    selector: "sf-quill",
    templateUrl: "./quill.component.html",
    styleUrls: ["./quill.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: QuillComponent,
            multi: true
        }
    ]
})
export class QuillComponent implements AfterViewInit, ControlValueAccessor {
    @Input() custom: CustomizeQuill;
    @Input() options: QuillOptionsStatic;

    onChange: any = () => {};
    onTouched: any = () => {};

    quillInstance: Quill = undefined;

    private _quillContent: any = null;
    private _touched = false;
    private _disabled = false;

    private BASE_CONFIG: Partial<QuillOptionsStatic> = {
        theme: "snow",
        modules: {
            toolbar: {
                handlers: {
                    clear: this.clear.bind(this),
                    redo: this.redo.bind(this),
                    undo: this.undo.bind(this)
                }
            }
        }
    };

    @ViewChild("editor", { static: false }) editorDiv: ElementRef;

    constructor(private _sanitizer: DomSanitizer) {}

    ngAfterViewInit(): void {
        if (!(window as any).Quill) {
            import("quill/dist/quill" as any).then((q: any) => {
                // Install Clear / Redo / Undo icons for Quill editor
                let icons = q.default.import("ui/icons");
                icons[
                    "clear"
                ] = `<svg viewbox="0 0 18 18"> <rect class=ql-stroke height=16 width=16 x=1 y=1></rect> <line class=ql-stroke x1=5 x2=13 y1=5 y2=13></line> <line class=ql-stroke x1=5 x2=13 y1=13 y2=5></line></svg>`;
                icons[
                    "redo"
                ] = `<svg viewbox="0 0 18 18"><polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon><path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path></svg>`;
                icons[
                    "undo"
                ] = `<svg viewbox="0 0 18 18"><polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon><path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path></svg>`;

                this.custom?.buttons?.forEach((item) => {
                    if (item.icon) {
                        icons[item.name] = item.icon;
                    }
                });
                (window as any).Quill = q.default;
                this._create();
            });
        } else {
            let icons = (window as any).Quill.import("ui/icons");
            this.custom?.buttons?.forEach((item) => {
                if (item.icon) {
                    icons[item.name] = item.icon;
                }
            });
            this._create();
        }
    }

    clear() {
        if (this.quillInstance) {
            this.quillInstance.setContents([] as any);
            this.quillInstance.setText("");
        }
    }

    redo() {
        this.quillInstance?.history.redo();
    }

    undo() {
        this.quillInstance?.history.undo();
    }

    writeValue(content: string) {
        this._quillContent = content;
        if (this.quillInstance) {
            this.quillInstance.clipboard.dangerouslyPasteHTML(
                0,
                this._sanitizer.sanitize(
                    SecurityContext.HTML,
                    this._quillContent
                )
            );
        }
    }

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    markAsTouched() {
        if (!this._touched) {
            this.onTouched();
            this._touched = true;
        }
    }

    setDisabledState(disabled: boolean) {
        this._disabled = disabled;
        if (this.quillInstance) {
            this.quillInstance.enable(!disabled);
        }
    }

    private _create() {
        // Support both styles of options.modules.toolbar - simple array, object
        if (Array.isArray(this.options?.modules?.toolbar)) {
            this.options = { ...this.options };
            this.options.modules.toolbar = {
                container: [...this.options.modules.toolbar]
            };
        }
        this.options = deepMerge(this.BASE_CONFIG, this.options);

        this.quillInstance = new (window as any).Quill(
            this.editorDiv.nativeElement,
            this.options
        );
        if (this.custom) {
            this.quillInstance.once("editor-change", () => {
                let root = this.quillInstance.root.parentElement.parentElement;
                this.custom.pickers?.forEach((picker) => {
                    // Update your dropdown with labels
                    let placeholderPickerItems = root.querySelectorAll(
                        `.ql-${picker.name} .ql-picker-item`
                    );
                    placeholderPickerItems.forEach(
                        (item: any) =>
                            (item.textContent = picker.getDisplay(
                                item.dataset.value
                            ))
                    );
                    let htmlLabel = root.querySelector(
                        `.ql-${picker.name} .ql-picker-label`
                    );
                    htmlLabel.innerHTML = `${picker.label}${htmlLabel.innerHTML}`;
                });
                this.custom.buttons?.forEach((item) => {
                    // Update custom button with text label, icon set earlier
                    if (item.label) {
                        let htmlLabel = root.querySelector(
                            `button.ql-${item.name}`
                        );
                        htmlLabel.innerHTML = `${item.label}${htmlLabel.innerHTML}`;
                    }
                });
            });
        }

        if (this._quillContent) {
            this.quillInstance.clipboard.dangerouslyPasteHTML(
                0,
                this._sanitizer.sanitize(
                    SecurityContext.HTML,
                    this._quillContent
                )
            );
        }

        this.quillInstance.enable(!this._disabled);

        this.quillInstance.on("text-change", (delta, oldDelta, source) => {
            this.markAsTouched();
            this._quillContent = this._sanitizer.sanitize(
                SecurityContext.HTML,
                this.quillInstance.root.innerHTML
            );
            this.onChange(this._quillContent);
        });
    }
}
