import { createSelector } from "reselect";
import { hashValue } from "./sf-select.utilities";
import { Injectable } from "@angular/core";
import { SfSelectView, SfSelectItem } from "./sf-select.interface";
import { SfSelectState } from "./sf-select-reducer.service";
import { cloneDeep } from "@sf/common";
import {
    difference,
    groupBy,
    intersection,
    union,
    uniq
} from "../../helpers/array";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

// defining memoized selectors///
// Order matters here. Using the Reselect library to memoize functions to improve performance
let _itemsWithIdSelector = createSelector(
    _getItems,
    _getIdKey,
    _getLabelKey,
    _getGroupIdKey,
    _getBottomMetadataKey,
    _getRightMetadataKey,
    _getDisabledKey,
    _getTitleKey,
    _getGroupTitleKey,
    _addIdAndLabelToItems
);

let _recentlySelectedItemsWithIdSelector = createSelector(
    _getRecentlySelectedItems,
    _getIdKey,
    _getLabelKey,
    _getGroupIdKey,
    _getBottomMetadataKey,
    _getRightMetadataKey,
    _getDisabledKey,
    _getTitleKey,
    _getGroupTitleKey,
    _addIdAndLabelToItems
);

let _itemsWithSelectedSelector = createSelector(
    _itemsWithIdSelector,
    _getSelectedItems,
    _markSelectedItems
);

let _annotatedItemsSelector = createSelector(
    _itemsWithSelectedSelector,
    _getActiveItem,
    _markActiveItem
);

let _filteredItemsNoActiveSelector = createSelector(
    _itemsWithSelectedSelector,
    _getItemFilter,
    _getHandleFilterExternally,
    _getShowOnlySelected,
    _getAdditionalPropertiesToFilter,
    _filterItems
);

let _filteredRecentlySelectedItems = createSelector(
    _recentlySelectedItemsWithIdSelector,
    _getItemFilter,
    _getHandleFilterExternally,
    _getShowOnlySelected,
    _getAdditionalPropertiesToFilter,
    _filterItems
);

let _groupedItemsNoActiveSelector = createSelector(
    _filteredItemsNoActiveSelector,
    _itemsWithSelectedSelector,
    _getIsSingleSelect,
    _groupItems
);

let _groupedItemsSelector = createSelector(
    _groupedItemsNoActiveSelector,
    _getActiveItem,
    _markActiveGroupItems
);

let _visibleGroupedItemsSelector = createSelector(
    _groupedItemsSelector,
    _getLimitDropDownItems,
    _limitItems
);

let _hasLimitedDropdownSelector = createSelector(
    _visibleGroupedItemsSelector,
    _getLimitDropDownItems,
    _hasLimitedDropdown
);

let _getDisplaySelector = createSelector(
    _itemsWithSelectedSelector,
    _getSelectedItems,
    _getDisplay
);

let _getIsSelectAllActiveSelector = createSelector(
    _getActiveItem,
    _getIsSelectAllActive
);

let _getItemIdsSelector = createSelector(_itemsWithIdSelector, _getItemIds);

let _getFilteredItemIdsSelector = createSelector(
    _filteredItemsNoActiveSelector,
    _getItemIds
);

let _getItemIdsWithGroupsSelector = createSelector(
    _itemsWithIdSelector,
    _getItemIdsWithGroups
);

let _getFilteredItemIdsWithGroupsSelector = createSelector(
    _filteredItemsNoActiveSelector,
    _getItemIdsWithGroups
);

let _getOrderedItemIdsSelector = createSelector(
    _getItemIdsSelector,
    _getItemIdsWithGroupsSelector,
    _getIsItemsGrouped,
    _getHasSelectAll,
    _getOrderedItemIds
);

let _getFilteredOrderedItemIdsSelector = createSelector(
    _getFilteredItemIdsSelector,
    _getFilteredItemIdsWithGroupsSelector,
    _getIsItemsGrouped,
    _getHasSelectAll,
    _getOrderedItemIds
);

let _getGroupIdsSelector = createSelector(_itemsWithIdSelector, _getGroupIds);

let _getDisabledIdsSelector = createSelector(
    _itemsWithIdSelector,
    _getDisabledIds
);

let _getDisabledGroupIdsSelector = createSelector(
    _groupedItemsSelector,
    _getDisabledGroupIds
);

let _getFilteredGroupIdsSelector = createSelector(
    _filteredItemsNoActiveSelector,
    _getGroupIds
);

let _getPreviousItemIdSelector = createSelector(
    _getOrderedItemIdsSelector,
    _getFilteredOrderedItemIdsSelector,
    _getActiveItem,
    _getGroupIdsSelector,
    _getIsSingleSelect,
    _getDisabledIdsSelector,
    _getDisabledGroupIdsSelector,
    _getPreviousItemId
);

let _getNextItemIdSelector = createSelector(
    _getOrderedItemIdsSelector,
    _getFilteredOrderedItemIdsSelector,
    _getActiveItem,
    _getGroupIdsSelector,
    _getIsSingleSelect,
    _getDisabledIdsSelector,
    _getDisabledGroupIdsSelector,
    _getNextItemId
);

let _getCurrentSelectionSelector = createSelector(
    _itemsWithSelectedSelector,
    _getCurrentSelection
);

export let _getOutputSelector = createSelector(
    _getCurrentSelectionSelector,
    _getIsSingleSelect,
    _getOutput
);

let _getIsSelectAllCheckedSelector = createSelector(
    _filteredItemsNoActiveSelector,
    _getShowOnlySelected,
    _getIsSelectAllChecked
);

let _getIsValidSelector = createSelector(
    _getSelectedItems,
    _getIsRequired,
    _getItemIdsSelector,
    _getIsValid
);

let _getToggleShowSelectedLabelSelector = createSelector(
    _getShowOnlySelected,
    _getToggleShowSelectedLabel
);

let _getSelectAllLabelSelector = createSelector(
    _getIsFiltered,
    _getShowOnlySelected,
    _getSelectAllLabel
);

let _getShowSelectAllSelector = createSelector(
    _filteredItemsNoActiveSelector,
    _getHasSelectAll,
    _getIsSingleSelect,
    _getShowSelectAll
);

let _getShowNoItemsMessageSelector = createSelector(
    _filteredItemsNoActiveSelector,
    _getShowNoItemsMessage
);

let _getHasSelectionsSelector = createSelector(
    _itemsWithSelectedSelector,
    _getSelectedItems,
    _getHasSelections
);

let _getHashedActiveItemIdSelector = createSelector(
    _getActiveItem,
    _getHashedValue
);

let _getHasBottomMetadataSelector = createSelector(
    _getBottomMetadataKey,
    _getHasBottomMetadata
);

let _getHasRightMetadataSelector = createSelector(
    _getRightMetadataKey,
    _getHasRightMetadata
);

let _getIsSelectAllDisabledSelector = createSelector(
    _filteredItemsNoActiveSelector,
    _getIsSelectAllDisabled
);

let _getHideMainListSelector = createSelector(
    _getShowRecentSelections,
    _getShowMainListWithRecents,
    _filteredRecentlySelectedItems,
    _getHideMainList
);

export let _getViewSelector = (createSelector as any)(
    _visibleGroupedItemsSelector,
    _getDisplaySelector,
    _hasLimitedDropdownSelector,
    _getLimitDropDownItems,
    _getIsItemsGrouped,
    _getIsSelectAllCheckedSelector,
    _getIsSelectAllActiveSelector,
    _getIsSelectAllDisabledSelector,
    _getPreviousItemIdSelector,
    _getActiveItem,
    _getHashedActiveItemIdSelector,
    _getNextItemIdSelector,
    _getIsSingleSelect,
    _getHasSelectAll,
    _getHasFilter,
    _getFilterIcon,
    _getHasFooter,
    _getBindAsHtml,
    _getHasBottomMetadataSelector,
    _getHasRightMetadataSelector,
    _getRightMetadataIsIcon,
    _getShowOnlySelected,
    _getIsOpen,
    _getIsRequired,
    _getIsValidSelector,
    _getisDirty,
    _getIsDisabled,
    _getIsReadonly,
    _getIsFiltered,
    _getToggleShowSelectedLabelSelector,
    _getSelectAllLabelSelector,
    _getShowSelectAllSelector,
    _getShowNoItemsMessageSelector,
    _getHasSelectionsSelector,
    _getShowIconInDisplay,
    _getCurrentSelectionSelector,
    _getItemFilter,
    _getMaxPopupHeight,
    _getShowRecentSelections,
    _filteredRecentlySelectedItems,
    _getHideMainListSelector,
    _getView
);

@Injectable()
export class SfSelectSelectors {
    constructor() {}

    getOutput(state: any) {
        return _getOutputSelector(state as never);
    }

    getView(state: any) {
        return _getViewSelector(state);
    }
}

// --------------------------///

// ---internal selectors---///

function _getItems(state: any) {
    return state.items;
}

function _getSelectedItems(state: any) {
    return state.selectedItems;
}

function _getActiveItem(state: any) {
    return state.activeItem;
}

function _getIdKey(state: any) {
    return state.idKey;
}

function _getGroupIdKey(state: any) {
    return state.groupIdKey;
}

function _getLabelKey(state: any) {
    return state.labelKey;
}

function _getBindAsHtml(state: any) {
    return state.bindAsHtml;
}

function _getBottomMetadataKey(state: any) {
    return state.bottomMetadataKey;
}

function _getRightMetadataKey(state: any) {
    return state.rightMetadataKey;
}

function _getRightMetadataIsIcon(state: any) {
    return state.rightMetadataIsIcon;
}

function _getDisabledKey(state: any) {
    return state.disabledKey;
}

function _getTitleKey(state: any) {
    return state.titleKey;
}

function _getGroupTitleKey(state: any) {
    return state.groupTitleKey;
}

function _getItemFilter(state: any) {
    return state.itemFilter;
}

function _getIsFiltered(state: any) {
    return !!state.itemFilter;
}

function _getIsItemsGrouped(state: any) {
    return !!state.groupIdKey;
}

function _getIsSingleSelect(state: any) {
    return !!state.isSingleSelect;
}

function _getHasSelectAll(state: any) {
    return state.hasSelectAll;
}

function _getHasFilter(state: any) {
    return state.hasFilter;
}

function _getFilterIcon(state: any) {
    return state.filterIcon;
}

function _getHasFooter(state: any) {
    return state.hasFooter;
}

function _getShowOnlySelected(state: any) {
    return state.showOnlySelected;
}

function _getIsOpen(state: any) {
    return state.isOpen;
}

function _getIsRequired(state: any) {
    return state.isRequired;
}

function _getisDirty(state: any) {
    return state.isDirty;
}

function _getIsDisabled(state: any) {
    return state.isDisabled;
}

function _getIsReadonly(state: any) {
    return state.isReadonly;
}

function _getShowSelectedToggle(state: any) {
    return state.showSelectedToggle;
}

function _getShowIconInDisplay(state: any) {
    return state.showIconInDisplay;
}

function _getAdditionalPropertiesToFilter(state: any) {
    return state.additionalPropertiesToFilter;
}

function _getMaxPopupHeight(state: any) {
    return state.maxPopupHeight;
}

function _getShowRecentSelections(state: any) {
    return state.showRecentSelections;
}

function _getRecentlySelectedItems(state: any) {
    return state.recentlySelectedItems;
}

function _getShowMainListWithRecents(state: any) {
    return state.showMainListWithRecents;
}

function _getHandleFilterExternally(state: any) {
    return state.handleFilterExternally;
}

function _getLimitDropDownItems(state: SfSelectState) {
    return state.limitDropDownItems;
}

export function _addIdAndLabelToItems(
    items: any[],
    idKey: string,
    labelKey: string,
    groupIdKey: string,
    bottomMetadataKey: string,
    rightMetadataKey: string,
    disabledKey: string,
    titleKey: string,
    groupTitleKey: string
) {
    let mappedItems = items.map(function (i: any) {
        let newProperties = {
            $$msId: i[idKey],
            $$msLabel: `${i[labelKey]}`,
            $$msGroupId: i[groupIdKey],
            $$msBottomMetadata: i[bottomMetadataKey],
            $$msRightMetadata: i[rightMetadataKey]
                ? i[rightMetadataKey]
                : undefined,
            $$msDisabled: i[disabledKey] ? true : undefined,
            $$msHashedId: hashValue(i[idKey]),
            $$msTitle: i[titleKey] || i[labelKey],
            $$msGroupTitle: i[groupTitleKey]
        };
        return Object.assign({}, i, newProperties);
    });

    return mappedItems;
}

export function _markSelectedItems(items: any[], selectedItems: any[]) {
    return items.map((i: any) => {
        if (selectedItems.includes(i.$$msId)) {
            return Object.assign({}, i, { $$msSelected: true });
        }
        return i;
    });
}

export function _markActiveItem(items: any[], activeItem: any) {
    return items.map((i: any) => {
        if (i.$$msId === activeItem) {
            return Object.assign({}, i, { $$msActive: true });
        }
        return i;
    });
}

export function _filterItems(
    items: any[],
    itemFilter: any,
    handleFilterExternally: boolean,
    showOnlySelected: boolean,
    additionalPropertiesToFilter: any
) {
    let filteredItems = items;
    if (Array.isArray(additionalPropertiesToFilter)) {
        additionalPropertiesToFilter = [
            ...additionalPropertiesToFilter,
            "$$msLabel"
        ];
    } else {
        additionalPropertiesToFilter = ["$$msLabel"];
    }

    if (itemFilter && !handleFilterExternally) {
        filteredItems = filteredItems.filter((i: any) => {
            let filterString = additionalPropertiesToFilter
                .reduce((result: any, prop: string) => {
                    if (i.hasOwnProperty(prop)) {
                        return result + i[prop];
                    }
                    return result;
                }, "")
                .toLowerCase();
            return filterString.includes(itemFilter.toLowerCase());
        });
    }

    if (showOnlySelected) {
        filteredItems = filteredItems.filter((item) => item.$$msSelected);
    }

    return filteredItems;
}

export function _groupItems(
    filteredItems: any[],
    allItems: any[],
    isSingleSelect: boolean
) {
    let groups = Object.entries(groupBy(filteredItems, "$$msGroupId")).map(
        ([key, groupItems]) => {
            let itemsInGroup = allItems.filter(
                (item) => item["$$msGroupId"] == key
            );
            let title =
                itemsInGroup[0] && (itemsInGroup[0] as any).$$msGroupTitle
                    ? (itemsInGroup[0] as any).$$msGroupTitle
                    : key;
            return {
                id: key,
                label: key,
                $$msHashedId: hashValue(key),
                selected: !isSingleSelect
                    ? itemsInGroup.every((item) => item["$$msSelected"])
                        ? true
                        : undefined
                    : undefined,
                $$msDisabled: itemsInGroup.every((item) => item["$$msDisabled"])
                    ? true
                    : undefined,
                $$msTitle: title,
                items: groupItems
            };
        }
    );

    return groups;
}

export function _limitItems(groups: any[], limit: number) {
    if (!limit) {
        return groups;
    }

    let currentCount = 0;
    return cloneDeep(groups)
        .map((group) => {
            if (currentCount >= limit) {
                return null;
            }
            const newCount = currentCount + group.items.length;
            if (newCount <= limit) {
                currentCount = newCount;
                return group;
            }

            group.items.length = limit - currentCount;
            currentCount = limit;
            return group;
        })
        .filter((group) => group !== null);
}

export function _hasLimitedDropdown(groups: any[], limit: number) {
    if (!limit) {
        return false;
    }

    let currentCount = 0;
    return groups.reduce((hasLimited, group) => {
        if (hasLimited === true) {
            return hasLimited;
        }

        const newCount = currentCount + group.items.length;
        if (newCount === limit) {
            return true;
        }

        currentCount = newCount;
        return false;
    }, false);
}

// note that this function isn't pure, but it is not being used directly, so the overall function is pure
export function _markActiveGroupItems(
    groupedItems: any[],
    activeItemId: string
) {
    let itemsWithGroups = groupedItems.map((group: any) => {
        group.active = group.id === activeItemId;
        group.items.forEach((item: any) => {
            item.$$msActive = item.$$msId === activeItemId;
        });
        return group;
    });
    return itemsWithGroups;
}

export function _getItemIds(items: any[]) {
    return items.map((item) => item["$$msId"]);
}

export function _getItemIdsWithGroups(items: any[]) {
    return Object.entries(groupBy(items, "$$msGroupId")).flatMap(
        ([key, groupItems]) => {
            return union(
                [key],
                groupItems.map((item) => item["$$msId"])
            );
        }
    );
}

function _getGroupIds(items: any[]) {
    return uniq(items.map((item) => item["$$msGroupId"]));
}

function _getDisabledIds(items: any[]) {
    return uniq(
        items
            .filter((item) => item["$$msDisabled"])
            .map((item) => item["$$msId"])
    );
}

function _getDisabledGroupIds(groupedItems: any[]) {
    return groupedItems
        .filter((item) => item["$$msDisabled"])
        .map((item) => item["id"]);
}

export function _getPreviousItemId(
    allIds: any[],
    filteredIds: any[],
    currentId: string,
    groupIds: any[],
    isSingleSelect: boolean,
    disabledIds: any[],
    disabledGroupIds: any[]
) {
    let allPreviousIds, intersect, iInFiltered, iInAll;
    allIds = difference(allIds, disabledIds);
    filteredIds = difference(filteredIds, disabledIds);
    allIds = difference(allIds, disabledGroupIds);
    filteredIds = difference(filteredIds, disabledGroupIds);
    if (isSingleSelect) {
        allIds = difference(allIds, groupIds);
        filteredIds = difference(filteredIds, groupIds);
    }
    iInFiltered = filteredIds.indexOf(currentId);
    iInAll = allIds.indexOf(currentId);
    if (filteredIds.length === 0) {
        return undefined;
    }
    if (iInFiltered === -1 && iInAll === -1) {
        return undefined;
    }
    if (iInFiltered === -1 && iInAll !== -1) {
        allPreviousIds = allIds.slice(0, iInAll);
        intersect = intersection(filteredIds, allPreviousIds);
        if (intersect.length === 0) {
            return undefined;
        }
        return intersect[intersect.length - 1];
    }
    if (iInFiltered === 0) {
        return undefined;
    }
    return filteredIds[iInFiltered - 1];
}

export function _getNextItemId(
    allIds: any[],
    filteredIds: any[],
    currentId: string,
    groupIds: any[],
    isSingleSelect: boolean,
    disabledIds: any[],
    disabledGroupIds: any[]
) {
    let allNextIds, intersect, iInFiltered, iInAll;
    allIds = difference(allIds, disabledIds);
    filteredIds = difference(filteredIds, disabledIds);
    allIds = difference(allIds, disabledGroupIds);
    filteredIds = difference(filteredIds, disabledGroupIds);
    if (isSingleSelect) {
        allIds = difference(allIds, groupIds);
        filteredIds = difference(filteredIds, groupIds);
    }
    iInFiltered = filteredIds.indexOf(currentId);
    iInAll = allIds.indexOf(currentId);
    if (filteredIds.length === 0) {
        return undefined;
    }
    if (!currentId) {
        return filteredIds[0];
    }
    if (iInFiltered === filteredIds.length - 1) {
        return filteredIds[filteredIds.length - 1];
    }
    if (iInFiltered === -1 && iInAll === -1) {
        return filteredIds[0];
    }
    if (iInFiltered === -1 && iInAll !== -1) {
        allNextIds = allIds.slice(iInAll + 1);
        intersect = intersection(allNextIds, filteredIds);
        if (intersect.length === 0) {
            return filteredIds[filteredIds.length - 1];
        }
        if (allNextIds.length === 0) {
            return intersect[intersect.length - 1];
        }
        return intersect[0];
    }
    return filteredIds[iInFiltered + 1];
}

export function _getOrderedItemIds(
    itemIds: any[],
    itemIdsWithGroups: any[],
    isGrouped: boolean,
    hasSelectAll: boolean
) {
    let itemList;
    if (isGrouped) {
        itemList = itemIdsWithGroups;
    } else {
        itemList = itemIds;
    }
    if (hasSelectAll) {
        itemList = union(["msSelectAllItem"], itemList);
    }
    return itemList;
}

export function _getDisplay(items: any[], selectedItems: any[]) {
    let validSelectedItems: any[];
    if (!items || items.length === 0) {
        return "";
    }
    if (!selectedItems || !selectedItems.length) {
        return "";
    }

    // if we get here, something is selected
    let result;

    // see if the selected item is in our list of items
    // it's not considered a 'valid' selection unless it is in 'items'
    validSelectedItems = items
        .map((item) => item["$$msId"])
        .filter((id) => selectedItems.includes(id));

    if (validSelectedItems.length === 1) {
        result = items
            .find((item) => item["$$msId"] == validSelectedItems[0])
            .$$msLabel.trim();
        return result;
    }
    if (validSelectedItems.length < 3) {
        result = items
            .filter((i) => validSelectedItems.includes(i.$$msId))
            .map((item) => item["$$msLabel"].trim())
            .reduce((acc, val, i) => acc + (i > 0 ? ", " : "") + val, "");
        if (result) {
            // only return the match if we found a match
            return result;
        }
    }
    if (validSelectedItems.length >= 3) {
        result = validSelectedItems.length + " selected";
        return result;
    }
    return result;
}

export function _getIsSelectAllChecked(
    filteredItems: any[],
    showOnlySelected: boolean
) {
    if (showOnlySelected) {
        return undefined;
    }
    if (filteredItems.length === 0) {
        return undefined;
    }
    return filteredItems.every((item) => {
        return item.$$msSelected || item.$$msDisabled;
    })
        ? true
        : undefined;
}

export function _getIsSelectAllActive(activeItem: any) {
    return activeItem === "msSelectAllItem" ? true : undefined;
}

export function _getIsSelectAllDisabled(filteredItems: any[]) {
    return filteredItems.every((item) => item["$$msDisabled"])
        ? true
        : undefined;
}

export function _getCurrentSelection(items: any[]) {
    return items.filter((item) => item["$$msSelected"]);
}

export function _getOutput(currentSelection: any, isSingleSelect: boolean) {
    let selectedItems = currentSelection
        .map((item: any) => {
            return { ...item };
        })
        .map((item: any) => {
            // remove the $$ms keys
            Object.entries(item).forEach(function ([key, value]) {
                if (key.includes("$$ms")) {
                    delete item[key];
                }
            });
            return item;
        });

    if (isSingleSelect && selectedItems.length > 0) {
        return selectedItems[0];
    }

    if (isSingleSelect && selectedItems.length === 0) {
        return undefined;
    }

    return selectedItems;
}

export function _getIsValid(
    selectedItems: any[],
    isRequired: boolean,
    itemIDs: any[]
) {
    if (!isRequired) {
        return true;
    }
    return intersection(itemIDs, selectedItems).length !== 0;
}

export function _getToggleShowSelectedLabel(showOnlySelected: boolean) {
    if (showOnlySelected) {
        return "Show All";
    }
    return "Show Only Selected";
}

export function _getSelectAllLabel(
    isFiltered: boolean,
    showOnlySelected: boolean
) {
    if (showOnlySelected) {
        return "Clear Selection";
    }
    if (isFiltered && !showOnlySelected) {
        return "Select All Filtered";
    }
    return "Select All";
}

export function _getShowSelectAll(
    filteredItems: any[],
    hasSelectAll: boolean,
    isSingleSelect: boolean
) {
    if (isSingleSelect) {
        return false;
    }
    if (!hasSelectAll) {
        return false;
    }
    if (!filteredItems || !filteredItems.length) {
        return false;
    }
    return true;
}

export function _getShowNoItemsMessage(filteredItems: any[]) {
    if (!filteredItems || !filteredItems.length) {
        return true;
    }
    return false;
}

export function _getHasSelections(items: any[], selectedItems: any[]) {
    let validSelectedItems;
    if (!items || items.length === 0) {
        return false;
    }
    if (!selectedItems || !selectedItems.length) {
        return false;
    }

    validSelectedItems = items
        .map((item: any) => item["$$msId"])
        .filter((id) => selectedItems.includes(id));

    if (validSelectedItems.length) {
        return true;
    }
    return false;
}

function _getHashedValue(value: any) {
    return hashValue(value);
}

export function _getNumberOfItems(filteredItemIds: any[]) {
    return filteredItemIds.length;
}

function _getHasBottomMetadata(key: any) {
    return !!key;
}

function _getHasRightMetadata(key: any) {
    return !!key;
}

function _getHasMetadata(
    hasBottomMetadata: boolean,
    hasRightMetadata: boolean
) {
    return hasBottomMetadata || hasRightMetadata;
}

function _getHideMainList(
    showRecentSelections: boolean,
    showAllOptionsInitially: boolean,
    filteredRecentSelections: any[]
) {
    return (
        showRecentSelections &&
        !showAllOptionsInitially &&
        filteredRecentSelections.length > 0
    );
}

function _getView(
    groupedItems: any[],
    display: string,
    hasLimitedDropdown: boolean,
    dropdownLimit: number,
    isItemsGrouped: boolean,
    isSelectAllChecked: boolean,
    isSelectAllActive: boolean,
    isSelectAllDisabled: boolean,
    previousItemId: string,
    activeItemId: string,
    hashedActiveItemId: string,
    nextItemId: string,
    isSingleSelect: boolean,
    hasSelectAll: boolean,
    hasFilter: boolean,
    filterIcon: IconProp,
    hasFooter: boolean,
    bindAsHtml: boolean,
    hasBottomMetadata: boolean,
    hasRightMetadata: boolean,
    rightMetadataIsIcon: boolean,
    showOnlySelected: boolean,
    isOpen: boolean,
    isRequired: boolean,
    isValid: boolean,
    isDirty: boolean,
    isDisabled: boolean,
    isReadonly: boolean,
    isFiltered: boolean,
    toggleShowSelectedLabel: boolean,
    selectAllLabel: string,
    showSelectAll: boolean,
    showNoItemsMessage: boolean,
    hasSelections: boolean,
    showIconInDisplay: boolean,
    currentSelection: string[],
    itemFilter: string,
    maxPopupHeight: number,
    showRecentSelections: boolean,
    recentlySelectedItems: SfSelectItem[],
    hideMainList: boolean
): SfSelectView {
    return {
        msDisplay: display,
        msGroupedItems: groupedItems,
        hasLimitedDropdown: hasLimitedDropdown,
        dropdownLimit: dropdownLimit,
        isItemsGrouped: isItemsGrouped,
        isSelectAllChecked: isSelectAllChecked,
        isSelectAllActive: isSelectAllActive,
        isSelectAllDisabled: isSelectAllDisabled,
        previousItemId: previousItemId,
        activeItemId: activeItemId,
        hashedActiveItemId: hashedActiveItemId,
        nextItemId: nextItemId,
        isSingleSelect: isSingleSelect,
        hasSelectAll: hasSelectAll,
        hasFilter: hasFilter,
        filterIcon: filterIcon,
        hasFooter: hasFooter,
        bindAsHtml: bindAsHtml,
        hasBottomMetadata: hasBottomMetadata,
        hasRightMetadata: hasRightMetadata,
        rightMetadataIsIcon: rightMetadataIsIcon,
        showOnlySelected: showOnlySelected,
        isOpen: isOpen,
        isRequired: isRequired,
        isValid: isValid,
        isDirty: isDirty,
        isDisabled: isDisabled,
        isReadonly: isReadonly,
        isFiltered: isFiltered,
        toggleShowSelectedLabel: toggleShowSelectedLabel,
        selectAllLabel: selectAllLabel,
        showSelectAll: showSelectAll,
        showNoItemsMessage: showNoItemsMessage,
        hasSelections: hasSelections,
        showIconInDisplay: showIconInDisplay,
        currentSelection: currentSelection,
        itemFilter: itemFilter,
        maxPopupHeight: maxPopupHeight,
        showRecentSelections: showRecentSelections,
        recentlySelectedItems: recentlySelectedItems,
        hideMainList: hideMainList
    };
}
