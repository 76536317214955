<div *ngIf="showingRecentItems">
    <table class="parent-hidden-data">
        <tr>
            <td class="type-icon pe-1">
                <fa-icon icon="building"></fa-icon>
            </td>
            <td style="width: 100%">
                <span class="float-start truncate-name">
                    <button *ngIf="!item.archived && !!item.name" id="btn-name" class="btn btn-link sf-btn-link truncate" sfTooltipOnOverflow="{{ item.name }}" container="body"
                        [openDelay]="100" [ngClass]="{ italic: !item.isEnabled }" (click)="uscService.goToContact(item)">
                        {{ item.name }}
                    </button>
                    <i *ngIf="item.archived && !!item.name" id="btn-name" class="truncate" sfTooltipOnOverflow="{{ item.name }}" [openDelay]="100" container="body">
                        {{ item.name }}
                    </i>
                </span>
                <span *ngIf="item.hasUsers && item.isEnabled" class="hidden-data">
                    <button id="btn-log-in-as" class="btn btn-link sf-btn-link" (click)="uscService.becomeUser(item)" aria-label="Actions">
                        &nbsp;<fa-icon icon="sign-in-alt"></fa-icon>
                    </button>
                </span>
                <span *ngIf="item.archived" class="lessImportant">
                    &nbsp;<fa-icon icon="archive" tooltipClass="tooltip-interaction-not" ngbTooltip="Organization is archived" placement="right" container="body"></fa-icon>
                </span>
            </td>
            <td style="width: 100%">
                <span class="hidden-data float-end badge {{item.statusClass}}" tooltipClass="tooltip-interaction-not" container="body"
                        ngbTooltip="{{ item.statusTooltip }}" placement="left" [openDelay]="400">
                    <button *ngIf="!!item.status" id="btn-status" class="btn btn-link sf-btn-link" (click)="uscService.goToServices(item)">
                        {{ item.status }}
                    </button>
                </span>
            </td>
            <td class="no-wrap ps-1">
                <span ngbDropdown container="body" class="float-end">
                    <button ngbDropdownToggle class="more-dots" aria-label="Actions" (click)="$event.stopPropagation()">
                        <fa-icon icon="ellipsis-h"></fa-icon>
                    </button>
                    <div *ngIf="!item.archived" ngbDropdownMenu class="inner-menu" role="menu">
                        <button *ngIf="item.isRecipient" id="menu-package-search" role="menuitem" ngbDropdownItem sfAutoFocus (click)="uscService.goToPackages(item, 'search')">
                            <fa-icon icon="search" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Package Search
                        </button>
                        <button *ngIf="item.isRecipient" id="menu-packages" role="menuitem" ngbDropdownItem (click)="uscService.goToPackagesForOrg(item)">
                            <fa-icon icon="file-alt"></fa-icon>
                            &nbsp;&nbsp;Packages
                        </button>
                        <button *ngIf="item.isRecipient" id="menu-job-queue" role="menuitem" ngbDropdownItem (click)="uscService.goToJobQueue(item)">
                            <fa-icon icon="wrench" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Job Queue Triage
                        </button>
                        <button id="menu-services" role="menuitem" ngbDropdownItem (click)="uscService.goToServices(item)">
                            <fa-icon icon="cogs" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Services
                        </button>
                        <button *ngIf="item.hasPaymentAccounts" id="menu-payment-accounts" role="menuitem" ngbDropdownItem (click)="uscService.goToPaymentAccounts(item)">
                            <fa-icon icon="dollar-sign" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Payment Accounts
                        </button>
                        <button *ngIf="item.hasUsers && item.isEnabled" id="menu-log-in-as" role="menuitem" ngbDropdownItem (click)="uscService.becomeUser(item)">
                            <fa-icon icon="sign-in-alt" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Log in as User
                        </button>
                        <button id="menu-manage-roles" role="menuitem" ngbDropdownItem (click)="uscService.goToAssignments(item)">
                            <fa-icon icon="users" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Manage Roles
                        </button>
                        <button *ngIf="item.isSubmitter" id="menu-manage-counties" role="menuitem" ngbDropdownItem (click)="uscService.goToCounties(item)">
                            <fa-icon icon="map" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Manage Counties
                        </button>
                        <button id="menu-org-info" role="menuitem" ngbDropdownItem (click)="uscService.goToContact(item)">
                            <fa-icon icon="info-circle" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Org Information
                        </button>
                        <button id="menu-org-settings" role="menuitem" ngbDropdownItem (click)="uscService.goToSettings(item)">
                            <fa-icon icon="cog" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Org Settings
                        </button>
                        <button id="menu-reports" role="menuitem" ngbDropdownItem (click)="uscService.goToReports(item)">
                            <fa-icon icon="table" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Reports
                        </button>
                        <button id="menu-hierarchy" role="menuitem" ngbDropdownItem (click)="uscService.goToHierarchy(item)">
                            <fa-icon [icon]="['fal','sitemap']" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Hierarchy
                        </button>
                        <button id="menu-security" role="menuitem" ngbDropdownItem (click)="uscService.goToSecurity(item)">
                            <fa-icon icon="key" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Security
                        </button>
                        <button id="menu-activity-logs" role="menuitem" ngbDropdownItem (click)="uscService.goToActivityLogs(item)">
                            <fa-icon icon="history" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Activity Logs
                        </button>
                        <button id="menu-transactions" role="menuitem" ngbDropdownItem (click)="uscService.goToTransactions(item)">
                            <fa-icon icon="dollar-sign" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Transactions
                        </button>
                    </div>
                    <div *ngIf="item.archived" ngbDropdownMenu class="inner-menu" role="menu">
                        <button id="menu-activity-logs" role="menuitem" ngbDropdownItem (click)="uscService.goToActivityLogs(item)">
                            <fa-icon icon="history" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Activity Logs
                        </button>
                        <button id="menu-unarchive" role="menuitem" ngbDropdownItem (click)="uscService.doUnarchive(item)">
                            <fa-icon icon="archive" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Unarchive Org
                        </button>
                    </div>
                </span>
            </td>
        </tr>
        <tr>
            <td colspan="3">
                <div class="lessImportant">
                    <span class="float-start truncate" sfTooltipOnOverflow="{{ item.id }}" [openDelay]="100" container="body">
                        {{ item.id }}&nbsp;
                    </span>
                    <span *ngIf="!item.isEnabled" class="disabled-org">
                        <fa-icon icon="toggle-off" tooltipClass="tooltip-interaction-not" ngbTooltip="Organization is disabled" placement="bottom" container="body"></fa-icon>
                    </span>
                    <span *ngIf="item.isSample" class="disabled-org">
                        <fa-icon icon="newspaper" tooltipClass="tooltip-interaction-not" ngbTooltip="Organization is sample" placement="bottom" container="body"></fa-icon>
                    </span>
                    <span *ngIf="item.inHierarchy && item.childIDs.length > 0 && !item.parentId" class="disabled-org" (click)="uscService.goToHierarchy(item)" style="cursor: pointer">
                        <fa-icon [icon]="['fal','sitemap']" tooltipClass="tooltip-interaction-not" ngbTooltip="Parent Organzization" placement="bottom" container="body"></fa-icon>
                    </span>
                    <span *ngIf="item.inHierarchy && item.parentId" class="disabled-org" (click)="uscService.goToHierarchy(item)" style="cursor: pointer">
                        <fa-icon [icon]="['fal','sitemap']" tooltipClass="tooltip-interaction-not" ngbTooltip="Child of {{item.parentOrgName}} ({{ item.parentId }})" placement="bottom" container="body"></fa-icon>
                    </span>
                    <span class="float-end truncate" sfTooltipOnOverflow="{{item.address.city}}, {{ item.address.state }}" [openDelay]="100" container="body">
                        {{ item.address.city }}, {{ item.address.state }}&nbsp;
                    </span>
                </div>
            </td>
            <td style="width: 30px; padding-left: 10px">&nbsp;</td>
        </tr>
    </table>
</div>

<div *ngIf="!showingRecentItems">
    <table class="parent-hidden-data">
        <tr>
            <td style="width: 100%" class="ps-1">
                <span>
                    <span class="truncate-name" tooltipClass="tooltip-interaction-not">
                        <button *ngIf="!item.archived && !!item.name" id="btn-name" class="btn btn-link sf-btn-link truncate" [ngClass]="{italic: !item.isEnabled}" container="body"
                                sfTooltipOnOverflow="{{item.name}}" [openDelay]="100" (click)="uscService.goToContact(item)">
                            {{ item.name }}
                        </button>
                        <i *ngIf="item.archived && !!item.name" id="btn-name" class="truncate" sfTooltipOnOverflow="{{item.name}}" [openDelay]="100" container="body">
                            {{ item.name }}
                        </i>
                    </span>
                    <span *ngIf="item.hasUsers && item.isEnabled" class="hidden-data">
                        <button id="btn-log-in-as" class="btn btn-link sf-btn-link" (click)="uscService.becomeUser(item)" aria-label="Actions">
                            &nbsp;<fa-icon icon="sign-in-alt"></fa-icon>
                        </button>
                    </span>
                    <span *ngIf="item.archived" class="lessImportant">
                        &nbsp;<fa-icon icon="archive" ngbTooltip="Organization is archived" tooltipClass="tooltip-interaction-not" placement="right" container="body"></fa-icon>
                    </span>
                </span>
            </td>
            <td style="width: 100%">
                <span class="hidden-data float-end badge {{item.statusClass}}" tooltipClass="tooltip-interaction-not" container="body"
                      ngbTooltip="{{item.disabledReason ? item.disabledReason : item.statusTooltip}}" placement="left" [openDelay]="400">
                    <button *ngIf="!!item.status" id="btn-services" class="btn btn-link sf-btn-link" (click)="uscService.goToServices(item)">
                        {{ item.status }}
                    </button>
                </span>
            </td>
            <td class="no-wrap ps-1">
                <span ngbDropdown container="body" class="float-end">
                    <button ngbDropdownToggle class="more-dots" aria-label="Actions" (click)="$event.stopPropagation()">
                        <fa-icon icon="ellipsis-h"></fa-icon>
                    </button>
                    <div *ngIf="!item.archived" ngbDropdownMenu class="inner-menu" role="menu">
                        <button *ngIf="item.isRecipient" id="menu-package-search" role="menuitem" ngbDropdownItem (click)="uscService.goToPackages(item, 'search')">
                            <fa-icon icon="search" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Package Search
                        </button>
                        <button *ngIf="item.isRecipient" id="menu-packages" role="menuitem" ngbDropdownItem (click)="uscService.goToPackagesForOrg(item)">
                            <fa-icon icon="file-alt" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Packages
                        </button>
                        <button *ngIf="item.isRecipient" id="menu-job-queue" role="menuitem" ngbDropdownItem (click)="uscService.goToJobQueue(item)">
                            <fa-icon icon="wrench" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Job Queue Triage
                        </button>
                        <button id="menu-services" role="menuitem" ngbDropdownItem (click)="uscService.goToServices(item)">
                            <fa-icon icon="cogs" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Services
                        </button>
                        <button *ngIf="item.hasPaymentAccounts" id="menu-payment-accounts" role="menuitem" ngbDropdownItem (click)="uscService.goToPaymentAccounts(item)">
                            <fa-icon icon="dollar-sign" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Payment Accounts
                        </button>
                        <button id="menu-log-in-as" role="menuitem" ngbDropdownItem (click)="uscService.becomeUser(item)" *ngIf="item.hasUsers && item.isEnabled">
                            <fa-icon icon="sign-in-alt" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Log in as User
                        </button>
                        <button id="menu-manage-roles" role="menuitem" ngbDropdownItem (click)="uscService.goToAssignments(item)">
                            <fa-icon icon="users" [fixedWidth]="true"></fa-icon>&nbsp;&nbsp;Manage Roles
                        </button>
                        <button *ngIf="item.isSubmitter" id="menu-manage-counties" role="menuitem" ngbDropdownItem (click)="uscService.goToCounties(item)">
                            <fa-icon icon="map" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Manage Counties
                        </button>
                        <button id="menu-org-info" role="menuitem" ngbDropdownItem (click)="uscService.goToContact(item)">
                            <fa-icon icon="info-circle" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Org Information
                        </button>
                        <button id="menu-org-settings" role="menuitem" ngbDropdownItem (click)="uscService.goToSettings(item)">
                            <fa-icon icon="cog" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Org Settings
                        </button>
                        <button id="menu-reports" role="menuitem" ngbDropdownItem (click)="uscService.goToReports(item)">
                            <fa-icon icon="table" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Reports
                        </button>
                        <button id="menu-hierarchy" role="menuitem" ngbDropdownItem (click)="uscService.goToHierarchy(item)">
                            <fa-icon [icon]="['fal','sitemap']" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Hierarchy
                        </button>
                        <button id="menu-security" role="menuitem" ngbDropdownItem (click)="uscService.goToSecurity(item)">
                            <fa-icon icon="key" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Security
                        </button>
                        <button id="menu-activity-logs" role="menuitem" ngbDropdownItem (click)="uscService.goToActivityLogs(item)">
                            <fa-icon icon="history" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Activity Logs
                        </button>
                        <button id="menu-transactions" role="menuitem" ngbDropdownItem (click)="uscService.goToTransactions(item)">
                            <fa-icon icon="dollar-sign" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Transactions
                        </button>
                    </div>
                    <div *ngIf="item.archived" ngbDropdownMenu class="inner-menu" role="menu">
                        <button id="menu-activity-logs" role="menuitem" ngbDropdownItem (click)="uscService.goToActivityLogs(item)">
                            <fa-icon icon="history" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Activity Logs
                        </button>
                        <button id="menu-unarchive" role="menuitem" ngbDropdownItem (click)="uscService.doUnarchive(item)">
                            <fa-icon icon="archive" [fixedWidth]="true"></fa-icon>
                            &nbsp;&nbsp;Unarchive Org
                        </button>
                    </div>
                </span>
            </td>
        </tr>
        <tr>
            <td colspan="2" class="ps-1">
                <div class="lessImportant">
                    <span class="float-start truncate pe-2" tooltipClass="tooltip-interaction-not" [openDelay]="100" sfTooltipOnOverflow="{{ item.id }}" container="body">
                        {{ item.id }}
                    </span>
                    <span class="float-end truncate pe-2" tooltipClass="tooltip-interaction-not" [openDelay]="100" container="body"
                          sfTooltipOnOverflow="{{item.address.city}}, {{ item.address.state }}">
                        {{ item.address.city }}, {{ item.address.state }}
                    </span>
                    <span *ngIf="!item.isEnabled" class="disabled-org pe-2">
                        <fa-icon icon="toggle-off" tooltipClass="tooltip-interaction-not" ngbTooltip="Organization is disabled" placement="bottom" container="body"></fa-icon>
                    </span>
                    <span *ngIf="item.isSample" class="disabled-org pe-2">
                        <fa-icon icon="newspaper" tooltipClass="tooltip-interaction-not" ngbTooltip="Organization is sample" placement="bottom" container="body"></fa-icon>
                    </span>
                    <span *ngIf="item.inHierarchy && item.childIDs.length > 0 && !item.parentId" class="disabled-org pe-2"
                          (click)="uscService.goToHierarchy(item)" style="cursor: pointer">
                        <fa-icon [icon]="['fal','sitemap']" tooltipClass="tooltip-interaction-not" ngbTooltip="Parent Organzization" placement="bottom" container="body"></fa-icon>
                    </span>
                    <span *ngIf="item.inHierarchy && item.parentId" class="disabled-org" (click)="uscService.goToHierarchy(item)" style="cursor: pointer">
                        <fa-icon [icon]="['fal','sitemap']" tooltipClass="tooltip-interaction-not" ngbTooltip="Child of {{item.parentOrgName}} ({{ item.parentId }})"
                            placement="bottom" container="body"></fa-icon>
                    </span>
                </div>
            </td>
            <td style="width: 30px; padding-left: 10px">
                &nbsp;
            </td>
        </tr>
    </table>
</div>
