import { Component, Input, OnInit, TemplateRef } from "@angular/core";

@Component({
    selector: "sf-tooltip",
    templateUrl: "./tooltip.component.html",
    styleUrls: ["./tooltip.component.scss"]
})
export class TooltipComponent implements OnInit {
    @Input()
    tooltipContent?: TemplateRef<any>;

    constructor() {}

    ngOnInit(): void {}
}
