import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { Observable } from "rxjs";
import { SignerNotarySetupStatus } from "../interfaces/signer-notary-setup-status";
import { NotaryMethod, NotaryStatus } from "@sf/userorg/common";
import { NotarySealXml } from "../interfaces/notary-seal-xml";

@Injectable({
    providedIn: "root"
})
export class EsignUserService {
    constructor(private _rpcClient: RpcClientService) {}

    getNotaryDisabled(userId: string): Observable<boolean> {
        return this._rpcClient.makeRequest("esign", "getNotaryDisabled", {
            userId
        });
    }

    getNotariesByStatus(
        startDate: any,
        endDate: any,
        notaryMethods: NotaryMethod[],
        notaryStatus: NotaryStatus
    ): Observable<any[]> {
        return this._rpcClient.makeRequest("userorg", "getNotariesByStatus", {
            startDate,
            endDate,
            notaryMethods,
            notaryStatus
        });
    }

    getNotariesWithFailedCertificate(
        startDate: any,
        endDate: any
    ): Observable<any[]> {
        return this._rpcClient.makeRequest(
            "userorg",
            "getNotariesWithFailedCertificate",
            {
                startDate,
                endDate
            }
        );
    }

    approveFailedNotaryCertificate(
        userID: string,
        nextENotaryTrainingDate?: any
    ): Observable<Record<NotaryMethod, NotaryStatus>> {
        return this._rpcClient.makeRequest(
            "userorg",
            "approveFailedNotaryCertificate",
            {
                userID,
                nextENotaryTrainingDate
            }
        );
    }

    rejectFailedNotaryCertificate(
        userID: string,
        comment: string
    ): Observable<void> {
        return this._rpcClient.makeRequest(
            "userorg",
            "rejectFailedNotaryCertificate",
            {
                userID,
                comment
            }
        );
    }

    approvePendingNotaries(userIDs: string[]): Observable<any[]> {
        return this._rpcClient.makeRequest(
            "userorg",
            "approvePendingNotaries",
            {
                userIDs
            }
        );
    }

    rejectPendingNotaries(
        userIDs: string[],
        rejectionReason: string
    ): Observable<any[]> {
        return this._rpcClient.makeRequest("userorg", "rejectPendingNotaries", {
            userIDs,
            rejectionReason
        });
    }

    enableNotaries(userIDs: string[]): Observable<any[]> {
        return this._rpcClient.makeRequest("userorg", "enableNotaries", {
            userIDs
        });
    }

    disableNotaries(userIDs: string[], comment: string): Observable<any[]> {
        return this._rpcClient.makeRequest("userorg", "disableNotaries", {
            userIDs,
            comment
        });
    }

    getSignerNotarySetupStatus(): Observable<SignerNotarySetupStatus> {
        return this._rpcClient.makeRequest(
            "esign",
            "getSignerNotarySetupStatus",
            {}
        );
    }

    signerSetupNeeded(userId: string, isNotary: boolean): Observable<boolean> {
        return this._rpcClient.makeRequest("esign", "signerSetupNeeded", {
            userId,
            isNotary
        });
    }

    createSampleSignerDocuments(submitterId: string): Observable<string[]> {
        return this._rpcClient.makeRequest(
            "esign",
            "createSampleSignerDocuments",
            {
                submitterId
            }
        );
    }

    createSampleNotaryDocuments(submitterId: string): Observable<string[]> {
        return this._rpcClient.makeRequest(
            "esign",
            "createSampleNotaryDocuments",
            {
                submitterId
            }
        );
    }

    verifyNotaryCertificate(pdfData: string, state: string, username: string) {
        return this._rpcClient.makeRequest("esign", "verifyNotaryCertificate", {
            pdfData,
            state,
            username
        });
    }

    isProxied() {
        return this._rpcClient.makeRequest("esign", "isProxied");
    }

    sendPrintDriverStatusEmail(
        username: string,
        status: string,
        settings: string,
        printDriverLog: string,
        queuedFiles: string,
        uploadLog: string
    ): Observable<string> {
        return this._rpcClient.makeRequest(
            "esign",
            "sendPrintDriverStatusEmail",
            {
                status,
                username,
                settings,
                printDriverLog,
                queuedFiles,
                uploadLog
            }
        );
    }

    getUserMapping(userID: string) {
        return this._rpcClient.makeRequest("esign", "getUserMapping", {
            userID
        });
    }

    getUserOrganizations(userId: string): Observable<number> {
        return this._rpcClient.makeRequest("esign", "getUserOrganizations", {
            userId
        });
    }

    getActiveSigningEvents(): Observable<
        { eSignEventID: string; notarizationType: string }[]
    > {
        return this._rpcClient.makeRequest(
            "esign",
            "getActiveSigningEvents",
            {}
        );
    }

    getUserHasP12(): Observable<any> {
        return this._rpcClient.makeRequest("esign", "getUserHasP12", {});
    }

    validateAndSaveDigitalCertificate(
        certificate: string,
        password: string
    ): Observable<string> {
        return this._rpcClient.makeRequest(
            "esign",
            "validateAndSaveDigitalCertificate",
            {
                certificate,
                password
            }
        );
    }

    saveDigitalCertificateFromLocalStorage(
        certificate: string
    ): Observable<void> {
        return this._rpcClient.makeRequest(
            "esign",
            "saveDigitalCertificateFromLocalStorage",
            {
                certificate
            }
        );
    }

    removeDigitalCertificate(userId: string): Observable<any> {
        if (userId) {
            return this._rpcClient.makeRequest(
                "esign",
                "removeDigitalCertificateByUserId",
                { userId }
            );
        } else {
            return this._rpcClient.makeRequest(
                "esign",
                "removeDigitalCertificate",
                {}
            );
        }
    }

    createDemoEvent(): Observable<string> {
        return this._rpcClient.makeRequest("esign", "createDemoEvent", {});
    }

    getDocBuilderConfig(): Observable<any> {
        return this._rpcClient.makeRequest("esign", "getDocBuilderConfig", {});
    }

    getNotarySealXMLList(): Observable<NotarySealXml[]> {
        return this._rpcClient.makeRequest("esign", "getNotarySealXMLList", {});
    }

    getNotarySealXML(sealId: string): Observable<string> {
        return this._rpcClient.makeRequest("userorg", "getNotarySealXML", {
            sealId
        });
    }

    setNotarySealXML(
        sealId: string,
        xml: string,
        dataMap: any
    ): Observable<void> {
        return this._rpcClient.makeRequest("userorg", "setNotarySealXML", {
            sealId,
            xml,
            dataMap
        });
    }

    generateSampleNotarySeal(xml: string, dataMap: any): Observable<string> {
        return this._rpcClient.makeRequest(
            "userorg",
            "generateSampleNotarySeal",
            {
                xml,
                dataMap
            }
        );
    }

    createNewNotarySealXML(
        state: string,
        ron: boolean,
        newYorkCity: boolean
    ): Observable<NotarySealXml> {
        return this._rpcClient.makeRequest("esign", "createNewNotarySealXML", {
            state,
            ron,
            newYorkCity
        });
    }

    deleteNotarySealXML(sealId: string) {
        return this._rpcClient.makeRequest("esign", "deleteNotarySealXML", {
            sealId
        });
    }

    generateAllNotarySeals(dataMap: any, old = false): Observable<string> {
        return this._rpcClient.makeRequest(
            "userorg",
            "generateAllNotarySeals",
            {
                dataMap,
                old
            }
        );
    }

    getNotarySealXmlHistory(sealId: string): Observable<NotarySealXml[]> {
        return this._rpcClient.makeRequest("esign", "getNotarySealXmlHistory", {
            sealId
        });
    }

    getNotariesWithSeal(
        state: string,
        ron: boolean,
        newYorkCity: boolean
    ): Observable<string[]> {
        return this._rpcClient.makeRequest("esign", "getNotariesWithSeal", {
            state,
            ron,
            newYorkCity
        });
    }

    generateNotarySealsForUsers(
        userIds: string[]
    ): Observable<Record<string, string>> {
        return this._rpcClient.makeRequest(
            "userorg",
            "generateNotarySealsForUsers",
            {
                userIds
            }
        );
    }
}
