<div *ngIf="!dataLoaded">
    <fa-icon icon="spinner" [spin]="true"></fa-icon>
    Loading . . .
</div>
<div *ngIf="dataLoaded && user">
    <fieldset>
        <div *ngIf="isLoggedIn" class="row extra-bottom">
            <div class="col">
                <div>
                    <b>This user is logged in right now.</b>&nbsp;&nbsp;
                    <fa-icon *ngIf="loggingOut" [spin]="true" icon="spinner"></fa-icon>
                    <button *ngIf="!loggingOut" class="btn btn-link sf-btn-link" (click)="askLogUserOut()">End Session</button>
                </div>
            </div>
        </div>
        <div *ngIf="showLastLogin" class="row extra-bottom">
            <div class="col">
                <div>
                    <span>Last successful login: {{user.lastSuccessfulLoginDayjs}}</span>
                </div>
            </div>
        </div>
        <div *ngIf="showLastAttempt" class="row extra-bottom">
            <div class="col">
                <div>
                    <span>Last login attempt: {{user.lastLoginAttemptDayjs}}</span>
                </div>
                <div *ngIf="user.attempts">
                    <b>
                        <span *ngIf="user.attempts == 1">{{user.attempts}} failed login attempt</span>
                        <span *ngIf="user.attempts > 1">{{user.attempts}} failed login attempts</span>
                    </b>
                </div>
            </div>
        </div>
        <div *ngIf="!showLastAttempt && !showLastLogin" class="row extra-bottom">
            <div class="col-md-11">
                <div>
                    <span>User has never tried to log in.</span>
                </div>
            </div>
        </div>
        <div *ngIf="loginCount > 0" class="row extra-bottom">
            <div class="col-md-11">
                <div>
                    <span *ngIf="loginCount > 1 && firstTrackedLoginDaysAgo > 1">
                        User has logged in {{loginCount}} times in the last {{firstTrackedLoginDaysAgo}} days.</span>
                    <span *ngIf="loginCount > 1 && firstTrackedLoginDaysAgo == 1">
                        User has logged in {{loginCount}} times since yesterday.</span>
                    <span *ngIf="loginCount > 1 && firstTrackedLoginDaysAgo == 0">
                        User has logged in {{loginCount}} times in the last 24 hours.</span>
                    <span *ngIf="loginCount == 1 && firstTrackedLoginDaysAgo > 1">
                        User has logged in once in the last {{firstTrackedLoginDaysAgo}} days.</span>
                    <span *ngIf="loginCount == 1 && firstTrackedLoginDaysAgo == 1">
                        User has logged in once since yesterday.</span>
                    <span *ngIf="loginCount == 1 && firstTrackedLoginDaysAgo == 0">
                        User has logged in once in the last 24 hours.</span>
                </div>
            </div>
        </div>
        <div class="row extra-bottom" *ngIf="user && (user.isLockedOut || user.attempts)">
            <div class="col-md-9">
                <label *ngIf="user.isLockedOut">
                    <b>User is locked out for {{user.attempts}} failed login attempts</b>
                </label>
                <div *ngIf="user.isLockedOut">
                    <button type="button" class="btn btn-secondary" (click)="clearLoginLock()">Unlock
                        <fa-icon icon="lock" class="lock-icon"></fa-icon>
                    </button>
                </div>
            </div>
        </div>

        <div class="row mb-3" *ngIf="isSuperUser && userCanLogin && loginCount > 0">
            <div class="col-md-9">
                <div class="mb-2">Login Attempts Report</div>
                <button type="button" class="btn btn-secondary tooltip-interaction"
                        ngbTooltip="View a report to see a user's successful and failed login attempts"
                        placement="right"
                        (click)="viewReport()">View Login Attempts Report
                </button>
            </div>
        </div>

        <div class="row mb-3" *ngIf="user.isSuspended || canSuspend">
            <div class="col-md-9">
                <label *ngIf="user.isSuspended" class="extra-right">
                    <b>User is suspended (Can't log in).</b>
                </label>
                <span *ngIf="canSuspend">
                    <button *ngIf="user.isSuspended" class="btn btn-secondary" (click)="unsuspendUser()">Unsuspend</button>
                    <button *ngIf="!(user.isSuspended)" class="btn btn-secondary" (click)="suspendUser()"
                        ngbTooltip="Temporarily block this user from being able to log in" placement="right">Suspend User
                    </button>
                </span>
                <span *ngIf="!canSuspend && !isSuperUser">
                    <span>Please contact </span>
                    <a href="mailto:support@simplifile.com">Support</a>
                    <span> to resolve.</span>
                </span>
            </div>
        </div>

        <hr class="hr-mini"/>
    </fieldset>

    <fieldset>
        <div class="row mb-3 extra-top" *ngIf="userCanLogin">
            <div class="col-md-9 extra-top">
                <div>Password</div>
                <button type="button" class="btn btn-secondary tooltip-interaction"
                        ngbTooltip="Send a link to create a new password to the user's email"
                        placement="right"
                        (click)="resetPassword()">Send Password Reset
                </button>
            </div>
        </div>
        <div class="row" *ngIf="isSuperUser && userCanLogin">
            <div class="col-md-9">
                <label>
                    <input type="checkbox" [(ngModel)]="mustChangePassword" (change)="save()"/>
                    <span> Force user to change password at the next login</span>
                </label>
            </div>
        </div>
        <div class="row mb-3" *ngIf="isSuperUser && userCanLogin">
            <div class="col-md-9">
                <label>
                    <input type="checkbox" [(ngModel)]="onlyAllowTokenLogin" (change)="save()"/>
                    <span> Allow only token-based logins for this user</span>
                </label>
            </div>
        </div>
        <div class="row mb-3 big-top-bold" *ngIf="isAdmin && userCanLogin && onlyAllowTokenLogin">
            <div class="col-md-9">
                <label>
                    <span>This user is blocked from logging in directly to Simplifile.</span>
                </label>
            </div>
        </div>
        <hr class="hr-mini"/>
    </fieldset>

    <fieldset *ngIf="isSuperUser && allSubmitterOrgs && allSubmitterOrgs.length">
        <div class="row mb-3 extra-top">
            <div class="col-md-3">
                <div>Default Organization</div>
                <sf-select
                    id="default-org" [name]="'default-org'"
                    [hidden]="allSubmitterOrgs.length <= 1"
                    [options]="allSubmitterOrgs"
                    [selectedOption]="selected.defaultOrg.id"
                    [placeholder]="'Select Organization'"
                    [isSingleSelect]="true"
                    [trackBy]="'id'" [labelBy]="'name'"
                    [hasFilter]="allSubmitterOrgs.length > 10"
                    (select)="defaultOrgSelected($event.$selection, $event.$isSelectionChanged)"
                    [hasClearButton]="true">
                </sf-select>
                <input type="text" class="form-control" [readOnly]="true" [hidden]="allSubmitterOrgs.length > 1"
                        aria-label="Default organization"
                    [value]="allSubmitterOrgs[0].name"/>
            </div>
        </div>
        <hr class="hr-mini"/>
    </fieldset>

    <fieldset *ngIf="showExternalID">
        <div class="row mb-3 extra-top">
            <div class="col-md-3 extra-top">
                <div>External Employee ID</div>
                <input type="text" class="form-control" aria-label="External employee ID"
                        [(ngModel)]="externalEmployeeID" (blur)="save()"/>
            </div>
        </div>
        <hr class="hr-mini"/>
    </fieldset>

</div>
