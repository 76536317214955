import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ManageFoldersModalComponent } from "../components/manage-folders-modal/manage-folders-modal.component";
import { combineLatest, Observable, of } from "rxjs";
import { MoveDocumentsModalComponent } from "../components/move-documents-modal/move-documents-modal.component";
import { finalize, map, switchMap } from "rxjs/operators";
import { SessionService, SpinnerService } from "@sf/common";
import { Folder } from "../interfaces/folder";
import { Document } from "../interfaces/document";
import { EsignDocumentService } from "./esign-document.service";
import { EsignOrganizationService } from "./esign-organization.service";

@Injectable({
    providedIn: "root"
})
export class ReviewFoldersService {
    constructor(
        private _modal: NgbModal,
        private _sessionService: SessionService,
        private _esignDocumentService: EsignDocumentService,
        private _esignOrganizationService: EsignOrganizationService,
        private _spinnerService: SpinnerService
    ) {}

    getReviewFolders(submitterIds: string[]): Observable<Folder[]> {
        return this._esignOrganizationService.getReviewFolders(submitterIds);
    }

    manageFolders(folders: Folder[], organizations: any[]) {
        let modal = this._modal.open(ManageFoldersModalComponent, {
            backdrop: "static",
            size: "lg"
        }).componentInstance;
        modal.folders = folders.map((folder) => Object.assign({}, folder));
        modal.organizations = organizations;

        return modal.result;
    }

    moveDocuments(
        documents: Document[],
        reviewFolderList: Folder[],
        requireComments: boolean,
        manageFolders?: Function
    ): Observable<[string[], string]> {
        if (documents.length === 0) return;

        let modal = this._modal.open(MoveDocumentsModalComponent);
        let component = modal.componentInstance;
        component.locations = reviewFolderList;
        if (manageFolders) {
            component.showFooter =
                this._sessionService.hasAnyPermissionInAnyOrg([
                    "submitter_signing_review_folders",
                    "trustee_review_folders"
                ]);
            component.manageFolders = () => {
                modal.close();
                manageFolders();
            };
        }
        component.requireComment = requireComments;
        let inRONEvent = !!documents.find((document) => !!document.eventId);
        component.inRONEvent = inRONEvent;

        let documentIDs = documents.map((doc) => doc.documentId);

        return component.result.pipe(
            // documents need to be removed from the event before moving to review
            switchMap((result: { location: string; comment: string }) => {
                if (inRONEvent) {
                    this._spinnerService.startSpinner();
                    return this._esignDocumentService
                        .removeDocumentsFromEvent(documentIDs)
                        .pipe(
                            map(() => result),
                            finalize(() => this._spinnerService.stopSpinner())
                        );
                } else {
                    return of(result);
                }
            }),
            switchMap((result: { location: string; comment: string }) => {
                let folderId = result.location;
                let comment = result.comment;
                let observable: Observable<string[]>;

                this._spinnerService.startSpinner();
                if (folderId === "signing") {
                    observable =
                        this._esignDocumentService.moveDocumentsToSigningQueue(
                            documentIDs,
                            comment
                        );
                } else if (folderId === "data-entry") {
                    observable = this._esignDocumentService.correctDocuments(
                        documentIDs,
                        comment
                    );
                } else if (folderId === "hold") {
                    observable =
                        this._esignDocumentService.moveDocumentsToHoldQueue(
                            documentIDs,
                            comment
                        );
                } else {
                    observable =
                        this._esignDocumentService.moveDocumentsToFolder(
                            documentIDs,
                            folderId,
                            comment
                        );
                }
                return combineLatest([observable, of(folderId)]).pipe(
                    finalize(() => this._spinnerService.stopSpinner())
                );
            })
        );
    }
}
