<sf-modal-header [title]="title"></sf-modal-header>
<div class="modal-body">
    <form [formGroup]="authForm">
        <div *ngIf="hasMOUEsignPermission" class="mb-3">
            <strong
                >Are you authorized to electronically sign a county legal agreement
                for your organization?</strong
            >
            <div class="mt-2">
                <label
                    ><input type="radio" value="yes" formControlName="authorized" />
                    Yes</label
                >
                <label class="ms-3"
                    ><input type="radio" value="no" formControlName="authorized" />
                    No, someone else needs to sign.</label
                >
            </div>
        </div>
        <div *ngIf="authForm.get('authorized').value === 'no'">
            <strong
                >Are any of following administrative users authorized to electronically sign a county legal agreement for your organization?</strong
            >
            <div class="mt-2">
                <label
                    ><input
                        type="radio"
                        value="yes"
                        formControlName="hasAccount"
                    />
                    Yes</label
                >
                <label class="ms-3"
                    ><input
                        type="radio"
                        value="no"
                        formControlName="hasAccount"
                    />
                    No</label
                >
            </div>
            <div
                *ngIf="authForm.get('hasAccount').value === 'yes'"
                class="user-input"
            >
                <sf-select
                    formControlName="authorizedSigner"
                    placeholder="Select User..."
                    [options]="authorizedSigners"
                    trackBy="username"
                    labelBy="name"
                    [isSingleSelect]="true"
                >
                </sf-select>
            </div>
            <div *ngIf="authForm.get('hasAccount').value === 'no'">
                <label for="authorizedSignerEmail"
                    ><small
                        >Enter an authorized signer's email address
                        (Required)</small
                    ></label
                >
                <input
                    id="authorizedSignerEmail"
                    class="form-control user-input"
                    type="text"
                    formControlName="authorizedSignerEmail"
                />
            </div>
            <div class="mt-2">
                <small
                    >Your authorized signer will receive an email guiding them
                    through the county legal agreement electronic signature
                    process.</small
                >
            </div>
        </div>
    </form>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>
