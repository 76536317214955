import { Injectable } from "@angular/core";
import { RpcClientService } from "@sf/common";
import { BehaviorSubject, merge, Observable, Subject } from "rxjs";
import { filter, shareReplay, switchMap } from "rxjs/operators";
import { Check } from "../interfaces/check";
import { SigningGroup } from "../interfaces/signing-group";
import { PowerOfAttorney } from "../interfaces/power-of-attorney";
import { DigitalSignatureRequirement } from "../interfaces/digital-signature-requirement";
import { PageInfo } from "../interfaces/page-info";
import { UIEventDocuments } from "../interfaces/ui-event-document";
import { DataEntryNavigation } from "../interfaces/data-entry-navigation";
import { ReferenceDocumentInfo } from "../interfaces/reference-document-info";
import { Document } from "../interfaces/document";
import { MatchingDocuments } from "../interfaces/matching-documents";

@Injectable({
    providedIn: "root"
})
export class EsignDocumentService {
    private _getCount$: BehaviorSubject<string> = new BehaviorSubject<string>(
        null
    );
    private _currentCount$ = new Subject<number>();
    private _analyzingReferenceDocument: boolean;
    countRpc$: Observable<number> = this._getCount$.pipe(
        filter((documentId) => !!documentId),
        switchMap((documentId) =>
            this._rpcClient.makeRequest<number>(
                "esign",
                "getESignDocumentCommentCount",
                {
                    documentId
                }
            )
        ),
        shareReplay({ refCount: true, bufferSize: 1 })
    );

    commentCount$ = merge(this.countRpc$, this._currentCount$).pipe(
        shareReplay({ refCount: true, bufferSize: 1 })
    );

    constructor(private _rpcClient: RpcClientService) {}

    getChecks(organizationIds: string[]): Observable<Check[]> {
        return this._rpcClient.makeRequest("esign", "getChecks", {
            organizationIds
        });
    }

    getESignReviewQueueDocuments(
        organizationIds: string[]
    ): Observable<Document[]> {
        return this._rpcClient.makeRequest(
            "esign",
            "getESignReviewQueueDocuments",
            {
                organizationIds
            }
        );
    }

    getESignQueueDocuments(): Observable<Document[]> {
        return this._rpcClient.makeRequest(
            "esign",
            "getESignQueueDocuments",
            {}
        );
    }

    submitPaperPackages(packageIDs: string[]) {
        return this._rpcClient.makeRequest("erecord", "submitPaperPackages", {
            packageIDs
        });
    }

    createNewCheck(
        submitterId: string,
        recipientId?: string
    ): Observable<string> {
        return this._rpcClient.makeRequest("esign", "createNewCheck", {
            submitterId,
            recipientId
        });
    }

    createNewCheckByProject(
        projectId: string,
        recipientId?: string
    ): Observable<string> {
        return this._rpcClient.makeRequest("esign", "createNewCheckByProject", {
            projectId,
            recipientId
        });
    }

    getNonVoidChecksForPackage(packageId: string): Observable<string[]> {
        return this._rpcClient.makeRequest(
            "esign",
            "getNonVoidChecksForPackage",
            {
                packageId
            }
        );
    }

    createCheckForPaperPackage(packageId: string): Observable<string> {
        return this._rpcClient.makeRequest(
            "esign",
            "createCheckForPaperPackage",
            {
                packageId
            }
        );
    }

    voidChecks(documentIds: string[], reason: string): Observable<void> {
        return this._rpcClient.makeRequest("esign", "voidChecks", {
            documentIds,
            reason
        });
    }

    deleteChecks(documentIds: string[]): Observable<void> {
        return this._rpcClient.makeRequest("esign", "deleteChecks", {
            documentIds
        });
    }

    printCoverLetterValidation(documentIds: string[]): Observable<string> {
        return this._rpcClient.makeRequest(
            "esign",
            "printCoverLetterValidation",
            {
                documentIds
            }
        );
    }

    exportChecks(documentIds: string[]): Observable<string> {
        return this._rpcClient.makeRequest("esign", "exportChecks", {
            documentIds
        });
    }

    searchESignDocuments(
        query: string,
        signingQueue: boolean
    ): Observable<any[]> {
        return this._rpcClient.makeRequest("esign", "searchESignDocuments", {
            query,
            signingQueue
        });
    }

    correctDocuments(
        documentIds: string[],
        comment: string
    ): Observable<string[]> {
        return this._rpcClient.makeRequest("esign", "correctDocuments", {
            documentIds,
            comment
        });
    }

    moveDocumentsToHoldQueue(
        documentIds: string[],
        comment: string
    ): Observable<string[]> {
        return this._rpcClient.makeRequest(
            "esign",
            "moveDocumentsToHoldQueue",
            {
                documentIds,
                comment
            }
        );
    }

    moveDocumentsToSigningQueue(
        documentIDs: string[],
        comment: string
    ): Observable<string[]> {
        return this._rpcClient.makeRequest(
            "esign",
            "moveDocumentsToSigningQueue",
            {
                documentIDs,
                comment
            }
        );
    }

    moveDocumentsToFolder(
        documentIDs: string[],
        folderId: string,
        comment: string
    ): Observable<string[]> {
        return this._rpcClient.makeRequest("esign", "moveDocumentsToFolder", {
            documentIDs,
            folderId,
            comment
        });
    }

    deleteDocuments(documentIds: string[]): Observable<void> {
        return this._rpcClient.makeRequest("esign", "deleteDocuments", {
            documentIds
        });
    }

    claimDocumentForSigning(
        organizationId: string,
        autoAssignGroup: SigningGroup,
        documentIds: string[]
    ): Observable<Document> {
        return this._rpcClient.makeRequest("esign", "claimDocumentForSigning", {
            organizationId,
            autoAssignGroup,
            documentIds
        });
    }

    claimSpecificDocumentForSigning(documentId: string): Observable<Document> {
        return this._rpcClient.makeRequest(
            "esign",
            "claimSpecificDocumentForSigning",
            {
                documentId
            }
        );
    }

    getAutoAssignDocumentCount(
        group: any,
        documentIds: string[]
    ): Observable<number> {
        return this._rpcClient.makeRequest(
            "esign",
            "getAutoAssignDocumentCount",
            {
                group,
                documentIds
            }
        );
    }

    getAutoAssignDocuments(
        group: any,
        documentIds: string[]
    ): Observable<Document[]> {
        return this._rpcClient.makeRequest("esign", "getAutoAssignDocuments", {
            group,
            documentIds
        });
    }

    resetSigners(documentIds: string[]): Observable<void> {
        return this._rpcClient.makeRequest("esign", "resetSigners", {
            documentIds
        });
    }

    endSigning(organizationIds: string[]): Observable<void> {
        return this._rpcClient.makeRequest("esign", "endSigning", {
            organizationIds
        });
    }

    getPackageIdsForArchive(documentIds: string[]): Observable<string[]> {
        return this._rpcClient.makeRequest("esign", "getPackageIdsForArchive", {
            documentIds
        });
    }

    archiveStandAloneDocuments(
        documentIds: string[],
        archive: boolean
    ): Observable<void> {
        return this._rpcClient.makeRequest(
            "esign",
            "archiveStandAloneDocuments",
            {
                documentIds,
                archive
            }
        );
    }

    archivePackages(packageIDs: string[], archive: boolean): Observable<void> {
        return this._rpcClient.makeRequest("erecord", "archivePackages", {
            packageIDs,
            archived: archive
        });
    }

    resubmitUnsignableDocuments(documentIds: string[]): Observable<string> {
        return this._rpcClient.makeRequest(
            "esign",
            "resubmitUnsignableDocuments",
            {
                documentIds
            }
        );
    }

    _getUploadError(errors: string[]) {
        let errorsDisplay: Set<string> = new Set<string>();
        let errorDisplay: string;
        errors.forEach((error) => {
            if (error === "No Analysis Mapping matched this document.") {
                errorsDisplay.add(
                    "This document is not recognized. Contact support to correct the issue."
                );
            } else if (
                error ===
                "The name of the document could not be found on the document."
            ) {
                errorsDisplay.add(
                    "The name of the document could not be found on the document. Contact support to correct the issue."
                );
            } else if (error.startsWith("Duplicate document")) {
                errorsDisplay.add(
                    "This document is a duplicate of a document you have already uploaded."
                );
            }
        });

        if (errorsDisplay.size === 0) {
            errorDisplay = "One or more files had an analysis mapping error.";
        } else if (errorsDisplay.size === 1) {
            errorsDisplay.forEach((error) => {
                errorDisplay = error;
            });
        } else {
            errorDisplay = "The following errors have occurred:";
            errorsDisplay.forEach((error) => {
                errorDisplay += "<br><br>• " + error;
            });
        }
        return errorDisplay;
    }

    getESignUnsignableDocuments(
        organizationIds: string[]
    ): Observable<Document[]> {
        return this._rpcClient.makeRequest(
            "esign",
            "getESignUnsignableDocuments",
            {
                organizationIds
            }
        );
    }

    getESignUploadErrorDocuments(
        organizationIds: string[]
    ): Observable<Document[]> {
        return this._rpcClient.makeRequest(
            "esign",
            "getESignUploadErrorDocuments",
            {
                organizationIds
            }
        );
    }

    checkOriginalImageFiles(documentIds: string[]): Observable<any> {
        return this._rpcClient.makeRequest("esign", "checkOriginalImageFiles", {
            documentIds
        });
    }

    getDataEntryDocuments(): Observable<Document[]> {
        return this._rpcClient.makeRequest(
            "esign",
            "getDataEntryDocuments",
            {}
        );
    }

    getDataEntryDocumentGroups(): Observable<Record<string, number>> {
        return this._rpcClient.makeRequest(
            "esign",
            "getDataEntryDocumentGroups",
            {}
        );
    }

    createNonRecordableDocument(
        submitterId: string,
        templateId: string,
        paper: boolean,
        name: string,
        state: string,
        projectId: string
    ): Observable<string> {
        return this._rpcClient.makeRequest(
            "esign",
            "createNonRecordableDocument",
            { submitterId, templateId, paper, name, state, projectId }
        );
    }

    getNonRecordableTemplates(): Observable<any[]> {
        return this._rpcClient.makeRequest(
            "esign",
            "getNonRecordableTemplates",
            {}
        );
    }
    getBundlesForClaim(organizationId: string): Observable<any[]> {
        return this._rpcClient.makeRequest("esign", "getBundlesForClaim", {
            organizationId
        });
    }

    getClaimedBundles(organizationId: string): Observable<any[]> {
        return this._rpcClient.makeRequest("esign", "getClaimedBundles", {
            organizationId
        });
    }

    getBundleOwner(organizationId: string, bundle: string): Observable<any> {
        return this._rpcClient.makeRequest("esign", "getBundleOwner", {
            organizationId,
            bundle
        });
    }

    claimBundle(organizationId: string, bundle: string): Observable<any[]> {
        return this._rpcClient.makeRequest("esign", "claimBundle", {
            organizationId,
            bundle
        });
    }

    unclaimBundle(organizationId: string, bundle: string): Observable<any[]> {
        return this._rpcClient.makeRequest("esign", "unclaimBundle", {
            organizationId,
            bundle
        });
    }

    getESignDocumentCount(): Observable<number> {
        return this._rpcClient.makeRequest(
            "esign",
            "getESignDocumentCount",
            {}
        );
    }

    getDataEntryDocumentCount(): Observable<number> {
        return this._rpcClient.makeRequest(
            "esign",
            "getDataEntryDocumentCount",
            {}
        );
    }

    getAdminESignDocumentCount(): Observable<number> {
        return this._rpcClient.makeRequest(
            "esign",
            "getAdminESignDocumentCount",
            {}
        );
    }

    getReviewESignDocumentCount(): Observable<number> {
        return this._rpcClient.makeRequest(
            "esign",
            "getReviewESignDocumentCount",
            {}
        );
    }

    getESignUnsignableDocumentCount(): Observable<number> {
        return this._rpcClient.makeRequest(
            "esign",
            "getESignUnsignableDocumentCount",
            {}
        );
    }

    getUploadedDocumentErrorsCount(): Observable<number> {
        return this._rpcClient.makeRequest(
            "esign",
            "getUploadedDocumentErrorsCount",
            {}
        );
    }

    getPrintOnlyDocumentCount(): Observable<number> {
        return this._rpcClient.makeRequest(
            "esign",
            "getPrintOnlyDocumentCount",
            {}
        );
    }

    getCheckCount(): Observable<number> {
        return this._rpcClient.makeRequest("esign", "getCheckCount", {});
    }

    getPrintOnlyDocuments(organizationIds: string[]): Observable<Document[]> {
        return this._rpcClient.makeRequest("esign", "getPrintOnlyDocuments", {
            organizationIds
        });
    }

    getCompletedDocumentsByOrganizationAndDate(
        organizationIds: string[],
        startDate: any,
        endDate: any
    ): Observable<Document[]> {
        return this._rpcClient.makeRequest(
            "esign",
            "getCompletedDocumentsByOrganizationAndDate",
            { organizationIds, startDate, endDate }
        );
    }

    getAdminESignQueueDocuments(
        organizationIds: string[]
    ): Observable<Document[]> {
        return this._rpcClient.makeRequest(
            "esign",
            "getAdminESignQueueDocuments",
            { organizationIds }
        );
    }

    advancedSearchESignDocuments(
        searchParams: any
    ): Observable<{ queueDocuments: Document[]; total: number }> {
        return this._rpcClient.makeRequest(
            "esign",
            "advancedSearchESignDocuments",
            {
                searchParams
            }
        );
    }

    changeESignDocumentSigners(
        documentIds: string[],
        assignedSigners: any
    ): Observable<void> {
        return this._rpcClient.makeRequest(
            "esign",
            "changeESignDocumentSigners",
            {
                documentIds,
                assignedSigners
            }
        );
    }

    findAndReplaceSigners(
        documentIds: string[],
        find: string,
        replace: string,
        type: string,
        searchParams: any
    ): Observable<string[]> {
        return this._rpcClient.makeRequest("esign", "findAndReplaceSigners", {
            documentIds,
            find,
            replace,
            type,
            searchParams
        });
    }

    loadBundles(organizationIds: string[]): Observable<string[]> {
        return this._rpcClient.makeRequest("esign", "loadBundles", {
            organizationIds
        });
    }

    getNextBundleComplianceDate(
        organizationId: string,
        create: boolean
    ): Observable<Date> {
        return this._rpcClient.makeRequest(
            "esign",
            "getNextBundleComplianceDate",
            {
                organizationId,
                create
            }
        );
    }

    getBundleCreateDocumentCount(
        organizationId: string,
        complianceDate: Date
    ): Observable<number> {
        return this._rpcClient.makeRequest(
            "esign",
            "getBundleCreateDocumentCount",
            {
                organizationId,
                complianceDate
            }
        );
    }

    createBundles(
        organizationId: string,
        complianceDate: string,
        docsPerBundle: number,
        bundleCount: number
    ): Observable<string[]> {
        return this._rpcClient.makeRequest("esign", "createBundles", {
            organizationId,
            complianceDate,
            docsPerBundle,
            bundleCount
        });
    }

    getBundlesForDate(
        organizationId: string,
        date: string
    ): Observable<string[]> {
        return this._rpcClient.makeRequest("esign", "getBundlesForDate", {
            organizationId,
            date
        });
    }

    getDataEntryBundlesForDate(
        organizationId: string,
        date: string
    ): Observable<string[]> {
        return this._rpcClient.makeRequest(
            "esign",
            "getDataEntryBundlesForDate",
            {
                organizationId,
                date
            }
        );
    }

    undoCreateBundles(
        organizationId: string,
        bundles: string[]
    ): Observable<void> {
        return this._rpcClient.makeRequest("esign", "undoCreateBundles", {
            organizationId,
            bundles
        });
    }

    getBundleDates(
        organizationId: string,
        rush: boolean
    ): Observable<string[]> {
        return this._rpcClient.makeRequest("esign", "getBundleDates", {
            organizationId,
            rush
        });
    }

    getNeedsPOADocuments(organizationIds: string[]): Observable<Document[]> {
        return this._rpcClient.makeRequest("esign", "getNeedsPOADocuments", {
            organizationIds
        });
    }

    getNeedsPOADocumentCount(): Observable<number> {
        return this._rpcClient.makeRequest(
            "esign",
            "getNeedsPOADocumentCount",
            {}
        );
    }

    getArchivedDocuments(
        organizationIds: string[],
        startDate: any,
        endDate: any,
        limit: number
    ): Observable<Document[]> {
        return this._rpcClient.makeRequest("esign", "getArchivedDocuments", {
            organizationIds,
            startDate,
            endDate,
            limit
        });
    }

    addPOAToPackage(
        documentId: string,
        copyFromDocumentId: string,
        poaId: string
    ): Observable<string> {
        return this._rpcClient.makeRequest("esign", "addPOAToPackage", {
            documentId,
            copyFromDocumentId,
            poaId
        });
    }

    addPOAForDocument(
        documentId: string,
        poaName: string
    ): Observable<PowerOfAttorney> {
        return this._rpcClient.makeRequest("esign", "addPOAForDocument", {
            documentId,
            poaName
        });
    }

    removePackage(packageId: string): Observable<any> {
        return this._rpcClient.makeRequest("erecord", "removePackages", {
            packageIDs: [packageId]
        });
    }

    getSigningQueuesDocumentCount(): Observable<number> {
        return this._rpcClient.makeRequest(
            "esign",
            "getESignDocumentCount",
            {}
        );
    }

    getReviewQueuesDocumentCount(): Observable<number> {
        return this._rpcClient.makeRequest(
            "esign",
            "getReviewESignDocumentCount",
            {}
        );
    }

    getCheckNumber(documentId: string): Observable<number> {
        return this._rpcClient.makeRequest("esign", "getCheckNumber", {
            documentId
        });
    }

    getCoverSheetId(documentId: string): Observable<string> {
        return this._rpcClient.makeRequest("esign", "getCoverSheetId", {
            documentId
        });
    }

    addCoverSheet(documentId: string, details: any) {
        return this._rpcClient.makeRequest("document", "addPagesToDocument", {
            documentId: documentId + "/Cover_Sheet",
            details: details
        });
    }

    removeCoverSheet(documentId: string): Observable<void> {
        return this._rpcClient.makeRequest("esign", "removeCoverSheet", {
            documentId
        });
    }

    generateSimultaneousRecordingDocuments(
        documentIds: string[]
    ): Observable<void> {
        return this._rpcClient.makeRequest(
            "esign",
            "generateSimultaneousRecordingDocuments",
            {
                documentIds
            }
        );
    }

    requiresDigitalSignature(
        documentId: string,
        signatureField: string,
        isRON: boolean
    ): Observable<DigitalSignatureRequirement> {
        return this._rpcClient.makeRequest(
            "esign",
            "requiresDigitalSignature",
            {
                documentId,
                signatureField,
                isRON
            }
        );
    }

    isDigitalSignatureRequiredForEvent(eventId: string): Observable<boolean> {
        return this._rpcClient.makeRequest(
            "esign",
            "isDigitalSignatureRequiredForEvent",
            { eventId }
        );
    }

    signDocument(
        documentId: string,
        signatureField: string,
        isRON: boolean,
        inEvent: boolean
    ): Observable<{ pageInfo: PageInfo[] }> {
        return this._rpcClient.makeRequest("esign", "signDocument", {
            documentId,
            signatureField,
            isRON,
            inEvent
        });
    }

    addDocumentSignature(
        documentId: string,
        signatureField: string,
        certificate: string,
        password: string,
        isRON: boolean
    ): Observable<any> {
        return this._rpcClient.makeRequest("esign", "addDocumentSignature", {
            documentId,
            signatureField,
            certificate,
            password,
            isRON
        });
    }

    setDan(documentId: string, dan: string): Observable<void> {
        return this._rpcClient.makeRequest("esign", "setDan", {
            documentId,
            dan
        });
    }

    applyNonSignatureAnchor(
        documentId: string,
        tagField: string,
        value: string,
        inEvent: boolean
    ): Observable<{ pageInfo: PageInfo[] }> {
        return this._rpcClient.makeInternationalRequest(
            "esign",
            "applyNonSignatureAnchor",
            {
                documentId,
                tagField,
                value,
                inEvent
            }
        );
    }

    applyCheckmarkGroup(
        documentId: string,
        groupName: string,
        values: Record<string, string>,
        inEvent: boolean
    ): Observable<{ pageInfo: PageInfo[] }> {
        return this._rpcClient.makeInternationalRequest(
            "esign",
            "applyCheckmarkGroup",
            {
                documentId,
                groupName,
                values,
                inEvent
            }
        );
    }

    complete2PhaseSigning(
        eventId: string,
        isNotary: boolean,
        isWitnessed: boolean,
        b64EncodedP12Bytes?: string,
        p12Password?: string
    ): Observable<any> {
        return this._rpcClient.makeRequest("esign", "complete2PhaseSigning", {
            eventId,
            isWitnessed,
            isNotary,
            b64EncodedP12Bytes,
            p12Password
        });
    }

    getEventDocuments(eventId: string): Observable<UIEventDocuments> {
        return this._rpcClient.makeInternationalRequest(
            "esign",
            "getEventDocuments",
            {
                eventId
            }
        );
    }

    setDocumentReviewed(documentId: string): Observable<any> {
        return this._rpcClient.makeRequest("esign", "setDocumentReviewed", {
            documentId
        });
    }

    ocrReferenceDocument(
        documentId: string,
        referenceDocumentId: string
    ): Observable<string> {
        return this._rpcClient.makeRequest("esign", "ocrReferenceDocument", {
            documentId,
            referenceDocumentId
        });
    }

    cancelOcrReferenceFile(documentId: string): Observable<void> {
        return this._rpcClient.makeRequest("esign", "cancelOcrReferenceFile", {
            documentId
        });
    }

    getNonRecordableDocumentId(
        documentId: string,
        documentType: string
    ): Observable<string> {
        return this._rpcClient.makeRequest(
            "esign",
            "getNonRecordableDocumentId",
            { documentId, documentType }
        );
    }

    createSigningEvent(
        submitterId: string,
        notaryId: string,
        documentIds: string[]
    ): Observable<string> {
        return this._rpcClient.makeRequest("esign", "createSigningEvent", {
            submitterId,
            notaryId,
            documentIds
        });
    }

    skipDocumentSignature(
        documentId: string,
        signatureField: string
    ): Observable<void> {
        return this._rpcClient.makeInternationalRequest(
            "esign",
            "skipDocumentSignature",
            {
                documentId,
                signatureField
            }
        );
    }

    removeDocumentsFromEvent(documentIds: string[]): Observable<void> {
        return this._rpcClient.makeRequest(
            "esign",
            "removeDocumentsFromEvent",
            {
                documentIds
            }
        );
    }

    removeUnsignedDocumentsFromEvent(eventId: string): Observable<string[]> {
        return this._rpcClient.makeRequest(
            "esign",
            "removeUnsignedDocumentsFromEvent",
            {
                eventId
            }
        );
    }

    getDataEntryNavigation(
        documentId: string
    ): Observable<DataEntryNavigation> {
        return this._rpcClient.makeRequest("esign", "getDataEntryNavigation", {
            documentId
        });
    }

    getSignersNeedingSetup(
        notaryId: string,
        documentIds: string[],
        isRON: boolean
    ): Observable<string[]> {
        return this._rpcClient.makeRequest("esign", "getSignersNeedingSetup", {
            notaryId,
            documentIds,
            isRON
        });
    }

    getSignersInActiveEvent(documentIds: string[]): Observable<string[]> {
        return this._rpcClient.makeRequest("esign", "getSignersInActiveEvent", {
            documentIds
        });
    }

    getESignDocumentData(documentID: any): Observable<any> {
        return this._rpcClient.makeRequest("esign", "getSignDocumentData", {
            documentID
        });
    }

    addESignDocumentComment(
        documentID: string,
        comment: string
    ): Observable<any> {
        return this._rpcClient.makeRequest("esign", "addDocumentComment", {
            documentID,
            comment
        });
    }

    getESignDocumentCommentCount(documentID?: string): void {
        if (!documentID && this._getCount$.getValue() !== null) {
            this._getCount$.next(this._getCount$.getValue());
        } else if (documentID) {
            this._getCount$.next(documentID);
        }
    }

    updateCommentCount(count: number) {
        this._currentCount$.next(count);
    }

    setDocumentName(documentId: string, name: string): Observable<void> {
        return this._rpcClient.makeRequest("document", "setDocumentName", {
            documentId,
            name
        });
    }

    setESignDocumentName(documentId: string, name: string): Observable<void> {
        return this._rpcClient.makeRequest("esign", "setDocumentName", {
            documentId,
            name
        });
    }

    setRecipientVerified(documentId: string): Observable<void> {
        return this._rpcClient.makeRequest("esign", "setRecipientVerified", {
            documentId
        });
    }

    generateTemplatePreviewImage(
        documentId: string,
        preview: boolean
    ): Observable<any> {
        return this._rpcClient.makeRequest(
            "esign",
            "generateTemplatePreviewImage",
            {
                documentId,
                preview
            }
        );
    }

    saveAndValidateTemplateData(
        documentId: string,
        templateData: any,
        bValidate: boolean,
        bSample: boolean
    ): Observable<any> {
        return this._rpcClient.makeRequest(
            "esign",
            "saveAndValidateTemplateData",
            {
                documentId,
                templateData,
                bValidate,
                bSample
            },
            true
        );
    }

    updateRecommendedAddresses(
        documentId: string,
        verifiedAddresses: any[]
    ): Observable<void> {
        return this._rpcClient.makeRequest(
            "esign",
            "updateRecommendedAddresses",
            {
                documentId,
                verifiedAddresses
            }
        );
    }

    getTemplateData(documentId: string): Observable<any> {
        return this._rpcClient.makeRequest("esign", "getTemplateData", {
            documentId: documentId
        });
    }

    getTemplateForm(documentId: string, submitterId: string): Observable<any> {
        return this._rpcClient.makeRequest("esign", "getTemplateForm", {
            documentId,
            submitterId
        });
    }

    getReferenceDocumentInfo(
        documentId: string
    ): Observable<ReferenceDocumentInfo> {
        return this._rpcClient.makeRequest(
            "esign",
            "getReferenceDocumentInfo",
            {
                documentId
            }
        );
    }

    addReferenceDocument(
        documentId: string,
        documentMap: any
    ): Observable<void> {
        return this._rpcClient.makeRequest("esign", "addReferenceDocument", {
            documentId,
            documentMap
        });
    }

    removeReferenceDocument(
        documentId: string,
        referenceDocumentId: string
    ): Observable<void> {
        return this._rpcClient.makeRequest("esign", "removeReferenceDocument", {
            documentId,
            referenceDocumentId
        });
    }

    getDocDetails(documentId: string): Observable<any> {
        return this._rpcClient.makeRequest("document", "getDocumentDetails", {
            documentId
        });
    }

    getDocumentPages(documentId: string): Observable<any> {
        return this._rpcClient.makeRequest("document", "getDocumentPages", {
            documentId
        });
    }

    analyzePDFAndCreateDocUI(
        pdfData: any,
        submitterId: string,
        packageId: string,
        filename: string
    ): Observable<any> {
        return this._rpcClient.makeRequest(
            "esign",
            "analyzePDFAndCreateDocUI",
            {
                pdfData,
                submitterId,
                packageId,
                filename
            }
        );
    }

    searchReferenceDocuments(
        party: string,
        min: string,
        loanNumber: string,
        parcelId: string,
        startDate: any,
        endDate: any,
        street: string,
        city: string,
        state: string,
        zip: string,
        loanAmount: string
    ): Observable<any> {
        return this._rpcClient.makeRequest(
            "esign",
            "searchReferenceDocuments",
            {
                party,
                min,
                loanNumber,
                parcelId,
                startDate,
                endDate,
                street,
                city,
                state,
                zip,
                loanAmount
            }
        );
    }

    packageHasPrintedCheck(packageIds: string[]): Observable<boolean> {
        return this._rpcClient.makeRequest("esign", "packageHasPrintedCheck", {
            packageIds
        });
    }

    getESignDocumentIdsByLoanNumber(loanNumber: string): Observable<string[]> {
        return this._rpcClient.makeRequest(
            "esign",
            "getESignDocumentIdsByLoanNumber",
            { loanNumber }
        );
    }

    setAnalyzingReferenceDocument(value: boolean) {
        this._analyzingReferenceDocument = value;
    }

    getAnalyzingReferenceDocument() {
        return this._analyzingReferenceDocument;
    }

    getMersAdjustments(
        documentId: string
    ): Observable<{ prepend: string; append: string }> {
        return this._rpcClient.makeRequest("esign", "getMersAdjustments", {
            documentId
        });
    }

    getSignersForDataEntry(
        documentId: string,
        isMers: boolean
    ): Observable<{ displayLabel: string; valueName: string }[]> {
        return this._rpcClient.makeRequest("esign", "getSignersForDataEntry", {
            documentId,
            isMers
        });
    }

    allowElectronicSignaturesForPaperDocuments(
        documentId: string
    ): Observable<boolean> {
        return this._rpcClient.makeRequest(
            "esign",
            "allowElectronicSignaturesForPaperDocuments",
            { documentId }
        );
    }

    findAndGenerateMOU(
        submitterId: string,
        recipientId: string,
        ignoreSubmitterFields: boolean
    ): Observable<{ documentId: string; status: string }> {
        return this._rpcClient.makeRequest("esign", "findAndGenerateMOU", {
            submitterId,
            recipientId,
            ignoreSubmitterFields
        });
    }

    signMOU(
        documentId: string,
        signatureField: string
    ): Observable<{ pageInfo: PageInfo[] }> {
        return this._rpcClient.makeRequest("esign", "signMOU", {
            documentId,
            signatureField
        });
    }

    approveMOU(documentId: string): Observable<void> {
        return this._rpcClient.makeRequest("esign", "approveMOU", {
            documentId
        });
    }

    anyEventActive(eventIds: string[]): Observable<boolean> {
        return this._rpcClient.makeRequest("esign", "anyEventActive", {
            eventIds
        });
    }

    getDocumentImage(documentId: string, format: string): Observable<any> {
        return this._rpcClient.makeRequest("esign", "getDocumentImage", {
            documentId,
            format
        });
    }

    setSampleDocument(documentId: string, sample: boolean): Observable<void> {
        return this._rpcClient.makeRequest("esign", "setSampleDocument", {
            documentId,
            sample
        });
    }

    uploadAnalysisFile(
        submitterID: string,
        projectID: string,
        contents: string,
        filename: string
    ): Observable<any> {
        return this._rpcClient.makeRequest("erecord", "uploadAnalysisFile", {
            submitterID,
            projectID,
            contents,
            filename
        });
    }

    getDocumentsUsingDefaultValues(
        submitterId: string,
        group: string,
        documentType: string,
        fields: any
    ): Observable<MatchingDocuments[]> {
        return this._rpcClient.makeRequest(
            "esign",
            "getDocumentsUsingDefaultValues",
            {
                submitterId,
                group,
                documentType,
                fields
            }
        );
    }

    updateDocumentsWithDefaultValues(
        submitterId: string,
        matchingDocuments: MatchingDocuments[],
        fields: any
    ): Observable<void> {
        return this._rpcClient.makeRequest(
            "esign",
            "updateDocumentsWithDefaultValues",
            {
                submitterId,
                matchingDocuments,
                fields
            }
        );
    }

    getESignDocumentsByIds(documentIds: string[]): Observable<Document[]> {
        return this._rpcClient.makeRequest("esign", "getESignDocumentsByIds", {
            documentIds
        });
    }

    mobileSigningCloseModal(eventId: string): Observable<void> {
        return this._rpcClient.makeInternationalRequest(
            "esign",
            "mobileSigningCloseModal",
            {
                eventId
            }
        );
    }

    mobileSigningScroll(eventId: string, scroll: number): Observable<void> {
        return this._rpcClient.makeInternationalRequest(
            "esign",
            "mobileSigningScroll",
            {
                eventId,
                scroll
            }
        );
    }

    mobileSigningContextChange(
        eventId: string,
        context: string
    ): Observable<void> {
        return this._rpcClient.makeInternationalRequest(
            "esign",
            "mobileSigningContextChange",
            {
                eventId,
                context
            }
        );
    }

    mobileSigningPlaceholderAction(
        eventId: string,
        documentId: string,
        field: string,
        showModal: boolean,
        modalValue?: any
    ): Observable<void> {
        return this._rpcClient.makeInternationalRequest(
            "esign",
            "mobileSigningPlaceholderAction",
            {
                eventId,
                documentId,
                field,
                showModal,
                modalValue
            }
        );
    }

    mobileSigningSignatureModal(
        eventId: string,
        step: "signature" | "initials",
        type: "text" | "draw",
        image?: string
    ): Observable<void> {
        return this._rpcClient.makeInternationalRequest(
            "esign",
            "mobileSigningSignatureModal",
            {
                eventId,
                step,
                type,
                image
            }
        );
    }

    getEventDocumentsForCurrentParticipant(
        eventId: string
    ): Observable<UIEventDocuments> {
        return this._rpcClient.makeInternationalRequest(
            "esign",
            "getEventDocumentsForCurrentParticipant",
            { eventId }
        );
    }

    analyzePDFsAndCreateDocsUI(
        submitterId: string,
        system: string,
        projectId: string,
        pdfDataArray: string[],
        filenames: string[],
        userId: string
    ) {
        return this._rpcClient.makeRequest(
            "esign",
            "analyzePDFsAndCreateDocsUI",
            { pdfDataArray, submitterId, system, projectId, filenames, userId }
        );
    }

    getAdditionalReferenceTypes(): Observable<Record<string, string>> {
        return this._rpcClient.makeRequest(
            "esign",
            "getAdditionalReferenceTypes",
            {}
        );
    }

    setReferenceDocumentNameAndType(
        eSignDocumentID: string,
        referenceDocumentID: string,
        name: string,
        type: string
    ) {
        return this._rpcClient.makeRequest(
            "document",
            "setReferenceDocumentNameAndType",
            { eSignDocumentID, referenceDocumentID, name, type }
        );
    }

    renderDocumentsForEvent(eventId: string): Observable<void> {
        return this._rpcClient.makeRequest("esign", "renderDocumentsForEvent", {
            eventId
        });
    }

    claimDocumentForDataEntry(documentId: string): Observable<void> {
        return this._rpcClient.makeRequest(
            "esign",
            "claimDocumentForDataEntry",
            {
                documentId
            }
        );
    }

    getDataEntryUser(documentId: string): Observable<string> {
        return this._rpcClient.makeRequest("esign", "getDataEntryUser", {
            documentId
        });
    }
}
