import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BankingExportColumn } from "@sf/submitter/common";
import { RpcClientService } from "@sf/common";
import { CustomBankingExportReport } from "@sf/submitter/main";

@Injectable({
    providedIn: "root"
})
export class SubmitterReportBuilderService {
    constructor(protected _rpcClient: RpcClientService) {}

    getBankingExportReportAvailableFieldsJSON(
        orgID: string
    ): Observable<BankingExportColumn> {
        return this._rpcClient.makeRequest(
            "reporting",
            "getBankingExportReportAvailableFieldsJSON",
            {
                submitterID: orgID
            }
        );
    }

    getBankingExportReportConfigurationJSON(orgID: string): Observable<any> {
        return this._rpcClient.makeRequest(
            "reporting",
            "getBankingExportReportConfigurationJSON",
            {
                submitterID: orgID
            }
        );
    }

    getProjectStatusReportAvailableFieldsJSON(
        orgID: string
    ): Observable<BankingExportColumn> {
        return this._rpcClient.makeRequest(
            "reporting",
            "getProjectStatusReportAvailableFieldsJSON",
            {
                submitterID: orgID
            }
        );
    }

    getCustomBankingExportReports(): Observable<CustomBankingExportReport[]> {
        return this._rpcClient.makeRequest(
            "admin",
            "getCustomBankingExportReports"
        );
    }

    saveCustomBankingExportReport(
        customBankingExportReport: CustomBankingExportReport
    ): Observable<string> {
        return this._rpcClient.makeRequest(
            "admin",
            "saveCustomBankingExportReport",
            customBankingExportReport
        );
    }

    deleteCustomBankingExportReport(id: string): Observable<void> {
        return this._rpcClient.makeRequest(
            "admin",
            "deleteCustomBankingExportReport",
            {
                id
            }
        );
    }
}
