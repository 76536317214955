import { NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import { Name } from "./name.interface";
import { IconName, IconPrefix } from "@fortawesome/pro-solid-svg-icons";

export const STICKY_EVENT_TYPE: string = "sign-event.edit-dialog.event-type";
export const HOME_USER_SETTING = "sign-event.home";

export interface SignEventUserSettings {
    showOnlyMine: boolean;
    hideTestEvents: boolean;
    bucketSortOrders: any;
    viewMode: string;
}

export enum EEligibilityStatus {
    YES = "YES",
    NO = "NO"
}

export interface EventAction {
    name: EventActionType;
    iconName: IconName;
    iconPrefix: IconPrefix;
    isPermitted: boolean;
    permission: string; // Permission required to execute this action.
    tooltip?: string;
    types: StatusType[];
    ariaLabel?: string;
}

export enum EventActionType {
    VIEW = "View",
    CANCEL = "Cancel",
    COPY = "Copy",
    START_EVENT = "Start Session",
    ARCHIVE = "Archive",
    UNARCHIVE = "Unarchive",
    DOWNLOAD = "Download"
}

export enum Participant {
    buyer = "Buyer",
    seller = "Seller",
    realtor = "Realtor"
}

export interface SignEventParticipant {
    name: string;
    type: Participant;
}

export enum StatusType {
    DRAFT = "DRAFT",
    SENT = "SENT",
    COMPLETE = "COMPLETE",
    CANCELLED = "CANCELLED",
    VOIDED = "VOIDED",
    EXPIRED = "EXPIRED",
    ARCHIVED = "ARCHIVED",
    DELETED = "DELETED"
}

export enum PageType {
    LIST = "LIST",
    WORKFLOW = "WORKFLOW",
    PARTICIPANTS = "PARTICIPANTS",
    DOCUMENTS = "DOCUMENTS",
    ACTIVITY = "ACTIVITY"
}

export enum ESignEventNotarizationType {
    IPEN = "IPEN",
    RON = "RON"
}

export interface UIAddress {
    street1: string;
    street2?: string;
    street3?: string;
    city: string;
    countyCode?: string; // fips
    state: string; // State abbreviation
    zipCode: string;
}

export interface UIESignEventUser {
    name: Name;
    email: string;
    phone: string;
    username: string;
    // The following fields are set and used on the FE only.
    displayName?: string;
}

export interface UIESignEventNotary extends UIESignEventUser {
    ronSetup?: UINotarySetup;
    ipenSetup?: UINotarySetup;
    // The following fields are set and used on the FE only.
    pkEnabled?: boolean;
}

export interface UIESignEventMetadata {
    totalDocuments: number;
    totalNonWitnessedDocuments: number;
    totalWitnessedDocuments: number;
    totalTaggedDocuments: number;
    documentTaggingComplete: boolean;
    totalParticipants: number;
    totalSigningParticipants: number;
    totalNonSigningParticipants: number;
    totalUnassignedWitnesses: number;
    requiresNotary: boolean;
    notaryAssigned: boolean;
    nonWitnessedSigningComplete: boolean;
    witnessedSigningComplete: boolean;
    workflowStatusItems: UIWorkflowStatusItems;
}

export interface UIWorkflowStatusItems {
    participants: Status;
    documents: Status;
    tagDocuments: Status;
    invitation: Status;
    notary: Status;
    assignWitnessesWorkflowSetting: Status;
    appointment: Status;
    executeNonWitnessed: { [participantId: string]: Status };
    executeWitnessed: Status;
    actionItemsCount: number;
    pendingItemsCount?: number;
    sendDocsForERecording?: Status;
    downloadESignedDocs: Status;
    downloadERecordedDocs: Status;
    useEmailTemplates: boolean;
}

export enum Status {
    COMPLETE = "COMPLETE",
    INCOMPLETE = "INCOMPLETE",
    INCOMPLETE_BLOCKED = "INCOMPLETE_BLOCKED",
    PENDING = "PENDING",
    DISABLED = "DISABLED",
    OPTIONAL = "OPTIONAL",
    OPTIONAL_BLOCKED = "OPTIONAL_BLOCKED",
    NOT_NEEDED = "NOT_NEEDED",
    NONE = "NONE",
    E_RECORD = "E_RECORD",
    SENT = "SENT",
    REJECTED = "REJECTED",
    RECORDED = "RECORDED"
}

export interface UIEEVideo {
    id: string;
    fileName: string;
    requestedDate: Date;
    duration: number;
}

export interface UIEEligibility {
    recipientID: string;
    recipientName: string;
    faceToFaceInState: EEligibilityStatus;
    remoteInState: EEligibilityStatus;
    faceToFaceOutState: EEligibilityStatus;
    remoteOutState: EEligibilityStatus;
    securityErecordEligible: EEligibilityStatus;
    lastModifiedDate: string;
}

export interface UIAppointment {
    closingDate: string;
    closingTime: NgbTimeStruct;
    timeZoneID: string;
    duration: number;
    address: UIAddress;
    notes: string;
}

export interface UIStateRequirementData {
    witnessesRequired: boolean;
}

export enum EventType {
    PROPERTY_NOTARY = "PROPERTY_NOTARY",
    PROPERTY = "PROPERTY",
    NOTARY = "NOTARY",
    SIMPLE = "SIMPLE"
}

export enum MessageReferenceType {
    NOTARY_ORDER = "NOTARY_ORDER"
}

export enum MessagingUserType {
    LENDER = "LENDER",
    SETTLEMENT = "SETTLEMENT",
    NOTARY = "NOTARY",
    TITLE = "TITLE"
}

export interface UIEEMessage {
    messageID?: string;
    user?: UIESignEventUser;
    message: string;
    date?: string;
    readDate?: string;
    referenceType: MessageReferenceType;
    referenceID: string;
    originatorType?: MessagingUserType;
    recipientType?: MessagingUserType;
    recipientOrgID?: string;
    organizationID?: string;
    vendorOrgID?: string;
}

export interface UIESignEvent {
    eSignEventID?: string;
    organizationID: string;
    recipientID: string;
    externalResourceIdentifier?: string;
    status?: StatusType;
    eSignEventNotarizationType?: ESignEventNotarizationType;
    eventType: EventType;
    propertyAddress: UIAddress;
    stateRequirementData?: UIStateRequirementData;
    name: string;
    fileID?: string;
    metadata?: UIESignEventMetadata;
    video?: UIEEVideo;
    notary?: UIESignEventUser;
    notaryOrganizationID?: string;
    creator?: UIESignEventUser;
    eEligibility?: UIEEligibility;
    permissions?: string[];
    version?: number;
    sampleESignEvent?: boolean;
    isDemo?: boolean;
    isNotaryServiceSetupDemo?: boolean;
    allowsSessionTagging?: boolean;
    appointment?: UIAppointment;
    testEvent?: boolean;
    systemOriginator?: UIEESystemOriginator;
    originatorReferenceID?: string;
    eClosingPlatform?: UIEClosingPlatform;
    submitterPackageID?: string;
    orgImageUrl?: string;
    taggingPackageID?: string;
    cancellationReason?: string;
    creationDate?: string;
    completionDate?: string;
    canceledDate?: string;
    modifiedDate?: string;
    inviteSentDate?: string;
    privateEvent: boolean;
    notaryVendorOrganization?: boolean;
    notaryServiceOrderID?: string; // TCX, etc.
    notaryServiceOrderDate?: string;
    messages?: UIEEMessage[];
    documentIDs?: string[]; // Optionally used when copying an event.
    ronAllowPersonallyKnownVerification?: boolean;
    documentPurgeInProgress?: boolean;
    documentPostProcessingInProgress?: boolean;
    timeZone?: string;
    reviewAccessDate?: string;
    signingAccessDate?: string;
    signingExpirationDate?: string;
    recordingDisabled?: boolean;
    recordingReEnabled?: boolean;
    FNFSigningStartDate?: string;

    /** vvv fields that only exist on the front-end vvv **/
    cardHeight?: number; // Added on client side for scrolling purposes.
    displayDetails?: string; // for search display
    /** ^^^ fields that only exist on the front-end ^^^ **/
}

export interface UIESignEventAddress {
    street1: string;
    street2?: string;
    street3?: string;
    city: string;
    state: string;
    zipCode: string;
    recordingJurisdictions?: UIRecordingJurisdiction[];
}

export interface UIRecordingJurisdiction {
    zipCode: string;
    name: string;
    fullName: string;
    state: string;
    recipientID: string;
    enabled: boolean;
    eNotaryAvailable: boolean;
    securityErecordEligible: boolean;
}

export enum SortType {
    LAST_MODIFIED_ASC = "LAST_MODIFIED_ASC",
    LAST_MODIFIED_DESC = "LAST_MODIFIED_DESC",
    CREATED_DATE_ASC = "CREATED_DATE_ASC",
    CREATED_DATE_DESC = "CREATED_DATE_DESC",
    LAST_INVITE_SENT_DATE_ASC = "LAST_INVITE_SENT_DATE_ASC",
    LAST_INVITE_SENT_DATE_DESC = "LAST_INVITE_SENT_DATE_DESC",
    EVENT_NAME_ASC = "EVENT_NAME_ASC",
    EVENT_NAME_DESC = "EVENT_NAME_DESC",
    COMPLETED_DATE_ASC = "COMPLETED_DATE_ASC",
    COMPLETED_DATE_DESC = "COMPLETED_DATE_DESC",
    CANCELED_DATE_ASC = "CANCELED_DATE_ASC",
    CANCELED_DATE_DESC = "CANCELED_DATE_DESC",
    APPOINTMENT_DATE_ASC = "APPOINTMENT_DATE_ASC",
    APPOINTMENT_DATE_DESC = "APPOINTMENT_DATE_DESC",
    APPOINTMENT_DATE_UNASSIGNED = "APPOINTMENT_DATE_UNASSIGNED"
}

export interface UIESignEventListFilter {
    organizationIDs: string[];
    status?: StatusType;
    showOnlyMine: boolean;
    hideTestEvents: boolean;
    limit?: number;
    offset?: number;
    sort?: SortType;
}

export interface CardSortOption {
    id: SortType;
    title: string;
    flip?: boolean;
    statuses: StatusType[] | "ALL";
}

export interface UIEERecordingJurisdictionEligibility {
    zipCode: string;
    name: string;
    state: string;
    recipientID: string;
    isERecordable: boolean;
    acceptsIPENInState: boolean;
    acceptsIPENOutOfState: boolean;
    acceptsRONInState: boolean;
    acceptsRONOutOfState: boolean;
    simplifileIsApprovedIPENVendor: boolean;
    simplifileIsApprovedRONVendor: boolean;
}

export interface UIAddressBookEntry {
    id?: string;
    name: Name;
    emailAddress: string;
    mobilePhone: string;
    companyName: string;
    displayContact?: string;
    displayName?: string;
}

export interface UIESignEventLiteSearch {
    id: string;
    name: string;
    status: string;
    notarizationType: string;
    organizationID: string;
    propertyAddress: string;
    participants: string[];
}

export enum ESignEventEditDialogType {
    CREATE,
    COPY,
    UPDATE
}

export interface UINotarySetup {
    setupVerificationPassed: boolean;
    stateVerificationPassed: boolean;
    notaryState: string;
    notaryCommissionHasExpired: boolean;
    methodAuthorizationPassed: boolean;
}

export interface UINotaryTypesSetup {
    ronSetup: UINotarySetup;
    ipenSetup: UINotarySetup;
}

export interface UIStateRequirementData {
    witnessesRequired: boolean;
}

export enum UIEESystemOriginator {
    ESIGN_EVENT = "ESIGN_EVENT",
    CAPC = "CAPC",
    DOC_BUILDER = "DOC_BUILDER",
    E_DELIVERY = "E_DELIVERY"
}

export enum UIEClosingPlatform {
    ENCOMPASS = "ENCOMPASS",
    DOCUTECH = "DOCUTECH"
}

export interface UICurrentUserCanViewEventResponse {
    canViewEvent: boolean;
    eventName: string;
}

export interface UIEEPropertyParticipantDataResults {
    daysTillCanSign: number;
    failedParticipantNames: string[];
}

export interface UIEEFee {
    sourceOrg: string;
    organizationID: string; // organization being charged the fee
    type: string;
    description: string;
    date: Date;
    amount: number;
    manual: boolean; // manually entered vs. calculated
}
