/**
 * This is a 'drop-down' selector of organizations
 * This is only for super users
 * It allows searching for any organization in the system
 * Single-select only. For multi-select, use organization-multi-selector or organization-multi-select-dialog.
 */
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from "@angular/core";
import { Observable, Subject, Subscription } from "rxjs";
import { map, tap } from "rxjs/operators";
import { cloneDeep } from "@sf/common";
import {
    Organization,
    OrganizationService,
    SelectableOrganization
} from "@sf/userorg/common";
import { ActiveService, SelectableState, StatesService } from "@sf/common";
import { RecentOrganizationsService } from "../../services/recent-organizations.service";
import { Router } from "@angular/router";

// prettier-ignore
@Component({
    selector: "sf-organization-search-selector",
    templateUrl: "./organization-search-selector.component.html",
    styleUrls: ["./organization-search-selector.component.scss"]
})
export class OrganizationSearchSelectorComponent
    implements OnInit, OnChanges, OnDestroy
{
    /* Inputs */
    @Input()
    selectedOrgID: string; // (optional) (single select) OrganizationID initially selected
    @Input()
    excludeDisabledOrgs: boolean; // whether to exclude organizations that are disabled
    @Input()
    selectableOrgServices: string[]; // array of service names that an organization must have to be displayed
    @Input()
    autoInitialSelect: boolean; // initially select an item - determined by selectedOrgID, else first item in list
    @Input()
    searchLimit: number; // (optional - default is 25) max number of organizations to show in list
    @Input()
    canClearSelectedOrg: boolean; // whether to show an 'X' to clear selection
    @Input()
    hideBorder: boolean; // hides the border around the selector so it looks like a link
    @Input()
    includeNoneOption: SelectableOrganization; // (optional) item to include at the top of the list
    @Input()
    required: boolean = null; // if required in form
    @Input()
    placeholder: string; // (optional) placeholder - will be defaulted
    @Input()
    windowClass: string; // CSS class that is passed to the sf-select control - defaults to 'not-full-width'
    @Input() // Conditional CSS classes passed in
    conditionalClasses: { [key: string]: any };
    @Input()
    includeStateAbbrevs: boolean; // include states as selectable items - default is false
    @Input()
    showDisabledText: boolean; // show (Disabled) at the end of disabled organizations
    @Input()
    sortDisabledToBottom: boolean; // if true, sorts disabled results to the bottom of the results
    @Input()
    changedOrganizationName: string; // (optional)  If an organization changes its preferred name, we can change the name in the selector to match
    @Input()
    includeSampleOrgs: boolean; // (optional) true (default), include sample orgs in results; false, do not include sample orgs
    @Input()
    excludedOrgServices: string[]; // (optional) exclusion list of service names that an organization cannot have some or all of
    @Input()
    isDisabled: boolean; // (optional) flag to indicate if selector is disabled
    @Input()
    id?: string;        // id for the control in the HTML
    @Input()
    orgIdFirst?: boolean;   // (optional) if true, label will show org id first (ex: "(SIMPFL) Simplifile")

    /* Outputs */
    @Output()
    select: EventEmitter<any> = new EventEmitter(); // sends an Organization object

    /* Private Variables */
    private _$ngOnDestroy: Subject<void> = new Subject();
    private cachedStates: SelectableState[] = null;

    /* Public Variables */
    organizations: Organization[] = [];
    //selectedOrganization: Organization = null;
    selectableItems: SelectableOrganization[] = [];
    controlID = "sf-organization-search-selector";

    constructor(
        private orgService: OrganizationService,
        private statesService: StatesService,
        private recentOrgService: RecentOrganizationsService,
        private router: Router
    ) {
        this.isAllowedDisabled = this.isAllowedDisabled.bind(this);
        this.isAllowedOrgService = this.isAllowedOrgService.bind(this);
        this.isNotExcludedByService = this.isNotExcludedByService.bind(this);
        this.isNotArchived = this.isNotArchived.bind(this);
    }

    ngOnInit() {
        if (!this.placeholder) {
            this.placeholder = "Select Organization...";
        }
        if (!this.windowClass) {
            this.windowClass = "not-full-width";
        }
        if (!this.conditionalClasses) {
            this.conditionalClasses = {};
        }
        this.conditionalClasses["no-style-select-box"] = this.hideBorder;

        if (!this.searchLimit) {
            this.searchLimit = 25;
        }
        this.ensureCachedStates();
        this.populateOrgs({}).subscribe(() => {
            this.selectSelectedItem();
        });

        if (this.includeSampleOrgs == null) {
            this.includeSampleOrgs = true;
        }

        if (this.id) {
            this.controlID = this.id;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes.selectedOrgID &&
            changes.selectedOrgID.currentValue &&
            changes.selectedOrgID.currentValue !==
                changes.selectedOrgID.previousValue
        ) {
            //do nothing
        }
        if (changes.changedOrganizationName &&
                !!changes.changedOrganizationName.previousValue &&
                changes.changedOrganizationName.currentValue &&
                changes.changedOrganizationName.currentValue !== changes.changedOrganizationName.previousValue) {
            let clonedSelectableItems = cloneDeep(this.selectableItems);
            let changedOrgIndex = clonedSelectableItems.findIndex(
                (org) => org.id === this.selectedOrgID
            );
            let changedOrg = clonedSelectableItems[changedOrgIndex];
            if (changedOrg) {
                changedOrg.label = changes.changedOrganizationName.currentValue + " (" + this.selectedOrgID + ")";
                clonedSelectableItems[changedOrgIndex] = changedOrg;
                this.selectableItems = clonedSelectableItems;
            }
        }
    }

    ngOnDestroy() {
        this._$ngOnDestroy.next();
        this._$ngOnDestroy.complete();
    }

    getOrgs(event?: any): Subscription {
        return this.populateOrgs(event).subscribe(() => {
            // nothing
        });
    }

    selectSelectedItem() {
        // figure out which organization should be initially selected
        let currentItem: SelectableOrganization = null;
        if (this.selectedOrgID) {
            currentItem = this.selectableItems.find(
                (org: SelectableOrganization) => {
                    return org.id === this.selectedOrgID;
                }
            );
        }

        if (!currentItem && this.selectedOrgID) {
            if (this.includeStateAbbrevs && this.selectedOrgID.length == 2) {
                let selectedStates = this.insertStates([], this.selectedOrgID);
                if (selectedStates && selectedStates.length) {
                    // need new array here
                    this.selectableItems = this.selectableItems.concat(selectedStates);
                    //this.selectableItems = union(selectedStates, this.selectableItems);
                    this.handleOrgSelection({
                        $selection: {
                            id: selectedStates[0].id,
                            label: selectedStates[0].label,
                            isState: true
                        }
                    });
                }
            } else {
                // we get here if the organization with the selected ID is not in the list of selectable orgs
                // so get the org and add it to the list
                this.orgService.getOrganizations([this.selectedOrgID])
                    .subscribe((results: Organization[]) => {
                        this.organizations.push(results[0]);
                        // need new array here
                        let selectable = this.makeSelectableOrganization(results[0]);
                        this.selectableItems = this.selectableItems.concat([selectable]);
                        //this.selectableItems = union(this.selectableItems, [selectable]);
                        this.handleOrgSelection({
                            $selection: {
                                id: results[0].id,
                                label: results[0].name
                            }
                        });
                        this.selectableItems = this.sortOrgs(this.selectableItems);
                    });
            }
        } else if (this.autoInitialSelect && this.organizations.length > 0) {
            if (this.selectedOrgID) {
                // select the org with the selected ID
                this.handleOrgSelection({
                    $selection: {
                        id: this.selectedOrgID,
                        label: null
                    }
                });
            } else {
                // select the first org in the list
                this.handleOrgSelection({
                    $selection: {
                        id: this.selectableItems[0].id,
                        label: this.selectableItems[0].label
                    }
                });
            }
        } else if (this.selectedOrgID) {
            // normal case - if we have a selected ID, select that one
            this.handleOrgSelection({
                $selection: {
                    id: this.selectedOrgID,
                    label: null
                }
            });
        } else {
            // this.selectedOrgID is not set, but never happens
        }
    }

    ensureCachedStates() {
        if (this.includeStateAbbrevs && !this.cachedStates) {
            this.cachedStates = this.statesService.getAllStatesAndTerritories();
        }
    }

    populateOrgs(event?: any): Observable<Organization[]> {
        let $searchValue = event.$searchValue;

        // if user entered search criteria
        if ($searchValue !== null && $searchValue !== "" && $searchValue !== undefined) {
            // do a search - employ unreadable pipe/tap/crap
            return this.orgService.search($searchValue, this.searchLimit, this.selectableOrgServices)
                .pipe(
                    map((results) => results.filter(this.isAllowedDisabled)),
                    map((results) => results.filter((org) => {
                        if (org.sample && !this.includeSampleOrgs) return false;
                        else return true;
                    })),
                    map((results) => results.filter(this.isNotArchived)),
                    map((results) => results.filter(this.isNotExcludedByService)),
                    map((results) => {
                        if (!this.sortDisabledToBottom) {
                            return results;
                        } else {
                            return results.sort(this.sortByDisabled);
                        }
                    }),
                    tap((results: Organization[]) => {
                        this.organizations = results;
                        this.selectableItems = this.organizations.map((org) => {
                            return this.makeSelectableOrganization(org);
                        });
                        this.selectableItems = this.sortOrgs(
                            this.selectableItems
                        );
                        if (this.includeStateAbbrevs) {
                            this.selectableItems = this.insertStates(this.selectableItems, $searchValue);
                        }
                    })
                );
        }

        // use the most recently accessed orgs
        return this.recentOrgService.getPastRecentOrganizationsForList().pipe(
            map((results) => results.filter(this.isAllowedOrgService)),
            map((results) => results.filter((org) => {
                if (org.sample && !this.includeSampleOrgs) {
                    return false;
                } else {
                    return true;
                }
            })),
            map((results) => results.filter(this.isNotArchived)),
            map((results) => results.filter(this.isNotExcludedByService)),
            map((results) => results.filter(this.isAllowedDisabled)),
            tap((results) => {
                this.organizations = results;
                this.selectableItems = this.organizations.map((org) => {
                    return this.makeSelectableOrganization(org);
                });
                this.selectableItems = this.sortOrgs(this.selectableItems);
                if (this.includeNoneOption) {
                    this.selectableItems.unshift(this.includeNoneOption);
                }
            })
        );
    }

    private sortByDisabled(a: Organization, b: Organization): number {
        let aStr: string = a.name + " (" + a.id + ")";
        let bStr: string = b.name + " (" + b.id + ")";
        if ((a.enabled && b.enabled) || (!a.enabled && !b.enabled)) {
            //if both enabled or disabled, sort by "label"
            return aStr.localeCompare(bStr);
        } else if (a.enabled && !b.enabled) {
            return -1; //enabled org (a) up, disabled (b) down
        } else if (!a.enabled && b.enabled) {
            return 1; //enabled org (b) up, disabled (a) down
        }
    }

    // insert state abbreviations that match the search
    private insertStates(
        orgs: SelectableOrganization[],
        searchString: string
    ): SelectableOrganization[] {
        if (!searchString) {
            return orgs;
        }

        searchString = searchString.toUpperCase();

        let matchingStates = this.cachedStates.filter((state: any) => {
            if (state.abbrev) {
                let upperAbbrev = state.abbrev.toUpperCase();
                if (upperAbbrev.startsWith(searchString))
                    return true;
            }
            if (state.name) {
                let upperName = state.name.toUpperCase();
                if (upperName.startsWith(searchString))
                    return true;
            }
            return false;
        });

        let results: SelectableOrganization[] = [];
        matchingStates.forEach((state) => {
            results.push({
                label: state.abbrev + " (" + state.name + ")",
                id: state.name,
                selected: false,
                isState: true
            });
        });

        results = results.concat(orgs);

        return results;
    }

    private sortOrgs(
        unsorted: SelectableOrganization[]
    ): SelectableOrganization[] {
        return unsorted;
        // don't really sort - that makes things uglier
    }

    isAllowedDisabled(org: Organization) {
        return !(this.excludeDisabledOrgs && !org.enabled);
    }

    isAllowedOrgService(org: Organization): boolean {
        if (!this.selectableOrgServices || !this.selectableOrgServices.length) {
            return true;
        }
        let found = false;
        org.activeServices.map((service: ActiveService) => {
            let inList = this.selectableOrgServices.find((allowed: string) => {
                return service.id === allowed;
            });
            if (inList) {
                found = true;
            }
        });
        return found;
    }

    isNotArchived(org: Organization): boolean {
        return (!org.archived);
    }

    isNotExcludedByService(org: Organization): boolean {
        if (!this.excludedOrgServices || !this.excludedOrgServices.length) {
            return true;
        }
        let found = false;
        org.activeServices.map((service: ActiveService) => {
            let inList = this.excludedOrgServices.find((exService: string) => {
                return service.id.toUpperCase() === exService.toUpperCase();
            });
            if (inList) {
                found = true;
            }
        });
        return !found;  //since this is used in filter false=exclude
    }

    /**
     * fires a 'select' event to notify listeners
     */
    handleOrgSelection(event: any) {
        if (event.hasOwnProperty("$selection")) {
            let selectionObject = event.$selection;

            // only fire event if an event handler was provided
            if (this.select) {
                let selection: SelectableOrganization = selectionObject;
                let org: Organization = null;
                if (selection && selection.id) {
                    if (selection.isState) {
                        org = this.organizationFromSelectable(selection);
                    } else {
                        org = this.findOrganizationInList(selection.id);
                    }
                }
                //in the case where selector has nothing, this forces a reload of the current URL so any page reliant
                //on what is selected isn't left with nothing
                if (org == null && this.selectedOrgID) {
                    this._reloadCurrentRoute();
                }
                this.select.emit(org);
            }
        }
    }

    private _reloadCurrentRoute(): void {
        const currentUrl = this.router.url;
        this.router.navigateByUrl("/", {skipLocationChange: true}).then(() => {
            this.router.navigateByUrl(currentUrl, {skipLocationChange: true});
        });
    }

    private findOrganizationInList(orgID: string): Organization {
        let org: Organization = this.organizations.find((thisOrg) => {
            return thisOrg.id == orgID;
        });
        return org;
    }

    private organizationFromSelectable(selectable: SelectableOrganization): Organization {
        let org: Organization = {
            acctManager: null,
            actionCount: null,
            activeServices: null,
            address: null,
            archived: null,
            canModify: null,
            contact: null,
            contacts: null,
            contractExecuted: null,
            contractExpires: null,
            createdDate: null,
            displayableServices: null,
            enabled: true,
            fax: null,
            faxText: null,
            hasSuspendedService: false,
            id: selectable.id,
            indentLevel: null,
            isEnabled: true,
            isState: true,
            modifiedDate: null,
            name: selectable.label,
            legalName: null,
            phone: null,
            phoneText: null,
            salesRep: null,
            sample: false,
            sortableServices: null,
            status: null,
            statusClass: null,
            statusNumber: null,
            timezone: null,
            verifiedAddressID: null,
            website: null
        };
        return org;
    }

    private makeSelectableOrganization(
        o: Organization
    ): SelectableOrganization {
        let so: SelectableOrganization = {
            id: o.id,
            label: this.orgIdFirst ? "(" + o.id + ") " + o.name : o.name + " (" + o.id + ")",
            selected: false
        };
        so.title = so.label;
        if (!o.enabled && this.showDisabledText) {
            so.label += " (Disabled)";
        }
        return so;
    }
}
