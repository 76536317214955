import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
    AttachToBodyDirective,
    ClickOffDirective,
    FileDropZoneDirective,
    VisibilityObserverDirective
} from "./directives";
import {
    NgbModule,
    NgbAlertModule,
    NgbCollapseModule,
    NgbDropdownModule,
    NgbProgressbarModule,
    NgbTimepickerModule,
    NgbTooltipModule
} from "@ng-bootstrap/ng-bootstrap";
import {
    FilterPanelComponent,
    FilterPanelOverlayService
} from "./components/filter-panel";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { PortalModule } from "@angular/cdk/portal";
import { OverlayModule } from "@angular/cdk/overlay";
import { EllipsisTooltipDirective } from "./directives/ellipsis-tooltip.directive";
import { PermissionDirective } from "./directives/permission.directive";
import { TooltipOnOverflowDirective } from "./directives/tooltip-on-overflow.directive";
import { ActionsCellRendererComponent } from "./components/actions-cell-renderer/actions-cell-renderer.component";
import { AgGridTooltipComponent } from "./components/ag-grid-tooltip/ag-grid-tooltip.component";
import { CardComponent } from "./components/card/card.component";
import { ContextMenuComponent } from "./components/context-menu/context-menu.component";
import { HeaderTemplateRendererComponent } from "./components/header-template-renderer/header-template-renderer.component";
import { HelpButtonComponent } from "./components/help-button/help-button.component";
import { LoadingBarComponent } from "./components/loading-bar/loading-bar.component";
import { OpenHoursComponent } from "./components/open-hours/open-hours.component";
import { SliderToggleComponent } from "./components/selectors-and-toggles/slider-toggle/slider-toggle.component";
import { OffCanvasHelpComponent } from "./components/off-canvas-help/off-canvas-help.component";
import { TemplateRendererComponent } from "./components/template-renderer/template-renderer.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OpenHoursToNgbTimePipe } from "./components/open-hours/pipes/open-hours-to-ngb-time.pipe";
import { SpinnerComponent } from "./spinner/spinner.component";
import { PillComponent } from "./components/pill/pill.component";
import { SortableTableDirective } from "./directives/sortable-table.directive";
import { TourComponent } from "./tour/components/tour/tour.component";
import { TourStepComponent } from "./tour/components/tour-step/tour-step.component";
import { MessageComponent } from "./message/message.component";
import { NotificationComponent } from "./notification/notification.component";
import { ViewManagerComponent } from "./components/view-manager";
import { AriaDescriptionDirective } from "./directives/aria-description.directive";
import { A11yModule } from "@angular/cdk/a11y";
import { VideoDirective } from "./components/video/video.directive";
import { VimeoOverlayComponent } from "./components/video/vimeo-overlay.component";
import { QuillComponent } from "./components/quill/quill.component";
import { GlobalSearchComponent } from "./global-search/global-search.component";
import { GlobalSearchResultDirective } from "./global-search/global-search-result.directive";
import { AgGridDefaultsDirective } from "./directives/ag-grid-defaults.directive";
import { TooltipDirective } from "./tooltip/tooltip.directive";
import { TooltipComponent } from "./tooltip/tooltip.component";
// import { TooltipConfig } from "./tooltip/tooltip.config";
import { PopoverComponent } from "./popover/popover.component";
import { NgbButtonsModule } from "./buttons/buttons.module";

@NgModule({
    declarations: [
        ClickOffDirective,
        AttachToBodyDirective,
        FilterPanelComponent,
        EllipsisTooltipDirective,
        PermissionDirective,
        TooltipOnOverflowDirective,
        ActionsCellRendererComponent,
        AgGridTooltipComponent,
        CardComponent,
        ContextMenuComponent,
        HeaderTemplateRendererComponent,
        HelpButtonComponent,
        LoadingBarComponent,
        OpenHoursComponent,
        SliderToggleComponent,
        TemplateRendererComponent,
        OpenHoursToNgbTimePipe,
        SpinnerComponent,
        PillComponent,
        SortableTableDirective,
        TourComponent,
        TourStepComponent,
        MessageComponent,
        NotificationComponent,
        FileDropZoneDirective,
        ViewManagerComponent,
        VisibilityObserverDirective,
        AriaDescriptionDirective,
        VimeoOverlayComponent,
        VideoDirective,
        QuillComponent,
        GlobalSearchComponent,
        GlobalSearchResultDirective,
        OffCanvasHelpComponent,
        AgGridDefaultsDirective,
        TooltipDirective,
        TooltipComponent,
        PopoverComponent
    ],
    imports: [
        CommonModule,
        NgbTooltipModule,
        FontAwesomeModule,
        OverlayModule,
        PortalModule,
        FormsModule,
        ReactiveFormsModule,
        NgbProgressbarModule,
        NgbTimepickerModule,
        NgbCollapseModule,
        NgbDropdownModule,
        NgbModule,
        NgbButtonsModule,
        A11yModule
    ],
    providers: [FilterPanelOverlayService],
    exports: [
        ClickOffDirective,
        AttachToBodyDirective,
        FilterPanelComponent,
        EllipsisTooltipDirective,
        PermissionDirective,
        TooltipOnOverflowDirective,
        TooltipOnOverflowDirective,
        ActionsCellRendererComponent,
        AgGridTooltipComponent,
        CardComponent,
        ContextMenuComponent,
        HeaderTemplateRendererComponent,
        HelpButtonComponent,
        LoadingBarComponent,
        OpenHoursComponent,
        SliderToggleComponent,
        TemplateRendererComponent,
        PillComponent,
        SortableTableDirective,
        TourComponent,
        TourStepComponent,
        MessageComponent,
        NotificationComponent,
        FileDropZoneDirective,
        VisibilityObserverDirective,
        AriaDescriptionDirective,
        VimeoOverlayComponent,
        VideoDirective,
        QuillComponent,
        GlobalSearchComponent,
        GlobalSearchResultDirective,
        OffCanvasHelpComponent,
        ViewManagerComponent,
        AgGridDefaultsDirective,
        TooltipDirective,
        TooltipComponent,
        // TooltipConfig,
        PopoverComponent
    ]
})
export class SfCommonModule {}
