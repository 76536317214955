import {
    ConfirmationModalComponent,
    dayjs,
    getLinkWithChangedParams,
    GrowlService,
    SessionService,
    SfValidators,
    SpinnerService
} from "@sf/common";
import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from "@angular/core";
import {
    Contract,
    ContractExtended,
    ContractFee,
    ContractFeeType,
    ContractService,
    ContractType,
    Organization,
    OrganizationEntitlement,
    Product,
    ProductBasic,
    TokenService,
    User,
    UserorgActivationService,
    UserOrgService
} from "@sf/userorg/common";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import {
    AdminLicenseUtilityInterface,
    EnrollmentCodeSelectDialogComponent,
    LicenseUtilityService,
    OrganizationServicesCoreService
} from "@sf/userorg/main";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { first } from "rxjs/operators";
import { SubmitterOrganizationService } from "@sf/submitter/common";

interface SetupThing extends Contract {
    exclusiveSubmissionFee?: number;
    licenseFee?: number;
    renewalFee?: number;
    submissionFee?: any;

    agreementUrl?: string;
    agreementMissing?: boolean;
    agreementLoading?: boolean;
}

// prettier-ignore
@Component({
    selector: "sf-license-submitter",
    templateUrl: "./license-submitter.component.html",
    styleUrls: [
        "../../styles/license-styles.scss",
        "./license-submitter.component.scss"
    ]
})
export class LicenseSubmitterComponent implements OnInit, OnChanges {
    @Input() orgID: string;
    @Input() adminLicenceUtility: AdminLicenseUtilityInterface;

    /** Private Variables **/
    licenseType: ContractType = ContractType.ERECORDING;
    product: ProductBasic = {
        productID: "submitter",
        label: "eRecording"
    };
    contractTemplate: any = null;

    isSuperUser = false;
    setup: SetupThing;
    msa: SetupThing = null;
    editMode = false;
    userCanEdit = false;
    originalValues: SetupThing;
    isProcessing = false;
    loaded = false;
    isSuspended = false;
    licenseExpired = false;
    salesPerson: User = null;
    warning: string = null;
    formattedExpirationDate = "";
    formattedExecutedDate = "";
    formattedPaidDate = "";
    expirationDayjs: dayjs.Dayjs;
    currentInvite: any = null; // set in licenseUtilityService
    currentInviteMessage: string = null;
    missingTemplate = false;
    pendingContract: ContractExtended = null;
    futureContract: ContractExtended = null;
    selectableBillingCycles: any[];
    selectedBillingCycle: any;
    displayableBillingCycle: string;
    selectablePaymentTerms: any[];
    selectedPaymentTerm: any;
    displayablePaymentTerms: string;
    fieldsNotDefined: boolean = false;
    maxDate: dayjs.Dayjs = null;
    minDate: dayjs.Dayjs = null;
    isLicenseEditOverridden: boolean = false;
    opportunityID: string = null;
    enrollmentCodeName: string;

    smaSetup: SetupThing;
    selectedRecordingBillingCycle: any;
    displayableRecordingBillingCycle: string;
    showPricingTiers = false;
    newEffectiveDate: string; // if not empty, addendum effective in the future
    smaEffectiveDate: string; // if not empty, amendment effective in the future
    perPackageLink = false;
    perPackageDays = 90;
    perPackageRegEx = "(\\s*\\S){1,}";
    perPackageStartDate: dayjs.Dayjs = dayjs("2016-05-01");
    minimumLicenseFee = 0;
    hasSalesforceEditPermission: boolean = false;
    entitlement: OrganizationEntitlement = null;

    constructor(
        private _route: ActivatedRoute,
        private router: Router,
        private sessionService: SessionService,
        private growlService: GrowlService,
        private contractService: ContractService,
        private spinnerService: SpinnerService,
        private activationService: UserorgActivationService,
        private licenseService: LicenseUtilityService,
        private tokenService: TokenService,
        private userorgService: UserOrgService,
        private modalService: NgbModal,
        private coreService: OrganizationServicesCoreService,
        private submitterOrganizationService: SubmitterOrganizationService
    ) {}

    ngOnInit(): void {
        this.isSuperUser = this.sessionService.isSuperUser();

        this.hasSalesforceEditPermission = this.sessionService.hasPermission("admin_salesforce_id_edit", "SIMPFL")
                && sf.liveConfig.SalesforceIntegrationSettings.isTalendServiceEnabled;

        this.selectableBillingCycles = this.contractService.getSelectableBillingCycles(this.product.productID);
        this.selectablePaymentTerms = this.contractService.getSelectablePaymentTerms();
        if (this.isSuperUser) {
            this.userCanEdit = this.sessionService.hasPermission("admin_license_edit", "SIMPFL");
            this.contractService.checkLicenseTemplate("MSA")
                .subscribe((response: boolean) => {
                    this.missingTemplate = !response;
                });
        }

        this.contractService.getLicenseOverrideSetting().subscribe((overrideEnabled: boolean) => {
            this.isLicenseEditOverridden = overrideEnabled;
            if (!this.isLicenseEditOverridden) {
                this.maxDate = dayjs().add(1, "year");
                this.minDate = dayjs().add(1, "day").startOf("day");
            }
        });

        this.reload();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.orgID && !changes.orgID.firstChange) {
            this.orgID = changes.orgID.currentValue;
            this.reload();
        }
    }

    reload() {
        this.showPricingTiers = false;
        this.warning = null;
        this.editMode = false;
        this.loaded = false;
        this.smaSetup = null;
        this.contractTemplate = null;
        this.msa = null;
        this.enrollmentCodeName = null;

        this.opportunityID = null;
        this.userorgService.getAllOrganizationEntitlements(this.orgID)
            .subscribe((entitlements: OrganizationEntitlement[]) => {
                if (entitlements) {
                    entitlements.forEach((entitlement) => {
                        if (entitlement.product == Product.SUBMITTER) {
                            this.entitlement = entitlement;
                            this.isSuspended = entitlement.status == "SUSPENDED";
                            if (this.isSuspended) {
                                let disabledReason = this.coreService.getFriendlyDisabledReason(entitlement.entitlementStatusReason);
                                this.warning = "Service is suspended";
                                if (disabledReason) {
                                    this.warning += " due to " + disabledReason;
                                }
                            }
                            this.opportunityID = entitlement.salesforceOpportunityID;
                        }
                    });
                }
            });

        this.contractService.findContract(this.orgID, this.licenseType)
            .subscribe((response: Contract) => {
                this.useContract(response);
                if (response) {
                    if (response.contractID) {
                        this.licenseService.refreshDocumentIcon(this.orgID, response);
                    }
                    if (response.enrollmentCode) {
                        this.licenseService.getEnrollmentCodeName(response.enrollmentCode).then((name: string) => {
                            this.enrollmentCodeName = name;
                        });
                    }
                }
                this.loaded = true;
            });
        this.contractService.getPendingContract(this.orgID, this.licenseType)
            .subscribe((response: Contract) => {
                this.pendingContract = response as ContractExtended;
                this.contractService.getFutureContract(this.orgID, this.licenseType)
                    .subscribe((response: Contract) => {
                        this.futureContract = response as ContractExtended;

                        //if contract is both pending and future, only want the pending
                        if (!!this.pendingContract && !!this.futureContract && this.pendingContract.contractID == this.futureContract.contractID) {
                            this.futureContract = null;
                        }
                    });
            });

        this.contractService.findContract(this.orgID, "MSA")
            .subscribe((response: Contract) => {
                if (response && response.contractID) {
                    this.msa = response;
                    this.licenseService.refreshDocumentIcon(this.orgID, response);
                }
            });

        if (this.userCanEdit) {
            this.contractService.getMinimumContractFees(this.orgID, this.licenseType)
                .subscribe((response: any) => {
                    if (response) {
                        // the tiers bring me to tears
                        this.minimumLicenseFee = response.LICENSE_FEE?.contractFeeTiers[0].feeAmount;
                    }
                });
        }

        // kinda unique to eRecord contract
        this.submitterOrganizationService.getSubmitterOrganizationConfigPayments(this.orgID, true, false)
            .pipe(first())
            .subscribe((settings: any) => {
                if (settings["Payments"]) {
                    this.perPackageLink = settings["Payments"].link_one_time_package_fee;
                    this.perPackageDays = this.perPackageLink && settings["Payments"].linked_package_days_back;
                    this.perPackageRegEx = this.perPackageLink && settings["Payments"].linked_package_name_regex;

                    if (!this.perPackageLink && !this.perPackageDays) {
                        // use default value if they haven't set the 'related package' stuff
                        this.perPackageDays = 90;
                    }
                    if ((this.perPackageDays == null) || (typeof this.perPackageDays == 'undefined')) {
                        // use default value if the value is missing
                        this.perPackageDays = 90;
                    }
                    if (!this.perPackageRegEx) {
                        this.perPackageRegEx = "(\\s*\\S){1,}"; // default value
                    }
                    let savedStartDate = this.perPackageLink && settings["Payments"].linked_package_start_date;
                    if (savedStartDate) {
                        this.perPackageStartDate = dayjs(savedStartDate);
                    }
                }
            });

        // This hokiness is unique to the eRecord contract.
        // Get any OLD price increase documents
        this.smaEffectiveDate = "";
        this.newEffectiveDate = "";
        this.contractService.findContract(this.orgID, "SERVICE_AMENDMENT")
            .subscribe((response: Contract) => {
                if (response && response.contractID) {
                    // We have a currently-effective service amendment
                    this.smaSetup = response;
                    if (response.effectiveDate) {
                        let effectiveDayjs = dayjs(response.effectiveDate);
                        if (dayjs().isBefore(effectiveDayjs)) {
                            // in the future
                            this.smaEffectiveDate = "Effective " + this.licenseService.formatDayjs(effectiveDayjs);
                        }
                    }
                    this.licenseService.refreshDocumentIcon(this.orgID, response);
                } else {
                    // no current service amendment, so look for a 'future' one
                    this.contractService.getSubsequentContract(this.orgID, "SERVICE_AMENDMENT")
                        .subscribe((response2: Contract) => {
                            if (response2 && response2.contractID) {
                                //  found a future amendment
                                this.smaSetup = response2;
                                if (response2.effectiveDate) {
                                    let effectiveDayjs = dayjs(response2.effectiveDate);
                                    this.smaEffectiveDate =
                                            "Effective " + this.licenseService.formatDayjs(effectiveDayjs);
                                }
                                this.licenseService.refreshDocumentIcon(this.orgID, response2);

                                // now we need to get the corresponding future eRecord contract
                                this.contractService.getSubsequentContract(this.orgID, this.licenseType)
                                    .subscribe((response3: Contract) => {
                                        if (response3 && response3.contractID) {
                                            // get the new submision fee
                                            let newFee = "";
                                            if (response3.contractFees) {
                                                (response3.contractFees as any[]).forEach((fee: any) => {
                                                    if (fee.feeType == "SUBMISSION_FEE") {
                                                        // this.setup.submissionFee = fee.contractFeeTiers.length > 0 ? fee.contractFeeTiers[0].feeAmount : null;
                                                        newFee = "$" + this.licenseService.roundOff(
                                                                response3.contractFees.SUBMISSION_FEE.contractFeeTiers[0].feeAmount, 2);
                                                    }
                                                });
                                            }
                                            // get effective date
                                            this.newEffectiveDate = newFee;
                                            if (response3.effectiveDate) {
                                                let effectiveDayjs = dayjs(response3.effectiveDate);
                                                this.newEffectiveDate +=
                                                        " Effective " + this.licenseService.formatDayjs(effectiveDayjs);
                                            }
                                        }
                                    });
                            }
                        });
                }
            });

        // get the salesperson name
        this.userorgService.getOrganizationSalesRep(this.orgID, this.product.productID)
            .pipe(first())
            .subscribe((seller: User) => {
                if (seller) {
                    if (!seller.name) {
                        // This probably means that no salesperson is assigned
                        seller = null;
                    } else {
                        this.userorgService.buildUserFullName(seller);
                    }
                }
                this.salesPerson = seller;
            });

        if (this.isSuperUser) {
            this.licenseService.lookForInvite(this, this.orgID, this.product.productID);
        }
    }

    showSalesPerson() {
        this.licenseService.showSalesPerson(this.salesPerson);
    }

    showSignerDetails(contract: ContractExtended) {
        this.licenseService.showSignerDetails(this.currentInvite, contract, this.orgID, this.product.label);
    }

    useContract(contract: Contract) {
        if (contract) {
            this.setup = contract;
            this.originalValues = this.userorgService.cloneObj(contract);

            // pull fees out of array for easier UI
            this.setup.licenseFee = 0;
            this.setup.renewalFee = 0;
            //this.setup.submissionFee = 0;
            this.setup.exclusiveSubmissionFee = 0;
            if (contract.contractFees) {
                Object.values(contract.contractFees).forEach((fee: ContractFee) => {
                    if (fee.feeType == "LICENSE_FEE") {
                        this.setup.licenseFee = fee.contractFeeTiers.length > 0 ? fee.contractFeeTiers[0].feeAmount : null;
                        this.setup.licenseFee = this.licenseService.roundOff(this.setup.licenseFee, 2);
                    } else if (fee.feeType == "RENEWAL_FEE") {
                        this.setup.renewalFee = fee.contractFeeTiers.length > 0 ? fee.contractFeeTiers[0].feeAmount : null;
                        this.setup.renewalFee = this.licenseService.roundOff(this.setup.renewalFee, 2);
                    } else if (fee.feeType == "SUBMISSION_FEE") {
                        // this.setup.submissionFee = fee.contractFeeTiers.length > 0 ? fee.contractFeeTiers[0].feeAmount : null;
                        this.setup.contractFees.SUBMISSION_FEE.contractFeeTiers[0].feeAmount =
                                this.licenseService.roundOff(this.setup.contractFees.SUBMISSION_FEE.contractFeeTiers[0].feeAmount, 2);
                    } else if (fee.feeType == "SIMPLIFILE_EXCLUSIVE_SUBMISSION_FEE") {
                        this.setup.exclusiveSubmissionFee = fee.contractFeeTiers.length > 0 ? fee.contractFeeTiers[0].feeAmount : null;
                        this.setup.exclusiveSubmissionFee = this.licenseService.roundOff(this.setup.exclusiveSubmissionFee, 2);
                    }
                });
            } else {
                // contract fees are too dang complicated
                this.licenseService.dummyTheDumbFees(this.setup, [
                    ContractFeeType.LICENSE_FEE,
                    ContractFeeType.SIMPLIFILE_EXCLUSIVE_SUBMISSION_FEE,
                    ContractFeeType.RENEWAL_FEE
                ]);
            }

            if (!this.setup.contractFees.SIMPLIFILE_EXCLUSIVE_SUBMISSION_FEE) {
                this.licenseService.initializeFee(this.setup, ContractFeeType.SIMPLIFILE_EXCLUSIVE_SUBMISSION_FEE);
            }

            this.originalValues.licenseFee = this.setup.licenseFee;
            this.originalValues.renewalFee = this.setup.renewalFee;
            this.originalValues.submissionFee = this.userorgService.cloneObj(this.setup.contractFees ?
                    this.setup.contractFees.SUBMISSION_FEE : undefined);
            this.originalValues.exclusiveSubmissionFee = this.setup.exclusiveSubmissionFee;

            this.formattedExpirationDate = this.licenseService.formatDate(contract.expirationDate);
            this.formattedExecutedDate = this.licenseService.formatDate(contract.executedDate);
            this.formattedPaidDate = this.licenseService.formatDate(contract.paidDate);
            this.expirationDayjs = dayjs(contract.expirationDate);

            this.licenseExpired = this.licenseService.isDateBeforeToday(this.formattedExpirationDate);

            this.selectedBillingCycle = this.selectableBillingCycles.find((cycle) => {
                return cycle.id == contract.billingCycle;
            });
            this.selectedRecordingBillingCycle = this.selectedBillingCycle;
            if (contract.contractFees.RECORDING_FEE && contract.contractFees.RECORDING_FEE.billingCycle) {
                this.selectedRecordingBillingCycle = this.selectableBillingCycles.find((cycle) => {
                    return cycle.id == contract.contractFees.RECORDING_FEE.billingCycle;
                });
            }
            this.displayableBillingCycle = this.selectedBillingCycle ? this.selectedBillingCycle.label : "Unknown";
            this.displayableRecordingBillingCycle =
                    this.selectedRecordingBillingCycle ? this.selectedRecordingBillingCycle.label : "Unknown";
            if (!this.selectedBillingCycle) {
                this.fieldsNotDefined = true;
                this.selectedBillingCycle = this.selectableBillingCycles[1]; //default to DAILY
                this.setup.billingCycle = this.selectedBillingCycle.id;
            }
            if (!this.selectedRecordingBillingCycle) {
                this.fieldsNotDefined = true;
                this.selectedRecordingBillingCycle = this.selectableBillingCycles[1]; //default to DAILY
                this.setup.contractFees.RECORDING_FEE.billingCycle = this.selectedRecordingBillingCycle.id;
            }

            this.selectedPaymentTerm = this.selectablePaymentTerms.find((term) => {
                return term.id == contract.paymentTerms;
            });
            this.displayablePaymentTerms = this.selectedPaymentTerm ? this.selectedPaymentTerm.label : "Unknown";
            if (!this.selectedPaymentTerm) {
                this.fieldsNotDefined = true;
                this.selectedPaymentTerm = this.selectablePaymentTerms[0]; //default to NET1
                this.setup.paymentTerms = this.selectedPaymentTerm.id;
            }
        } else {
            this.setup = <SetupThing>{
                licenseFee: 0,
                renewalFee: 0,
                contractFees: null,
                exclusiveSubmissionFee: 0,
                freeSubmissions: 0,
                contractType: this.licenseType
            };
            // contract fees are too dang complicated
            this.licenseService.dummyTheDumbFees(this.setup, [
                ContractFeeType.LICENSE_FEE,
                ContractFeeType.SIMPLIFILE_EXCLUSIVE_SUBMISSION_FEE,
                ContractFeeType.RENEWAL_FEE
            ]);
            this.originalValues = this.setup;
        }
    }

    beginEdit() {
        if (!this.userCanEdit) {
            return;
        }
        if (this.pendingContract) {
            this.growlService.error("There is already a license that is pending approval. " +
                    "If you want to make changes, you'll need to cancel the pending license.");
            return;
        }
        if (this.futureContract) {
            this.growlService.error("There is already a pending future license. " +
                    "If you want to make changes, you'll need to cancel the future license first.");
            return;
        }
        if (this.newEffectiveDate) {
            this.growlService.error("This organization has a price change pending. " +
                    "If you want to make changes, you'll need to cancel the pending change.");
            return;
        }
        if (this.isSuspended) {
            this.growlService.error("This service is suspended. If you want to make changes, you'll need to unsuspend the service.");
            return;
        }

        this.userorgService.getOrganization(this.orgID)
            .pipe(first())
            .subscribe((org: Organization) => {
                if (!org || !org.enabled) {
                    this.growlService.error("This organization is disabled, so the license can't be edited.");
                    return;
                }
                this.editMode = true;
                if (!this.setup.expirationDate) {
                    this.setup.expirationDate = this.licenseService.getNextYearDateYMD();
                }
            });
    }

    selectBillingCycle($event: any) {
        if ($event && $event.$selection) {
            let newCycle: any = $event.$selection;
            this.selectedBillingCycle = newCycle;
            this.setup.billingCycle = newCycle.id;
            this.displayableBillingCycle = this.selectedBillingCycle.label;
        }
    }

    selectRecordingBillingCycle($event: any) {
        if ($event && $event.$selection) {
            let newCycle: any = $event.$selection;
            this.selectedRecordingBillingCycle = newCycle;
            this.setup.contractFees.RECORDING_FEE.billingCycle = newCycle.id;
            this.displayableRecordingBillingCycle = this.selectedRecordingBillingCycle.label;
        }
    }

    selectPaymentTerm($event: any) {
        if ($event && $event.$selection) {
            let newTerm: any = $event.$selection;
            this.selectedPaymentTerm = newTerm;
            this.setup.paymentTerms = newTerm.id;
            this.displayablePaymentTerms = this.selectedPaymentTerm.label;
        }
    }

    packageDateError() {}

    expirationDateChanged(newDate: dayjs.Dayjs) {
        this.setup.expirationDate = this.licenseService.formatDayjs(newDate);
    }

    validateInputs() {
        this.warning = null;

        // validate data
        if (typeof this.setup.licenseFee == "undefined" || this.setup.licenseFee == null) {
            this.warning = "License Fee is required";
            return;
        }
        if (typeof this.setup.renewalFee == "undefined" || this.setup.renewalFee == null) {
            this.warning = "Renewal Fee is required";
            return;
        }
        if (typeof this.setup.exclusiveSubmissionFee == "undefined" || this.setup.exclusiveSubmissionFee == null) {
            this.warning = "Simplifile Exclusive Submission Fee is required";
            return;
        }
        if (typeof this.setup.contractFees.SUBMISSION_FEE == "undefined" ||
                this.setup.contractFees.SUBMISSION_FEE == null ||
                this.setup.contractFees.SUBMISSION_FEE.contractFeeTiers.length <= 0) {
            this.warning = "Submission Fee Pricing Tiers are required";
            return;
        }

        if (!this.userorgService.isPositiveIntegerString(this.setup.freeSubmissions)) {
            this.warning = "Free Submissions must be a positive whole number or zero";
            return;
        }
        if (this.setup.contractFees.SUBMISSION_FEE.contractFeeTiers[0].feeAmount === 0 ||
                !SfValidators.isPositiveFloatString(
                        this.setup.contractFees.SUBMISSION_FEE.contractFeeTiers[0].feeAmount)) {
            this.warning = "Submission Fee must be a positive number";
            return;
        }
        if (!SfValidators.isPositiveFloatString(this.setup.exclusiveSubmissionFee)) {
            this.warning = "Simplifile Exclusive Submission Fee must be a positive number";
            return;
        }
        if (!SfValidators.isPositiveFloatString(this.setup.renewalFee)) {
            this.warning = "Renewal Fee must be a positive number";
            return;
        }
        if (!SfValidators.isPositiveFloatString(this.setup.licenseFee)) {
            this.warning = "Initial license Fee must be a positive number";
            return;
        }

        let fee: number = SfValidators.getNumberFromString(this.setup.renewalFee);
        if (fee < this.minimumLicenseFee) {
            this.warning = "Renewal Fee must not be less than $" +
                    this.licenseService.roundOff(this.minimumLicenseFee, 2);
            return;
        }
        fee = SfValidators.getNumberFromString(this.setup.licenseFee);
        if (fee < this.minimumLicenseFee) {
            this.warning = "Initial License Fee must not be less than $" +
                    this.licenseService.roundOff(this.minimumLicenseFee, 2);
            return;
        }

        if (!this.setup.expirationDate) {
            this.warning = "License Expiration Date is required";
        } else if (this.sessionService.getEnv() == "PROD") {
            this.expirationDayjs = dayjs(this.setup.expirationDate);
            let now = dayjs();
            if (this.expirationDayjs.isBefore(now)) {
                this.warning = "License Expiration Date cannot be in the past";
                return;
            }
        }
        if (this.maxDate && !dayjs(this.setup.expirationDate).isBefore(this.maxDate)) {
            this.warning = "License Expiration Date cannot be more than a year from today";
            return;
        }
        if (this.minDate && dayjs(this.setup.expirationDate).isBefore(this.minDate)) {
            this.warning = "License Expiration Date cannot be less than the minimum date of " +
                    dayjs(this.minDate).format("MM/DD/YYYY");
            return;
        }
        if (dayjs(this.setup.expirationDate).isBefore(this.setup.effectiveDate)) {
            return "License Expiration Date cannot be before the effective date";
        }

        if (!this.setup.billingCycle && this.msa) {
            this.warning = "License Billing Cycle is required";
            return;
        }

        if (!this.setup.paymentTerms && this.msa) {
            this.warning = "License Payment Terms is required";
            return;
        }

        if (this.isLessThan(this.setup.exclusiveSubmissionFee,
                this.setup.contractFees.SUBMISSION_FEE.contractFeeTiers[0].feeAmount)) {
            this.warning = "The Exclusive Submission Fee must be greater than or equal to the Submission Fee";
            return;
        }

        // round off to 2 decimal places
        this.setup.exclusiveSubmissionFee = this.licenseService.roundOff(this.setup.exclusiveSubmissionFee, 2);
        this.setup.licenseFee = this.licenseService.roundOff(this.setup.licenseFee, 2);
        this.setup.renewalFee = this.licenseService.roundOff(this.setup.renewalFee, 2);
        //this.setup.submissionFee = this.licenseService.roundOff(this.setup.submissionFee, 2);
        this.setup.contractFees.SUBMISSION_FEE.contractFeeTiers[0].feeAmount =
                this.licenseService.roundOff(this.setup.contractFees.SUBMISSION_FEE.contractFeeTiers[0].feeAmount, 2);

        if (!SfValidators.testFee(this.setup.licenseFee)) {
            this.warning = "Invalid License Fee format";
            return;
        }
        if (!SfValidators.testFee(this.setup.renewalFee)) {
            this.warning = "Invalid Renewal Fee format";
            return;
        }
        if (!SfValidators.testFee(this.setup.exclusiveSubmissionFee)) {
            this.warning = "Invalid Exclusive Submission Fee format";
            return;
        }
        if (!SfValidators.testFee(this.setup.contractFees.SUBMISSION_FEE.contractFeeTiers[0].feeAmount)) {
            this.warning = "Invalid Submission Fee format";
            return;
        }

        if (this.perPackageLink) {
            let saveStartDate: string = undefined;
            if (this.perPackageStartDate) {
                saveStartDate = this.perPackageStartDate.format("MM/DD/YYYY");
            }
            if (!saveStartDate) {
                this.warning = "'Earliest recording date' must be a valid date";
                return;
            }
            if (!SfValidators.isPositiveIntegerString(this.perPackageDays)) {
                this.warning = "'Number of days back' must be a number";
                return;
            }
        }

        if (this.hasSalesforceEditPermission && this.opportunityID && this.opportunityID.length != 18) {
            this.warning = "Salesforce Opportunity ID must be 18 characters";
            return;
        }
    }

    // compare 2 'numbers'
    isLessThan(a: any, b: any) {
        let aNum: number = a;
        let bNum: number = b;
        if (typeof a == "string") {
            aNum = parseFloat(a);
        }
        if (typeof b == "string") {
            bNum = parseFloat(b);
        }
        return aNum < bNum;
    }

    cancelSend(isFutureContract?: boolean) {
        if (isFutureContract && !!this.futureContract) {
            this.licenseService.confirmCancel(this.product.label, this.actualCancelFuture.bind(this), this.futureContract, true);
        } else if (!!this.pendingContract) {
            this.licenseService.confirmCancel(this.product.label, this.actualCancelSend.bind(this), this.pendingContract, false);
        }
    }

    actualCancelFuture(): void {
        this.isProcessing = true;
        this.contractService.cancelFutureContract(this.orgID, this.futureContract.contractType, this.futureContract.contractID)
            .subscribe((success: boolean) => {
                if (success) {
                    this.growlService.success("Future license successfully removed.");
                }
                this.reload();
                this.processingFinished();
            }, () => {
                this.processingFinished();
            });
    }

    actualCancelSend() {
        this.isProcessing = true;
        this.contractService.cancelContractApprovalRequest(this.orgID, this.setup.contractType)
            .subscribe((result: any) => {
                this.reload();
                this.processingFinished();
            }, () => {
                this.processingFinished();
            });
    }

    sendContract(requiredSend: boolean) {
        this.licenseService.sendContract(requiredSend, this.addendumPresave.bind(this), this.setup,
                this.originalValues, this.orgID, () => {
            if (this.setup.perPackageFees != this.originalValues.perPackageFees) {
                this.promptForPaper();
            }
            this.reload();
        });
    }

    checkForPromptToSendContract(): boolean {
        this.formattedExpirationDate = this.licenseService.formatDate(this.setup.expirationDate);
        //let formattedOriginalExpirationDate = this.licenseService.formatDate(this.originalValues.expirationDate);
        this.licenseExpired = this.licenseService.isDateBeforeToday(this.formattedExpirationDate);

        // prompt user to approve if contract was previously signed and fees changed
        if (this.licenseService.numbersDifferent(this.setup.licenseFee, this.originalValues.licenseFee) ||
                this.licenseService.numbersDifferent(this.setup.renewalFee, this.originalValues.renewalFee) ||
                this.licenseService.numbersDifferent(
                        this.setup.contractFees.SUBMISSION_FEE.contractFeeTiers[0].feeAmount,
                        this.originalValues.submissionFee.contractFeeTiers[0].feeAmount) ||
                (this.selectedBillingCycle.id != this.originalValues.billingCycle && !this.fieldsNotDefined) ||
                (this.selectedPaymentTerm.id != this.originalValues.paymentTerms && !this.fieldsNotDefined)) {
            // if we are here, then fees have changed
            this.sendContract(true);
            return true;
        }

        // accounting blessed us with this separate billing cycle we need to check
        if ((this.setup.contractFees.RECORDING_FEE.billingCycle &&
                        !this.originalValues.contractFees.RECORDING_FEE.billingCycle) ||
                (!this.setup.contractFees.RECORDING_FEE.billingCycle &&
                        this.originalValues.contractFees.RECORDING_FEE.billingCycle)) {
            this.sendContract(true);
            return true;
        }
        if (this.setup.contractFees.RECORDING_FEE.billingCycle &&
                this.originalValues.contractFees.RECORDING_FEE.billingCycle) {
            if (this.setup.contractFees.RECORDING_FEE.billingCycle !=
                    this.originalValues.contractFees.RECORDING_FEE.billingCycle) {
                this.sendContract(true);
                return true;
            }
        }
        if (this.setup.perPackageFees != this.originalValues.perPackageFees) {
            this.sendContract(true);
            return true;
        }

        return false;
    }

    promptForPaper() {
        this.contractService.findContract(this.orgID, ContractType.PAPER_RECORDING)
            .subscribe((recPlusContract: Contract) => {
                if (recPlusContract != null) {
                    let modal: NgbModalRef = this.modalService.open(ConfirmationModalComponent);
                    const modalInstance = modal.componentInstance;
                    modalInstance.title = "Recording Plus";
                    modalInstance.message = "This organization also has the Recording Plus service. " +
                    "You may consider whether to also make per-package changes to that contract.";

                    modal.result.then((result: any) => {
                        // nothing on OK
                    }, () => {
                        // nothing on cancel
                    });
                }
            });
    }

    save() {
        if (!this.userCanEdit) {
            return;
        }

        this.validateInputs();
        if (this.warning) {
            return;
        }

        this.licenseService.licenseFeeChangeCheck(this.setup.licenseFee, this.originalValues.licenseFee)
            .then((goOn: boolean) => {
                if (goOn) {
                    let prompted = this.checkForPromptToSendContract();
                    if (!prompted) {
                        this.doTheSave();
                    }
                }
        });
    }

    addendumPresave() {
        if (!this.setup.exclusiveSubmissionFee) {
            this.setup.exclusiveSubmissionFee = this.setup.contractFees.SUBMISSION_FEE.contractFeeTiers[0].feeAmount;
        }

        this.setup.contractFees.LICENSE_FEE.contractFeeTiers[0].feeAmount = this.setup.licenseFee;
        this.setup.contractFees.SIMPLIFILE_EXCLUSIVE_SUBMISSION_FEE.contractFeeTiers[0].feeAmount = this.setup.exclusiveSubmissionFee;
        this.setup.contractFees.RENEWAL_FEE.contractFeeTiers[0].feeAmount = this.setup.renewalFee;

        this.savePaymentSettings();
    }

    savePaymentSettings() {
        let saveStartDate: string = undefined;
        if (this.perPackageStartDate) {
            saveStartDate = this.perPackageStartDate.format("MM/DD/YYYY");
        }

        // save per-package hoo-haw
        let settings: any = {
            Payments: {
                link_one_time_package_fee: {
                    value: this.perPackageLink
                },
                linked_package_days_back: {
                    value: this.perPackageDays
                },
                linked_package_name_regex: {
                    value: this.perPackageRegEx
                },
                linked_package_start_date: {
                    value: saveStartDate
                }
            }
        };
        this.submitterOrganizationService.setSubmitterOrganizationConfig(this.orgID, settings)
            .subscribe(() => {
                // ok
            }, (error) => {
                //this.growlService.error(error);
            });
    }

    doTheSave() {
        this.addendumPresave();

        // save contract
        this.isProcessing = true;
        this.contractService.saveContract(this.orgID, this.setup)
            .subscribe(() => {
                this.growlService.success("License details saved.");
                this.reload();
                this.processingFinished();
            }, () => {
                this.processingFinished();
            });
        if (this.opportunityID != this.entitlement.salesforceOpportunityID) {
            this.entitlement.salesforceOpportunityID = this.opportunityID;
            this.userorgService.updateOrganizationEntitlement(this.orgID, this.entitlement)
                    .subscribe((result: Organization) => {
                        this.growlService.success("Salesforce Opportunity ID saved.");
                    });
        }
    }

    showUpload() {
        if (this.pendingContract) {
            this.growlService.error("There is already a license that is pending approval. " +
                    "If you want to make changes, you'll need to cancel the pending license.");
            return;
        }
        if (this.futureContract) {
            this.growlService.error("There is already a pending future license. If you want to make changes, you'll need to cancel the future license first.");
            return;
        }
        if (this.isSuspended) {
            this.growlService.error("This service is suspended. If you want to make changes, you'll need to unsuspend the service.");
            return;
        }
        if (this.msa) {
            this.adminLicenceUtility.uploadAgreement(this.orgID, this.product, this.afterUpload.bind(this));
        } else {
            this.adminLicenceUtility.uploadMSA(this.orgID, () => {
                this.adminLicenceUtility.uploadAgreement(this.orgID, this.product, this.afterUpload.bind(this));
            });
        }
    }

    afterUpload() {
        this.reload();
    }

    showUploadMSA() {
        this.adminLicenceUtility.uploadMSA(this.orgID, this.afterUpload.bind(this));
    }

    /*
    // These fee structures are way too complicated
    ensureFeeStructure() {
        if (!this.setup.contractFees) {
            this.setup.contractFees = <any>{};
        }
        if (!this.setup.contractFees.LICENSE_FEE) {
            this.setup.contractFees.LICENSE_FEE = {
                contractFeeTiers: [
                    {
                        tierThreshold: 0,
                        feeAmount: 0
                    }
                ],
                thresholdResetPeriod: ContractFeeThresholdResetPeriod.INDEFINITE,
                feeType: ContractFeeType.LICENSE_FEE
            };
        }
        if (!this.setup.contractFees.RENEWAL_FEE) {
            this.setup.contractFees.RENEWAL_FEE = {
                contractFeeTiers: [
                    {
                        tierThreshold: 0,
                        feeAmount: 0
                    }
                ],
                thresholdResetPeriod: ContractFeeThresholdResetPeriod.INDEFINITE,
                feeType: ContractFeeType.RENEWAL_FEE
            };
        }
        if (!this.setup.contractFees.NFS_RETRY_FEE) {
            this.setup.contractFees.NFS_RETRY_FEE = {
                contractFeeTiers: [
                    {
                        tierThreshold: 0,
                        feeAmount: 25
                    }
                ],
                thresholdResetPeriod: ContractFeeThresholdResetPeriod.INDEFINITE,
                feeType: ContractFeeType.NFS_RETRY_FEE
            };
        }
        if (!this.setup.contractFees.SUBMISSION_FEE) {
            this.setup.contractFees.SUBMISSION_FEE = {
                contractFeeTiers: [
                    {
                        tierThreshold: 0,
                        feeAmount: 0
                    }
                ],
                thresholdResetPeriod: ContractFeeThresholdResetPeriod.INDEFINITE,
                feeType: ContractFeeType.SUBMISSION_FEE
            };
        }
        if (!this.setup.contractFees.SIMPLIFILE_EXCLUSIVE_SUBMISSION_FEE) {
            this.setup.contractFees.SIMPLIFILE_EXCLUSIVE_SUBMISSION_FEE = {
                contractFeeTiers: [
                    {
                        tierThreshold: 0,
                        feeAmount: 0
                    }
                ],
                thresholdResetPeriod: ContractFeeThresholdResetPeriod.INDEFINITE,
                feeType: ContractFeeType.SIMPLIFILE_EXCLUSIVE_SUBMISSION_FEE
            };
        }
    }
    */

    showInvite() {
        this.spinnerService.startSpinner();
        this.userorgService.getOrganization(this.orgID)
            .pipe(first())
            .subscribe((organization: Organization) => {
                this.spinnerService.stopSpinner();
                this.licenseService.showInvite(organization, this.product);
            }, () => {
                this.spinnerService.stopSpinner();
            }, () => {
                this.spinnerService.stopSpinner();
            });
    }

    cancelInvite() {
        this.licenseService.confirmCancelInvite(this.product.label, this.actualCancelInvite.bind(this));
    }

    actualCancelInvite() {
        this.tokenService.cancelServiceInvitation(this.orgID, this.product.productID)
            .subscribe(() => {
                this.currentInvite = null;
                this.adminLicenceUtility.refreshAfterActivate(this.orgID);
                this.growlService.success("Invitation canceled.");
            });
    }

    processingFinished() {
        setTimeout(() => {
            this.isProcessing = false;
        });
    }

    goToEnrollmentCode() {
        this.router.navigateByUrl(
            "/admin/sales/contract-pricing-templates?enrollmentCode=" + this.setup.enrollmentCode);
    }

    removeEnrollmentCode() {
        this.setup.enrollmentCode = null;
    }

    showEnrollmentCodes() {
        const modalRef = this.modalService.open(EnrollmentCodeSelectDialogComponent, {
            backdrop: "static",
            //size: "lg"
            windowClass: "enrollment-code-select"
        });

        const modalInstance = modalRef.componentInstance;
        modalInstance.contractType = this.licenseType;

        modalRef.result.then((template: any) => {
            if (template) {
                if (!template.contractTemplateFees || !template.contractTemplateFees.LICENSE_FEE ||
                        !template.contractTemplateFees.LICENSE_FEE.contractFeeTiers) {
                    this.growlService.error(
                            "Invalid Enrollment code. The template for this enrollment code appears corrupt.");
                    return;
                }
                this.contractTemplate = template;
                this.setup.enrollmentCode = template.registrationCode;
                this.setup.licenseFee = template.contractTemplateFees.LICENSE_FEE.contractFeeTiers[0].feeAmount;
                this.setup.renewalFee = template.contractTemplateFees.RENEWAL_FEE.contractFeeTiers[0].feeAmount;
                this.setup.contractFees.SUBMISSION_FEE = template.contractTemplateFees.SUBMISSION_FEE;
                if (template.contractTemplateFees.SIMPLIFILE_EXCLUSIVE_SUBMISSION_FEE) {
                    this.setup.exclusiveSubmissionFee =
                            template.contractTemplateFees.SIMPLIFILE_EXCLUSIVE_SUBMISSION_FEE.contractFeeTiers[0].feeAmount;
                } else {
                    this.setup.exclusiveSubmissionFee =
                            template.contractTemplateFees.SUBMISSION_FEE.contractFeeTiers[0].feeAmount;
                }
                this.setup.freeSubmissions = template.freeSubmissions;

                this.enrollmentCodeName = template.templateName;
                if (template.billingCycleType) {
                    this.selectedBillingCycle = this.selectableBillingCycles.find((cycle) => {
                        return cycle.id == template.billingCycleType;
                    });
                    this.setup.billingCycle = this.selectedBillingCycle.id;
                } else {
                    //default to DAILY
                    this.selectedBillingCycle = this.selectableBillingCycles[0];
                    this.setup.billingCycle = this.selectedBillingCycle.id;
                }
                this.displayableBillingCycle = this.selectedBillingCycle.label;

                if (template.paymentTerms) {
                    this.selectedPaymentTerm = this.selectablePaymentTerms.find((term) => {
                        return term.id == template.paymentTerms;
                    });
                    this.setup.paymentTerms = this.selectedPaymentTerm.id;
                } else {
                    //default to NET1
                    this.selectedPaymentTerm = this.selectablePaymentTerms[0];
                    this.setup.paymentTerms = this.selectedPaymentTerm.id;
                }
                this.displayablePaymentTerms = this.selectedPaymentTerm.label;
            }
        }, () => {
            // error
        });
    }

    updateContractFee(updatedContractFee: any) {
        this.setup.contractFees.SUBMISSION_FEE = updatedContractFee;
        //console.log(JSON.stringify(this.setup.contractFees.SUBMISSION_FEE));
    }

    removeTiers() {
        this.showPricingTiers = false;
    }

    showExclusiveFee(): boolean {
        if (typeof this.setup.exclusiveSubmissionFee == "undefined") {
            return false;
        }

        return this.licenseService.numbersDifferent(
            this.setup.contractFees.SUBMISSION_FEE.contractFeeTiers[0].feeAmount, this.setup.exclusiveSubmissionFee);
    }

    showLicenseDocuments() {
        this.licenseService.showLicenseDocuments(this.router, this.orgID);
    }

    goToSalesforce() {
        this.licenseService.goToSalesforce(this.opportunityID);
    }
}
