<sf-modal-header [title]="title"></sf-modal-header>
<div ngbAutofocus class="modal-body">
    <div *ngFor="let org of archivedUserDetails">
        The following roles were removed from <b>{{userId}}</b>
        at <b><span [innerHTML]="org.orgName | highlight: queryText"></span></b>
        (
        <button class="btn btn-link sf-btn-link" (click)="goToOrganization(org.orgId)"><span
                [innerHTML]="org.orgId | highlight: queryText"></span></button>
        ):
        <ul>
            <li *ngFor="let role of org.changedRoles">
                <span [innerHTML]="role.roleName | highlight: queryText"></span>
                <span *ngIf="role.roleID">
                    (
                    <button class="btn btn-link sf-btn-link"
                        (click)="goToRoleWithOrg(role.roleID, org.orgId)"><span
                        [innerHTML]="role.roleID | highlight: queryText"></span></button>
                    )
                </span>
            </li>
        </ul>
    </div>
</div>
<sf-modal-footer [primary]="primary"></sf-modal-footer>
