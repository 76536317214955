<div class="virtual-grid">
    <div class="row-not">
        <div class="header-item type-select">
            <sf-select (select)="typeChanged($event)"
                [options]="typeOptions" [selectedOptions]="selected.typeOptions"
                trackBy="option" labelBy="label"
                [isSingleSelect]="false" placeholder="All Log Types"
                [hasClearButton]="true" [hasFilter]="true">
            </sf-select>
        </div>
        <div class="header-item range-select">
            <sf-select (select)="timeRangeChanged($event)"
                [options]="timeOptions" [selectedOption]="selected.timeOption.option"
                trackBy="option" labelBy="label"
                [isSingleSelect]="true" placeholder="Select...">
            </sf-select>
        </div>
        <div class="header-item big-bottom" *ngIf="selected.timeOption.option == 'CUSTOM'">
            <div>
                <span class="datetime-label">From</span>
                <sf-date-picker [date]="selected.after" inputId="afterDate"
                    (dateChange)="customAfterDateChanged($event)"></sf-date-picker>
                <span *ngIf="!!selected.after">
                    <span class="datetime-inner">at</span>
                    <input maxlength="2" type="text" class="form-control time-input" name="afterHour" id="afterHour"
                            updateOn="change" (change)="customTimeChanged()"
                            aria-label="After this hour"
                            [(ngModel)]="afterHour"/>
                    <span class="datetime-inner">:</span>
                    <input maxlength="2" type="text" class="form-control time-input" name="afterMinute" id="afterMinute"
                            updateOn="change" (change)="customTimeChanged()"
                            aria-label="After this minute"
                            [(ngModel)]="afterMinute"/>
                </span>
            </div>
            <div>
                <span class="datetime-label">To</span>
                <sf-date-picker [date]="selected.before" inputId="beforeDate"
                    (dateChange)="customBeforeDateChanged($event)"></sf-date-picker>
                <span *ngIf="!!selected.before">
                    <span class="datetime-inner">at</span>
                    <input maxlength="2" type="text" class="form-control time-input" name="beforeHour" id="beforeHour"
                            (change)="customTimeChanged()" aria-label="Before this hour"
                            [(ngModel)]="beforeHour"/>
                    <span class="datetime-inner">:</span>
                    <input maxlength="2" type="text" class="form-control time-input" name="beforeMinute" id="beforeMinute"
                            (change)="customTimeChanged()" aria-label="Before this minute"
                            [(ngModel)]="beforeMinute"/>
                </span>
            </div>
        </div>
    </div>
    <div class="row-not extra-top">
        <div class="header-item">
            <sf-search *ngIf="!searchNotFilter" class="sf-search" [model]="filterQuery" placeholder="Filter"
                (onChange)="doSearchFilter()" [icon]="['far', 'filter']" [enable]="true"
                (cancelEvent)="clearFilter()" [showResults]="false"></sf-search>
            <sf-search *ngIf="searchNotFilter" class="sf-search" [model]="searchQuery" placeholder="Search"
                    (onChange)="doSearchSearch()" [icon]="['far', 'search']" [enable]="true"
                    (cancelEvent)="clearSearch()" [showResults]="false"></sf-search>
            <button *ngIf="canToggleSearch" class="btn btn-link sf-btn-link toggle-button" (click)="toggleFilterSearch()">
                <fa-icon *ngIf="searchNotFilter" icon="search" ngbTooltip="Toggle from search to filter"></fa-icon>
                <fa-icon *ngIf="!searchNotFilter" icon="filter" ngbTooltip="Toggle from filter to search"></fa-icon>
            </button>
        </div>
        <div class="header-item">
            <sf-search class="sf-search" [model]="performedBy" placeholder="Performed by (userid)"
                (onChange)="doSearchSearch()" [icon]="['far', 'search']" [enable]="true"
                (cancelEvent)="clearPerformedByFilter()" [showResults]="false"></sf-search>
        </div>
        <div *ngIf="updater" class="header-item">
            <div id="toggler" class="btn-group btn-group-toggle" ngbRadioGroup (change)="selectLive()"
                    [(ngModel)]="selectedLive">
                <label ngbButtonLabel class="btn-secondary">
                    <input ngbButton type="radio" [value]="0"/>
                    <span class="toggle-text">Static List</span>
                </label>
                <label ngbButtonLabel class="btn-secondary">
                    <input ngbButton type="radio" [value]="1"/>
                    <span class="toggle-text">Live List</span>
                </label>
            </div>
        </div>
        <div class="header-item">
            <span *ngIf="loaded && logs" class="search-results">
                Results: {{logs && filteredLogs.length ? (filteredLogs.length > 999 ? "Most recent 1000 displayed" : filteredLogs.length) : 0}}
            </span>
            <span *ngIf="!loaded" class="search-results">
                <fa-icon icon="spinner" [spin]="true"></fa-icon>
                Loading...
            </span>
        </div>
    </div>

    <cdk-virtual-scroll-viewport *ngIf="logs && loaded" itemSize="59" autosize="true" (scrolledIndexChange)="adjustColumnWidths()">
        <sf-activity-log-entry [entry]="log" *cdkVirtualFor="let log of filteredLogs" class="virtual-row">
        </sf-activity-log-entry>
    </cdk-virtual-scroll-viewport>
</div>
