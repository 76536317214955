import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { EncryptionService, GrowlService, SessionService } from "@sf/common";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
    Contract,
    ContractExtended,
    ContractService,
    TokenService
} from "@sf/userorg/common";
import { TokenDetailsDialogComponent } from "../../dialogs/token-details-dialog/token-details-dialog.component";
import { ArchivedUserDialogComponent } from "../../dialogs/archived-user-dialog/archived-user-dialog.component";
import { ContractDetailsDialogComponent } from "../../dialogs/contract-details-dialog/contract-details-dialog.component";

export interface ActivityLogEntry {
    typeLabel: string;
    taggedEntities: any[];
    date: string;
    performedBy: string;
    inBehalfOf: string;
    ipAddress: string;
    details: any;
}

export interface ChangedRoles {
    roleID: string;
    roleName: string;
}

export interface ArchivedUserDetails {
    orgId: string;
    orgName: string;
    changedRoles: ChangedRoles[];
}

// prettier-ignore
@Component({
    selector: "sf-activity-log-entry",
    templateUrl: "./activity-log-entry.component.html",
    styleUrls: ["./activity-log-entry.component.scss"]
})
export class ActivityLogEntryComponent implements OnInit {
    @Input()
    entry: ActivityLogEntry;

    @Input()
    dialogMode: boolean;

    /** Public Variables **/
    expanded = false;
    queryText = "";

    constructor(
            private router: Router,
            private sessionService: SessionService,
            private encryptionService: EncryptionService,
            private modalService: NgbModal,
            private growlService: GrowlService,
            private contractService: ContractService,
            private tokenService: TokenService
    ) {}

    ngOnInit() {
    }

    ngOnDestroy() {
    }

    showContract(id: string, activity: any) {
        let contract: Contract;
        let organizationID: string;
        if (activity && activity.taggedEntities) {
            activity.taggedEntities.forEach((ent: any) => {
                if (ent.type == "ORGANIZATION") {
                    organizationID = ent.id;
                }
            });
        }

        if (!organizationID) {
            alert("This contract link seems invalid");
            return;
        }

        this.contractService.getOneContract(id).subscribe((contract: Contract) => {
            if (!contract) {
                this.growlService.warning("This contract no longer exists");
            } else {
                let cx: ContractExtended = contract as ContractExtended;
                this.contractService.addContractDateDayjs(cx);

                const modalRef = this.modalService.open(ContractDetailsDialogComponent, {
                    backdrop: "static"
                });
                const modalInstance = modalRef.componentInstance;

                modalInstance.contract = cx;
                modalInstance.orgID = organizationID;

                modalRef.result.then(() => {
                    // nothing
                }, () => {
                    // nothing
                });
            }
        });
    }

    goToUser(id: string, page: string) {
        let scrambled = this.encryptionService.scrambleEncode(id);
        let pageUrl = "";

        switch (page) {
            case "manager":
                pageUrl = "/organizations";
                break;
            case "contact":
            default:
                pageUrl = "/contact";
                break;
        }

        let newUrl = "/admin/user/" + scrambled + pageUrl;
        this.router.navigate([newUrl]);
    }

    goToOrganization(orgID: string, page: string) {
        let newUrl: string = null;

        if (page) {
            switch (page) {
                case "contact":
                    newUrl =
                        "/admin/organization/" + orgID + "/config/contact";
                    break;
                case "manager":
                    newUrl =
                        "/admin/organization/" + orgID + "/config/users";
                    break;
                case "services":
                    newUrl =
                        "/admin/organization/" + orgID + "/config/services";
                    break;
                case "hierarchy":
                    newUrl =
                        "/admin/organization/" +
                        orgID +
                        "/config/hierarchy";
                    break;
                default:
                    break;
            }
        }

        this.router.navigate([newUrl]);
    }

    goToRoleWithOrg(id: string, orgID: string) {
        let newUrl = "/admin/organization/" + orgID + "/config/roles/" + id;
        this.router.navigate([newUrl]);
    }

    viewToken(tokenID: string) {
        this.tokenService.getInvitationByID(tokenID).subscribe(
            (invitation: any) => {
                if (
                    !this.sessionService.hasPermission(
                        "admin_manage_invitations",
                        "SIMPFL"
                    )
                ) {
                    delete invitation.tokenID;
                }
                const modalRef = this.modalService.open(
                    TokenDetailsDialogComponent,
                    {
                        backdrop: "static",
                        //size: "lg"
                        windowClass: "add-user-dialog"
                    }
                );
                const modalInstance = modalRef.componentInstance;

                modalInstance.invitation = invitation;

                modalRef.result.then((results: any) => {
                    // nothing
                }, () => {
                    // cancel
                });
            },
            () => {
                // nothing
            }
        );
    }

    goToRoleWithActivity(id: string, activity: any) {
        let orgID = null;
        activity.taggedEntities.forEach((entity: any) => {
            if (entity.type == "ORGANIZATION") {
                orgID = entity.id;
            }
        });
        this.goToRoleWithOrg(id, orgID);
    }

    goToApiKey(id: string) {
        let newUrl = "/admin/api/api-keys";
        this.router.navigate([newUrl]);
    }

    goToTag(tag: any, activity: any) {
        let win;
        switch (tag.type) {
            case "USER":
                this.goToUser(tag.id, "contact");
                break;
            case "ORGANIZATION":
                this.goToOrganization(tag.id, "contact");
                break;
            case "ROLE":
                this.goToRoleWithActivity(tag.id, activity);
                break;
            case "TOKEN":
                this.viewToken(tag.id);
                break;
            case "API_KEY":
                this.goToApiKey(tag.id);
                break;
            case "CONTRACT":
                this.showContract(tag.id, activity);
                break;
            default:
                break;
        }
    }

    goToArchivedUserDialog(details: any) {
        let archivedUserDetails: ArchivedUserDetails[] = [];
        Object.keys(details.archivedOrganizationInfo).forEach((orgId) => {
            archivedUserDetails.push({
                orgId: orgId,
                orgName: details.archivedOrganizationInfo[orgId],
                changedRoles: details.changedRolesMap[orgId]
            });
        });

        const modalRef = this.modalService.open(ArchivedUserDialogComponent, {
            backdrop: "static",
            size: "lg",
            scrollable: true
        });
        const modalInstance = modalRef.componentInstance;

        modalInstance.userId = details.archivedUser.user.id;
        modalInstance.archivedUserDetails = archivedUserDetails;
        modalInstance.title = "User Archived Details";

        modalRef.result.then((results: any) => {
            // nothing
        }, () => {
            // nothing
        });
    }
}
