import { Component } from "@angular/core";
import { BaseModalComponent } from "@sf/common";
import { Subject } from "rxjs";
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from "@angular/forms";
import { Folder } from "../../interfaces/folder";

@Component({
    selector: "sf-move-documents-modal",
    templateUrl: "./move-documents-modal.component.html"
})
export class MoveDocumentsModalComponent extends BaseModalComponent {
    private _subject = new Subject();
    result = this._subject.asObservable();
    moveForm: UntypedFormGroup;
    locations: Folder[];
    showFooter: boolean;
    requireComment: boolean;
    manageFolders: Function;
    inRONEvent: boolean;

    constructor(private _fb: UntypedFormBuilder) {
        super();
    }

    ngOnInit(): void {
        let location = "";
        let signingQueue = this.locations.find((l) => l.id === "signing");
        if (this.locations.length === 1) {
            location = this.locations[0].id;
        } else if (!!signingQueue) {
            location = signingQueue.id;
        }
        this.moveForm = this._fb.group({
            location: [location, Validators.required],
            comment: ""
        });
        if (this.requireComment) {
            this.moveForm.get("comment").setValidators(Validators.required);
        }
        this.primary = {
            text: "Move",
            disabled: !location || this.requireComment,
            callback: () => {
                this._subject.next(this.moveForm.value);
                this._subject.complete();
                return true;
            }
        };
        this.secondary = {
            text: "Cancel",
            callback: () => {
                this._subject.complete();
                return true;
            }
        };

        this.moveForm.valueChanges.subscribe((value) => {
            let validator =
                this.requireComment || value.location === "data-entry"
                    ? Validators.required
                    : undefined;
            let commentControl = this.moveForm.get("comment");
            commentControl.setValidators(validator);
            commentControl.updateValueAndValidity({ emitEvent: false });
            this.primary.disabled = this.moveForm.invalid;
        });
    }
}
