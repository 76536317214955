import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
    PersistedMemoryService,
    SessionService,
    WindowRefService
} from "@sf/common";
import {
    SIGN_EVENT_STORAGE_KEY,
    SignEventStorage
} from "../interfaces/esign-closing.interface";
import { UIESignEventDocument } from "../interfaces/sign-event-documents.interface";
import { UIESignEventParticipant } from "../interfaces/sign-event-participant.interface";
import {
    ESignEventNotarizationType,
    StatusType,
    UIEClosingPlatform,
    UIEESystemOriginator,
    UIESignEvent
} from "../interfaces/sign-event.interface";
import { ESignEventEventSnapshot } from "../snapshots/esign-event-snapshot";
import { TaggingSharedService } from "./tagging-shared.service";

@Injectable({
    providedIn: "root"
})
export class ESignEventRedirectService {
    /** Lifecycle Hooks **/

    constructor(
        private _router: Router,
        private _persistedMemoryService: PersistedMemoryService,
        private _sessionService: SessionService,
        private _taggingSharedService: TaggingSharedService,
        private _windowRef: WindowRefService
    ) {}

    /** Public Methods **/

    redirectOnLeaveEvent(eventSnapshot: ESignEventEventSnapshot): void {
        this._windowRef.nativeWindow.opener?.postMessage(
            { message: "CLOSE_PAGE" },
            this._windowRef.nativeWindow.origin
        );
        const eventStorage: SignEventStorage = this._persistedMemoryService.get(
            SIGN_EVENT_STORAGE_KEY
        );
        const onLeaveEventUrl = eventStorage?.onLeaveEventUrl;
        if (this._sessionService.isAnyOrganizationEnabled()) {
            const url = onLeaveEventUrl.replace(/^\/sf\/ui/, "");
            this._router.navigateByUrl(url).then(() => {
                if (eventSnapshot.status === StatusType.COMPLETE) {
                    this._taggingSharedService.processSessionCompleteSurveyTrigger(
                        eventSnapshot.eSignEventID
                    );
                }
            });
        } else {
            this._windowRef.nativeWindow.location.assign(onLeaveEventUrl);
        }
    }

    saveParticipantToken(participantToken: string) {
        let eventStorage: SignEventStorage = this._persistedMemoryService.get(
            SIGN_EVENT_STORAGE_KEY
        );
        if (eventStorage) {
            eventStorage.participantToken = participantToken;
        } else {
            eventStorage = { participantToken, videoRoom: null };
        }
        this._persistedMemoryService.set(SIGN_EVENT_STORAGE_KEY, eventStorage);
    }

    saveRedirectUrls(
        event: UIESignEvent,
        participant: UIESignEventParticipant,
        documents: UIESignEventDocument[]
    ) {
        let eventStorage: SignEventStorage = this._persistedMemoryService.get(
            SIGN_EVENT_STORAGE_KEY
        );
        const participantToken = eventStorage?.participantToken;
        let onLeaveEventUrl: string = "";
        let onSessionTimeoutUrl: string = "";
        if (event) {
            if (participant?.returnUrl) {
                let returnUrl = participant.returnUrl;
                if (event.eClosingPlatform === UIEClosingPlatform.ENCOMPASS) {
                    returnUrl = this._getEncompassReturnUrl(
                        event,
                        participant,
                        documents
                    );
                }
                onLeaveEventUrl = returnUrl;
                onSessionTimeoutUrl = returnUrl;
            } else {
                onLeaveEventUrl = this._getOnLeaveEventUrl(
                    event,
                    participantToken
                );
                onSessionTimeoutUrl = this._getOnSessionTimeoutUrl(
                    event,
                    participantToken
                );
            }
        } else if (participantToken) {
            const participantLoginUrl = `/sf/ui/sign-event/external/sign-event-auth/${participantToken}`;
            onLeaveEventUrl = participantLoginUrl;
            onSessionTimeoutUrl = participantLoginUrl;
        }
        if (eventStorage) {
            eventStorage.onLeaveEventUrl = onLeaveEventUrl;
            eventStorage.onSessionTimeoutUrl = onSessionTimeoutUrl;
        } else {
            eventStorage = {
                participantToken: null,
                videoRoom: null,
                onLeaveEventUrl,
                onSessionTimeoutUrl
            };
        }
        this._persistedMemoryService.set(SIGN_EVENT_STORAGE_KEY, eventStorage);
        return eventStorage;
    }

    /** Private Methods **/

    private _getEncompassReturnUrl(
        event: UIESignEvent,
        participant: UIESignEventParticipant,
        documents: UIESignEventDocument[]
    ) {
        let eccStatus: string;
        const signingComplete =
            event.status === StatusType.COMPLETE ||
            documents
                ?.filter((doc) => this._isSignerOnDocument(participant, doc))
                .every((doc) => !!doc.signedByDate?.[participant.id]);
        const reviewComplete = documents?.every(
            (doc) => !!doc.reviewedByDate?.[participant.id]
        );
        if (signingComplete) {
            eccStatus = "signing_complete";
        } else if (reviewComplete) {
            eccStatus = "viewing_complete";
        }
        if (eccStatus) {
            return this._addEccStatusQueryParam(
                participant.returnUrl,
                eccStatus
            );
        } else {
            return participant.returnUrl;
        }
    }

    private _isSignerOnDocument(
        participant: UIESignEventParticipant,
        document: UIESignEventDocument
    ): boolean {
        if (!participant || !document?.signers) {
            return false;
        }
        return document.signers.some(
            (signer) =>
                participant.id === signer.id ||
                (participant.role === signer.role &&
                    participant.roleIndex === signer.roleIndex)
        );
    }

    private _addEccStatusQueryParam(
        urlString: string,
        eccStatus: string
    ): string {
        if (urlString.startsWith("/")) {
            const urlTree = this._router.parseUrl(urlString);
            urlTree.queryParams.event = eccStatus;
            return this._router.serializeUrl(urlTree);
        } else {
            const url = new URL(urlString);
            url.searchParams.set("event", eccStatus);
            return url.toString();
        }
    }

    private _getOnLeaveEventUrl(
        event: UIESignEvent,
        participantToken: string
    ): string {
        const permissions = event.permissions ?? [];
        if (
            permissions.includes("esign_event_notary") ||
            permissions.includes("esign_event_assign_notary")
        ) {
            if (event.isNotaryServiceSetupDemo) {
                return "/sf/ui/settings/notary-credentials";
            }
            if (
                !!event.notaryServiceOrderID ||
                event.notaryVendorOrganization
            ) {
                return "/sf/ui/settings/notary-journal";
            }
            switch (event.systemOriginator) {
                case UIEESystemOriginator.CAPC:
                    const loanID = event.originatorReferenceID;
                    return `/sf/ui/lender/${loanID}/closing`;
                case UIEESystemOriginator.DOC_BUILDER:
                    return "/sf/ui/signing/signing-list";
                default:
                    return `/sf/ui/sign-event/event/${event.eSignEventID}/workflow`;
            }
        } else if (
            event.systemOriginator === UIEESystemOriginator.DOC_BUILDER
        ) {
            return "/sf/ui/signing/signing-list";
        } else {
            return `/sf/ui/sign-event/external/sign-event-auth/${participantToken}`;
        }
    }

    private _getOnSessionTimeoutUrl(
        event: UIESignEvent,
        participantToken: string
    ): string {
        if (participantToken) {
            switch (event.systemOriginator) {
                case UIEESystemOriginator.DOC_BUILDER:
                    const redirect = encodeURIComponent(
                        `/ui/sign-event/event/${event.eSignEventID}/closing/terms`
                    );
                    return `/sf/ui/login?fr=${redirect}`;
                default:
                    return `/sf/ui/sign-event/external/sign-event-auth/${participantToken}`;
            }
        } else {
            let redirect: string;
            if (event.isNotaryServiceSetupDemo) {
                redirect = "/sf/ui/settings/notary-credentials";
            } else if (
                !!event.notaryServiceOrderID ||
                event.notaryVendorOrganization
            ) {
                redirect = encodeURIComponent("/ui/settings/notary-journal");
            } else {
                switch (event.systemOriginator) {
                    case UIEESystemOriginator.CAPC:
                        const loanID = event.originatorReferenceID;
                        redirect = encodeURIComponent(
                            `/ui/lender/${loanID}/closing`
                        );
                        break;
                    case UIEESystemOriginator.DOC_BUILDER:
                        redirect = encodeURIComponent(
                            `/ui/sign-event/event/${event.eSignEventID}/closing/terms`
                        );
                        break;
                    default:
                        // Close the video session if notarization type is RON
                        const isRON =
                            event.eSignEventNotarizationType ===
                            ESignEventNotarizationType.RON;
                        const query = isRON ? "?cv=1" : "";
                        redirect = encodeURIComponent(
                            `/ui/sign-event/event/${event.eSignEventID}/workflow${query}`
                        );
                }
            }
            return `/sf/ui/login?fr=${redirect}`;
        }
    }
}
