import {
    Directive,
    ElementRef,
    Renderer2,
    ViewContainerRef,
    ComponentRef,
    Input,
    TemplateRef,
    LOCALE_ID,
    Inject,
    OnChanges,
    SimpleChanges,
    OnDestroy,
    OnInit,
    Output,
    EventEmitter
} from "@angular/core";
import { getLocaleDirection } from "@angular/common";
import { generateRandomString } from "../helpers/string";
import { TooltipComponent } from "./tooltip.component";
import {
    Placement,
    placementNames,
    autoReplacement,
    TooltipConfig,
    TriggerAliases
} from "./tooltip.config";
import { fromEvent, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
let triggerCount = 0;

/**
 * Directive that displays a tooltip given a tricger of some sort. Usually hover
 *
 * Mostly matches the ngbTooltip VS5
 *
 */
@Directive({
    selector: "[sfTooltip]",
    exportAs: "sfTooltip"
})
export class TooltipDirective implements OnChanges, OnInit, OnDestroy {
    private _tooltipContainer: HTMLElement;
    private _guid = generateRandomString(5);
    private _sfTooltip: string | null | undefined;
    private _tooltipComponentRef: ComponentRef<TooltipComponent>;
    private _tooltipContainerRef: ElementRef;
    private _tooltipHtml: HTMLElement;
    private _placements: Placement[];
    private _cleanupFns: (() => void)[] = [];
    private _autoCloseWatchers: Subscription[] = [];
    private _startDir: "left" | "right";
    private _endDir: "left" | "right";
    private _openTimer: any = null;
    private _closeTimer: any = null;
    private $close: Subject<void> = new Subject();
    private _nativeHasListeners: boolean = false;

    /**
     * setter for the tooltip value. Either a string or a TemplateRef.
     * If an invalid value is set, or simply not provided, then hide any existing tooltip
     **/
    @Input()
    set sfTooltip(value: string | null | undefined) {
        this._sfTooltip = value;
        if (!value && this._tooltipComponentRef) {
            this.close();
        }
    }

    /**
     * getter for the tooltip value. Either a string or a TemplateRef
     **/
    get sfTooltip() {
        return this._sfTooltip;
    }

    /**
     * String seperated by spaces indicating locations next to the target element where
     * the tooltip should appear. This directive will attempt to display the tooltip at the first location
     * specified, but if there will be overlap (and the tooltip obscured) then subsequent locations will be tried.
     * if no position will be unobstructed, then the tooltip will appear at the first indicated location, regardless of visibility.
     *
     * any values entered that are not "valid" are simply ignored
     *
     * allowed values are
     *     "auto",
     *     "top",
     *     "bottom",
     *     "start",
     *     "left",
     *     "end",
     *     "right",
     *     "top-start",
     *     "top-left",
     *     "top-end",
     *     "top-right",
     *     "bottom-start",
     *     "bottom-left",
     *     "bottom-end",
     *     "bottom-right",
     *     "start-top",
     *     "left-top",
     *     "start-bottom",
     *     "left-bottom",
     *     "end-top",
     *     "right-top",
     *     "end-bottom",
     *     "right-bottom"
     **/
    @Input()
    placement: string = this.config.placement;

    /**
     * Indicates which element the tooltip component will be appended to (only "body" is currently supported)
     **/
    @Input()
    container: "body" | "" | null = this.config.container;

    /**
     * If true, the tooltip will NOT appear
     **/
    @Input()
    disableTooltip: boolean;

    /**
     * a list of triggers, seperated by spaces, that the parent element will listen for,
     * and then trigger the showing/hiding of the tooltip. These can be any "trigger" that are valid on an
     * html element ("mouseenter", "click", etc)
     **/
    @Input()
    triggers: string = this.config.triggers;

    /**
     * Delay in milliseconds before the tooltip is shown
     **/
    @Input()
    openDelay: number = this.config.openDelay;

    /**
     * Delay in milliseconds before the tooltip is hidden
     **/
    @Input()
    closeDelay: number = this.config.closeDelay;

    /**
     * Default true
     *
     * true: the tooltip will close if the user enters "esc", or clicks anywhere on the page
     * false: the tooltip will only close when a trigger (provided by the "triggers" input) is activated
     * inside: the tooltip will only close if the user enters "esc" or clicks anywhere inside the host element
     * outside: the tooltip will only close if the user enters "esc" or clicks anywhere outside the host element
     **/
    @Input()
    autoClose: true | false | "inside" | "outside" = this.config.autoClose;

    /**
     * a string indicating a css selector, or an html element, on which to "anchor" the tooltip
     * normally the "anchor" is the host element that contains this directive
     **/
    @Input()
    positionTarget: string | HTMLElement;

    /**
     * a css class that can be added to the tooltip
     **/
    @Input()
    tooltipClass: string;

    @Output()
    hidden: EventEmitter<any> = new EventEmitter();

    @Output()
    shown: EventEmitter<any> = new EventEmitter();

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private readonly viewContainerRef: ViewContainerRef,
        private config: TooltipConfig,
        @Inject(LOCALE_ID) private locale: string
    ) {
        if (getLocaleDirection(locale) === "ltr") {
            this._startDir = "left";
            this._endDir = "right";
        } else {
            this._startDir = "right";
            this._endDir = "left";
        }
    }

    ngOnInit() {
        // set up the tooltip 'anchor'
        this.renderer.setStyle(
            this._getPositionTargetElement(),
            "anchor-name",
            `--anchor-${this._guid}`
        );

        // tooltip creation and placement
        this._parsePlacements(this.placement);
        this._buildTooltip();
        this._moveTooltip();

        // listeners for when to show/hide the tooltip
        this._setupTriggers(this.triggers);
    }

    ngOnChanges(changes: SimpleChanges) {
        let rebuild: boolean = false;
        let needToMove: boolean = false;
        if (changes.placement && !changes.placement.firstChange) {
            this._parsePlacements(changes.placement.currentValue);
            needToMove = true;
        }
        if (changes.sfTooltip && !changes.sfTooltip.firstChange) {
            rebuild = true;
            needToMove = true;
        }
        if (changes.container && !changes.container.firstChange) {
            rebuild = true;
            needToMove = true;
        }
        if (changes.triggers && !changes.triggers.firstChange) {
            // clear any old triggers first
            this._cleanupFns.forEach((cleanupFn: () => void) => cleanupFn());
            this._cleanupFns = [];
            this._setupTriggers(changes.triggers.currentValue);
        }
        if (changes.positionTarget && !changes.positionTarget.firstChange) {
            rebuild = true;
            needToMove = true;
            this.renderer.removeStyle(
                this._getPositionTargetElement(),
                "anchor-name"
            );
            this.renderer.setStyle(
                this._getPositionTargetElement(),
                "anchor-name",
                `--anchor-${this._guid}`
            );
        }

        if (changes.autoClose && !changes.autoClose.firstChange) {
            if (this.isOpen()) {
                // clean up any existing
                this.$close.next();
            }
            this._setupAutoClose();
        }

        // rebuild and/or move the tooltip
        if (rebuild) {
            if (this._tooltipContainer) {
                this._tooltipContainer.remove();
            }
            this._buildTooltip();
        }
        if (needToMove) {
            this._moveTooltip();
        }
    }

    ngOnDestroy() {
        this._cleanupFns.forEach((cleanupFn: () => void) => cleanupFn());
        this._autoCloseWatchers.forEach((watcher) => watcher.unsubscribe());
    }

    /**
     * Public Method: close/hide the tooltip if open, or open/show the tooltip if closed
     **/
    toggle(): void {
        if (this.isOpen()) {
            this.close();
        } else {
            this.open();
        }
    }

    /**
     * Public Method: closes/hides the tooltip
     **/
    close(): void {
        if (this._openTimer) {
            clearTimeout(this._openTimer);
            this._openTimer = null;
            return;
        }

        if (this.closeDelay) {
            this._closeTimer = setTimeout(() => {
                this._hideTooltip();
                this._closeTimer = null;
            }, this.closeDelay);
        } else {
            this._hideTooltip();
        }
    }

    /**
     * Public Method: opens/shows the tooltip
     **/
    open(): void {
        if (!this._sfTooltip || this.disableTooltip) {
            return;
        }

        if (this._closeTimer) {
            clearTimeout(this._closeTimer);
            this._closeTimer = null;
            return;
        }

        if (this.openDelay) {
            this._openTimer = setTimeout(() => {
                this._showTooltip();
                this._openTimer = null;
            }, this.openDelay);
        } else {
            this._showTooltip();
        }
    }

    /**
     * Public Method: returns whether the tooltip is open/visible
     **/
    isOpen(): boolean {
        return (
            this._tooltipContainer &&
            this._tooltipContainer.style.opacity === "1"
        );
    }

    private _showTooltip() {
        // there is a possibility that the location of the anchor
        // element has moved, necessitating a recheck of overlap
        // (right now this is mostly important in storybook)
        this._moveTooltip();
        this._tooltipContainer.style.opacity = "1";
        this.shown.emit();
        this._setupAutoClose();
    }

    private _hideTooltip() {
        // Hide the tooltip
        this._tooltipContainer.style.opacity = "0";

        // prevent a scroll bar when the tooltip is hidden, if it is out-of-bounds
        this._tooltipContainer.style.display = "none";
        this.hidden.emit();
        this.$close.next();
    }

    private _buildTooltip() {
        if (!this._sfTooltip) {
            return;
        }

        this._tooltipComponentRef =
            this.viewContainerRef.createComponent(TooltipComponent);
        this._tooltipContainerRef = this._tooltipComponentRef.location;
        this._tooltipContainer = this._tooltipContainerRef.nativeElement;
        this._tooltipHtml =
            this._tooltipContainer.querySelector("div[role=tooltip]");
        if (this.tooltipClass) {
            this._tooltipHtml.className =
                this._tooltipHtml.className + " " + this.tooltipClass;
        }

        if (typeof this._sfTooltip === "string") {
            // tooltip from text
            this._tooltipHtml.textContent = this._sfTooltip;
        }

        this._tooltipContainerRef.nativeElement.classList.add(
            "tip-" + this._guid
        );
        this._tooltipContainerRef.nativeElement.style.positionAnchor = `--anchor-${this._guid}`;
        this._tooltipContainer.style.opacity = "0";
        if (this.container === "body") {
            document.body.appendChild(this._tooltipContainer);
        }
    }

    private _moveTooltip() {
        // make sure the tooltip is a part of the DOM before checking overlaps
        this._tooltipContainer.style.display = "block";

        // determine if the placement location of the tooltip is visible
        let container = this._getContainerElement();
        let viewContainerRect = container.getBoundingClientRect();
        for (let i = 0; i < this._placements.length; ++i) {
            this._assignLocationStyle(
                this._tooltipComponentRef,
                this._placements[i]
            );

            // apply the placement to the tooltip, and test out-of-bounds
            let tooltipRect = this._tooltipHtml.getBoundingClientRect();
            if (
                !viewContainerRect ||
                (tooltipRect.top > viewContainerRect.top &&
                    tooltipRect.right < viewContainerRect.right &&
                    tooltipRect.bottom < viewContainerRect.bottom &&
                    tooltipRect.left > viewContainerRect.left)
            ) {
                // good placement
                return;
            }
        }

        // if no good placement was found, then default to the first placement provided
        this._assignLocationStyle(
            this._tooltipComponentRef,
            this._placements[0]
        );
    }

    private _assignLocationStyle(
        compRef: ComponentRef<TooltipComponent>,
        placement: string
    ) {
        let split = placement.split("-");
        let side = split[0];
        let layout = split[1];
        let locationStyle: any = compRef.location.nativeElement.style;

        // clear any previous values
        locationStyle.bottom = "";
        locationStyle.left = "";
        locationStyle.top = "";
        locationStyle.right = "";
        locationStyle.positionArea = "";
        this.renderer.setAttribute(this._tooltipHtml, "placement", side);

        switch (side) {
            case "top":
                locationStyle.bottom = "anchor(top)";
                break;
            case "right":
                locationStyle.left = "anchor(right)";
                break;
            case "bottom":
                locationStyle.top = "anchor(bottom)";
                break;
            case "left":
                locationStyle.right = "anchor(left)";
                break;
        }
        if (!layout) {
            // if the layout variable is empty (!layout), then it means "center"
            locationStyle.positionArea = side;
            if (side === "top" || side === "bottom") {
                // centering the tooltip is finicky when the anchor is close to the edge of the screen
                locationStyle.left = "calc(2px + anchor(left) - 50%)";
                locationStyle.right = "calc(2px + anchor(right) - 50%)";
            }
        } else {
            // match the left/right of to the tooltip to the left/right of the target element
            locationStyle[layout] = "anchor(" + layout + ")"; // left/right
        }
    }

    private _parsePlacements(placement: string) {
        let parsed = placement ? placement.trim().split(" ") : ["auto"];
        let onlyOneAuto: boolean = false;

        for (let i = parsed.length; i >= 0; --i) {
            // replace auto with default directions (only 1 'auto' is allowed)
            if (parsed[i] === "auto" && !onlyOneAuto) {
                parsed = [
                    ...parsed.slice(0, i),
                    ...autoReplacement,
                    ...parsed.slice(i + 1)
                ];
                onlyOneAuto = true;
            }
            if (!placementNames.includes(parsed[i] as Placement)) {
                parsed.splice(i, 1); // remove any strings that aren't valid placements
                continue;
            }
            parsed[i] = parsed[i].split("start").join(this._startDir);
            parsed[i] = parsed[i].split("end").join(this._endDir);
        }

        // final assignment
        this._placements = parsed as Placement[];
        if (!this._placements || this._placements.length === 0) {
            this._placements = [...autoReplacement];
        }
    }

    private _setupTriggers(triggers: string) {
        const parsedTriggers = this._parseTriggers(
            !triggers ? this.config.triggers : triggers
        );

        if (parsedTriggers.length === 0) {
            return () => {};
        }

        for (const [openTrigger, closeTrigger] of parsedTriggers) {
            if (!closeTrigger) {
                // if there is no closing trigger, then have the open trigger "toggle" a close
                this._addEventListener(openTrigger, (event) => {
                    this.isOpen() ? this.close() : this.open();
                });
            } else {
                this._addEventListener(openTrigger, this.open.bind(this));
                this._addEventListener(closeTrigger, this.close.bind(this));
            }
        }
    }

    private _addEventListener(
        triggerName: string,
        listener: (event: KeyboardEvent) => void
    ) {
        this._nativeHasListeners = true;
        this.elementRef.nativeElement.addEventListener(triggerName, listener);
        this._cleanupFns.push(() => {
            this._nativeHasListeners = false;
            this.elementRef.nativeElement.removeEventListener(
                triggerName,
                listener
            );
        });
    }

    // copied directly from ngTooltip
    private _parseTriggers(triggers: string): [string, string?][] {
        const trimmedTriggers = (triggers || "").trim();

        if (trimmedTriggers.length === 0) {
            return [];
        }

        const parsedTriggers = trimmedTriggers
            .split(/\s+/)
            .map((trigger) => trigger.split(":"))
            .map(
                (triggerPair) =>
                    (TriggerAliases[triggerPair[0]] || triggerPair) as [
                        string,
                        string?
                    ]
            );

        const manualTriggers = parsedTriggers.filter((triggerPair) =>
            triggerPair.includes("manual")
        );

        if (manualTriggers.length > 1) {
            throw `Triggers parse error: only one manual trigger is allowed`;
        }

        if (manualTriggers.length === 1 && parsedTriggers.length > 1) {
            throw `Triggers parse error: manual trigger can't be mixed with other triggers`;
        }

        return manualTriggers.length ? [] : parsedTriggers;
    }

    private _setupAutoClose() {
        if (this.autoClose) {
            this._autoCloseWatchers.push(
                fromEvent<KeyboardEvent>(document, "keydown")
                    .pipe(takeUntil(this.$close))
                    .subscribe(this._executeAutoClose.bind(this))
            );
            this._autoCloseWatchers.push(
                fromEvent<KeyboardEvent>(document, "mousedown")
                    .pipe(takeUntil(this.$close))
                    .subscribe(this._executeAutoClose.bind(this))
            );
            this._autoCloseWatchers.push(
                fromEvent<KeyboardEvent>(document, "mouseup")
                    .pipe(takeUntil(this.$close))
                    .subscribe(this._executeAutoClose.bind(this))
            );
        }
    }

    private _executeAutoClose(event: KeyboardEvent) {
        const element = event.target as HTMLElement;
        if (this.autoClose === "inside" || this.autoClose === true) {
            if (
                this.elementRef.nativeElement.contains(element) &&
                !this._nativeHasListeners
            ) {
                this._hideTooltip();
            }
        } else if (this.autoClose === "outside") {
            if (!this.elementRef.nativeElement.contains(element)) {
                this._hideTooltip();
            }
        }
    }

    private _getPositionTargetElement(): HTMLElement {
        return (
            (typeof this.positionTarget === "string"
                ? document.querySelector(this.positionTarget)
                : this.positionTarget) || this.elementRef.nativeElement
        );
    }

    private _getContainerElement() {
        let potentialParent: HTMLElement = this._tooltipHtml.parentElement;
        let style = window.getComputedStyle(potentialParent);
        do {
            potentialParent = potentialParent.parentElement;
            style = window.getComputedStyle(potentialParent);
        } while (
            potentialParent !== document.body &&
            style.overflowX === "visible" &&
            style.overflowY === "visible" &&
            style.overflow === "visible"
        );

        return potentialParent ? potentialParent : document.body;
    }
}
