import { Injectable, RendererFactory2, Renderer2 } from "@angular/core";
import {
    from,
    interval,
    Observable,
    of,
    throwError,
    TimeoutError,
    Subject
} from "rxjs";
import {
    LiveConfigService,
    SessionService,
    UserSettingsService
} from "@sf/common";
import { RpcClientService } from "@sf/common";
import {
    catchError,
    debounceTime,
    first,
    map,
    switchMapTo,
    switchMap,
    take,
    takeWhile,
    timeout,
    withLatestFrom,
    finalize
} from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { SubscriptionBaseService } from "@sf/common";
import { SocketService } from "@sf/common";
import {
    ModuleList,
    TrayAppUserTelemetry,
    TrayMessage,
    TrayUpdate
} from "../../interfaces/tray.interface";
import { TrayUpdateModalComponent } from "../../components/tray-update-modal/tray-update-modal.component";
import { LoggerService } from "@sf/common";
import { AlertModalComponent } from "@sf/common";
import { getOS } from "@sf/common";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TrayRelinkModalComponent } from "../../components/tray-relink-modal/tray-relink-modal.component";
import { Router } from "@angular/router";
import { TrayDownloadModalComponent } from "../../components/tray-download-modal/tray-download-modal.component";
import { retryBackoff } from "@sf/common";

@Injectable({
    providedIn: "root"
})
export class TrayService extends SubscriptionBaseService {
    /** Private Variables **/
    protected namespace = "tray";
    protected product = "tray"; // Not really needed for this subscription

    private TRAY_LINKED_USER_SETTING = "tray_successfully_linked";
    private _dialog: any;
    private _modalInstance: any;
    private _updateComplete = false;
    private _downloadModelShowing = false;

    // The number of semver patch versions behind the current version that is allowed
    // before we force an upgrade.
    private _allowedVersionDiff = 2;

    private _forceTrayAppUpgrade: boolean;
    private _renderer: Renderer2;
    private _isTrayOnline$: Subject<boolean> = new Subject();
    public trayOnline: Observable<boolean> = this._isTrayOnline$.asObservable();

    /** Public Variables **/

    constructor(
        protected _socket: SocketService,
        private _sessionService: SessionService,
        private userSettingsService: UserSettingsService,
        private _rpcClient: RpcClientService,
        private _modalService: NgbModal,
        private _http: HttpClient,
        private _logger: LoggerService,
        private _router: Router,
        private _liveConfigService: LiveConfigService,
        private _rendererFactory: RendererFactory2
    ) {
        super(_socket);
        this._forceTrayAppUpgrade = this._liveConfigService.get(
            "FalconSettings.forceTrayAppUpgrade"
        );

        // Necessary in order to use Renderer2 from a service
        this._renderer = this._rendererFactory.createRenderer(null, null);
        this._init();
    }

    /** Public Methods **/
    /**
     * Returns true when the tray app is enabled and linked
     * This only returns the status when the app was initialized. If the tray was linked
     * since then and the page has not been refreshed it will still return false.
     * @returns {boolean}
     */
    trayEnabled(): boolean {
        return (
            this._sessionService.useTray() ||
            document.cookie.indexOf("tray") > -1 ||
            this.userSettingsService.getUserSettingBoolean(
                this.TRAY_LINKED_USER_SETTING
            )
        );
    }

    /**
     * Verifies the ability to connect to the tray app. Does not verify the ability
     * to load a module. Resolves with true if successful, errors with false if not.
     * @returns {Observable<boolean>}
     */
    isTrayOnline(): Observable<boolean> {
        return of(true).pipe(
            debounceTime(300),
            switchMapTo(this.sendMessage({ method: "trayOnline" }, true)),
            withLatestFrom(this._getLatestVersion()),
            map(([resp, latestVersion]: [any, string]) => {
                const isVersionSupported =
                    !this._forceTrayAppUpgrade ||
                    this._isVersionSupported(resp.trayVersion, latestVersion);
                if (!isVersionSupported) {
                    const downloadTrayModal = this._modalService.open(
                        TrayDownloadModalComponent
                    );
                    this._downloadModelShowing = true;

                    downloadTrayModal.componentInstance.trayInstallerUrl =
                        this.getInstallerUrl();

                    downloadTrayModal.result.then((result: any) => {
                        if (result === "primary") {
                            const url = this.getInstallerUrl();
                            const link = this._renderer.createElement("a");
                            link.setAttribute("target", "_blank");
                            link.setAttribute("href", url);
                            link.setAttribute(
                                "download",
                                this.getInstallerName()
                            );
                            link.click();
                            link.remove();
                        }

                        this._downloadModelShowing = false;
                    });
                }

                this._logUserTelemetry(
                    Object.assign({}, resp.telemetry, {
                        trayVersion: resp.trayVersion
                    })
                );

                return isVersionSupported;
            }),
            catchError(() => {
                return throwError(false);
            })
        );
    }

    /**
     * Tests the end-to-end linking of the tray app and the ability to call functions in modules
     * Returns a promise that resolves to true if it is successful and rejects if unsuccessful.
     * If handleTrayError is true, it will automatically try to link the app
     *
     * @param {boolean} handleTrayError
     * @param {{[moduleName]: string}} [additionalModulesToInstall] - additional modules that should be installed at test time
     * @returns {Observable<boolean>}
     */
    verifyTrayLink(
        handleTrayError?: boolean,
        additionalModulesToInstall?: ModuleList | any //TODO: Figure out actual ModuleList structure or is just an object okay or what???
    ): Observable<boolean> {
        const dependencies = {
            ...additionalModulesToInstall,
            ...{ "java-test": "1.0.3" }
        };

        return this.sendMessage(
            {
                module: "java-test",
                method: "test",
                dependencies: dependencies,
                data: {
                    testPing: true
                }
            },
            !handleTrayError
        ).pipe(map(() => true));
    }

    clearLink() {
        return this._http.post("/sf/websocket/tray/clearCookies", {
            userID: this._sessionService.getUsername()
        });
    }

    clearAndLink(
        notFullTest?: boolean,
        additionalModulesToInstall?: ModuleList | any, //TODO: Figure ModuleList interface structure
        timeout_interval?: number
    ) {
        return this.clearLink().pipe(
            timeout(1000),
            catchError((error) => {
                // if the "clear link" fails (while attempting to delete the tray app cookies)
                // this will attempt to link the tray app again anyway
                return of(true);
            }),
            switchMap(() => {
                return this.linkTray(
                    notFullTest,
                    additionalModulesToInstall,
                    timeout_interval
                );
            })
        );
    }

    // /**
    //  * Performs a full linking and end-to-end test of tray app communication by:
    //  * 1 - getting the tray app protocol link
    //  * 2 - opening the tray app by opening the app protocol link in a hidden iframe
    //  * 3 - listening for the getTrayKey event that the tray app sends to the browser through middleware
    //  * 4 - connecting to middleware and reporting the link that was sent to the browser
    //  * 5 - restarts the middleware connection to add the 'tray' cookie middleware gives us
    //  * 6 - sends a request to the tray app to verify communication
    //  *      a - this request includes an install of the jre and java-test modules when 'notFullTest' is not defined
    //  *      b - this request is just a simple verification of tray app communication (no module verification) when 'notFullTest' is true
    //  *      c - additional modules to install can be specified as part of the request to avoid multiple 'updating' messages - forces a full test if present
    //  *      d - multiple attempts to first do simple communication with the tray app are included because of various issues. see description of _checkConnection
    //  * @param {boolean} notFullTest - choose between testing module installation and not
    //  * @param {{[moduleName]: string}} [additionalModulesToInstall] - additional modules that should be installed at link time
    //  * @returns {Promise<any>}
    //  */
    linkTray(
        notFullTest?: boolean,
        additionalModulesToInstall?: ModuleList | any, //TODO: Figure ModuleList interface structure
        timeout_interval?: number
    ): Observable<any> {
        //  * 1 - getting the tray app protocol link
        return this._getTrayLink().pipe(
            //  * 2 - opening the tray app by opening the app protocol link in a hidden iframe
            switchMap((link: string) => {
                this._openTrayApp(link);
                return of(true);
            }),
            catchError(() =>
                throwError(`Failed to get the application protocol link`)
            ),
            //  * 3 - listening for the getTrayKey event that the tray app sends to the browser through middleware
            switchMap(() => this.on("getTrayKey")),
            map((response: any) => {
                return response.data;
            }),
            //  * 4 - connecting to middleware and reporting the link that was sent to the browser
            switchMap((responseData: any) => {
                return this._http.post("/sf/websocket/tray/link", {
                    link: responseData.link,
                    userID: this._sessionService.getUsername()
                });
            }),
            timeout(
                timeout_interval
                    ? timeout_interval
                    : 60000 * (this._sessionService.getEnv() !== "PROD" ? 1 : 5)
            ),
            catchError((error) => {
                if (error instanceof TimeoutError) {
                    return throwError(
                        `The linking request took longer than expected. Did you allow your browser to open the Simplifile application? Please try again.
                        If this problem persists, please contact Simplifile Support at 800.460.5657.`
                    );
                }
                return throwError(error);
            }),
            //  * 5 - restarts the middleware connection to add the 'tray' cookie middleware gives us
            switchMap(() => {
                // restart our websocket so that the "tray" cookie gets associated with the websocket connection
                this._socket.reconnect();
                return of(true);
            }),
            take(1),
            //  * 6 - sends a request to the tray app to verify communication
            switchMap(() => this._checkConnection(10)),
            catchError(() =>
                throwError(
                    `Failed to verify application communication connection`
                )
            ),
            switchMap(() => {
                if (notFullTest && !additionalModulesToInstall) {
                    return of(true);
                }
                return this.verifyTrayLink(
                    false,
                    additionalModulesToInstall
                ).pipe(
                    catchError(() =>
                        throwError(
                            `Failed to verify successful launch of module`
                        )
                    )
                );
            }),
            catchError((error: any) => {
                this._logger.debug("Linking tray: failed, setting link failed");
                this._logger.debug("Linking tray: failed, error: ", error);
                this.userSettingsService.setUserSetting(
                    this.TRAY_LINKED_USER_SETTING,
                    true
                );
                return throwError(error);
            }),
            switchMap(() => {
                this.userSettingsService.setUserSetting(
                    this.TRAY_LINKED_USER_SETTING,
                    true
                );
                return of(true);
            })
        );
    }

    /**
     * Returns the tray installer url
     * @returns {string}
     */
    getInstallerUrl(): string {
        return `/install/desktop/dist/${this.getInstallerName()}`;
    }

    /**
     * Returns the name of the installer file
     * @returns {string}
     */
    getInstallerName() {
        return `Simplifile_Utilities_Installer${
            getOS() !== "Windows" ? ".dmg" : ".exe"
        }`;
    }

    /**
     * Data structure for tray messages
     * @typedef TrayMessage
     * @property {string} module - the name of the module
     * @property {string} method - the method to call on the module
     * @property {{[moduleName]: string}} dependencies - object property/value map listing required modules and versions
     * @property {*} data - the data to pass to the method call on the module
     */

    /**
     * Sends a request to a module of the tray app.
     * When dontHandleLinkErrors is true, will not try to automatically relink tray app
     * @param {TrayMessage} trayMessage
     * @param {boolean} dontHandleLinkErrors
     * @returns {Observable<any>}
     */
    sendMessage(
        trayMessage: TrayMessage | any,
        dontHandleLinkErrors?: boolean
    ): Observable<any> {
        return of(true).pipe(
            switchMap(() =>
                this.send("appRequest", {
                    module: trayMessage.module,
                    method: trayMessage.method,
                    deps: trayMessage.dependencies,
                    data: trayMessage.data
                })
            ),
            catchError((error: any) => {
                if (
                    !dontHandleLinkErrors &&
                    (error === "noBrowserId" ||
                        error === "Tray app not connected")
                ) {
                    if (this._router.url.indexOf("tray-setup") === -1) {
                        const relinkModal = this._modalService.open(
                            TrayRelinkModalComponent
                        );
                        relinkModal.componentInstance.clearAndLink =
                            this.clearAndLink.bind(this);
                        relinkModal.componentInstance.trayInstallerUrl =
                            this.getInstallerUrl();
                        return from(relinkModal.result).pipe(
                            switchMap((result: any) => {
                                if (result === "Linked") {
                                    return this.sendMessage(trayMessage);
                                }
                            })
                        );
                    }
                }
                return throwError(error);
            })
        );
    }

    /**
     * Log a message to the backend.
     * @param msg
     */
    logMessage(msg: any) {
        msg = msg.replace(/</g, "&lt;").replace(/>/g, "&gt;");
        return this._rpcClient
            .makeRequest("admin", "saveLog", {
                type: "Environment",
                message: msg
            })
            .pipe(
                catchError(() => of(false)) // swallow / ignore
            );
    }

    /**
     * Installs latest versions of listed modules
     * @param {{NAME: string, VERSION: string}[]} modules - array of modules to install
     * @return {Observable<any>}
     */
    installModules(modules: ModuleList): Observable<any> {
        const modulesObj = Object.assign(
            {},
            ...modules.map((item) => ({ [item["NAME"]]: item.VERSION }))
        );

        return this.sendMessage({
            module: "trayApp",
            method: "installModules",
            data: {
                modules: modulesObj
            }
        });
    }

    /**  Private Methods  **/

    /**
     * Initializes listeners to tray updates and tray errors
     * Links tray if it has been successfully linked before and is not currently linked
     */
    private _init() {
        // we always want to be listening for tray updates. Never unsubscribe.
        this.onAll().subscribe({
            error: (err: any) => {
                this._logger.debug("Tray error: ", err);
            }
        });

        this.on("updating").subscribe((resp: any) => {
            this._handleTrayUpdate(resp.data as TrayUpdate);
        });

        if (
            this.userSettingsService.getUserSettingBoolean(
                this.TRAY_LINKED_USER_SETTING
            )
        ) {
            const trayOnlineSubscription = this.isTrayOnline()
                .pipe(catchError(() => this.clearAndLink(true)))
                .subscribe((isOnline) => {
                    this._isTrayOnline$.next(isOnline);
                    trayOnlineSubscription.unsubscribe();
                });
        }
    }

    /**
     * Creates a hidden iframe with the tray app application protocol link that removes itself
     * once it loads
     * @param {string} link
     */
    private _openTrayApp(link: string) {
        let i = document.createElement("iframe");
        i.style.display = "none";
        i.onload = () => {
            document.body.removeChild(i);
        };
        i.src = link;
        document.body.appendChild(i);
    }

    /**
     * Gets the application protocol link used for linking the tray app
     * Intentionally not a public function. To link the tray, always use
     * the 'linkTray' function.
     * @returns {Observable<string>}
     */
    private _getTrayLink() {
        return this._http
            .post("/sf/websocket/tray/getlink", {
                userID: this._sessionService.getUsername()
            })
            .pipe(
                switchMap(() => {
                    this._socket.reconnect();
                    return this.send("getLink", {}).pipe(
                        map((response: any) => {
                            return `simplifile://${response.link}?${window.location.protocol}//${window.location.hostname}`;
                        })
                    );
                })
            );
    }

    /**
     * Displays a progress dialog while the tray is updating dependencies
     * @param {{percentProgress: number}} updateObj
     */
    private _handleTrayUpdate(updateObj: TrayUpdate) {
        let dialogVm: any = this._modalInstance ? this._modalInstance : {};
        this._updateComplete = false;
        this._logger.debug("Tray updating", updateObj);

        dialogVm.percentProgress = updateObj.percentProgress;
        dialogVm.message = updateObj.message;

        if (updateObj.complete === false && !this._dialog) {
            this._pollTrayAppDuringUpdate(); // Poll the tray app during the update process to make sure it is still connected
            this._showUpdateDialog(dialogVm);
            dialogVm = this._modalInstance;
            return;
        }

        if (updateObj.complete) {
            this._updateComplete = true;
            // Give some time for the dialog to render completeness then close the dialog
            setTimeout(() => {
                if (this._dialog) {
                    this._dialog.close();
                }
                this._dialog = undefined;
                dialogVm = {
                    percentProgress: 0
                };
            }, 1000);
            return;
        }
    }

    /**
     * Verifies that the tray has been registered and calls can be made to it. Because the tray app itself has to
     * notify middleware to complete the registration, and for security and various other reasons we don't allow
     * the tray app to send a message to the browser unless the browser initiates the messaging, we must attempt
     * calls into the tray app at increasing intervals up to some limit of attempts.
     * @param maxRetries
     * @return {Promise<boolean>}
     * @private
     */
    private _checkConnection(maxRetries: number): Observable<any> {
        const initialInterval = 1000;
        const maxInterval = 10000;
        return of(true).pipe(
            switchMap(() => this.isTrayOnline()),
            retryBackoff(
                {
                    initialInterval: initialInterval,
                    maxRetries: maxRetries,
                    maxInterval: maxInterval
                },
                (attempt) => {
                    this._logger.debug(
                        `Linking tray: isTrayOnline failed. Tries remaining: ${
                            maxRetries - attempt
                        }`
                    );
                }
            ),
            catchError((error: any) => {
                return throwError(error);
            }),
            finalize(() => {
                this._logger.debug(
                    "Linking tray: isTrayOnline completed successfully"
                );
            })
        );
    }

    private _showUpdateDialog(data: any) {
        this._dialog = this._modalService.open(TrayUpdateModalComponent);
        this._modalInstance = this._dialog.componentInstance;
        this._modalInstance.message = data.message;
        this._modalInstance.percentProgress = data.percentProgress;
    }

    private _pollTrayAppDuringUpdate() {
        interval(10000)
            .pipe(
                switchMap(() => this.isTrayOnline()),
                catchError((err) => {
                    this._dialog.close();
                    let modalInstance =
                        this._modalService.open(
                            AlertModalComponent
                        ).componentInstance;
                    modalInstance.title =
                        "Lost Connection to Simplifile Utilities";
                    modalInstance.message = `There was a connection interruption when updating Simplifile Utilities.
                    Attempting to reconnect to Simplifile Utilities...`;
                    this.sendMessage(
                        {
                            module: "trayApp",
                            method: "updateWID"
                        },
                        true
                    );
                    this._updateComplete = true;
                    return throwError(err);
                }),
                takeWhile(() => !this._updateComplete)
            )
            .subscribe();
    }

    /**
     * Add/Update telemetry for Tray App user
     */
    private _logUserTelemetry(data: any): void {
        const telemetry: TrayAppUserTelemetry = {
            ...data,
            username: this._sessionService.getUsername(),
            userAgent: navigator.userAgent
        };

        this._rpcClient
            .makeRequest("admin", "logUserTelemetry", { telemetry })
            .pipe(
                catchError(() => {
                    this._logger.debug(
                        "Tray error: Unable to log telemetry: ",
                        telemetry
                    );
                    return of(false);
                })
            )
            .subscribe();
    }

    private _getLatestVersion(): Observable<string> {
        return this._http
            .get("/install/desktop/dist/latest.yml", { responseType: "text" })
            .pipe(
                first(),
                map((yml: string) => {
                    try {
                        const lines = yml.split("\n");
                        const [_, version] = lines[0].split(": ");
                        return version;
                    } catch (e) {
                        console.error("Unable to retrieve latest version: ", e);
                    }
                })
            );
    }

    private _isVersionSupported(
        version: string,
        latestVersion: string
    ): boolean {
        if (!version) return false;

        const [major, minor, patch] = version.split(".");
        const [ltMajor, ltMinor, ltPatch] = latestVersion.split(".");
        if (
            Number(major) === Number(ltMajor) &&
            Number(minor) === Number(ltMinor) &&
            Number(patch) >= Number(ltPatch) - this._allowedVersionDiff
        ) {
            return true;
        }

        return false;
    }
}
