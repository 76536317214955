import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import {
    OrganizationEntitlement,
    SuspendedService
} from "../interfaces/organization.interface";
import { ActiveService, SessionService } from "@sf/common";
import { UserOrgService } from "./user-org.service";
import { UserorgActivationService } from "./userorg-activation.service";

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class OrganizationSuspensionService {
    private _shownNotification: boolean = false;

    constructor(
        private _userOrgService: UserOrgService,
        private _sessionService: SessionService,
        private _activationService: UserorgActivationService
    ) {}

    /**
     * find the first suspended service for the currently logged-in user
     */
    isAnyServiceSuspended(): Observable<SuspendedService> {
        let response: SuspendedService;

        // can't call Session service for this, because we need to make a back-end call to get latest
        return this._userOrgService.getOrganizationListJustServices().pipe(map((organizationData: any[]) => {
            organizationData.find((organization: any) => {
                return organization.activeServices.find((service: ActiveService) => {
                    if (service.status === "SUSPENDED" && this.isFinancialAdmin(organization.organization.id)) {
                        response = this.buildServiceResponse(organization, service);
                    }
                });
            });
            return response;
        }));
    }

    /**
     * get all suspended services
     * @param orgID
     * @private
     */
    areAnyServicesSuspended(): Observable<SuspendedService[]> {
        let response: SuspendedService[] = [];

        // can't call Session service for this, because we need to make a back-end call to get latest
        return this._userOrgService.getOrganizationListJustServices().pipe(map((organizationData: any[]) => {
            organizationData.forEach((organization: any) => {
                organization.activeServices.find((service: ActiveService) => {
                    if (service.status === "SUSPENDED" && this.isFinancialAdmin(organization.organization.id)) {
                        let servy = this.buildServiceResponse(organization, service);
                        response.push(servy);
                    }
                });
            });
            return response;
        }));
    }

    private isFinancialAdmin(orgID: string): boolean {
        return this._sessionService.hasAnyPermission(
                ["organization_payment_approval", "organization_accounting"],
                orgID);
    }

    private buildServiceResponse(
        organization: any,
        service: ActiveService
    ): any {
        return {
            orgID: organization.organization.id,
            orgName: organization.organization.name,
            serviceID: service.id,
            serviceName: service.label,
            suspendedReason:
                service.status === "SUSPENDED" ? service.statusDetails : null
        };
    }

    /**
     * Checks to see if a particular service for the given organization is suspended or not.
     * Intended to be used by other modules to know if the suspension dialog should be shown at a billable event.
     * @param orgID Organization id (ex: 'UTTDLY')
     * @param serviceID Service (or Product) id (ex: 'SUBMITTER_SIGNING')
     * @returns {*} true if service is suspended, false otherwise
     */
    isServiceSuspended(orgID: string, serviceID: string): Observable<boolean> {
        let result: boolean = false;
        return this._userOrgService.getOrganizationEntitlements(orgID).pipe(
            map((services: OrganizationEntitlement[]) => {
                services.forEach((service: OrganizationEntitlement) => {
                    if (service.status === "SUSPENDED" && service.product.toLowerCase() == serviceID.toLowerCase()) {
                        result = true;
                    }
                });
                return result;
            })
        );
    }

    isPaymentIssues(reason: string): boolean {
        let paymentReasons = [
            "PAYMENT_ISSUES",
            "PAST_DUE_ACH_PAYMENT",
            "PAST_DUE_CARD_PAYMENT",
            "PAST_DUE_ECHECK_PAYMENT",
            "PAST_DUE_INVOICE_PAYMENT"
        ];
        return paymentReasons.includes(reason);
    }
}
