import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    NavigationEnd,
    Resolve,
    Router,
    RouterStateSnapshot
} from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
    BaseModalComponent,
    GrowlService,
    LoggerType,
    PersistedMemoryService,
    SessionService,
    SocketService,
    SubscriptionBaseService,
    WindowRefService
} from "@sf/common";
import * as log from "loglevel";
import {
    BehaviorSubject,
    combineLatest,
    merge,
    Observable,
    of,
    Subject,
    Subscription,
    throwError
} from "rxjs";
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    first,
    map,
    pairwise,
    switchMap,
    take,
    takeUntil,
    tap
} from "rxjs/operators";
import { PrivateEventDialogComponent } from "../dialogs/private-event-dialog/private-event-dialog.component";
import {
    SIGN_EVENT_STORAGE_KEY,
    SignEventStorage
} from "../interfaces/esign-closing.interface";
import { EE_LOG_COLORS } from "../interfaces/sign-event-logging";
import {
    ESignEventChangedData,
    ESignEventChangedNotification,
    UIDocumentsModel,
    UIEEParticipantDeviceState,
    UIEERoom,
    UIESignEventWrapper,
    UIParticipantsModel,
    UIRoomStatus
} from "../interfaces/sign-event-notification.interface";
import { UIESignEventParticipant } from "../interfaces/sign-event-participant.interface";
import {
    RoomParticipantStatusType,
    RoomStatusType
} from "../interfaces/sign-event-room-status.interface";
import {
    PersonallyKnownStatus,
    STEP_COMPLETED_ACCEPTANCE_STATUSES,
    UIRoomParticipant
} from "../interfaces/sign-event-room.interface";
import {
    ESignEventNotarizationType,
    StatusType,
    UICurrentUserCanViewEventResponse,
    UIEESystemOriginator,
    UIESignEvent
} from "../interfaces/sign-event.interface";
import { ESignEventNamePipe } from "../pipes/esign-event-name.pipe";
import { ESignEventSnapshot } from "../snapshots/esign-event-snapshot";
import { ESignEventRedirectService } from "./esign-event-redirect.service";
import { TaggingSharedService } from "./tagging-shared.service";

@Injectable({
    providedIn: "root"
})
export class SignEventNotificationService
    extends SubscriptionBaseService
    implements Resolve<boolean>
{
    /** Public Variables **/
    eSignEvent$: Observable<UIESignEvent>;
    eventPermissions$: Observable<any>;
    participants$: Observable<UIESignEventParticipant[]>;
    documents$: Observable<UIDocumentsModel>;
    room$: Observable<UIEERoom>;
    roomStatus$: Observable<UIRoomStatus>;
    roomParticipant$: Observable<UIRoomParticipant>;
    participant$: Observable<UIESignEventParticipant>;
    activeParticipant$: Observable<UIESignEventParticipant>; // The signer selected by the notary
    deviceShareCandidates$: Observable<UIESignEventParticipant[]>;
    sharedDeviceState$: Observable<UIEEParticipantDeviceState>;
    participantSetup$: Observable<boolean>;
    showingNotaryLeftDlg$: Observable<boolean>;
    notaryCanceledEvent$: Observable<boolean>;
    eventCanceled: boolean;
    readonly SIGNING_STATUSES: RoomStatusType[] = [
        RoomStatusType.NOTARY_SESSION_STARTED,
        RoomStatusType.NOTARY_SELECTING_FIRST_SIGNER,
        RoomStatusType.PARTICIPANT_IS_SIGNING,
        RoomStatusType.NOTARY_SELECTING_NEXT_SIGNER,
        RoomStatusType.NOTARY_IS_NOTARIZING,
        RoomStatusType.SESSION_PAUSED
    ];
    readonly END_STATUSES: RoomStatusType[] = [
        ...this.SIGNING_STATUSES,
        RoomStatusType.SIGNING_COMPLETE,
        RoomStatusType.SESSION_COMPLETE
    ];
    snapshot: ESignEventSnapshot;

    /** Private Variables **/
    private readonly _eSignEventID$: Subject<string> = new Subject();
    private readonly _needNotaryPages = [
        "phone-id-upload",
        "id-verification",
        "video-setup",
        "state-requirements",
        "participant-location",
        "kba",
        "participant-room"
    ];
    private readonly _notUsingPK: PersonallyKnownStatus[] = [
        PersonallyKnownStatus.NOT_ALLOWED,
        PersonallyKnownStatus.DENIED
    ];
    private _currentClosingPage: string;
    private _onNotaryNeedsToBePresentPage: boolean = false;
    private _eventChange = new Subject<void>();
    private _eventChangeID: string;
    private _isNotaryChange = new Subject<void>();
    private _signingInProgress: boolean;
    private _ignoringUpdates = false;
    private _lastIgnoredChange: ESignEventChangedData;
    private _watcher: Subscription;
    private _eventSubject: BehaviorSubject<UIESignEvent>;
    private _eventPermissionSubject: BehaviorSubject<any>;
    private _participantsSubject: BehaviorSubject<UIParticipantsModel>;
    private _documentSubject: BehaviorSubject<UIDocumentsModel>;
    private _roomSubject: BehaviorSubject<UIEERoom>;
    private _roomStatusSubject: BehaviorSubject<UIRoomStatus>;
    private _showingNotaryLeftSubject: BehaviorSubject<boolean>;
    private _notaryCanceledSubject: BehaviorSubject<boolean>;
    private _eeName: ESignEventNamePipe;
    private _showingNotSignedIn: boolean = false;
    private _participantPreviousStatus: any = {};
    private readonly _logger = log.getLogger(LoggerType.ESIGNEVENTS);

    /** Lifecycle Hooks **/

    constructor(
        private _taggingSharedService: TaggingSharedService,
        protected _socket: SocketService,
        private _modalService: NgbModal,
        private _http: HttpClient,
        private _persistedMemoryService: PersistedMemoryService,
        private _router: Router,
        private _sessionService: SessionService,
        private _windowRef: WindowRefService,
        private _redirectService: ESignEventRedirectService,
        private _growlService: GrowlService
    ) {
        super(_socket);
        // Because this service is provided in root, injection of our name pipe doesn't work.
        this._eeName = new ESignEventNamePipe();
        this.product = "esign_events";
        this.namespace = "esign_event";
        this.eventCanceled = false;
        this.snapshot = new ESignEventSnapshot();

        this._signingInProgress = false;
        this._goBackToLobby = this._goBackToLobby.bind(this);
        this._eSignEventID$.subscribe((eventID) =>
            this._watchForChanges(eventID)
        );
        this._router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                if (event.url.includes("/sign-event/events/")) {
                    this._eSignEventID$.next(null);
                }
            });

        this._initESignEvent$();
        this._initEventPermissions$();
        this._initParticipants$();
        this._initDocuments$();
        this._initRoom$();
        this._initRoomStatus$();

        this._initRoomParticipant$();
        this._initParticipant$();
        this._initActiveParticipant$();
        this._initDeviceShareCandidates$();
        this._initSharedDeviceState$();
        this._initParticipantSetup$();
        this._initNotaryObservables$();
        this._watchForNotaryLeft();
        this._redirectToStart(false);
        this._watchForParticipantNotSignedIn();
    }

    /** Public Methods **/

    get signingInProgress() {
        return this._signingInProgress;
    }

    set signingInProgress(inProgress: boolean) {
        this._signingInProgress = inProgress;
    }

    startIgnoringUpdates() {
        this._ignoringUpdates = true;
    }

    stopIgnoringUpdates() {
        this._ignoringUpdates = false;
        if (this._lastIgnoredChange) {
            this._handleChange(this._lastIgnoredChange);
            this._lastIgnoredChange = undefined;
        }
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        const url = state.url;
        const urlParts = url.split("/");
        const eSignEventID = urlParts[3];
        // Don't resubscribe unless the ID has changed.
        if (eSignEventID !== this.snapshot.event.eSignEventID) {
            this._cancelIgnoringUpdates();
            this._eSignEventID$.next(eSignEventID);
        }
        this._setOnNotaryNeedsToBePresentPage(urlParts);
        return this.eSignEvent$.pipe(
            first((event) => !!event),
            map((event) => {
                if (
                    url.includes("/closing/") ||
                    (url.includes("/sign-event/") &&
                        url.includes("/signing/") &&
                        url.includes("share=true"))
                ) {
                    this._watchForCanceledEvent();
                }
                return event.permissions.includes("esign_event_notary");
            })
        );
    }

    logoutRoomParticipant(redirectNow: boolean) {
        const event = this.snapshot.event;
        let logout$: Observable<any>;
        if (
            this.snapshot.room.participantID ||
            event.systemOriginator !== UIEESystemOriginator.DOC_BUILDER
        ) {
            const url = "/sf/esign_event/auth/logoutRoomParticipant";
            const data = { eSignEventID: event.eSignEventID };
            logout$ = this._http.post(url, data);
        } else {
            logout$ = of({});
        }
        if (redirectNow) {
            logout$.subscribe(() => {
                this._redirectService.redirectOnLeaveEvent(event);
            });
        } else {
            this._setRedirectSoon(true);
        }
    }

    cancelToken() {
        let eventStorage: SignEventStorage = this._persistedMemoryService.get(
            SIGN_EVENT_STORAGE_KEY
        );
        if (eventStorage) {
            eventStorage.participantToken = "canceled";
            eventStorage.videoRoom = {
                token: null,
                roomName: null
            };
        } else {
            eventStorage = { participantToken: "canceled", videoRoom: null };
            this._persistedMemoryService.set(
                SIGN_EVENT_STORAGE_KEY,
                eventStorage
            );
        }
    }

    updateAll$(
        eSignEventID: string,
        overrideDefaultErrorHandling: boolean = false
    ): Observable<UIESignEventWrapper> {
        return this._getESignEvent(
            eSignEventID,
            true,
            true,
            true,
            true,
            true,
            overrideDefaultErrorHandling
        ).pipe(
            take(1),
            tap((wrapper: UIESignEventWrapper) => {
                this.snapshot.updateAll(wrapper);
                this._eventPermissionSubject.next(wrapper.event.permissions);
                this._eventSubject.next(wrapper.event);
                this._participantsSubject.next(wrapper.participants);
                this._documentSubject.next(wrapper.documents);
                this._roomSubject.next(wrapper.room);
                this._roomStatusSubject.next(wrapper.roomStatus);
            })
        );
    }

    /** Private Methods **/

    private _cancelIgnoringUpdates() {
        this._lastIgnoredChange = undefined;
        this._ignoringUpdates = false;
    }

    private _initESignEvent$() {
        this._eventSubject = new BehaviorSubject<UIESignEvent>(null);
        this.eSignEvent$ = this._eventSubject.asObservable();
    }

    private _initEventPermissions$() {
        this._eventPermissionSubject = new BehaviorSubject<any>(null);
        this.eventPermissions$ = this._eventPermissionSubject.asObservable();
    }

    private _initParticipants$() {
        this._participantsSubject = new BehaviorSubject<UIParticipantsModel>(
            null
        );
        this.participants$ = this._participantsSubject
            .asObservable()
            .pipe(map((participantsModel) => participantsModel?.list));
    }

    private _initDocuments$() {
        this._documentSubject = new BehaviorSubject<UIDocumentsModel>(null);
        this.documents$ = this._documentSubject.asObservable();
    }

    private _initRoom$() {
        this._roomSubject = new BehaviorSubject<UIEERoom>(null);
        this.room$ = this._roomSubject.asObservable();
    }

    private _initRoomStatus$() {
        this._roomStatusSubject = new BehaviorSubject<UIRoomStatus>(null);
        this.roomStatus$ = this._roomStatusSubject.asObservable();
    }

    private _initRoomParticipant$() {
        this.roomParticipant$ = this.room$.pipe(
            filter((room) => !!room),
            map((room) => {
                return room.uiRoomParticipants.find(
                    (roomParty) => roomParty.currentUser
                );
            })
        );
    }

    private _initParticipant$() {
        this.participant$ = combineLatest([
            this.roomParticipant$,
            this.participants$
        ]).pipe(
            distinctUntilChanged(),
            switchMap(([roomParticipant, participants]) => {
                let participant: UIESignEventParticipant = undefined;
                if (!!roomParticipant && participants) {
                    participants.forEach((party) => {
                        if (
                            party.id === roomParticipant.eSignEventParticipantID
                        ) {
                            participant = party;
                        }
                    });
                }
                return of(participant);
            })
        );
    }

    private _initActiveParticipant$() {
        this.activeParticipant$ = combineLatest([
            this.roomStatus$,
            this.participants$
        ]).pipe(
            filter(
                ([roomStatus, participants]) => !!roomStatus && !!participants
            ),
            map(([roomStatus, participants]) => {
                let activeParticipant: UIESignEventParticipant;
                if (roomStatus.participant) {
                    activeParticipant =
                        participants.find(
                            (p) => p.id === roomStatus.participant.id
                        ) || roomStatus.participant;
                }
                return activeParticipant;
            }),
            distinctUntilChanged((prev, curr) => prev === curr)
        );
    }

    private _initDeviceShareCandidates$() {
        this.deviceShareCandidates$ = combineLatest([
            this.participants$,
            this.roomStatus$
        ]).pipe(
            filter(
                ([participants, roomStatus]) => !!participants && !!roomStatus
            ),
            map(([participants, roomStatus]) =>
                participants.filter(
                    (participant) =>
                        participant.metadata.isSigner &&
                        !!roomStatus.roomParticipantStatuses.find(
                            (status) =>
                                status.participantID === participant.id &&
                                status.type ===
                                    RoomParticipantStatusType.NOT_SIGNED_IN
                        )
                )
            )
        );
    }

    private _initSharedDeviceState$() {
        this.sharedDeviceState$ = this.room$.pipe(
            filter((room) => !!room),
            map((room) => {
                let findFunction: (
                    deviceState: UIEEParticipantDeviceState
                ) => boolean;
                if (this.snapshot.event.isNotary) {
                    findFunction = (deviceState) => deviceState.isNotaryDevice;
                } else {
                    const id = this.snapshot.room.participantID;
                    findFunction = (deviceState) =>
                        Object.keys(deviceState.deviceUsers).includes(id);
                }
                return room.sharedDeviceState.deviceStates.find(findFunction);
            })
        );
    }

    private _checkRONAuthStepCompletion(
        roomParticipant: UIRoomParticipant,
        isSigner: boolean
    ): boolean {
        let isParticipantSetUp =
            STEP_COMPLETED_ACCEPTANCE_STATUSES.includes(
                roomParticipant.termsStatus?.type
            ) &&
            STEP_COMPLETED_ACCEPTANCE_STATUSES.includes(
                roomParticipant.privacyPolicyStatus?.type
            ) &&
            roomParticipant.cameraVerificationStatus?.type === "PASSED" &&
            roomParticipant.microphoneVerificationStatus?.type === "PASSED" &&
            roomParticipant.speakersVerificationStatus?.type === "PASSED";
        if (isSigner && isParticipantSetUp) {
            isParticipantSetUp = STEP_COMPLETED_ACCEPTANCE_STATUSES.includes(
                roomParticipant.eConsentStatus?.type
            );
            if (
                isParticipantSetUp &&
                this._notUsingPK.includes(
                    roomParticipant.ronAllowPersonallyKnown
                )
            ) {
                isParticipantSetUp =
                    roomParticipant.kbaVerificationStatus?.type === "PASSED";
            }
        }
        return isParticipantSetUp;
    }

    private _checkIPENAuthStepCompletion(
        roomParticipant: UIRoomParticipant,
        isSigner: boolean
    ): boolean {
        let isParticipantSetUp =
            STEP_COMPLETED_ACCEPTANCE_STATUSES.includes(
                roomParticipant.termsStatus?.type
            ) &&
            STEP_COMPLETED_ACCEPTANCE_STATUSES.includes(
                roomParticipant.privacyPolicyStatus?.type
            );
        if (isSigner) {
            isParticipantSetUp =
                isParticipantSetUp &&
                STEP_COMPLETED_ACCEPTANCE_STATUSES.includes(
                    roomParticipant.eConsentStatus?.type
                );
        }
        return isParticipantSetUp;
    }

    private _initParticipantSetup$() {
        this.participantSetup$ = combineLatest([
            this.roomParticipant$,
            this.participant$,
            this.eSignEvent$
        ]).pipe(
            filter(
                ([roomParticipant, participant, event]) =>
                    !!roomParticipant && !!participant && !!event
            ),
            map(([roomParticipant, participant, event]) => {
                let isParticipantSetUp = false;
                if (
                    event.eSignEventNotarizationType ===
                    ESignEventNotarizationType.RON
                ) {
                    isParticipantSetUp = this._checkRONAuthStepCompletion(
                        roomParticipant,
                        participant.metadata?.isSigner
                    );
                } else if (
                    event.eSignEventNotarizationType ===
                    ESignEventNotarizationType.IPEN
                ) {
                    isParticipantSetUp = this._checkIPENAuthStepCompletion(
                        roomParticipant,
                        participant.metadata?.isSigner
                    );
                }
                return isParticipantSetUp;
            })
        );
    }

    private _showCanceledMessage(event: UIESignEvent) {
        const isNotary = (): boolean => {
            return (
                event.notary?.username === this._sessionService.getUsername()
            );
        };
        let message: string = "You will receive an email with more details.";
        if (event.systemOriginator === UIEESystemOriginator.DOC_BUILDER) {
            if (event.cancellationReason === "All documents removed") {
                message =
                    "The event was cancelled because all documents were removed. The event will now end.";
            } else if (!isNotary()) {
                const notaryName =
                    this._eeName.transform(event.notary?.name) ?? "The notary";
                message = `${notaryName} has canceled this event. The event will now end.`;
            } else {
                // Don't show the dialog to the notary who just canceled the event.
                return;
            }
        }
        this._notaryCanceledSubject.next(true);

        const logout = () => {
            this.cancelToken();
            return this.logoutRoomParticipant(true);
        };
        const modalRef = this._modalService.open(BaseModalComponent, {
            backdrop: "static"
        });
        const modal = modalRef.componentInstance;
        modal.unmaskModal = true;
        modal.title = "This eSign Event Has Been Canceled";
        modal.message = message;
        modal.primary = {
            text: "OK",
            callback: () => {
                logout();
                return true;
            }
        };
        modalRef.dismissed.subscribe(() => {
            logout();
        });
    }

    private _watchForCanceledEvent() {
        const eventSnap = this.snapshot.event;
        const eSignEventID = eventSnap.eSignEventID;

        if (this._eventChangeID && eSignEventID !== this._eventChangeID) {
            // Unsubscribe to previously watched event.
            this._eventChange.next();
            this._eventChangeID = null;
            this.eventCanceled = false;
        }
        // We only watch for a cancel event if we are not the notary because the notary is the one who cancels the event.
        // However, DocBuilder events can be canceled by moving all the documents to review, so for DocBuilder, we have
        // to watch for this.
        if (
            eSignEventID &&
            !this._eventChangeID &&
            (!eventSnap.isNotary ||
                eventSnap.systemOriginator === UIEESystemOriginator.DOC_BUILDER)
        ) {
            this._eventChangeID = eSignEventID;
            this._notaryCanceledSubject.next(false);
            this.roomStatus$
                .pipe(
                    takeUntil(merge(this._eventChange, this._isNotaryChange)),
                    filter((roomStatus) => !!roomStatus)
                )
                .subscribe((roomStatus) => {
                    const previouslyCanceled = this.eventCanceled;
                    this.eventCanceled =
                        roomStatus.type === RoomStatusType.CANCELED;
                    if (!previouslyCanceled && this.eventCanceled) {
                        this.eSignEvent$
                            .pipe(
                                first(
                                    (event) =>
                                        event?.status === StatusType.CANCELLED
                                )
                            )
                            .subscribe((event) => {
                                this._showCanceledMessage(event);
                            });
                    }
                });
        } else if (
            eventSnap.isNotary &&
            eventSnap.systemOriginator !== UIEESystemOriginator.DOC_BUILDER
        ) {
            // Stop watching for event cancellation if we've switched from participant to notary (IPEN)
            this._isNotaryChange.next();
        }
    }

    private _clearOldValues() {
        this.snapshot.updateAll(null);
        this._eventSubject.next(null);
        this._eventPermissionSubject.next([]);
        this._participantsSubject.next(null);
        this._documentSubject.next(null);
        this._roomSubject.next(null);
        this._roomStatusSubject.next(null);
    }

    private _watchForChanges(eSignEventID: string) {
        if (this.snapshot.event.eSignEventID) {
            if (this._watcher) {
                this._watcher.unsubscribe();
                this._watcher = undefined;
            }
            this._socket.send(
                "unsubscribe",
                null,
                `esign_event/${this.snapshot.event.eSignEventID}`
            );
        }

        this._clearOldValues();
        if (eSignEventID) {
            this.updateAll$(eSignEventID, true).subscribe();
            this._watcher = this.on("set", eSignEventID).subscribe(
                (notification: ESignEventChangedNotification) => {
                    const changedData: ESignEventChangedData =
                        notification.data;
                    if (!this._ignoringUpdates) {
                        if (eSignEventID === changedData.eSignEventID) {
                            this._handleChange(changedData);
                        }
                    } else if (eSignEventID === changedData.eSignEventID) {
                        this._lastIgnoredChange = changedData;
                    }
                }
            );
        }
    }

    private _toBoolean(value: boolean | string): boolean {
        return typeof value === "boolean" ? value : value === "true";
    }

    private _handleChange(changedData: ESignEventChangedData) {
        const getESignEvent = this._toBoolean(changedData.eventChanged);
        const getParticipants = this._toBoolean(
            changedData.participantsChanged
        );
        const getDocuments = this._toBoolean(changedData.documentsChanged);
        const getRoom = this._toBoolean(changedData.roomChanged);
        if (getESignEvent || getParticipants || getDocuments || getRoom) {
            this._getESignEvent(
                changedData.eSignEventID,
                getESignEvent,
                getParticipants,
                getDocuments,
                getRoom,
                false, // RoomStatus gets pushed in the wrapper, so we never need to ask for it here.
                true
            ).subscribe((eventWrapper) => this._emitChanges(eventWrapper));
        }
        // The RoomStatus is pushed with the change info, so we don't have to get it, we can just emit it.
        if (changedData.roomStatus) {
            this._emitRoomStatusChange(changedData.roomStatus);
        }
    }

    private _emitChanges(wrapper: UIESignEventWrapper) {
        const event = this._eventSubject.value;
        const newEvent = wrapper.event;
        if (
            newEvent &&
            (!event ||
                event.eSignEventID !== newEvent.eSignEventID ||
                newEvent.version > event.version)
        ) {
            this.snapshot.handleEventUpdate(newEvent);
            this._eventPermissionSubject.next(wrapper.event.permissions);
            this._eventSubject.next(newEvent);
        }
        const participants = this._participantsSubject.value;
        const newParticipants = wrapper.participants;
        if (
            newParticipants &&
            (!participants ||
                participants.eSignEventID !== newParticipants.eSignEventID ||
                newParticipants.version > participants.version)
        ) {
            this._participantsSubject.next(newParticipants);
        }
        const docs = this._documentSubject.value;
        const newDocs = wrapper.documents;
        if (
            newDocs &&
            (!docs ||
                docs.eSignEventID !== newDocs.eSignEventID ||
                newDocs.version > docs.version)
        ) {
            this._documentSubject.next(newDocs);
        }
        const room = this._roomSubject.value;
        const newRoom = wrapper.room;
        if (
            newRoom &&
            (!room ||
                room.eSignEventID !== newRoom.eSignEventID ||
                newRoom.version > room.version)
        ) {
            this.snapshot.handleRoomUpdate(newRoom);
            this._roomSubject.next(newRoom);
        }
        this._emitRoomStatusChange(wrapper.roomStatus);
    }

    private _emitRoomStatusChange(newRoomStatus: UIRoomStatus) {
        const roomStatus = this._roomStatusSubject.value;
        if (
            newRoomStatus &&
            (!roomStatus ||
                roomStatus.eSignEventID !== newRoomStatus.eSignEventID ||
                newRoomStatus.version > roomStatus.version)
        ) {
            this.snapshot.handleRoomStatusUpdate(newRoomStatus);
            this._roomStatusSubject.next(newRoomStatus);
        }
    }

    private _setOnNotaryNeedsToBePresentPage(urlParts: string[]) {
        if (urlParts?.length > 5 && urlParts[4] === "closing") {
            this._currentClosingPage = urlParts[5];
            this._onNotaryNeedsToBePresentPage = this._needNotaryPages.includes(
                this._currentClosingPage
            );
        } else {
            this._currentClosingPage = null;
            this._onNotaryNeedsToBePresentPage = false;
        }
    }

    private _watchForNotaryLeft() {
        // Note to future self: You might think there is a race condition in looking at "this.isNotary". However, that
        // is guaranteed to be set because every page has to be resolved before continuing on.
        this.roomStatus$
            .pipe(
                filter(
                    (roomStatus) =>
                        !!roomStatus &&
                        !this.snapshot.event.isNotary &&
                        this._onNotaryNeedsToBePresentPage
                ),
                distinctUntilChanged((a, b) => a?.type === b?.type), // Only continue when changing from some other state.
                pairwise(), // Skips the first emission which handles the case where the participant arrives before the notary (will only happen on the participant-room).
                filter(
                    ([previousRoomStatus, roomStatus]) =>
                        roomStatus?.type ===
                            RoomStatusType.NOTARY_NOT_PRESENT &&
                        previousRoomStatus.eSignEventID ===
                            roomStatus.eSignEventID && // With DocBuilder users the room status from the previous event can throw us off.
                        previousRoomStatus?.type !==
                            RoomStatusType.NOTARY_IS_TAGGING
                ) // Only continue when notary not present (true case).
            )
            .subscribe(() => {
                this._clearVideoRoomFromStorage();
                this._showNotaryLeft();
            });
    }

    private _clearVideoRoomFromStorage() {
        const eventStorage: SignEventStorage = this._persistedMemoryService.get(
            SIGN_EVENT_STORAGE_KEY
        );
        if (eventStorage?.videoRoom) {
            eventStorage.videoRoom = null;
            this._persistedMemoryService.set(
                SIGN_EVENT_STORAGE_KEY,
                eventStorage
            );
        }
    }

    private _goBackToLobby(): boolean {
        let url: string;
        if (
            this.snapshot.event.systemOriginator ===
            UIEESystemOriginator.DOC_BUILDER
        ) {
            url = "/sf/ui/signing/signing-list";
            this._windowRef.nativeWindow.location.assign(url);
        }
        if (this._currentClosingPage === "phone-id-upload") {
            url = "/sf/ui/sign-event/external/phone-id-done?code=notaryGone";
            this._windowRef.nativeWindow.location.assign(url);
        } else {
            url = `/sign-event/event/${this.snapshot.event.eSignEventID}/closing/participant-room/messages`;
            this._router.navigateByUrl(url).then();
        }

        this._showingNotaryLeftSubject.next(false);
        return true;
    }

    private _initNotaryObservables$() {
        this._showingNotaryLeftSubject = new BehaviorSubject<boolean>(false);
        this.showingNotaryLeftDlg$ =
            this._showingNotaryLeftSubject.asObservable();
        this._notaryCanceledSubject = new BehaviorSubject<boolean>(false);
        this.notaryCanceledEvent$ = this._notaryCanceledSubject.asObservable();
    }

    private _showNotaryLeft() {
        const modal = this._modalService.open(BaseModalComponent, {
            backdrop: "static"
        });
        const comp = modal.componentInstance;
        comp.unmaskModal = true;
        comp.title = "Notary Left Event";
        const isRON = this.snapshot.event.isRON;
        this._showingNotaryLeftSubject.next(true);
        comp.message = `Notary has left the event.  You may be required to repeat the authentication process${
            isRON ? " and re-connect to video" : ""
        } when the notary rejoins.`;
        comp.primary = {
            text: "OK",
            callback: this._goBackToLobby
        };
        modal.result.then(
            () => {},
            (error: any) => {
                if (error === 1 || error === "exit") {
                    this._goBackToLobby();
                }
            }
        );
    }

    private _getESignEvent(
        eSignEventID: string,
        getESignEvent: boolean,
        getParticipants: boolean,
        getDocuments: boolean,
        getRoom: boolean,
        getRoomStatus: boolean,
        overrideDefaultErrorHandling: boolean = false
    ): Observable<UIESignEventWrapper> {
        let error: any;
        return this._taggingSharedService
            .getESignEvent(
                eSignEventID,
                getESignEvent,
                getParticipants,
                getDocuments,
                getRoom,
                getRoomStatus,
                overrideDefaultErrorHandling
            )
            .pipe(
                catchError((err) => {
                    // Store error to throw later if this isn't an event privacy issue
                    error = err;
                    return this._taggingSharedService.currentUserCanViewEvent(
                        eSignEventID
                    );
                }),
                switchMap(
                    (
                        response:
                            | UIESignEventWrapper
                            | UICurrentUserCanViewEventResponse
                    ) => {
                        if (
                            typeof response === "object" &&
                            "canViewEvent" in response
                        ) {
                            if (!response.canViewEvent) {
                                this._openPrivateEventDialog(
                                    response.eventName
                                );
                                return throwError(
                                    `The current user no longer has access to event ${response.eventName} as it has been marked as private`
                                );
                            } else {
                                const errorMessage =
                                    typeof error === "object"
                                        ? error.error?.errorMessage ||
                                          error.errorMessage
                                        : error;
                                this._growlService.error(errorMessage);
                                return throwError(error);
                            }
                        } else {
                            return of(response);
                        }
                    }
                )
            );
    }

    private _openPrivateEventDialog(eventName: string) {
        const modalRef = this._modalService.open(
            PrivateEventDialogComponent,
            PrivateEventDialogComponent.MODAL_OPTIONS
        );
        const modalInstance: PrivateEventDialogComponent =
            modalRef.componentInstance;
        modalInstance.eventName = eventName;
        modalInstance.eventStatus = this.snapshot.event?.status;
    }

    private _setRedirectSoon(redirectSoon: boolean) {
        let eventStorage: SignEventStorage =
            this._persistedMemoryService.get(SIGN_EVENT_STORAGE_KEY) ?? {};
        eventStorage.redirectSoon = redirectSoon;
        this._persistedMemoryService.set(SIGN_EVENT_STORAGE_KEY, eventStorage);
    }

    /**
     * If we were showing the "You were logged out" dialog, and the user refreshed the page, they would NOT get
     * redirected to their start location. To close that loophole we save a flag and redirect them as soon as the page
     * reloads.
     * @param force Redirect them no matter what. Otherwise, we only redirect them if the "redirectSoon" variable is set.
     * @private
     */
    private _redirectToStart(force: boolean): void {
        const eventStorage: SignEventStorage = this._persistedMemoryService.get(
            SIGN_EVENT_STORAGE_KEY
        );
        const redirect: boolean = force || eventStorage?.redirectSoon;
        if (eventStorage?.redirectSoon) {
            delete eventStorage.redirectSoon;
            this._persistedMemoryService.set(
                SIGN_EVENT_STORAGE_KEY,
                eventStorage
            );
        }
        if (redirect) {
            this._redirectService.redirectOnLeaveEvent(this.snapshot.event);
        }
    }

    private _showNotSignedIn() {
        const modal = this._modalService.open(BaseModalComponent, {
            backdrop: "static"
        });
        const comp = modal.componentInstance;
        this._showingNotSignedIn = true;
        comp.unmaskModal = true;
        comp.title = "Signed Out";
        comp.message =
            "You have been signed out, and will now be redirected to your starting location.";
        comp.primary = {
            text: "OK",
            callback: () => {
                this._redirectToStart(true);
                return true;
            }
        };
        modal.result
            .then(
                () => {},
                (error: any) => {
                    if (error === 1 || error === "exit") {
                        this._redirectToStart(true);
                    }
                }
            )
            .finally(() => {
                this._showingNotSignedIn = false;
            });
    }

    private _watchForParticipantNotSignedIn(): void {
        combineLatest([this.roomStatus$, this.participant$])
            .pipe(
                filter(
                    ([roomStatus, participant]) => !!roomStatus && !!participant
                ),
                debounceTime(2000)
            )
            .subscribe(
                ([roomStatus, participant]: [
                    UIRoomStatus,
                    UIESignEventParticipant
                ]) => {
                    // This check only applies to regular eSign Event participants.
                    if (
                        this.snapshot.event.isNotary ||
                        this.snapshot.event.systemOriginator !==
                            UIEESystemOriginator.ESIGN_EVENT ||
                        this._showingNotSignedIn
                    ) {
                        return;
                    }
                    const participantStatus =
                        roomStatus.roomParticipantStatuses.find(
                            (roomParticipantStatus) =>
                                roomParticipantStatus.participantID ===
                                participant.id
                        );
                    this._logger.debug(
                        "%c_watchForParticipantNotSignedIn: %s",
                        EE_LOG_COLORS.pink,
                        participantStatus?.type
                    );
                    if (participantStatus) {
                        const previousStatus =
                            this._participantPreviousStatus[participant.id];
                        const wasSignedIn =
                            previousStatus &&
                            previousStatus !==
                                RoomParticipantStatusType.NOT_SIGNED_IN;
                        const nowSignedOut =
                            participantStatus.type ===
                            RoomParticipantStatusType.NOT_SIGNED_IN;
                        if (wasSignedIn && nowSignedOut) {
                            this._clearVideoRoomFromStorage();
                            this.logoutRoomParticipant(false);
                            this._showNotSignedIn();
                        }
                        this._participantPreviousStatus[participant.id] =
                            participantStatus.type;
                    }
                }
            );
    }
}
