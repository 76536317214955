<div class="logo">
    <div class="logo-back">
        <button class="btn btn-link" (click)="logout()">
            <fa-icon icon="chevron-left"></fa-icon>
            <span> Back to Login</span>
        </button>
    </div>
</div>
<div class="login-container">
    <div class="head-text">
        Our Terms of Use have been updated
    </div>

    <div class="explain">
        <div class="eline">
            <span class="section-label">By continuing, you agree to be bound by the Simplifile Terms of Use and Privacy Policy.</span>
        </div>
        <div class="tline">
            <a href="http://simplifile.com/about-us/terms" target="_blank">Simplifile Terms of Use</a>
        </div>
        <div class="tline">
            <a href="http://simplifile.com/about-us/privacy-policy" target="_blank">Simplifile Privacy Policy</a>
        </div>
    </div>
    <div>
        <div class="input-area">
            <button class="btn btn-primary" (click)="accept()">Accept</button>
            <button class="btn btn-secondary" (click)="logout()">Log Out</button>
        </div>
    </div>
</div>
