import { UIESignEventParticipant } from "./sign-event-participant.interface";

export interface DocSettings {
    stackingOrder: number;
    notaryPresenceRequired: boolean;
    eRecordable: boolean;
}

export interface DocumentInfo {
    type: "new" | "existing";
    name: string;
    notaryPresenceRequired: boolean;
    notaryTagsPresent: boolean;
    eCompleteTagsPresent: boolean;
    eRecordable: boolean;
    file?: any;
    id?: string;
    deleting?: boolean;
    stackingOrder?: number;
    originalSettings?: DocSettings;
}

export enum EEDocumentTaggingStatus {
    INCOMPLETE = "INCOMPLETE",
    COMPLETE = "COMPLETE",
    NOT_NEEDED = "NOT_NEEDED",
    COMPLETE_EXTERNAL = "COMPLETE_EXTERNAL"
}

export enum EEERecordingStatusType {
    E_RECORD = "E_RECORD",
    SENT = "SENT",
    REJECTED = "REJECTED",
    RECORDED = "RECORDED"
}

export enum EEDocumentType {
    CERTIFICATE_OF_COMPLETION = "CERTIFICATE_OF_COMPLETION",
    NONE = "NONE"
}

export interface EEERecordingStatus {
    date: string;
    type: EEERecordingStatusType;
    variant: number;
}

export interface UIESignEventDocumentMetadata {
    notaryTagsPresent: boolean;
    isSigned: boolean;
}

export interface UIESignEventDocument {
    id: string;
    metadata: UIESignEventDocumentMetadata;
    notaryPresenceRequired: boolean;
    fileName: string;
    documentType: EEDocumentType;
    taggingStatus: EEDocumentTaggingStatus;
    eRecordable: boolean;
    eRecordableDocumentID: string;
    eRecordingStatus: EEERecordingStatus;
    eSignSigningStatus?: string;
    signers: UIESignEventParticipant[];
    actualSigners: string[];
    reviewedByDate: { [participantID: string]: DateString };
    signedByDate: { [participantID: string]: DateString };
    closingTypeUpgraded?: boolean; // Doc closing type upgraded from INK to E.
    cocDocument: boolean; // Is this a Certificate of Completion?
    taggingDocumentID: string; // Doc ID in Tagging Editor
    stackingOrder: number;
}

export enum ESignEventDownloadAction {
    ALL = "ALL",
    SIGNED = "SIGNED"
}

export enum DocumentListType {
    ALL = "ALL",
    NON_WITNESSED = "NON_WITNESSED",
    WITNESSED = "WITNESSED",
    E_SIGNED = "E_SIGNED",
    E_RECORDABLE = "E_RECORDABLE",
    E_RECORDED = "E_RECORDED"
}

export interface UIDownloadVideoResponse {
    status: VideoStatusType;
    downloadCheckDate: DateString;
}

export enum VideoStatusType {
    ENQUEUED = "ENQUEUED",
    PROCESSING = "PROCESSING",
    COMPLETED = "COMPLETED",
    DELETED = "DELETED",
    FAILED = "FAILED"
}
