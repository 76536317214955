<dialog #d open>
    <sf-dialog-modal-header
        [title]="title"
        [titleIcon]="titleIcon"
        [showDismiss]="showDismiss"
        [working]="working"
        (dismiss)="dismiss()"
    ></sf-dialog-modal-header>
    <div class="modal-body" [ngStyle]="bodyStyles">
        <span *ngIf="message" [innerHTML]="message" id="modal-message"></span>
        <ng-container *ngIf="template">
            <ng-container *ngTemplateOutlet="template; context: templateContext"></ng-container>
        </ng-container>
    </div>
    <sf-dialog-modal-footer
        [primary]="primary"
        [secondary]="secondary"
        [tertiary]="tertiary"
        [working]="working"
        (dismiss)="dismiss()"
    ></sf-dialog-modal-footer>
</dialog>
