<sf-modal-header
    [title]="
        singleDocumentID
            ? 'This Document Was Rejected'
            : 'This Package Was Rejected'
    "
></sf-modal-header>
<div class="modal-body">
    <div
        id="sf-submitter-rejected-status-dialog"
        class="sf-submitter-rejected-status-dialog sf-shared-doc-status-dialog"
    >
        <p *ngIf="!singleDocumentID">
            One or more of the documents in this package has been rejected and
            should be modified before re-submission.
            <span class="bold">Here are some things you can do next:</span>
        </p>
        <p *ngIf="singleDocumentID">
            This document has been rejected and should be modified before
            re-submission.
            <span class="bold">Here are some things you can do next:</span>
        </p>

        <div *ngIf="hasErrors && documents.length > 0">
            <div>
                <hr class="section-header__top" />
                <h4 class="section-header__content">
                    FIX ISSUES WITH REJECTED DOCUMENT{{
                        documents.length > 1 ? "S" : ""
                    }}
                </h4>
                <hr class="section-header__bottom" />
            </div>
            <section
                *ngFor="let document of documents; index as i; last as isLast"
                [attr.id]="document.id"
            >
                <div class="document-info">
                    <div class="doc-name-title-container">
                        <h4 class="doc-name-title">
                            <a
                                class="break-point sf-mask"
                                (click)="
                                    openDocument(document)
                                "
                                >My {{ document.name }}</a
                            >
                        </h4>
                        <div name="docType" class="doc-type">
                            {{ document.docType | uppercase }}
                        </div>
                        <div
                            *ngIf="
                                !!document.validationErrors &&
                                document.validationErrors.length
                            "
                        >
                            <ul>
                                <li
                                    class="rejection-reason sf-mask"
                                    *ngFor="
                                        let error of document.validationErrors;
                                        index as i
                                    "
                                >
                                    {{ error }}
                                </li>
                            </ul>
                            <div
                                *ngIf="
                                    document.rejectionSuggests$
                                        | async as rejectionSuggestions
                                "
                            >
                                <div
                                    class="rejection-suggestion"
                                    *ngFor="
                                        let suggestion of $any(
                                            rejectionSuggestions
                                        );
                                        index as i
                                    "
                                >
                                    <div *ngIf="i === 0">
                                        <b>Simplifile Suggests:</b>
                                    </div>
                                    <ul>
                                        <li class="suggestion">
                                            <div
                                                [innerHtml]="
                                                    suggestion.suggestion
                                                "
                                            ></div>
                                            <div
                                                class="suggestion-feedback"
                                                *ngIf="
                                                    !suggestion.feedbackRecorded
                                                "
                                            >
                                                <div class="me-2">
                                                    Was this suggestion helpful?
                                                </div>
                                                <div
                                                    class="suggestion-feedback-button-wrapper"
                                                >
                                                    <button
                                                        type="button"
                                                        class="btn btn-secondary"
                                                        (click)="
                                                            sendFeedback(
                                                                suggestion,
                                                                'HELPFUL'
                                                            )
                                                        "
                                                    >
                                                        Yes
                                                    </button>
                                                    <button
                                                        type="button"
                                                        class="btn btn-secondary"
                                                        (click)="
                                                            sendFeedback(
                                                                suggestion,
                                                                'NOT_HELPFUL'
                                                            )
                                                        "
                                                    >
                                                        No
                                                    </button>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div
                            *ngIf="
                                !!document.voidedEvents &&
                                document.voidedEvents.length
                            "
                        >
                            <h5>Reasons for voiding</h5>
                            <div
                                class="{{ document.status }} sf-mask"
                                *ngFor="
                                    let event of document.voidedEvents;
                                    index as i
                                "
                            >
                                {{ event }}
                            </div>
                        </div>
                    </div>

                    <div name="doc-status" class="doc-status-col">
                        <sf-pill
                            *ngIf="!useStatusIcons"
                            (click)="openDocument(document)"
                            [value]="document.status | lowercase"
                        >
                            {{ document.status }}</sf-pill
                        >
                        <sf-status-icon
                            *ngIf="useStatusIcons"
                            (statusClick)="
                                openDocument(document)
                            "
                            [status]="document.status"
                        >
                        </sf-status-icon>
                    </div>
                </div>
                <hr *ngIf="!isLast" />
            </section>
        </div>
        <section>
            <hr class="section-header__top" />
            <h4 class="section-header__content">NEED MORE HELP?</h4>
            <hr class="section-header__bottom" />
            <section>
                <div name="approved-contact-methods">
                    <div
                        *ngIf="
                            statusDetails.recipientName ==
                            this.statusDetails.validationErrors[0]
                                ?.rejectingRecipient?.name
                        "
                    >
                        <a
                            [routerLink]="[
                                '/submitter/recipient-information',
                                statusDetails.recipientID
                            ]"
                            (click)="closeModal()"
                            >Contact {{ statusDetails.recipientName }},
                            {{ statusDetails.recipientID | slice: 0:2 }}</a
                        >
                    </div>
                    <div class="mb-2">
                        <button class="btn btn-link chat-modal-btn"
                                (click)="openChatModal()"
                        >Contact Simplifile</button>
                    </div>
                </div>
                <section name="organization-information">
                    <h6>
                        The following information about your package may be
                        helpful:
                    </h6>
                    <section class="need-more-help">
                        <div
                            *ngIf="
                                statusDetails.recipientName &&
                                statusDetails.recipientName ==
                                    this.statusDetails.validationErrors[0]
                                        ?.rejectingRecipient?.name
                            "
                            name="organization-name"
                        >
                            <b>County:</b> {{ statusDetails.recipientName }},
                            {{ statusDetails.recipientID | slice: 0:2 }}
                        </div>

                        <div
                            *ngIf="
                                statusDetails.recipientName !=
                                this.statusDetails.validationErrors[0]
                                    ?.rejectingRecipient?.name
                            "
                        >
                            <div
                                *ngIf="
                                    this.statusDetails.validationErrors[0]
                                        ?.rejectingRecipient?.name
                                "
                                name="rejected-by"
                            >
                                <b>Rejected By:</b>
                                {{
                                    this.statusDetails.validationErrors[0]
                                        ?.rejectingRecipient?.name
                                }}
                            </div>

                            <div
                                *ngIf="
                                    this.statusDetails.validationErrors[0]
                                        ?.rejectingRecipient
                                "
                                name="rejected-by"
                            >
                                <b>Phone:</b>
                                {{
                                    this.statusDetails.validationErrors[0]
                                        ?.rejectingRecipient?.phone
                                }}
                            </div>
                        </div>

                        <div
                            *ngIf="
                                statusDetails.firstSubmitDate &&
                                statusDetails.recipientTimeZone
                            "
                            name="first-submission-date"
                        >
                            <b>Initial Submission Date:</b>
                            {{
                                statusDetails.firstSubmitDate
                                    | formatTime
                                        : statusDetails.recipientTimeZone
                                        : "MM/DD/YYYY h:mm A zz"
                                        : true
                            }}
                        </div>
                        <div class="break-point" name="package-name">
                            <b>Package Name:</b> <span class="sf-mask"> {{ statusDetails.packageName }}</span>
                        </div>
                        <div name="docTypes">
                            <b>Document Types: </b>
                            <span
                                *ngFor="
                                    let document of documentsAll;
                                    index as i;
                                    first as isFirst
                                "
                                >{{ isFirst ? "" : ", "
                                }}{{ document.docType }}</span
                            >
                        </div>
                        <div
                            *ngIf="statusDetails.grantees.length > 0"
                            name="grantees"
                        >
                            <b>Grantee:</b>
                            <span
                                [id]="'grantee-' + i"
                                *ngFor="
                                    let grantee of statusDetails.grantees;
                                    index as i;
                                    first as isFirst
                                "
                                class="sf-mask"
                                >{{ isFirst ? "" : ", " }}{{ grantee }}</span
                            >
                        </div>
                        <div
                            *ngIf="statusDetails.grantors.length > 0"
                            name="grantors"
                        >
                            <b>Grantor:</b>
                            <span
                                [id]="'grantor-' + i"
                                *ngFor="
                                    let grantor of statusDetails.grantors;
                                    index as i;
                                    first as isFirst
                                "
                                class="sf-mask"
                                >{{ isFirst ? "" : ", " }}{{ grantor }}</span
                            >
                        </div>
                        <div *ngIf="remoteId" id="remoteID">
                            <b>Remote ID:</b> <span class="sf-mask">{{ remoteId }}</span>
                        </div>
                        <div name="doc-count">
                            <b># of Docs in Package:</b>
                            {{ statusDetails.numberOfDocsInPackage }}
                        </div>
                        <ul
                            *ngIf="customRejectionInstructions?.length"
                            class="mt-1"
                        >
                            <li
                                *ngFor="
                                    let instruction of customRejectionInstructions
                                "
                                class="sf-mask"
                            >
                                {{ instruction }}
                            </li>
                        </ul>
                    </section>
                </section>
            </section>
        </section>
    </div>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>
