import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Icons } from "../../../interfaces";

@Component({
    selector: "sf-dialog-modal-header",
    templateUrl: "./dialog-modal-header.component.html",
    styleUrls: ["./dialog-modal-header.component.scss"]
})
export class DialogModalHeaderComponent implements OnInit {
    @Input()
    title: string;
    @Input()
    dismissValue: any;
    @Input()
    titleIcon?: IconProp;
    @Input()
    showDismiss = true;
    @Input()
    helpLink?: {
        url?: string;
        tooltip?: string;
    };
    @Input()
    working: boolean;
    @Output()
    dismiss: EventEmitter<string> = new EventEmitter();

    icons: Icons = {};

    constructor() {}

    ngOnInit() {
        console.log("Dialog Modal Header: ", this.title);
    }

    showHelp() {
        let url: string = location.origin + this.helpLink.url;
        window.open(url, "_blank");
    }
}
