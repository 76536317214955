import {
    Component,
    OnInit,
    Input,
    ViewChild,
    ElementRef,
    OnDestroy,
    AfterViewInit,
    EventEmitter,
    Output
} from "@angular/core";
import { ModalButton } from "../../interfaces/modal-button";
import { Subject, isObservable } from "rxjs";
import { first, startWith, takeUntil } from "rxjs/operators";

@Component({
    selector: "sf-dialog-modal-footer",
    templateUrl: "./dialog-modal-footer.component.html",
    styleUrls: ["./dialog-modal-footer.component.scss"]
})
export class DialogModalFooterComponent
    implements OnInit, OnDestroy, AfterViewInit
{
    @Input()
    primary: ModalButton;
    @Input()
    secondary: ModalButton;
    @Input()
    tertiary: ModalButton;
    @Input()
    quaternary: ModalButton;
    @Input()
    quinary: ModalButton;
    @Input()
    working: boolean;
    @Output()
    dismiss: EventEmitter<string> = new EventEmitter();

    @ViewChild("primaryButtonEl")
    primaryButtonEl: ElementRef;

    /** Private Variables **/
    private _ngOnDestroy$: Subject<void> = new Subject();

    ngOnInit(): void {
        this._initButton(this.primary);
        this._initButton(this.secondary);
        this._initButton(this.tertiary);
        this._initButton(this.quaternary);
        this._initButton(this.quinary);
    }

    //In the HTML quinary has been placed after the secondary button.
    // Falcon has approved this due to the padding on the tertiary button not allowing three buttons to be on the left side if placed in order

    ngOnDestroy(): void {
        this._ngOnDestroy$.next();
    }

    ngAfterViewInit() {
        if (this.primary?.autoFocus) {
            this.primaryButtonEl?.nativeElement?.focus();
        }
    }

    /** Public Methods **/

    buttonClick(btn: ModalButton, fallback: string) {
        if (btn.callback) {
            const result = btn.callback();
            if (isObservable(result)) {
                this.working = true;
                result.pipe(first()).subscribe((shouldClose) => {
                    this.working = false;
                    if (shouldClose) {
                        this.dismiss.emit(btn.responseValue);
                    }
                });
            } else if (result) {
                this.dismiss.emit(btn.responseValue);
            }
        } else {
            this.dismiss.emit(btn.responseValue ?? fallback);
        }
    }

    /** Private Methods **/

    private _initButton(button: ModalButton) {
        if (button) {
            if (button.formGroup) {
                button.formGroup.statusChanges
                    .pipe(
                        startWith(button.formGroup.status),
                        takeUntil(this._ngOnDestroy$)
                    )
                    .subscribe((validity: string) => {
                        button.disabled = validity === "INVALID";
                    });
            }
            // If only one tooltip given, use it when disabled. This is for backwards compatibility.
            if (button.toolTip && !button.disabledTooltip) {
                button.disabledTooltip = button.toolTip;
            }
        }
    }
}
