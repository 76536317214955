<sf-modal-header [title]="title"></sf-modal-header>
<div class="select-holder">
    <sf-organization-search-selector
        class="submitter-selector"
        windowClass="org-selector"
        placeholder="Select an organization..."
        [selectedOrgID]="selectedOrg"
        [selectableOrgServices]="submitterOrgServices"
        (select)="selectOrg($event.id)"
    >
    </sf-organization-search-selector>
</div>
<sf-modal-footer [primary]="primary" [secondary]="secondary"></sf-modal-footer>
