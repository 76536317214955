import { Injectable } from "@angular/core";
import { PendingPaymentsNewService } from "./pending-payments-new.service";
import { BehaviorSubject, iif, Observable, of } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { dayjs } from "@sf/common";
import { sortBy } from "@sf/common";
import { cloneDeep } from "@sf/common";
import { PendingPaymentsCountsObj } from "../interfaces/pending-payments-filter-options.interface";

@Injectable({
    providedIn: "root"
})
export class PendingPaymentsDataService {
    private _allPayments: any;
    private _pendingEcheckPayments: any;
    private _pendingACHPayments: any;
    private _pendingCCPayments: any;
    private _getUIPendingPaymentsMinutes: number = 10;
    private _getUIPendingPaymentsTime: dayjs.Dayjs = null;
    private _processingUIPendingPayments: boolean = false;
    private _uiPendingPayments$: BehaviorSubject<any> = new BehaviorSubject([]); // transactions that are returned to UI - could be a filtered list based on selected org and selected payment type
    private _allPaymentsCache$: BehaviorSubject<any> = new BehaviorSubject([]); // lets store the result of the backend call with all payments together
    private _filteredEcheckPayments$: BehaviorSubject<any> =
        new BehaviorSubject([]); // filtered pending payments
    private _pendingPaymentCountsCache$: BehaviorSubject<PendingPaymentsCountsObj> =
        new BehaviorSubject<PendingPaymentsCountsObj>(null);

    public readonly uiPendingPayments$: Observable<any> =
        this._uiPendingPayments$.asObservable();

    constructor(
        private _pendingPaymentsNewService: PendingPaymentsNewService
    ) {}

    /**
     * @param allOrgsIDArray - string array of orgID(s) user belongs to
     * @param filterOrgs - string array of orgID(s) to filter payments on
     * @param selectedPaymentTypes - string array of payment methods
     * @param paymentTypeFilterOption - string indicating payment type to filter on
     * @param hideApprovedPayments - boolean to determine if approved payments should display
     * @param clearCache - optional boolean to determine if we need to bust the cache and get fresh data
     * @param iniitialPendingPayments - optional on initial load we already have the pending payments from the page data resolver, so we don't need to make another backend call
     */
    getUIPendingPayments(
        orgIDArray: string[],
        filterOrgs: string[],
        selectedPaymentTypes: string[],
        paymentTypeFilterOption: string,
        hideApprovedPayments: boolean,
        clearCache?: boolean,
        initialPendingPayments?: any
    ) {
        // Ensure we have org ids or payment type(s) to start with
        if (
            !orgIDArray ||
            (orgIDArray && orgIDArray.length === 0) ||
            !selectedPaymentTypes ||
            (selectedPaymentTypes && selectedPaymentTypes.length === 0)
        ) {
            this._uiPendingPayments$.next("PENDING");
            return;
        }

        // if actively fetching pending payments
        if (this._processingUIPendingPayments) {
            this._uiPendingPayments$.next("PENDING");
            // this._filterPayments(
            //     paymentTypeFilterOption,
            //     hideApprovedPayments,
            //     filterOrgs
            // );
            return;
        }

        // is cache expired?
        if (this._getUIPendingPaymentsTime || clearCache) {
            let now: dayjs.Dayjs = dayjs();
            if (
                now.diff(this._getUIPendingPaymentsTime, "minutes") >=
                    this._getUIPendingPaymentsMinutes ||
                clearCache
            ) {
                this._allPaymentsCache$.next(null);
                this._uiPendingPayments$.next(null);
                this._allPayments = [];
            }
        }

        // get result, from cache or back end
        let value = this._allPaymentsCache$.getValue();
        if (value && value.length > 0) {
            this._filterPayments(
                paymentTypeFilterOption,
                hideApprovedPayments,
                filterOrgs
            );
        } else {
            this._processingUIPendingPayments = true;
            this._fetchPendingPayments(
                orgIDArray,
                filterOrgs,
                selectedPaymentTypes,
                paymentTypeFilterOption,
                hideApprovedPayments,
                initialPendingPayments
            );
        }
    }

    /**
     * @param orgIDArray - string array of orgID(s) selected by user
     * @param filterOrgs - string array of orgID(s) to filter payments on
     * @param selectedPaymentTypes - string array of payment methods
     * @param paymentTypeFilterOption - string indicating payment type to filter on
     * @param hideApprovedPayments - boolean to determine if approved payments should display
     * @param iniitialPendingPayments - optional array on initial load we already have the pending payments from the page data resolver, so we don't need to make another backend call
     */
    private _fetchPendingPayments(
        orgIDArray: string[],
        filterOrgs: string[],
        selectedPaymentTypes: string[],
        paymentTypeFilterOption: string,
        hideApprovedPayments: boolean,
        initialPendingPayments?: any[]
    ) {
        iif(
            () => initialPendingPayments && initialPendingPayments.length > 0,
            of(initialPendingPayments),
            this._pendingPaymentsNewService.getAllPendingPayments(
                orgIDArray,
                selectedPaymentTypes
            )
        )
            .pipe(
                switchMap((pendingPayments: any) => {
                    this._allPayments = pendingPayments;
                    // This is where we add the pending payment counts to a new observable. Need method to retrieve object with the counts
                    this._setupPendingPaymentsCounts(
                        orgIDArray,
                        pendingPayments
                    );
                    this._setupUIPendingPaymentsInfo(this._allPayments);
                    this._allPayments = this._allPayments?.sort(
                        sortBy("label")
                    );
                    return this._setUpPaymentsInfoByPaymentMethod(
                        orgIDArray,
                        this._allPayments,
                        hideApprovedPayments
                    );
                }),
                tap(() => {
                    this._allPaymentsCache$.next(this._allPayments);
                    this._filterPayments(
                        paymentTypeFilterOption,
                        hideApprovedPayments,
                        filterOrgs
                    );
                })
            )
            .subscribe(() => {
                this._processingUIPendingPayments = false;
                // reset cache time
                this._getUIPendingPaymentsTime = dayjs();
            });
    }

    private _setUpPaymentsInfoByPaymentMethod(
        orgIDArray: string[],
        pendingPayments: any,
        hideApproved: boolean
    ) {
        this._pendingEcheckPayments = pendingPayments?.filter(
            (pendingPayment: any) =>
                pendingPayment.pendingPaymentMethodType === "ECHECK"
        );
        this._pendingACHPayments = pendingPayments?.filter(
            (pendingPayment: any) =>
                pendingPayment.pendingPaymentMethodType === "ACH"
        );
        this._pendingCCPayments = pendingPayments?.filter(
            (pendingPayment: any) =>
                pendingPayment.pendingPaymentMethodType === "CREDIT_CARD"
        );

        if (this._pendingACHPayments?.length > 0) {
            this._pendingACHPayments.sort((a: any, b: any) =>
                a.items.length > b.items.length ? 1 : -1
            );
        }

        // if (this._pendingCCPayments?.length > 0) {
        //     this._pendingCCPayments.forEach((pendingPayment: any) => {
        //         pendingPayment.orgCCAccounts = pendingPayment.orgCCAccounts.map(
        //             (account: any) => {
        //                 const expDate = dayjs(
        //                     `${account.expirationDate.expMonth}/1/${account.expirationDate.expYear}`
        //                 ).format("MM/YY");
        //                 return {
        //                     id: account.id,
        //                     label:
        //                         account.cardType !== "DEBIT"
        //                             ? `${account.label} (Exp. ${expDate})`
        //                             : `${account.label} (Debit Exp. ${expDate})`,
        //                     token: account.token
        //                 };
        //             }
        //         );
        //     });
        // }

        if (this._pendingEcheckPayments?.length > 0) {
            return this._pendingPaymentsNewService
                .getOrganizationsAvailableRefundAccounts(orgIDArray)
                .pipe(
                    map((refundAccounts: any) => refundAccounts.flat()),
                    tap((refundAccounts: any) => {
                        this._pendingEcheckPayments.forEach((payment: any) => {
                            if (payment.isRefund) {
                                payment.refundAccounts = refundAccounts.filter(
                                    (account: any) =>
                                        account.orgID ===
                                        payment.echeck.organizationID
                                );
                                payment.targetAccountID =
                                    payment.refundAccounts[0].id;
                            }
                        });
                        // TODO: add date sorting here (do for ACH and CC too?)
                        this.filterApprovedEcheckPayments(
                            hideApproved,
                            orgIDArray,
                            this._pendingEcheckPayments,
                            true
                        );
                        this._filteredEcheckPayments$.next(
                            this._pendingEcheckPayments
                        ); // This is so we have a reference to check when we click Hide Approved in the UI
                    })
                );
        }

        return of([]);
    }

    private _filterPayments(
        paymentTypeOption: string,
        hideApproved: boolean,
        filterOrgs: string[]
    ) {
        let hasDelinquentEchecks: boolean;
        let hasDelinquentCreditCardPayments: boolean;
        let hasAnyUnapprovedEcheckPayments: boolean;
        let hasAnyEcheckPayments: boolean;
        let hasAnyCreditCardPayments: boolean;
        let hasAnyACHPayments: boolean;

        if (!paymentTypeOption) {
            // should only run on initial get since we don't know which payment type to select initially
            if (this._allPaymentsCache$.getValue()) {
                hasDelinquentEchecks = this._allPaymentsCache$
                    .getValue()
                    .some(
                        (payment: any) =>
                            payment.pendingPaymentMethodType === "ECHECK" &&
                            payment.isDelinquent &&
                            filterOrgs.find(
                                (orgID: string) =>
                                    orgID === payment.organizationID
                            )
                    );
                hasDelinquentCreditCardPayments = this._allPaymentsCache$
                    .getValue()
                    .some(
                        (payment: any) =>
                            payment.pendingPaymentMethodType ===
                                "CREDIT_CARD" &&
                            payment.isDelinquent &&
                            filterOrgs.find(
                                (orgID: string) =>
                                    orgID === payment.organizationID
                            )
                    );
                hasAnyUnapprovedEcheckPayments = this._allPaymentsCache$
                    .getValue()
                    .some(
                        (payment: any) =>
                            payment.pendingPaymentMethodType === "ECHECK" &&
                            !payment.approved &&
                            filterOrgs.find(
                                (orgID: string) =>
                                    orgID === payment.organizationID
                            )
                    );
                hasAnyEcheckPayments = this._allPaymentsCache$
                    .getValue()
                    .some(
                        (payment: any) =>
                            payment.pendingPaymentMethodType === "ECHECK" &&
                            filterOrgs.find(
                                (orgID: string) =>
                                    orgID === payment.organizationID
                            )
                    );
                hasAnyCreditCardPayments = this._allPaymentsCache$
                    .getValue()
                    .some(
                        (payment: any) =>
                            payment.pendingPaymentMethodType ===
                                "CREDIT_CARD" &&
                            filterOrgs.find(
                                (orgID: string) =>
                                    orgID === payment.organizationID
                            )
                    );
                hasAnyACHPayments = this._allPaymentsCache$
                    .getValue()
                    .some(
                        (payment: any) =>
                            payment.pendingPaymentMethodType === "ACH" &&
                            filterOrgs.find(
                                (orgID: string) =>
                                    orgID === payment.organizationID
                            )
                    );
            }

            if (
                (!hasAnyEcheckPayments ||
                    (hasAnyEcheckPayments &&
                        !hasAnyUnapprovedEcheckPayments &&
                        hideApproved)) &&
                !hasAnyCreditCardPayments &&
                hasAnyACHPayments
            ) {
                paymentTypeOption = "ACH";
            } else if (
                !hasDelinquentEchecks &&
                (hasDelinquentCreditCardPayments ||
                    (!hasAnyUnapprovedEcheckPayments &&
                        hasAnyCreditCardPayments))
            ) {
                paymentTypeOption = "CREDIT_CARD";
            } else {
                paymentTypeOption = "ECHECK";
            }
        }
        let allFilteredPayments: any[] = [];
        filterOrgs.forEach((orgID: string) => {
            let filteredPayments: any[] = [];
            if (this._allPaymentsCache$.getValue()) {
                filteredPayments = this._allPaymentsCache$
                    .getValue()
                    .filter(
                        (payment: any) =>
                            paymentTypeOption ===
                                payment.pendingPaymentMethodType &&
                            orgID === payment.organizationID
                    );
            }
            allFilteredPayments = allFilteredPayments.concat(filteredPayments);
        });
        const clonedAllFilteredPayments: any[] = cloneDeep(allFilteredPayments);

        switch (paymentTypeOption) {
            case "ECHECK":
                this.filterApprovedEcheckPayments(
                    hideApproved,
                    filterOrgs,
                    clonedAllFilteredPayments
                );
                break;
            case "ACH":
                this._uiPendingPayments$.next(clonedAllFilteredPayments);
                break;
            case "CREDIT_CARD":
                this._uiPendingPayments$.next(clonedAllFilteredPayments);
                break;
            case "NONE":
                this._uiPendingPayments$.next([]);
                break;
            default:
                break;
        }
    }

    private _setupPendingPaymentsCounts(orgIDArray: string[], payments: any[]) {
        let masterPendingPaymentCounts: PendingPaymentsCountsObj = {
            none: {
                total: 0,
                echeck: 0,
                creditCard: 0,
                anyDelinquentEcheck: false,
                anyDelinquentCreditCard: false,
                anyErrorsCreditCard: false
            }
        };
        let totalPendingCount: number = 0;
        let totalEcheckPendingCount: number = 0;
        let totalCCPendingCount: number = 0;
        let anyDelinquentEcheck: boolean = false; // only change if false
        let anyDelinquentCreditCard: boolean = false; // only change if false
        let anyErrorsCreditCard: boolean = false;

        orgIDArray.forEach((orgID: string) => {
            let totalOrgPendingCount: number = 0;
            let totalOrgEcheckPendingCount: number = 0;
            let totalOrgCCPendingCount: number = 0;
            let anyOrgDelinquentEcheck: boolean = false; // only change if false
            let anyOrgDelinquentCreditCard: boolean = false; // only change if false
            let anyOrgErrorsCreditCard: boolean = false;
            let unapprovedOrgPendingPayments: any[] = [];
            if (payments?.length > 0) {
                unapprovedOrgPendingPayments = payments.filter(
                    (payment: any) =>
                        payment.organizationID === orgID &&
                        !payment.approved &&
                        (payment.pendingPaymentMethodType === "ECHECK" ||
                            payment.pendingPaymentMethodType === "CREDIT_CARD")
                );
            }

            unapprovedOrgPendingPayments.forEach((payment: any) => {
                if (payment.pendingPaymentMethodType === "ECHECK") {
                    totalOrgEcheckPendingCount++;
                    if (!anyOrgDelinquentEcheck && payment.isDelinquent) {
                        anyOrgDelinquentEcheck = true;
                    }
                }
                if (
                    payment.pendingPaymentMethodType === "CREDIT_CARD" &&
                    payment.errorMessage
                ) {
                    totalOrgCCPendingCount++;
                    if (!anyOrgErrorsCreditCard && payment.errorMessage) {
                        anyOrgErrorsCreditCard = true;
                    }
                    if (!anyOrgDelinquentCreditCard && payment.isDelinquent) {
                        anyOrgDelinquentCreditCard = true;
                    }
                }
            });

            totalOrgPendingCount = unapprovedOrgPendingPayments.length;
            totalPendingCount += totalOrgPendingCount;
            totalEcheckPendingCount += totalOrgEcheckPendingCount;
            totalCCPendingCount += totalOrgCCPendingCount;
            if (anyOrgDelinquentEcheck && !anyDelinquentEcheck) {
                anyDelinquentEcheck = true;
            }
            if (anyOrgDelinquentCreditCard && !anyDelinquentCreditCard) {
                anyDelinquentCreditCard = true;
            }
            if (anyOrgErrorsCreditCard && !anyErrorsCreditCard) {
                anyErrorsCreditCard = true;
            }

            // add an org pending payment count object if the org has any unapproved pending payments
            if (totalOrgPendingCount > 0) {
                masterPendingPaymentCounts[orgID] = {
                    total: totalOrgPendingCount,
                    echeck: totalOrgEcheckPendingCount,
                    creditCard: totalOrgCCPendingCount,
                    anyDelinquentEcheck: anyOrgDelinquentEcheck,
                    anyDelinquentCreditCard: anyOrgDelinquentCreditCard,
                    anyErrorsCreditCard: anyOrgErrorsCreditCard
                };
            }
        });
        // update all org pending payment object
        masterPendingPaymentCounts["none"] = {
            total: totalPendingCount,
            echeck: totalEcheckPendingCount,
            creditCard: totalCCPendingCount,
            anyDelinquentEcheck: anyDelinquentEcheck,
            anyDelinquentCreditCard: anyDelinquentCreditCard,
            anyErrorsCreditCard: anyErrorsCreditCard
        };

        // update the pending payments count cache
        this._pendingPaymentCountsCache$.next(masterPendingPaymentCounts);
    }

    private _setupUIPendingPaymentsInfo(payments: any) {
        if (!payments || payments.length == 0) return;
        return payments.map((pendingPayment: any) => {
            // Set for all pending payment types
            pendingPayment.label =
                pendingPayment.items.length > 1
                    ? `${pendingPayment.items.length} ACH Items`
                    : pendingPayment.items[0].label;
            // Set info for specific pending payment types to display in UI correctly
            if (pendingPayment.pendingPaymentMethodType === "ACH") {
                if (pendingPayment.items.length === 1) {
                    pendingPayment.isChild = false;
                    pendingPayment.referenceDate = pendingPayment.items[0].date;
                    pendingPayment.packageID =
                        pendingPayment.items[0].packageID;
                    pendingPayment.packageArchived =
                        pendingPayment.items[0].packageArchived;
                    pendingPayment.paymentMethod = pendingPayment.isRefund
                        ? "ACH Refund"
                        : "ACH";
                    pendingPayment.achReferenceID =
                        pendingPayment.items[0].achReferenceID;
                } else if (pendingPayment.items.length > 1) {
                    pendingPayment.paymentMethod = pendingPayment.isRefund
                        ? "ACH Refund"
                        : "ACH";
                    pendingPayment.items.forEach((item: any) => {
                        item.referenceDate = item.date;
                        item.isChild = true;
                        item.isRefund = item.feeTotals
                            ? +item.feeTotals.total < 0
                            : +item.fees.total < 0;
                        item.paymentAccountLabel =
                            pendingPayment.paymentAccountLabel;
                        item.organizationID = pendingPayment.organizationID;
                        item.paymentMethod = item.isRefund
                            ? "ACH Refund"
                            : "ACH";
                        item.paymentAccountID = pendingPayment.paymentAccountID;
                        item.oldPaymentAccountID =
                            pendingPayment.oldPaymentAccountID;
                        item.editableCategories = [item.editableCategories];
                        item.orgPaymentAccounts =
                            pendingPayment.orgPaymentAccounts;
                    });
                }
            } else if (pendingPayment.pendingPaymentMethodType === "ECHECK") {
                pendingPayment.isChild = false;
                pendingPayment.packageID = pendingPayment.echeck.packageID;
                pendingPayment.packageArchived =
                    pendingPayment.echeck.packageArchived;
                pendingPayment.editMode = false;
                pendingPayment.items = [pendingPayment.items]; // so the array of items is added as form control
                pendingPayment.targetAccountID = ""; // array of refund account ids
                pendingPayment.paymentMethod = pendingPayment.isRefund
                    ? "E-Check Refund"
                    : "E-Check";
            } else if (
                pendingPayment.pendingPaymentMethodType === "CREDIT_CARD"
            ) {
                pendingPayment.isChild = false;
                pendingPayment.packageID = pendingPayment.items[0].packageID;
                pendingPayment.packageArchived =
                    pendingPayment.items[0].packageArchived;
                pendingPayment.items = [pendingPayment.items]; // so the array of items is added as form control
                pendingPayment.badPaymentAccountID =
                    pendingPayment.paymentAccountID; // use to check for weird SFselect first click issue if you click out with out selecting a new account
                pendingPayment.paymentMethod = pendingPayment.isRefund
                    ? "Credit Card Refund"
                    : "Credit Card";
            }
            return pendingPayment;
        });
    }

    filterApprovedEcheckPayments(
        hideApproved: boolean,
        filterOrgs: any[],
        filteredPayments?: any,
        initialFilter?: boolean
    ) {
        // To make sure we are grabbing the echeck payments for the selected org(s) in the UI
        let allFilteredEcheckPayments: any[] = [];
        filterOrgs.forEach((orgID: string) => {
            const filteredEcheckPayments = this._filteredEcheckPayments$
                .getValue()
                .filter((payment: any) => orgID === payment.organizationID);
            allFilteredEcheckPayments = allFilteredEcheckPayments.concat(
                filteredEcheckPayments
            );
        });
        const clonedAllFilteredEcheckPayments: any[] = cloneDeep(
            allFilteredEcheckPayments
        );

        const uiFilteredPayments = filteredPayments
            ? filteredPayments
            : clonedAllFilteredEcheckPayments;
        if (hideApproved) {
            let unapprovedPayments = uiFilteredPayments.filter(
                (payment: any) => !payment.approved
            );
            if (!initialFilter) {
                this._uiPendingPayments$.next(unapprovedPayments);
            }
        } else {
            if (!initialFilter) {
                this._uiPendingPayments$.next(uiFilteredPayments);
            }
        }
    }

    updateFilteredPayments(overrideCollections: boolean, echeckID: string) {
        const echeckIndex = this._filteredEcheckPayments$
            .getValue()
            .findIndex((payment: any) => payment.echeck.echeckID === echeckID);
        this._filteredEcheckPayments$.getValue()[
            echeckIndex
        ].echeck.overrideCollections = overrideCollections;
    }

    updateEcheckNumber(echeckID: string, checkNumber: string) {
        const echeckIndex = this._allPaymentsCache$
            .getValue()
            .findIndex(
                (payment: any) =>
                    payment.pendingPaymentMethodType === "ECHECK" &&
                    payment.echeck.echeckID === echeckID
            );
        this._allPaymentsCache$.getValue()[echeckIndex].echeck.checkNumber =
            checkNumber;
    }

    updateModifiedDate(echeck: any) {
        const echeckIndex = this._allPaymentsCache$
            .getValue()
            .findIndex(
                (payment: any) =>
                    payment.pendingPaymentMethodType === "ECHECK" &&
                    payment.echeck.echeckID === echeck.echeckID
            );
        if (echeckIndex >= 0)
            this._allPaymentsCache$.getValue()[
                echeckIndex
            ].echeck.modifiedDate = echeck.modifiedDate;
    }

    updateEcheckMemo(echeckID: string, memo: string) {
        const echeckIndex = this._allPaymentsCache$
            .getValue()
            .findIndex(
                (payment: any) =>
                    payment.pendingPaymentMethodType === "ECHECK" &&
                    payment.echeck.echeckID === echeckID
            );
        this._allPaymentsCache$.getValue()[echeckIndex].echeck.memo = memo;
    }

    updatePaymentAccount(
        paymentID: string, // echeck id or failed cc transaction id
        paymentAccountID: string,
        paymentType: string
    ) {
        let paymentIndex: number;

        if (paymentType === "CREDIT_CARD") {
            paymentIndex = this._allPaymentsCache$
                .getValue()
                .findIndex(
                    (payment: any) =>
                        payment.pendingPaymentMethodType === paymentType &&
                        payment.failedTransactionItemID === paymentID
                );
        } else if (paymentType === "ECHECK") {
            paymentIndex = this._allPaymentsCache$
                .getValue()
                .findIndex(
                    (payment: any) =>
                        payment.pendingPaymentMethodType === paymentType &&
                        payment.echeck.echeckID === paymentID
                );
            this._allPaymentsCache$.getValue()[
                paymentIndex
            ].echeck.paymentAccountID = paymentAccountID;
        } else if (paymentType === "ACH") {
        }

        this._allPaymentsCache$.getValue()[paymentIndex].paymentAccountID =
            paymentAccountID;
    }

    getPendingPaymentsCountObj(): PendingPaymentsCountsObj {
        return this._pendingPaymentCountsCache$.getValue();
    }

    getUpdatedEcheck(echeck: any): any {
        const echeckIndex = this._allPaymentsCache$
            .getValue()
            .findIndex(
                (payment: any) =>
                    payment.pendingPaymentMethodType === "ECHECK" &&
                    payment.echeck.echeckID === echeck.echeck.echeckID
            );
        if (echeckIndex >= 0)
            return this._allPaymentsCache$.getValue()[echeckIndex];
        return null;
    }
}

/**
 * A lock that is granted when calling [[Semaphore.acquire]].
 */
type Lock = {
    release: () => void;
};

/**
 * A task that has been scheduled with a [[Semaphore]] but not yet started.
 */
type WaitingPromise = {
    resolve: (lock: Lock) => void;
    reject: (err?: Error) => void;
};

/**
 * A [[Semaphore]] is a tool that is used to control concurrent access to a common resource. This implementation
 * is used to apply a max-parallelism threshold.
 */
export class Semaphore {
    private running = 0;
    private waiting: WaitingPromise[] = [];
    private debugLogging = false;

    constructor(
        private label: string,
        public max: number = 1,
        isDebug?: boolean
    ) {
        if (max < 1) {
            throw new Error(
                `The ${label} semaphore was created with a max value of ${max} but the max value cannot be less than 1`
            );
        }
        this.debugLogging = isDebug;
    }

    /**
     * Allows the next task to start, if there are any waiting.
     */
    private take = () => {
        if (this.waiting.length > 0 && this.running < this.max) {
            this.running++;

            // Get the next task from the queue
            const task = this.waiting.shift();

            // Resolve the promise to allow it to start, provide a release function
            task.resolve({ release: this.release });
        }
    };

    /**
     * Acquire a lock on the target resource.
     *
     * ! Returns a function to release the lock, it is critical that this function is called when the task is finished with the resource.
     */
    acquire = (): Promise<Lock> => {
        if (this.debugLogging) {
            console.log(
                `Lock requested for the ${this.label} resource - ${this.running} active, ${this.waiting.length} waiting`
            );
        }

        if (this.running < this.max) {
            this.running++;
            return Promise.resolve({ release: this.release });
        }

        if (this.debugLogging) {
            console.log(
                `Max active locks hit for the ${this.label} resource - there are ${this.running} tasks running and ${this.waiting.length} waiting.`
            );
        }

        return new Promise<Lock>((resolve, reject) => {
            this.waiting.push({ resolve, reject });
        });
    };

    /**
     * Releases a lock held by a task. This function is returned from the acquire function.
     */
    private release = () => {
        this.running--;
        this.take();
    };

    /**
     * Purge all waiting tasks from the [[Semaphore]]
     */
    purge = () => {
        if (this.debugLogging) {
            console.info(
                `Purge requested on the ${this.label} semaphore, ${this.waiting.length} pending tasks will be cancelled.`
            );
        }

        this.waiting.forEach((task) => {
            task.reject(
                new Error(
                    "The semaphore was purged and as a result this task has been cancelled"
                )
            );
        });

        this.running = 0;
        this.waiting = [];
    };
}
