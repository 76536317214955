import { Component, Input } from "@angular/core";
import { UserCenterComponent } from "../../interfaces/user-center-component.interface";
import { PageData } from "../../interfaces/page-data.interface";
import { HelpControlItem } from "../../interfaces/help-control.interface";
import { SessionService } from "@sf/common";

@Component({
    selector: "sf-simple-user-center",
    templateUrl: "./simple-user-center.component.html",
    styleUrls: ["./simple-user-center.component.scss"]
})
export class SimpleUserCenterComponent implements UserCenterComponent {
    @Input() documentationLink: string;
    @Input() pageData: PageData = {};

    showCallUs: boolean = true;

    constructor(protected _sessionService: SessionService) {}

    ngOnInit(): void {
        let additionalHelpLinks: HelpControlItem[] = [];
        if (this._sessionService.hasProductInAnyOrg("admin")) {
            additionalHelpLinks.push({
                text: "Videos",
                link: "/sf/ui/admin/videos"
            });
        } else if (this._sessionService.hasProductInAnyOrg("esign_event")) {
            additionalHelpLinks.push({
                text: "Videos",
                link: "/sf/ui/sign-event/videos"
            });
        } else if (this._sessionService.hasProductInAnyOrg("capc")) {
            additionalHelpLinks.push({
                text: "Videos",
                link: "/sf/ui/lender/videos"
            });
        } else if (this._sessionService.hasProductInAnyOrg("submitter")) {
            additionalHelpLinks.push({
                text: "Videos",
                link: "/sf/ui/submitter/videos"
            });
        } else if (this._sessionService.hasProductInAnyOrg("recipient")) {
            additionalHelpLinks.push({
                text: "Videos",
                link: "/sf/ui/recipient/videos"
            });
        }

        if (Array.isArray(this.pageData.additionalHelpLinks)) {
            this.pageData.additionalHelpLinks.push(...additionalHelpLinks);
        } else {
            this.pageData.additionalHelpLinks = additionalHelpLinks;
        }

        this.showCallUs = !this._sessionService.isSuperUser();
    }
}
