<div *ngIf="alreadyAuthenticated">
    <!-- this shows if the login page is reloaded immediately after logging in, for a second or two -->
    <div class="small-logo-container">
        <img src="/sf/ui/images/sf-logo-white.png" alt=""/>
    </div>
</div>
<div *ngIf="!alreadyAuthenticated">
    <div class="logo d-flex justify-content-center">
        <div class="logo-back">
            <button class="btn btn-link" (click)="clickBack()" *ngIf="!isEpc">
                <span>Learn More About Simplifile</span>
            </button>
        </div>
    </div>
    <!-- WARNING MESSAGE -->
    <div class="serious-warning-row" *ngIf="!!formSeriousWarningMessage">
        <span [innerHTML]="formSeriousWarningMessage"></span>
    </div>
    <div class="warning-row" *ngIf="!!formWarningMessage">
        <span [innerHTML]="formWarningMessage"></span>
    </div>
    <!-- main container -->
    <div class="login-container" role="main">
        <h1 class="head-text">
            Log In
        </h1>
        <!-- SUCCESS MESSAGE -->
        <div class="success-row" [hidden]="!formSuccessMessage">
            <span [innerHTML]="formSuccessMessage"></span>
        </div>
        <!-- ERROR MESSAGES -->
        <div class="error-row" [hidden]="!formErrorMessages || !formErrorMessages.length">
            <ul *ngIf="formErrorMessages.length > 1">
                <li *ngFor="let message of formErrorMessages">
                    <span [innerHTML]="message"></span>
                </li>
            </ul>
            <div *ngIf="formErrorMessages.length == 1">
                <span [innerHTML]="formErrorMessages[0]"></span>
            </div>
        </div>
        <div  *ngIf="errorParameter != 'restrictedExpired'">
            <form [formGroup]="loginForm">
                <div class="row">
                    <div class="mb-3 col-md-12">
                        <input type="text" id="username" name="username" placeholder="Username or Email"
                                aria-label="Username or Email"
                                class="form-control" autofocus maxlength="255" formControlName="username"/>
                    </div>
                </div>
                <div class="row">
                    <div class="mb-3 col-md-12">
                        <input type="password" id="password" name="password" placeholder="Password" class="form-control"
                                aria-label="Password"
                                maxlength="255" formControlName="password"/>
                    </div>
                </div>
                <div class="row">
                    <div class="mb-3 col-md-12">
                        <label class="remember-label">
                            <input type="checkbox" formControlName="remember">
                            <span> Remember my username</span>
                        </label>
                    </div>
                </div>
                <div class="button-row">
                    <button id="loginButton" [disabled]="processing" class="btn btn-primary" (click)="submitForm()">
                        <span *ngIf="!processing">Log In</span>
                        <fa-icon *ngIf="processing" [spin]="true" icon="spinner" class="spinner-button"></fa-icon>
                    </button>
                </div>
            </form>
            <div class="link-container">
                <button class="btn btn-link" (click)="clickForgotUsername()">Forgot username?</button>
                <button class="btn btn-link" (click)="clickRegister()">Don't have an account?</button>
                <button class="btn btn-link" (click)="clickForgotPassword()">Forgot password?</button>
                <button class="btn btn-link" (click)="clickContact()">Contact Support</button>
            </div>
            <div class="terms-container">
                <div>
                    <span>Simplifile </span>
                    <a href="http://simplifile.com/about-us/terms">Terms of Use</a>
                    <span> and </span>
                    <a href="http://simplifile.com/about-us/privacy-policy">Privacy Policy</a>
                </div>
            </div>
        </div>
    </div>
</div>
