import { Injectable } from "@angular/core";
import { Organization } from "../interfaces/organization.interface";
import { RpcClientService } from "@sf/common";

export interface RoleStatus {
    assigned: number;
    total: number;
    users: string[];
}

export interface ProductBasic {
    productID: string;
    label: string;
}

export interface ProductExtended extends ProductBasic {
    status: string;
    statusDetails?: string;
    isActive?: boolean;
    isSuspended?: boolean;
    suspendedReason?: string;
    roles: RoleStatus[];
    users: string[];
    depth: number;
    childProducts: ProductExtended[];
    userCount: number;
    trialRegistrationCode?: string;
}

@Injectable({
    providedIn: "root"
})
export class UserorgActivationService {
    constructor(private rpcService: RpcClientService) {}

    updateUserSession() {
        return this.rpcService.makeRequest(
            "userorg",
            "reloadUserOrganizations",
            {}
        );
    }

    createOrganization(organization: any) {
        return this.rpcService.makeRequest(
            "userorg",
            "createOrganization",
            organization
        );
    }

    getOrganizationAdminUsers(orgID: string) {
        return this.rpcService.makeRequest(
            "userorg",
            "getOrganizationAdminUsers",
            {
                orgID: orgID
            }
        );
    }

    getServiceSuspendReasons() {
        return this.rpcService.makeRequest(
            "userorg",
            "getServiceSuspendReasons",
            {}
        );
    }

    getServiceSuspendPaymentReasons() {
        return this.rpcService.makeRequest(
            "userorg",
            "getServiceSuspendPaymentReasons",
            {}
        );
    }

    getServiceSuspendLicenseReasons() {
        return this.rpcService.makeRequest(
            "userorg",
            "getServiceSuspendLicenseReasons",
            {}
        );
    }

    getServiceSuspendAccountingReasons() {
        return this.rpcService.makeRequest(
            "userorg",
            "getServiceSuspendAccountingReasons",
            {}
        );
    }

    canModifyProductActivations(orgID: string) {
        return this.rpcService.makeRequest(
            "userorg",
            "canModifyProductActivations",
            { orgID: orgID }
        );
    }

    canCreateOrganizations() {
        return this.rpcService.makeRequest(
            "userorg",
            "canCreateOrganizations",
            {}
        );
    }

    // the response will be of type ProductExtended
    getActiveProducts(orgID: string) {
        return this.rpcService.makeRequest("userorg", "getActiveProducts", {
            orgID: orgID
        });
    }

    getEditableProducts(orgID: string): string[] {
        return [
            "erecord",
            "submitter",
            "submitter_paper",
            "submitter_signing",
            "third_party_documents",
            "submitter_api",
            "recipient",
            "recipient_primary",
            "recipient_secondary",
            "recipient_api",
            "capc",
            "lender",
            "collaboration_lender",
            "post_closing_lender",
            "licensed_collaborator_lender",
            "capc_lender_api",
            "vendor_lender_integration",
            "capc_eclose_demo",
            "settlement",
            "collaboration_settlement",
            "post_closing_settlement",
            "licensed_collaborator_settlement",
            "capc_submitter_api",
            "secured_disbursement",
            "settlement_initiated_loan",
            "vendor_settlement_integration",
            "third_party",
            "integration_access",
            "vendor",
            "lender_vendor",
            "notary_vendor",
            "esign_events_vendor",
            "recipient_vendor",
            "submitter_vendor",
            "vendor_api_integration",
            "notary_api_integration",
            "esign_events_api_integration",
            "recipient_vendor_api_integration",
            "submitter_vendor_api_integration",
            "internal_vendor",
            "internal_api_integration",
            "internal_erecord_plus_facilitator",
            "trustee",
            "notary",
            "esign_event",
            "doc_builder_ron",
            "mers_api_integration",
            "msp_api_integration",
            "submitter_consumer_portal",
            "subscription",
            "vendor_esign_events_integration"
        ];
    }

    suspendService(
        orgID: string,
        productID: string,
        reasonID: string,
        suspendDetails: string
    ) {
        return this.rpcService.makeRequest("userorg", "suspendProduct", {
            orgID: orgID,
            product: productID,
            suspendedReason: reasonID,
            suspendedDetails: suspendDetails
        });
    }

    unsuspendService(orgID: string, productID: string, suspendDetails: string) {
        return this.rpcService.makeRequest("userorg", "unSuspendProduct", {
            orgID: orgID,
            product: productID,
            suspendedDetails: suspendDetails
        });
    }

    activateProductSimple(
        orgID: string,
        productID: string,
        mode: string,
        notifyUsers: string[]
    ) {
        return this.rpcService.makeRequest("userorg", "activateProduct", {
            orgID: orgID,
            productID: productID,
            mode: mode,
            activateChildren: false,
            notifyUsers: notifyUsers
        });
    }

    isProductEnabledForOrganization(orgID: string, productID: string) {
        return this.rpcService.makeRequest(
            "userorg",
            "isProductEnabledForOrganization",
            {
                orgID: orgID,
                productID: productID
            }
        );
    }

    activateProduct(
        organization: Organization,
        product: ProductExtended,
        mode: string,
        activateChildren: boolean,
        notifyUsers: string[],
        children: any
    ) {
        return this.rpcService.makeRequest("userorg", "activateProduct", {
            orgID: organization.id,
            productID: product.productID,
            mode: mode,
            activateChildren: activateChildren,
            notifyUsers: notifyUsers,
            children: children,
            trialRegistrationCode: product.trialRegistrationCode
        });
    }

    deactivateProduct(
        organization: Organization,
        product: ProductBasic,
        notifyUsers: string[],
        deactivatedReason: string,
        deactivatedDetails: string
    ) {
        return this.rpcService.makeRequest("userorg", "deactivateProduct", {
            orgID: organization.id,
            productID: product.productID,
            notifyUsers: notifyUsers,
            deactivatedReason: deactivatedReason,
            deactivatedDetails: deactivatedDetails
        });
    }

    endTrial(organizationID: string, productID: string) {
        return this.rpcService.makeRequest("userorg", "endTrial", {
            orgID: organizationID,
            productID: productID
        });
    }

    // this returns a map of ALL products in our system
    getAllProducts() {
        return this.rpcService.makeRequest("userorg", "getAllProducts", {});
    }

    requestAdminActivation(
        organizationID: string,
        productID: string,
        notifyUsers: string[]
    ) {
        return this.rpcService.makeRequest(
            "userorg",
            "requestAdminActivation",
            {
                orgID: organizationID,
                productID: productID,
                notifyUsers: notifyUsers
            }
        );
    }

    activateProductFromTrial(organizationID: string, productID: string) {
        return this.rpcService.makeRequest(
            "userorg",
            "activateProductFromTrial",
            {
                orgID: organizationID,
                productID: productID
            }
        );
    }
}
