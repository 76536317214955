/**
 * Note that these methods all work only on settings for the currently logged-in user
 */
import { Injectable } from "@angular/core";
import { RpcClientService } from "./rpc-client.service";
import { SessionService } from "./session.service";
import { Session } from "../interfaces/session.interface";
import { getPath } from "../helpers/object";
import { Observable, Subject } from "rxjs";
import { dayjs } from "../date-and-time/plugins/dayjs";

declare const sf: any;

enum ParentFolders {
    COLLABORATION_AND_POST_CLOSING = "Collaboration and Post Closing",
    DOCUMENT_BUILDER = "Document Builder",
    MY_LIBRARY = "My library",
    RECIPIENT = "Recipient",
    SUBMITTER = "Submitter"
}

export interface VimeoData {
    vimeoProjects: VimeoProject[];
}

export interface VimeoProject {
    name: string;
    projectID: string;
    vimeoProjects: VimeoProject[];
    vimeoVideos?: VimeoVideo[];
    ancestorProjectList: VimeoProject[];
}

export interface VimeoVideo {
    videoID: string;
    name: string;
    description: string;
    duration: number;
    width?: number;
    height: number;
    released: string; // Date
    playerEmbedURL: string;
    picture200x150WithPlay: string;
}

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class VimeoVideoService {
    private cachedSettings = {};

    constructor(
            private _rpcClient: RpcClientService,
            private _sessionService: SessionService
    ) {

    }

    public getVideos(): Observable<VimeoData> {
        return this._rpcClient.makeRequest<VimeoData>(
                "userorg",
                "getVimeoData",
                {}
        );
    }

    public getVideoNames(proj: VimeoProject): boolean {
        let correctProjectName: boolean = false;
        if (this._sessionService.hasProductInAnyOrg("esign_event")) {
            correctProjectName ||= proj.name === "eSign Events";
        }
        if (this._sessionService.hasProductInAnyOrg("capc")) {
            correctProjectName ||=
                    proj.name === "Collaboration and Post Closing";
        }
        if (this._sessionService.hasProductInAnyOrg("recipient")) {
            correctProjectName ||= proj.name === "Recipient";
        }
        if (this._sessionService.hasProductInAnyOrg("submitter_signing")) {
            correctProjectName ||= proj.name === "Document Builder";
        }
        if (this._sessionService.hasProductInAnyOrg("submitter")) {
            correctProjectName ||= proj.name === "Submitter";
        }
        if (this._sessionService.hasProductInAnyOrg("admin")) {
            correctProjectName ||=
                    proj.name === "eSign Events" ||
                    proj.name === "Collaboration and Post Closing" ||
                    proj.name === "Recipient" ||
                    proj.name === "Document Builder" ||
                    proj.name === "Submitter";
        }

        return correctProjectName;
    }


    findVideo(vimeoData:VimeoData,parentFolder:string, subFolderName: string, videoID: string ) {
        let vimeoProjects =
                vimeoData.vimeoProjects.length > 0
                        ? vimeoData.vimeoProjects.filter(
                                (proj: VimeoProject): boolean => {
                                    return this.getVideoNames(proj);
                                }
                        )
                        : [];
        let vimeoProject: VimeoProject = vimeoProjects.find((p:VimeoProject)=>{return p.name === parentFolder});

        if(vimeoProject) {
            let vimeoSubFolder = vimeoProject.vimeoProjects.find((v)=>{return v.name === subFolderName});
            if(vimeoSubFolder) {
                return vimeoSubFolder.vimeoVideos.find((v:VimeoVideo) => v.videoID === videoID);
            }
        }
       return null;
    }


    convertDuration(duration: number): string {
        return dayjs
                .duration(duration, "seconds")
                .format("HH:mm:ss")
                .toString();
    }

}
