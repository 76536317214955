import { Injectable } from "@angular/core";
import { SessionService } from "./session.service";
import { ConfigurationService } from "./configuration.service";
import { CustomizableColumn } from "../interfaces";
import { Observable } from "rxjs";
import { ChooseColumnsDialogComponent } from "../modals/components";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

// prettier-ignore
@Injectable({
    providedIn: "root"
})
export class CustomColumnsService {
    returnEmptyColumnSet: boolean = false;
    private PAGE_KEYS: any = {
        adminContractManagementColumns: "adminContractManagementColumns",
        adminDefinitionsColumns: "adminDefinitionsColumns",
        adminDocumentTotals: "adminDocumentTotals",
        adminJobListColumns: "adminJobListColumns",
        adminOrganizationsColumns: "adminOrganizationsColumns",
        adminUsersColumns: "adminUsersColumns",
        approvedEcheckColumns: "approvedEcheckColumns",
        contractPricingTemplatesColumns: "contractPricingTemplatesColumns",
        customValueColumns: "customValueColumns",
        lenderListViewColumns: "lenderListViewColumns",
        eSignEventListViewColumns: "eSignEventListViewColumns",
        organizationListColumns: "organizationListColumns",
        printedEcheckColumns: "printedEcheckColumns",
        readyEcheckColumns: "readyEcheckColumns",
        rejectionSuggestionColumns: "rejectionSuggestionColumns",
        rejectionSuggestionColumnsNew: "rejectionSuggestionColumnsNew",
        recipientCompletedColumns: "recipientCompletedColumns",
        recipientJobQueueTriageColumns: "recipientJobQueueTriageColumns",
        recipientSearchColumns: "recipientSearchColumns",
        recipientPackageHistoryColumns: "recipientPackageHistoryColumns",
        recipientPackageListColumns: "recipientPackageListColumns",
        recipientPackageSearchColumns: "recipientPackageSearchColumns",
        roleAssignmentsColumns: "roleAssignmentsColumns",
        userListColumns: "userListColumns",
        submitterPackageListColumns: "submitterPackageListColumns",
        submitterSigningSignColumns: "submitterSigningSignColumns",
        submitterSigningReviewColumns: "submitterSigningReviewColumns",
        submitterSigningManageColumns: "submitterSigningManageColumns",
        submitterSigningNeedsPOAColumns: "submitterSigningNeedsPOAColumns",
        submitterSigningUnsignableColumns: "submitterSigningUnsignableColumns",
        submitterSigningPrintColumns: "submitterSigningPrintColumns",
        submitterSigningSigningGroupsColumns: "submitterSigningSigningGroupsColumns",
        submitterSigningDataEntryColumns: "submitterSigningDataEntryColumns",
        submitterSigningCheckPrintingColumns: "submitterSigningCheckPrintingColumns",
        submitterSigningArchiveColumns: "submitterSigningArchiveColumns",
        surveyResponseColumns: "surveyResponseColumns",
        adminEcheckProcessedColumns: "adminEcheckProcessedColumns",
        userRoleAssignmentsOrganizationsColumns: "userRoleAssignmentsOrganizationsColumns",
        userRoleAssignmentsRolesColumns: "userRoleAssignmentsRolesColumns",
        userRoleAssignmentsPermissionColumns: "userRoleAssignmentsPermissionColumns",
        notaryJournalColumns: "notaryJournalColumns",
        newNotaryJournalColumns: "newNotaryJournalColumns",
        orgTreeDesignerColumns: "orgTreeDesignerColumns",
        organizationPaymentAccountsColumns: "organizationPaymentAccountsColumns",
        adminBatchesColumns: "adminBatchesColumns",
        licenseAllDocumentsColumns: "licenseAllDocumentsColumns",
        adminRolesColumns: "adminRolesColumns",
        adminPermissionsColumns: "adminPermissionsColumns",
        adminRecipientSubmitters: "adminRecipientSubmitters",
        recipientSubmitters: "recipientSubmitters",
        templateManagerColumns: "templateManagerColumns",
        uploadDocumentErrorsColumns: "uploadDocumentErrorsColumns",
        emailTemplatesColumns: "emailTemplatesColumns",
        helperSecondaryDocumentsPage: "helperSecondaryDocumentsPage",
        analysisMappingsColumns: "analysisMappingsColumns",
        manageTransactionsColumns: "manageTransactionsColumns",
        capcToolsApiLogsColumns: "capcToolsApiLogsColumns",
        jobScheduleListColumns: "jobScheduleListColumns"
    };

    constructor(
        private sessionService: SessionService,
        private configurationService: ConfigurationService,
        private modalService: NgbModal
    ) {}

    getPageKey(pageKey: string): string {
        this.checkPageKey(pageKey);
        return this.PAGE_KEYS[pageKey];
    }

    checkPageKey(pageKey: string) {
        if (!this.PAGE_KEYS[pageKey]) {
            throw (
                "Page key specified is not registered with columns service: " +
                pageKey
            );
        }
    }

    /**
     * Get the saved column layout
     * Async method - uses a promise
     * @param pageKey - The key name for all page sets for the UI page you are dealing with
     * @param allColumns - A list of all possible columns (objects)
     * @param columnSetName - The column set name to use
     * @returns a list of all the saved visible columns (objects).
     *        Note that the response will be a subset of 'allColumns'.
     *        So, the response will include all the attributes that are included in allColumns.
     *        Only the 'width' attribute will be modified.
     */
    getColumnLayout(
        pageKey: string,
        allColumns: CustomizableColumn[],
        columnSetName: string
    ): Promise<CustomizableColumn[]> {
        this.checkPageKey(pageKey);
        return new Promise<CustomizableColumn[]>((resolve, reject) => {
            let settingKey = pageKey + ".columnSet." + columnSetName;
            this.configurationService
                .getUserSettingCustom(
                    this.sessionService.getUsername(),
                    settingKey
                )
                .subscribe((columnJson: string) => {
                    let savedColumns: CustomizableColumn[] = null;
                    let currentColumns: CustomizableColumn[] = null;

                    if (columnJson) {
                        savedColumns = JSON.parse(columnJson);
                    }

                    if (savedColumns) {
                        currentColumns = [];
                        savedColumns.forEach( (col) => {
                            let userColumn: CustomizableColumn =
                                this.findColumn(col, allColumns);
                            if (userColumn) {
                                const { groupBy, width, groupBySortDescending } = col;
                                const openGroups = col.openGroups ? new Set(col.openGroups) : undefined;
                                currentColumns.push({
                                    ...userColumn,
                                    groupBy,
                                    width,
                                    openGroups,
                                    groupBySortDescending
                                });
                            }
                        });
                    }

                    // make sure any columns marked 'alwaysVisible' are included
                    if (currentColumns) {
                        allColumns.forEach( (col) => {
                            if (col.alwaysVisible) {
                                let currentCol = this.findColumn(col, currentColumns);
                                if (!currentCol) {
                                    col.visible = true;
                                    currentColumns.push(col);
                                }
                            }
                        });
                    }

                    resolve(currentColumns);
                });
        });
    }

    /**
     * Get the name of the 'default' column set saved in the database
     * Async method - uses a promise
     * @param pageKey - The key name for all page sets for the UI page you are dealing with
     * @returns the column set name, or null if nothing found
     */
    getDefaultColumnSetName(pageKey: string) {
        this.checkPageKey(pageKey);
        let deferred = new Promise((resolve, reject) => {
            this.configurationService
                .getUserSettingCustom(this.sessionService.getUsername(), pageKey + ".defaultColumnSet")
                .subscribe((setName: string) => {
                    resolve(setName);
                });
        });
        return deferred;
    }

    /**
     * Save the current column layout
     * The save is async, but we don't let you know when it finishes
     * @param pageKey - The key name for all page sets for the UI page you are dealing with
     * @param columnSetName - The column set name to use
     * @param currentColumns - A list of the currently visible columns (objects)
     */
    saveCustomColumns(
        pageKey: string,
        columnSetName: string,
        currentColumns: CustomizableColumn[]
    ): void {
        this.checkPageKey(pageKey);
        const columnsToSave: (CustomizableColumn | { openGroups: string[] })[] =
            currentColumns.map((col) => ({
                uniqueId: col.uniqueId,
                displayName: col.displayName,
                width: col.width,
                groupBy: col.groupBy,
                groupBySortDescending: col.groupBySortDescending,
                openGroups: col.openGroups
                    ? Array.from(col.openGroups)
                    : undefined
            }));

        const key = pageKey + ".columnSet." + columnSetName;
        const json = JSON.stringify(columnsToSave);
        this.configurationService
            .setUserSettingCustom(this.sessionService.getUsername(), key, json)
            .subscribe();
    }

    /**
     * find a column in a list
     * @param column - column object
     * @param list - list to search
     */
    findColumn(
        column: CustomizableColumn,
        list: CustomizableColumn[]
    ): CustomizableColumn {
        let match: CustomizableColumn = list.find((col) => {
            return col.uniqueId == column.uniqueId;
        });
        return match;
    }

    markVisibleColumns(
        allColumns: CustomizableColumn[],
        visibleColumns: CustomizableColumn[]
    ) {
        allColumns.forEach( (col) => {
            if (col.alwaysVisible) {
                col.visible = true;
            } else {
                let foundCol = this.findColumn(col, visibleColumns);
                col.visible = !!foundCol;
            }
        });
    }

    customizeColumns(
        currentColumns: CustomizableColumn[],
        allColumns: CustomizableColumn[],
        allowReorder?: boolean,
        allowColumnSets?: boolean,
        putLockedColumnsLast?: boolean,
        unmaskModal?: boolean
    ): Observable<CustomizableColumn[]> {
        return new Observable((subscriber) => {
            const modalRef = this.modalService.open(
                ChooseColumnsDialogComponent,
                {
                    backdrop: "static",
                    size: "md"
                }
            );
            const modalInstance = modalRef.componentInstance;

            modalInstance.currentColumns = currentColumns;
            modalInstance.allColumns = allColumns;
            modalInstance.allowReorder = allowReorder;
            modalInstance.allowColumnSets = allowColumnSets;
            modalInstance.putLockedColumnsLast = putLockedColumnsLast;
            if(unmaskModal) {
                modalInstance.unmaskModal = unmaskModal;
            }

            modalRef.result
                .then((columns: CustomizableColumn[]) => {
                    if (columns && columns.length || this.returnEmptyColumnSet) {
                        subscriber.next(columns);
                    }
                    subscriber.complete();
                })
                .catch((err) => {
                    // "x" hit on the modal
                    if (err === "exit") {
                        // ignore it
                        return;
                    }
                    throw err;
                });
        });
    }

    // maybe a hack, but it works
    // maybe not the best place for this, but it works
    lastPixelRatio = 0; // he's going for speed
    lastScrollbarWidth = 17;
    getScrollbarWidth(): number {
        if (window.devicePixelRatio == this.lastPixelRatio) {
            // don't need to recalculate
            return this.lastScrollbarWidth;
        }

        // Creating invisible container
        const outer = document.createElement("div");
        outer.style.visibility = "hidden";
        outer.style.overflow = "scroll"; // forcing scrollbar to appear
        document.body.appendChild(outer);

        // Creating inner element and placing it in the container
        const inner = document.createElement("div");
        outer.appendChild(inner);

        // Calculating difference between container's full width and the child width
        this.lastScrollbarWidth = outer.offsetWidth - inner.offsetWidth;

        // Removing temporary elements from the DOM
        outer.parentNode.removeChild(outer);

        return this.lastScrollbarWidth;
    }
}
