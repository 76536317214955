import { Injectable } from "@angular/core";
import { RpcClientService, sortBy, unionBy } from "@sf/common";
import { Observable, of } from "rxjs";
import { Organization } from "@sf/userorg/common";
import { map } from "rxjs/operators";
import { TemplateDataConfig } from "../interfaces/template-data-config";
import { ReviewRule } from "../interfaces/review-rule";
import { Folder } from "../interfaces/folder";
import { SigningGroup } from "../interfaces/signing-group";
import { TemplateListItem } from "../interfaces/template-list-item";
import { GuideUser, GuideUsers } from "../interfaces/signer-guide-user";
import { CompliancePeriod } from "../interfaces/compliance-period";
import { RecipientOrganization } from "../interfaces/recipient-organization";
import {
    CountyRequirements,
    TemplateRequirements
} from "../interfaces/county-requirements";
import { Project } from "../interfaces/project";
import { ProjectService } from "./project.service";
import { EsignMiscService } from "./esign-misc.service";
import { StandardDocumentType } from "../interfaces/document";
import { SubmitterConfigPreparation } from "@sf/userorg/common";
import { BorrowerLetterSettings } from "../interfaces/borrower-letter-settings";

@Injectable({
    providedIn: "root"
})
export class EsignOrganizationService {
    constructor(
        private _rpcClient: RpcClientService,
        private _projectService: ProjectService,
        private _esignMiscService: EsignMiscService
    ) {}

    getCorporateSeals(organizationId: string): Observable<any[]> {
        return this._rpcClient.makeRequest("esign", "getCorporateSeals", {
            organizationId
        });
    }

    getCorporateSealsBySubmitters(
        organizationIds: string[]
    ): Observable<any[]> {
        return this._rpcClient.makeRequest(
            "esign",
            "getCorporateSealsBySubmitters",
            {
                organizationIds
            }
        );
    }

    exportESignConfiguration(
        submitterId: string,
        options: boolean[]
    ): Observable<any> {
        return this._rpcClient.makeRequest(
            "esign",
            "exportESignConfiguration",
            {
                submitterId,
                options
            }
        );
    }

    importESignConfiguration(
        submitterId: string,
        config: string,
        options: boolean[]
    ): Observable<any> {
        return this._rpcClient.makeRequest(
            "esign",
            "importESignConfiguration",
            {
                submitterId,
                config,
                options
            }
        );
    }

    getTemplateDataConfig(
        submitterId: string,
        group: string,
        documentType: string
    ): Observable<TemplateDataConfig> {
        return this._rpcClient.makeRequest("esign", "getTemplateDataConfig", {
            submitterId,
            group,
            documentType
        });
    }

    getTemplateDataConfigForDocumentType(
        submitterId: string,
        documentType: string
    ): Observable<TemplateDataConfig> {
        return this._rpcClient.makeRequest(
            "esign",
            "getTemplateDataConfigForDocumentType",
            {
                submitterId,
                documentType
            }
        );
    }

    saveTemplateDataConfig(
        submitterId: string,
        group: string,
        documentType: string,
        fields: any,
        ordered: boolean
    ): Observable<TemplateDataConfig> {
        return this._rpcClient.makeRequest("esign", "saveTemplateDataConfig", {
            submitterId,
            group,
            documentType,
            fields,
            ordered
        });
    }

    copyTemplateDataConfig(
        submitterId: string,
        group: string,
        documentType: string,
        fields: any,
        ordered: boolean
    ): Observable<TemplateDataConfig> {
        return this._rpcClient.makeRequest("esign", "copyTemplateDataConfig", {
            submitterId,
            group,
            documentType,
            fields,
            ordered
        });
    }

    getDefaultFieldOrderForConfig(
        submitterId: string,
        group: string,
        documentType: string
    ): Observable<string[]> {
        return this._rpcClient.makeRequest(
            "esign",
            "getDefaultFieldOrderForConfig",
            {
                submitterId,
                group,
                documentType
            }
        );
    }

    getCheckNumberConfig(submitterId: string): Observable<any> {
        return this._rpcClient.makeRequest("esign", "getCheckNumberConfig", {
            submitterId
        });
    }

    setCheckNumberConfig(checkNumber: any): Observable<any> {
        return this._rpcClient.makeRequest("esign", "setCheckNumberConfig", {
            checkNumber
        });
    }

    getCheckSignature(organizationId: string): Observable<any> {
        return this._rpcClient.makeRequest("esign", "getCheckSignature", {
            organizationId
        });
    }

    deleteCheckSignature(signatureId: string): Observable<any> {
        return this._rpcClient.makeRequest("esign", "deleteCheckSignature", {
            signatureId
        });
    }

    updateCheckSignature(signatureId: string, image: string): Observable<any> {
        return this._rpcClient.makeRequest("esign", "updateCheckSignature", {
            signatureId,
            image
        });
    }

    uploadCheckSignature(
        organizationId: string,
        image: string
    ): Observable<any> {
        return this._rpcClient.makeRequest("esign", "uploadCheckSignature", {
            organizationId,
            image
        });
    }

    getCorporateSealsByProjectId(
        organizationId: string,
        projectId: string
    ): Observable<any> {
        return this._rpcClient.makeRequest(
            "esign",
            "getCorporateSealsByProjectId",
            {
                organizationId,
                projectId
            }
        );
    }

    renameCorporateSeal(sealId: string, name: string) {
        return this._rpcClient.makeRequest("esign", "renameCorporateSeal", {
            sealId,
            name
        });
    }

    setDefaultCorporateSeal(sealId: string, defaultSeal: boolean) {
        return this._rpcClient.makeRequest("esign", "setDefaultCorporateSeal", {
            sealId,
            defaultSeal
        });
    }

    setSealIsLogo(sealId: string, logo: boolean) {
        return this._rpcClient.makeRequest("esign", "setSealIsLogo", {
            sealId,
            logo
        });
    }

    deleteCorporateSeal(sealId: string) {
        return this._rpcClient.makeRequest("esign", "deleteCorporateSeal", {
            sealId
        });
    }

    uploadCorporateSeal(
        organizationId: string,
        projectId: string,
        name: string,
        image: string
    ) {
        if (projectId) {
            return this._rpcClient.makeRequest(
                "esign",
                "uploadCorporateSealByProjectId",
                {
                    projectId,
                    name,
                    image,
                    organizationId
                }
            );
        } else {
            return this._rpcClient.makeRequest("esign", "uploadCorporateSeal", {
                organizationId,
                name,
                image
            });
        }
    }

    updateCorporateSeal(sealId: string, image: string) {
        return this._rpcClient.makeRequest("esign", "updateCorporateSeal", {
            sealId,
            image
        });
    }

    getReviewRules(organizationId: string): Observable<ReviewRule[]> {
        return this._rpcClient.makeRequest("esign", "getReviewRules", {
            organizationId
        });
    }

    getReviewRulesByProjectId(projectId: string): Observable<ReviewRule[]> {
        return this._rpcClient.makeRequest(
            "esign",
            "getReviewRulesByProjectId",
            {
                projectId
            }
        );
    }

    saveReviewRule(reviewRule: any): Observable<ReviewRule> {
        return this._rpcClient.makeRequest("esign", "saveReviewRule", {
            reviewRule
        });
    }

    getDocumentFormatRules(
        organizationId: string,
        projectId: string
    ): Observable<ReviewRule[]> {
        return this._rpcClient.makeRequest("esign", "getDocumentFormatRules", {
            organizationId,
            projectId
        });
    }

    removeReviewRule(organizationId: string, ruleId: string): Observable<void> {
        return this._rpcClient.makeRequest("esign", "removeReviewRule", {
            organizationId,
            ruleId
        });
    }

    createReviewFolder(
        organizationID: string,
        folderName: string
    ): Observable<Folder> {
        return this._rpcClient.makeRequest("esign", "createReviewFolder", {
            organizationID,
            folderName
        });
    }

    saveDocumentFormatRule(rule: any): Observable<void> {
        return this._rpcClient.makeRequest("esign", "saveDocumentFormatRule", {
            rule
        });
    }

    removeDocumentFormatRule(ruleId: string): Observable<void> {
        return this._rpcClient.makeRequest(
            "esign",
            "removeDocumentFormatRule",
            {
                ruleId
            }
        );
    }

    getDocumentRuleChangeCounts(rule: any, deleted: boolean): Observable<any> {
        return this._rpcClient.makeRequest(
            "esign",
            "getDocumentRuleChangeCounts",
            {
                rule,
                deleted
            }
        );
    }

    updateDocumentsForRuleChange(
        rule: any,
        originalRule: any,
        unsigned: boolean,
        deleted: boolean
    ): Observable<void> {
        return this._rpcClient.makeRequest(
            "esign",
            "updateDocumentsForRuleChange",
            {
                rule,
                originalRule,
                unsigned,
                deleted
            }
        );
    }

    getSigningGroups(organizationIds: string[]): Observable<SigningGroup[]> {
        return this._rpcClient.makeRequest("esign", "getSigningGroups", {
            organizationIds
        });
    }

    getSigningGroup(signingGroupId: string): Observable<SigningGroup> {
        return this._rpcClient.makeRequest("esign", "getSigningGroup", {
            signingGroupId
        });
    }

    saveSigningGroup(
        signingGroup: SigningGroup,
        updateDocuments: boolean
    ): Observable<SigningGroup> {
        return this._rpcClient.makeRequest("esign", "saveSigningGroup", {
            signingGroup,
            updateDocuments
        });
    }

    updateSigningGroups(
        signingGroupIds: string[],
        assignedSigners: any,
        updateDocuments: boolean
    ): Observable<SigningGroup[]> {
        return this._rpcClient.makeRequest("esign", "updateSigningGroups", {
            signingGroupIds,
            assignedSigners,
            updateDocuments
        });
    }

    removeSigningGroup(signingGroup: SigningGroup): Observable<void> {
        return this._rpcClient.makeRequest("esign", "removeSigningGroup", {
            signingGroup
        });
    }

    getSigningGroupForSigner1(
        organizationId: string
    ): Observable<SigningGroup> {
        return this._rpcClient.makeRequest(
            "esign",
            "getSigningGroupForSigner1",
            {
                submitterId: organizationId
            }
        );
    }

    acceptTrusteeProvider(
        submitterId: string,
        trusteeId: string
    ): Observable<void> {
        return this._rpcClient.makeRequest("esign", "acceptTrusteeProvider", {
            submitterId,
            trusteeId
        });
    }

    rejectTrusteeProvider(
        submitterId: string,
        trusteeId: string
    ): Observable<void> {
        return this._rpcClient.makeRequest("esign", "rejectTrusteeProvider", {
            submitterId,
            trusteeId
        });
    }

    getStandardTemplateGroups(): Observable<string[]> {
        return this._esignMiscService.getStandardTemplateGroups();
    }

    getRecordableDocumentTypes(): Observable<any[]> {
        return this._esignMiscService.getRecordableDocumentTypes();
    }

    getStandardDocumentTypeGroups(): Observable<string[]> {
        return this._rpcClient.makeRequest(
            "esign",
            "getStandardDocumentTypeGroups",
            {}
        );
    }

    getCorporateSealStates(
        submitterId: string,
        group: string,
        documentType: string
    ): Observable<string[]> {
        return this._rpcClient.makeRequest("esign", "getCorporateSealStates", {
            submitterId,
            group,
            documentType
        });
    }

    saveCorporateSealStates(
        submitterId: string,
        group: string,
        documentType: string,
        states: string[]
    ): Observable<void> {
        return this._rpcClient.makeRequest("esign", "saveCorporateSealStates", {
            submitterId,
            group,
            documentType,
            states
        });
    }

    getTemplateDocumentTypes(
        organizationId: string
    ): Observable<StandardDocumentType[]> {
        return this._rpcClient.makeRequest(
            "esign",
            "getTemplateDocumentTypes",
            {
                organizationId
            }
        );
    }

    getTemplateDocumentTypesNotInAGroup(
        organizationId: string
    ): Observable<StandardDocumentType[]> {
        return this._rpcClient.makeRequest(
            "esign",
            "getTemplateDocumentTypesNotInAGroup",
            {
                organizationId
            }
        );
    }

    getInternalCompliancePeriodsForOrg(
        submitterId: string
    ): Observable<Array<CompliancePeriod>> {
        return this._rpcClient.makeRequest(
            "esign",
            "getInternalCompliancePeriodsForOrg",
            { submitterId }
        );
    }

    saveInternalCompliancePeriod(
        submitterId: string,
        state: string,
        absoluteDays: number,
        reduceDays: number,
        useReduceNumberOfDays: boolean,
        applyToAllStates: boolean
    ): Observable<void> {
        return this._rpcClient.makeRequest(
            "esign",
            "saveInternalCompliancePeriod",
            {
                submitterId,
                state,
                absoluteDays,
                reduceDays,
                useReduceNumberOfDays,
                applyToAllStates
            }
        );
    }

    removeInternalCompliancePeriod(
        submitterId: string,
        state: string
    ): Observable<void> {
        return this._rpcClient.makeRequest(
            "esign",
            "removeInternalCompliancePeriod",
            {
                submitterId,
                state
            }
        );
    }

    removeAllInternalCompliancePeriods(submitterId: string): Observable<void> {
        return this._rpcClient.makeRequest(
            "esign",
            "removeAllInternalCompliancePeriods",
            { submitterId }
        );
    }

    getSigningAdmins(organizationId: string): Observable<any[]> {
        return this._rpcClient.makeRequest("esign", "getSigningAdmins", {
            organizationId
        });
    }

    getGuideUsers(organizationId: string): Observable<GuideUsers> {
        return this._rpcClient.makeRequest("esign", "getGuideUsers", {
            organizationId
        });
    }

    setSignerGuideUsers(signerGuideUsers: GuideUser[]): Observable<void> {
        return this._rpcClient.makeRequest("esign", "setSignerGuideUsers", {
            signerGuideUsers
        });
    }

    setNotaryGuideUsers(notaryGuideUsers: GuideUser[]): Observable<void> {
        return this._rpcClient.makeRequest("esign", "setNotaryGuideUsers", {
            notaryGuideUsers
        });
    }

    setDataEntryGuideUsers(dataEntryGuideUsers: GuideUser[]): Observable<void> {
        return this._rpcClient.makeRequest("esign", "setDataEntryGuideUsers", {
            dataEntryGuideUsers
        });
    }

    setAllowExpiredCertUsers(
        allowExpiredCertUsers: GuideUser[]
    ): Observable<void> {
        return this._rpcClient.makeRequest(
            "esign",
            "setAllowExpiredCertUsers",
            {
                allowExpiredCertUsers
            }
        );
    }

    getCheckExportMappings(submitterId: string): Observable<any[]> {
        return this._rpcClient.makeRequest("esign", "getCheckExportMappings", {
            submitterId
        });
    }

    saveCheckExportMappings(checkExportMappings: any): Observable<any> {
        return this._rpcClient.makeRequest("esign", "saveCheckExportMappings", {
            checkExportMappings
        });
    }

    getActiveDocBuilderCount(organizationId: string): Observable<any> {
        return this._rpcClient.makeRequest(
            "esign",
            "getBillableDocumentCount",
            {
                organizationId,
                activeOnly: true
            }
        );
    }

    getTotalDocBuilderCount(organizationId: string): Observable<any> {
        return this._rpcClient.makeRequest(
            "esign",
            "getBillableDocumentCount",
            {
                organizationId,
                activeOnly: false
            }
        );
    }

    getSigningSubmitters(permissions?: string[]): Observable<any[]> {
        return this._rpcClient.makeRequest("esign", "getSigningSubmitters", {
            permissions
        });
    }

    getThirdPartyDocumentsSubmitters(permissions: string[]): Observable<any[]> {
        return this._rpcClient.makeRequest(
            "esign",
            "getThirdPartyDocumentsSubmitters",
            {
                permissions
            }
        );
    }

    getTrusteeServicers(trusteeIds: string[]): Observable<any[]> {
        return this._rpcClient.makeRequest("esign", "getTrusteeServicers", {
            trusteeIds
        });
    }

    hasPendingTrusteeServicer(): Observable<boolean> {
        return this._rpcClient.makeRequest(
            "esign",
            "hasPendingTrusteeServicer",
            {}
        );
    }

    getRegisteredRecipients(orgIDs: Array<string>): Observable<any> {
        return this._rpcClient.makeRequest(
            "userorg",
            "getRegisteredRecipientsForOrgs",
            {
                orgIDs
            }
        );
    }

    getAllRecipients(): Observable<any> {
        return this._rpcClient.makeRequest("userorg", "getAllRecipients");
    }

    getRecipientsByIDs(recipientIDs: string[]): Observable<any[]> {
        return this._rpcClient.makeRequest("userorg", "getRecipientsByIDs", {
            recipientIDs
        });
    }

    searchOrganizations(query: string, limit: number, services: Array<string>) {
        return this._rpcClient.makeRequest("esign", "searchOrganizations", {
            query,
            limit,
            services
        });
    }

    getRecipientsByState(state: string): Observable<any[]> {
        return this._rpcClient.makeRequest("userorg", "getRecipientsByState", {
            state
        });
    }

    getMSPALRSubmitters(): Observable<any[]> {
        return this._rpcClient.makeRequest("esign", "getMSPALRSubmitters");
    }

    searchRecipients(
        query: string,
        selection: Organization[]
    ): Observable<Organization[]> {
        if (query) {
            return this._rpcClient
                .makeRequest("search", "searchBasicOrganizations", {
                    query: query,
                    limit: 50,
                    services: ["recipient"]
                })
                .pipe(
                    map((results: Organization[]) => {
                        let recipientList = results
                            .map(this._toRecipientListObj)
                            .filter((recipient) => {
                                return (
                                    recipient.name
                                        .toLowerCase()
                                        .includes(query.toLowerCase()) ||
                                    recipient.id
                                        .toLowerCase()
                                        .includes(query.toLowerCase())
                                );
                            })
                            .sort(sortBy("name"));
                        if (selection) {
                            recipientList = unionBy(
                                recipientList,
                                selection,
                                "id"
                            );
                        }
                        return recipientList;
                    })
                );
        } else if (selection) {
            return of(selection);
        } else {
            return of([]);
        }
    }

    _toRecipientListObj(recipient: any): RecipientOrganization {
        let label =
            (recipient.enabled ? "" : "(D) ") +
            (recipient.text || recipient.name) +
            ", " +
            (recipient.state || recipient.id.substr(0, 2));
        return Object.assign({ label: label }, recipient);
    }

    searchDocBuilderOrgs(
        query: string,
        selection: any,
        services = ["submitter_signing"]
    ): Observable<Organization[]> {
        if (query) {
            return this._rpcClient
                .makeRequest("search", "searchBasicOrganizations", {
                    query: query,
                    limit: 50,
                    services: services
                })
                .pipe(
                    map((results: Organization[]) => {
                        let docBuilderList = results
                            .filter((recipient) => {
                                return (
                                    recipient.name
                                        .toLowerCase()
                                        .includes(query.toLowerCase()) ||
                                    recipient.id
                                        .toLowerCase()
                                        .includes(query.toLowerCase())
                                );
                            })
                            .sort(sortBy("name"));
                        if (selection) {
                            docBuilderList = unionBy(
                                docBuilderList,
                                selection,
                                "id"
                            );
                        }
                        return docBuilderList;
                    })
                );
        } else if (selection) {
            return of(Array.isArray(selection) ? selection : [selection]);
        } else {
            return of([]);
        }
    }

    searchMultipleDocBuilderOrgs(
        queries: string[],
        selection: any,
        services = ["submitter_signing"]
    ): Observable<Organization[]> {
        if (queries) {
            return this._rpcClient
                .makeRequest("search", "searchMultipleBasicOrganizations", {
                    queries: queries,
                    limit: 50,
                    services: services
                })
                .pipe(
                    map((results: Organization[]) => {
                        let i = 0;
                        let docBuilderList = results
                            .filter((recipient) => {
                                return (
                                    recipient.name
                                        .toLowerCase()
                                        .includes(queries[i].toLowerCase()) ||
                                    recipient.id
                                        .toLowerCase()
                                        .includes(queries[i++].toLowerCase())
                                );
                            })
                            .sort(sortBy("name"));
                        if (selection) {
                            docBuilderList = unionBy(
                                docBuilderList,
                                selection,
                                "id"
                            );
                        }
                        return docBuilderList;
                    })
                );
        } else if (selection) {
            return of(selection);
        } else {
            return of([]);
        }
    }

    searchLenderOrgs(query: string, selection: any) {
        if (query) {
            return this._rpcClient
                .makeRequest("search", "searchBasicOrganizations", {
                    query: query,
                    limit: 50,
                    services: ["capc"]
                })
                .pipe(
                    map((results: Organization[]) => {
                        let lenderList = results
                            .filter((recipient) => {
                                return (
                                    recipient.name
                                        .toLowerCase()
                                        .includes(query.toLowerCase()) ||
                                    recipient.id
                                        .toLowerCase()
                                        .includes(query.toLowerCase())
                                );
                            })
                            .sort(sortBy("name"));
                        if (selection) {
                            lenderList = unionBy(lenderList, selection, "id");
                        }
                        return lenderList;
                    })
                );
        } else if (selection) {
            return of(selection);
        } else {
            return of([]);
        }
    }

    searchSubmitters(query: string) {
        return this._rpcClient.makeRequest(
            "search",
            "searchBasicOrganizations",
            {
                query: query,
                limit: 50,
                services: ["submitter"]
            }
        );
    }

    getAllAvailableCounties(): Observable<any[]> {
        return this._rpcClient.makeRequest(
            "userorg",
            "getAllAvailableCounties",
            {}
        );
    }

    getCheckBankAccountId(submitterId: string): Observable<string> {
        return this._rpcClient.makeRequest("esign", "getCheckBankAccountId", {
            submitterId
        });
    }

    setCheckBankAccountId(
        submitterId: string,
        bankAccountId: string
    ): Observable<void> {
        return this._rpcClient.makeRequest("esign", "setCheckBankAccountId", {
            submitterId,
            bankAccountId
        });
    }

    parseCSVMapping(submitterId: any, csvFile: string): Observable<any> {
        return this._rpcClient.makeRequest("esign", "parseCSVMapping", {
            submitterId,
            csvFile
        });
    }

    saveCSVMapping(csvMapping: any): Observable<any> {
        return this._rpcClient.makeRequest("esign", "saveCSVMapping", {
            csvMapping
        });
    }

    saveDefaultCSVMappings(submitterId: any): Observable<any> {
        return this._rpcClient.makeRequest("esign", "saveDefaultCSVMappings", {
            submitterId
        });
    }

    getCSVMappings(submitterId: any): Observable<any> {
        return this._rpcClient.makeRequest("esign", "getCSVMappings", {
            submitterId
        });
    }

    deleteCSVMapping(submitterId: any, apiKeyId: string): Observable<any> {
        return this._rpcClient.makeRequest("esign", "deleteCSVMapping", {
            submitterId,
            apiKeyId
        });
    }

    deleteAllCSVMappings(submitterId: any): Observable<any> {
        return this._rpcClient.makeRequest("esign", "deleteAllCSVMappings", {
            submitterId
        });
    }

    getBorrowerLetterTemplates(submitterId: string): Observable<any[]> {
        return this._rpcClient.makeRequest(
            "esign",
            "getBorrowerLetterTemplates",
            { submitterId }
        );
    }

    getCheckTemplates(submitterId: string): Observable<any[]> {
        return this._rpcClient.makeRequest("esign", "getCheckTemplates", {
            submitterId
        });
    }

    getDocTypesForSubmitterMapping(submitterId: string): Observable<any> {
        return this._rpcClient.makeRequest(
            "esign",
            "getDocTypesForSubmitterMapping",
            {
                submitterId
            }
        );
    }

    getReviewFolders(submitterIds: string[]): Observable<Folder[]> {
        return this._rpcClient.makeRequest("esign", "getReviewFolders", {
            submitterIds
        });
    }

    deleteReviewFolder(folderId: string): Observable<void> {
        return this._rpcClient.makeRequest("esign", "deleteReviewFolder", {
            folderId
        });
    }

    renameReviewFolder(folderId: string, name: string): Observable<void> {
        return this._rpcClient.makeRequest("esign", "renameReviewFolder", {
            folderId,
            name
        });
    }

    getTemplateList(organizationId: string): Observable<TemplateListItem[]> {
        return this._rpcClient.makeRequest("esign", "getTemplates", {
            organizationId
        });
    }

    getProjects(
        organizationId: string,
        includeArchived?: boolean,
        includeDraft?: boolean
    ): Observable<Project[]> {
        return this._projectService.getProjects(
            organizationId,
            includeArchived,
            includeDraft
        );
    }

    getCountyRequirements(recipientId: string): Observable<CountyRequirements> {
        return this._rpcClient.makeRequest("esign", "getCountyRequirements", {
            recipientId
        });
    }

    saveCountyRequirements(
        countyRequirements: CountyRequirements
    ): Observable<CountyRequirements> {
        return this._rpcClient.makeRequest("esign", "saveCountyRequirements", {
            countyRequirements
        });
    }

    getCountyTemplateRequirements(
        recipientId: string
    ): Observable<Record<string, TemplateRequirements>> {
        return this._rpcClient.makeRequest(
            "esign",
            "getCountyTemplateRequirements",
            {
                recipientId
            }
        );
    }

    saveCountyTemplateRequirements(
        recipientId: string,
        group: string,
        requirements: TemplateRequirements
    ): Observable<void> {
        return this._rpcClient.makeRequest(
            "esign",
            "saveCountyTemplateRequirements",
            {
                recipientId,
                group,
                requirements
            }
        );
    }

    getStateTemplateRequirements(
        state: string
    ): Observable<Record<string, TemplateRequirements>> {
        return this._rpcClient.makeRequest(
            "esign",
            "getStateTemplateRequirements",
            {
                state
            }
        );
    }

    saveStateTemplateRequirements(
        state: string,
        group: string,
        requirements: TemplateRequirements
    ): Observable<void> {
        return this._rpcClient.makeRequest(
            "esign",
            "saveStateTemplateRequirements",
            {
                state,
                group,
                requirements
            }
        );
    }

    getSubmitterOrganizations(submitterIds: string[]): Observable<any> {
        return this._rpcClient.makeRequest(
            "userorg",
            "getSubmitterOrganizations",
            { submitterIds }
        );
    }

    getSignersNotaries(organizationIds: string[]): Observable<any[]> {
        return this._rpcClient.makeRequest("esign", "getSignersNotaries", {
            organizationIds
        });
    }

    addSigningAdminsToTrialOrganization(
        submitterId: string,
        userIds: string[]
    ): Observable<void> {
        return this._rpcClient.makeRequest(
            "esign",
            "addSigningAdminsToTrialOrganization",
            {
                submitterId,
                userIds
            }
        );
    }

    isRonSettingEnabled(): Observable<boolean> {
        return this._rpcClient.makeRequest("esign", "isRonSettingEnabled", {});
    }

    getOrganizationsMissingLegalEntityInfo(): Observable<string[]> {
        return this._rpcClient.makeRequest(
            "esign",
            "getOrganizationsMissingLegalEntityInfo",
            {}
        );
    }

    sendMissingLegalEntityInfoEmail(
        submitterId: string,
        userIds: string[]
    ): Observable<string[]> {
        return this._rpcClient.makeRequest(
            "esign",
            "sendMissingLegalEntityInfoEmail",
            { submitterId, userIds }
        );
    }

    getEntityTypes() {
        return [
            { id: "Corporation" },
            { id: "Limited Liability Company" },
            { id: "Partnership" },
            { id: "Sole Proprietorship" }
        ];
    }

    getLegalEntityTypeAndState(
        organizationID: string
    ): Observable<{ legalEntityType: string; legalEntityState: string }> {
        return this._rpcClient.makeRequest(
            "userorg",
            "getLegalEntityTypeAndState",
            { organizationID }
        );
    }

    setLegalEntityTypeAndState(
        organizationID: string,
        entityType: string,
        entityState: string
    ): Observable<void> {
        return this._rpcClient.makeRequest(
            "userorg",
            "setLegalEntityTypeAndState",
            {
                organizationID,
                entityType,
                entityState
            }
        );
    }

    getEnabledStandardTemplateGroups(
        organizationID: string
    ): Observable<string[]> {
        return this._rpcClient.makeRequest(
            "userorg",
            "getEnabledStandardTemplateGroups",
            { organizationID }
        );
    }

    setEnabledStandardTemplateGroups(
        organizationID: string,
        templateGroups: string[]
    ): Observable<string[]> {
        return this._rpcClient.makeRequest(
            "userorg",
            "setEnabledStandardTemplateGroups",
            { organizationID, templateGroups }
        );
    }

    getSubmitterOrganizationConfigPreparation(
        submitterId: string,
        retrieveAllSettings?: boolean,
        valuesOnly?: boolean
    ): Observable<SubmitterConfigPreparation> {
        return this._rpcClient.makeRequest(
            "userorg",
            "getSubmitterOrganizationConfigPreparation",
            {
                submitterId: submitterId,
                retrieveAllSettings: retrieveAllSettings,
                valuesOnly: valuesOnly
            }
        );
    }

    getBorrowerLetterSettings(
        organizationId: string
    ): Observable<BorrowerLetterSettings> {
        return this._rpcClient.makeRequest(
            "esign",
            "getBorrowerLetterSettings",
            { organizationId }
        );
    }

    saveBorrowerLetterSettings(
        settings: BorrowerLetterSettings
    ): Observable<void> {
        return this._rpcClient.makeRequest(
            "esign",
            "saveBorrowerLetterSettings",
            { settings }
        );
    }

    setDefaultNotarizationType(type: "IPEN" | "RON"): Observable<void> {
        return this._rpcClient.makeRequest(
            "userorg",
            "setDefaultNotarizationType",
            { type }
        );
    }

    getScheduledDocumentCount(
        submitterId: string,
        readyToSubmit: boolean
    ): Observable<any> {
        return this._rpcClient.makeRequest(
            "esign",
            "getScheduledDocumentCount",
            {
                submitterId,
                readyToSubmit
            }
        );
    }

    changeScheduledDocuments(
        submitterId: string,
        action: string
    ): Observable<any> {
        return this._rpcClient.makeRequest(
            "esign",
            "changeScheduledDocuments",
            {
                submitterId,
                action
            }
        );
    }

    isPayoffLetterAPI(submitterId: string): Observable<boolean> {
        return this._rpcClient.makeRequest("esign", "isPayoffLetterAPI", {
            submitterId
        });
    }
}
