import { Component, Input, OnInit } from "@angular/core";
import { ModalButton } from "@sf/common";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { combineLatest } from "rxjs";
import { deepEqual } from "@sf/common";
import { SubmitterOrganizationService } from "@sf/submitter/common";
import { SubmitterReportBuilderService } from "../../../../../features/reports/src/lib/services/submitter-report-builder.service";
import { BankingExportColumnDetails } from "@sf/submitter/common";
import { SubmitterBankingExportCopyFromDialogComponent } from "../../../../../features/reports/src/lib/components/submitter-banking-export-copy-from-dialog/submitter-banking-export-copy-from-dialog.component";

@Component({
    selector: "sf-submitter-banking-export-report-builder-dialog",
    templateUrl:
        "./submitter-banking-export-report-builder-dialog.component.html",
    styleUrls: [
        "./submitter-banking-export-report-builder-dialog.component.scss"
    ]
})
export class SubmitterBankingExportReportBuilderDialogComponent
    implements OnInit
{
    @Input()
    orgID: string;
    @Input()
    initialSettings: [key: string][];
    @Input()
    hasChildOrganizations: boolean;

    title: string;
    helpLink: {
        url: string;
        tooltip: string;
    };
    primary: ModalButton;
    secondary: ModalButton;
    tertiary: ModalButton;
    quaternary: ModalButton;
    quinary: ModalButton;
    bankingExportReportColumns: string;
    allColumns: BankingExportColumnDetails[];
    selectedColumns: { [key: string]: string }[];

    /** This default string should match the string on the backend
     in SubmitterConfiguration.java:560 for SUBMITTER_BANKING_EXPORT_REPORT_JSON
     If the default changes it should be changed in both places. 07/26/2021 **/
    defaultSelection: { [key: string]: { index: number; label: string } } = {
        county: { index: 0, label: "County" },
        package: { index: 1, label: "Package" },
        document: { index: 2, label: "Document" },
        type: { index: 3, label: "Type" },
        pages: { index: 4, label: "Pages" },
        entry: { index: 5, label: "Entry" },
        recording_date: { index: 6, label: "Recording Date" },
        bank_date: { index: 7, label: "Bank Date" },
        recording_fee: { index: 8, label: "Recording Fee" },
        submission_fee: { index: 9, label: "Submission Fee" }
    };

    constructor(
        private _activeModal: NgbActiveModal,
        private _modalService: NgbModal,
        private _reportBuilderService: SubmitterReportBuilderService,
        private _submitterOrgService: SubmitterOrganizationService
    ) {}

    ngOnInit(): void {
        this.title = "Banking Export Report Builder";
        this.helpLink = {
            url: sf.liveConfig.DocumentationLinks[
                "documentationUrl_BANKING_EXPORT_REPORT_BUILDER"
            ],
            tooltip: "Click here for more information"
        };
        this.primary = {
            text: "Save",
            disabled: false,
            callback: this.saveSettings.bind(this)
        };
        this.secondary = {
            text: "Save to this and all child organizations",
            disabled: this.hasChildOrganizations === false,
            callback: this.saveForChildOrganizations.bind(this)
        };
        this.tertiary = {
            text: "Copy from...",
            callback: this.copyFrom.bind(this)
        };
        this.quaternary = {
            text: "Restore Defaults",
            disabled: false,
            callback: this.restoreDefaults.bind(this)
        };
        this.quinary = {
            text: "Cancel",
            callback: this.cancel.bind(this)
        };
        this.setReportData(this.orgID);
    }

    setReportData(submitterID: string) {
        this.allColumns = [];
        combineLatest([
            this._submitterOrgService.getSubmitterOrganizationConfigReports(
                submitterID,
                true,
                false
            ),
            this._reportBuilderService.getBankingExportReportAvailableFieldsJSON(
                submitterID
            )
        ]).subscribe(([configReports, columns]) => {
            this.bankingExportReportColumns =
                configReports[
                    "Reporting"
                ].submitter_banking_export_report_json.value;
            if (this.bankingExportReportColumns) {
                JSON.parse(this.bankingExportReportColumns).forEach(
                    (item: [key: string]) => {
                        const colID = Object.keys(item)[0];
                        const colValue = Object.values(item)[0];
                        columns[colID]["selected"] = true;
                        columns[colID]["label"] = colValue;
                        this.allColumns.push(columns[colID]);
                    }
                );
                Object.keys(columns).forEach((columnID: string) => {
                    if (!columns[columnID].selected) {
                        this.allColumns.push(columns[columnID]);
                    }
                });
                this.moveUncheckedColumnsToEnd();
                this.formatData();
            }
        });
    }

    saveSettings() {
        this._activeModal.close({
            updateChildren: false,
            selectedColumns: JSON.stringify(this.selectedColumns)
        });
    }

    saveForChildOrganizations() {
        this._activeModal.close({
            updateChildren: true,
            selectedColumns: JSON.stringify(this.selectedColumns)
        });
    }

    cancel() {
        this._activeModal.close();
    }

    copyFrom() {
        const modalRef = this._modalService.open(
            SubmitterBankingExportCopyFromDialogComponent,
            {
                size: "lg"
            }
        );
        modalRef.componentInstance.orgID = this.orgID;
        modalRef.result.then((selectedOrg) => {
            if (selectedOrg) {
                this.setReportData(selectedOrg);
            }
        });
    }

    restoreDefaults() {
        this.allColumns.forEach((column, index) => {
            if (this.defaultSelection[column.id]) {
                column.selected = true;
                column.label = this.defaultSelection[column.id].label;
                [
                    this.allColumns[index],
                    this.allColumns[this.defaultSelection[column.id].index]
                ] = [
                    this.allColumns[this.defaultSelection[column.id].index],
                    this.allColumns[index]
                ];
            } else {
                column.selected = false;
            }
        });
        this.moveUncheckedColumnsToEnd();
        this.formatData();
    }

    formatData() {
        this.selectedColumns = [];
        for (const column of this.allColumns) {
            if (column.selected) {
                const columnObj: { [key: string]: string } = {};
                columnObj[column.id] = column.label;
                this.selectedColumns.push(columnObj);
            } else {
                break;
            }
        }
        this.primary.disabled = deepEqual(
            this.selectedColumns,
            this.initialSettings
        );
        this.checkDefaultDisabled();
    }

    checkDefaultDisabled() {
        this.quaternary.disabled = true;
        let defaultCount = 0;
        if (
            this.selectedColumns.length ===
            Object.keys(this.defaultSelection).length
        ) {
            this.selectedColumns.forEach((column, index) => {
                const key = Object.keys(column)[0];
                const value = Object.values(column)[0];
                if (this.defaultSelection[key]) {
                    defaultCount++;
                    if (
                        this.defaultSelection[key].index !== index ||
                        this.defaultSelection[key].label !== value
                    ) {
                        this.quaternary.disabled = false;
                    }
                }
            });
        } else {
            this.quaternary.disabled = false;
        }
        if (defaultCount !== Object.keys(this.defaultSelection).length) {
            this.quaternary.disabled = false;
        }
    }

    toggleColumn(column: BankingExportColumnDetails) {
        this.moveUncheckedColumnsToEnd();
        this.validateLabel(column);
    }

    validateLabel(column: BankingExportColumnDetails) {
        if (!column.label || column.label === "") {
            column.label = column.defaultLabel;
        }
        this.formatData();
    }

    drop(event: CdkDragDrop<BankingExportColumnDetails[]>) {
        moveItemInArray(
            this.allColumns,
            event.previousIndex,
            event.currentIndex
        );
        this.moveUncheckedColumnsToEnd();
        this.formatData();
    }

    moveUncheckedColumnsToEnd() {
        // sorts the array in place
        this.allColumns.sort((a, b) => {
            if (a.selected && !b.selected) {
                return -1;
            } else if (!a.selected && b.selected) {
                return 1;
            } else if (a.selected && b.selected) {
                return 0;
            } else {
                if (a.uiLabel < b.uiLabel) {
                    return -1;
                } else if (a.uiLabel > b.uiLabel) {
                    return 1;
                }
                return 0;
            }
        });
    }
}
