<div *ngIf="!loaded">
    <fa-icon icon="spinner" [spin]="true"></fa-icon>
    <span> Loading...</span>
</div>
<div *ngIf="loaded">
    <div *ngIf="!editMode">
        <div *ngIf="!setup || !setup.contractID">
            <div *ngIf="!pendingContract" class="extra-margin">No Document Builder license on file</div>
            <div *ngIf="isSuperUser && opportunityID" class="extra-margin">
                Salesforce Opportunity ID:
                <button class="btn btn-link sf-btn-link" (click)="goToSalesforce()"
                        ngbTooltip="Click to view opportunity in Salesforce" placement="right">
                    {{opportunityID}}
                </button>
            </div>
            <div *ngIf="currentInvite && !pendingContract">
                <div>
                    <i>{{currentInviteMessage}}</i>
                </div>
                <div *ngIf="isSuperUser" class="extra-bottom">
                    <button type="button" class="btn btn-secondary"
                            (click)="showSignerDetails(null)">
                        <span>View Invitation Details</span>
                    </button>
                    <button type="button" class="btn btn-secondary"
                            (click)="cancelInvite()">
                        <span>Cancel Invitation</span>
                    </button>
                </div>
            </div>
            <div *ngIf="!!pendingContract">
                <div>
                    <i>A license is pending approval</i>
                </div>
                <div *ngIf="isSuperUser" class="extra-bottom">
                    <button type="button" class="btn btn-secondary"
                            (click)="showSignerDetails(pendingContract)">
                        <span>View License Details</span>
                    </button>
                    <button type="button" class="btn btn-secondary"
                            (click)="cancelSend()">
                        <span>Cancel Pending License</span>
                    </button>
                </div>
            </div>
            <div *ngIf="!!futureContract">
                <div>
                    <i>A future license is available...</i>
                </div>
                <div *ngIf="isSuperUser" class="extra-bottom">
                    <button type="button" class="btn btn-secondary"
                            (click)="showSignerDetails(futureContract)">
                        <span>View License Details</span>
                    </button>
                    <button type="button" class="btn btn-secondary"
                            (click)="cancelSend(true)">
                        <span>Cancel Future License</span>
                    </button>
                </div>
            </div>
            <div class="extra-margin" *ngIf="userCanEdit && !currentInvite && !pendingContract">
                <button type="button" class="btn btn-secondary"
                        (click)="showInvite()">
                    <span>Invite</span>
                </button>
                <button type="button" *ngIf="userCanEdit" class="btn btn-secondary"
                        (click)="showUpload()">
                    <span>Upload</span>
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="warning" class="alert alert-danger" [innerHTML]="warning"></div>

    <!-- NEW FORMAT -->
    <form *ngIf="setup.contractID" name="docBuilderLicenseForm">

        <!-- LICENSE DETAILS -->
        <div class="legend">License Details</div>
        <div class="wrapper" *ngIf="isSuperUser">
            <label>Signer Name/Title</label>
            <span>{{setup.signatureName}}</span>
            <span *ngIf="setup.signatureTitle"> ({{setup.signatureTitle}})</span>
        </div>
        <div class="wrapper">
            <label>Signature Date</label>
            <span>{{formattedExecutedDate}}</span>
        </div>
        <div class="wrapper">
            <label>Last Renewal Date</label>
            <span>{{formattedPaidDate}}</span>
        </div>
        <div class="wrapper">
            <label>Expiration Date</label>
            <span *ngIf="!editMode">
                <span>{{formattedExpirationDate}}</span>
                &nbsp;
                <button *ngIf="userCanEdit && licenseExpired" class="btn btn-link sf-btn-link"
                        aria-label="License expired" (click)="beginEdit()">
                    <fa-icon [fixedWidth]="true" icon="exclamation-circle" class="icon-alert tooltip-interaction"
                            ngbTooltip="License expired" placement="right"></fa-icon>
                </button>
                <span *ngIf="!userCanEdit && licenseExpired">
                    <fa-icon [fixedWidth]="true" icon="exclamation-circle" class="icon-alert tooltip-interaction"
                            ngbTooltip="License expired" placement="right"></fa-icon>
                </span>
            </span>
            <sf-date-picker *ngIf="editMode" name="expirationDate" [(ngModel)]="expirationDayjs"
                    (dateChange)="expirationDateChanged($event)" [required]="true" datePickerName="datePickerOne"
                    [maxDate]="maxDate" [minDate]="minDate"></sf-date-picker>
        </div>
        <div class="wrapper">
            <label>License Renewal Frequency</label>
            <span>Yearly</span>
        </div>
        <!--
        <div class="wrapper" *ngIf="salesPerson">
            <label>Sales Executive</label>
            <button class="btn btn-link sf-btn-link" (click)="showSalesPerson()">
                {{salesPerson.firstLastName}}
            </button>
        </div>
        -->
        <div class="wrapper" *ngIf="isSuperUser && opportunityID">
            <label>Salesforce Opportunity ID</label>
            <span>
                <button *ngIf="!editMode || (editMode && !hasSalesforceEditPermission)" class="btn btn-link sf-btn-link" (click)="goToSalesforce()"
                        ngbTooltip="Click to view opportunity in Salesforce" placement="right">
                    {{opportunityID}}
                </button>
                <input *ngIf="hasSalesforceEditPermission && editMode" [(ngModel)]="opportunityID"
                       name="salesforce_opportunity_id" class="salesforce form-control"/>
            </span>
        </div>
        <div *ngIf="pendingContract && !editMode" class="wrapper">
            <label><i>A new license is awaiting approval</i></label>
            <button *ngIf="isSuperUser" class="btn btn-link sf-btn-link tooltip-interaction" (click)="showSignerDetails(pendingContract)">
                Show Details
            </button>
            <span *ngIf="userCanEdit"> &nbsp;|&nbsp; </span>
            <button *ngIf="userCanEdit" class="btn btn-link sf-btn-link tooltip-interaction" (click)="cancelSend()"
                    ngbTooltip="Cancel approval request">
                Cancel Pending License
            </button>
        </div>
        <div *ngIf="futureContract && !editMode" class="wrapper">
            <label><i>Future license effective<br/>on: {{futureContract.effectiveDate | date}}</i></label>
            <button *ngIf="isSuperUser" class="btn btn-link sf-btn-link tooltip-interaction" (click)="showSignerDetails(futureContract)">
                Show Details
            </button>
            <span *ngIf="userCanEdit"> &nbsp;|&nbsp; </span>
            <button *ngIf="userCanEdit" class="btn btn-link sf-btn-link tooltip-interaction" (click)="cancelSend(true)"
                    ngbTooltip="Cancel future license">
                Cancel Future License
            </button>
        </div>

        <!-- LICENSE FEES -->
        <div class="legend">License Fees</div>
        <div class="wrapper wrapper-thick" *ngIf="isSuperUser && (setup.enrollmentCode || editMode)">
            <label>Enrollment Code</label>
            <span *ngIf="!editMode && setup.enrollmentCode">
                <button type="button" class="btn btn-link sf-btn-link"
                        (click)="goToEnrollmentCode()">
                    {{setup.enrollmentCode}}
                    <i *ngIf="enrollmentCodeName"> &nbsp;{{enrollmentCodeName}}</i>
                </button>
            </span>
            <span *ngIf="editMode">
                <span *ngIf="setup.enrollmentCode">{{setup.enrollmentCode}}</span>
                <button type="button" *ngIf="!setup.enrollmentCode" class="btn btn-link sf-btn-link"
                        (click)="showEnrollmentCodes()">Add Enrollment Code</button>
                <button type="button" *ngIf="setup.enrollmentCode" class="btn btn-secondary btn-small"
                        (click)="showEnrollmentCodes()">Change</button>
                <button type="button" *ngIf="setup.enrollmentCode" class="btn btn-secondary btn-small"
                        (click)="removeEnrollmentCode()">Remove</button>
            </span>
        </div>
        <div class="wrapper">
            <label for="licenseFee">Initial License Fee</label>
            <span *ngIf="!editMode">{{setup.licenseFee | currency}}</span>
            <span *ngIf="editMode">
                $<input maxlength="10" type="text" class="form-control feeInput" name="licenseFee" id="licenseFee"
                        [(ngModel)]="setup.licenseFee" [disabled]="!!setup.enrollmentCode || !!setup" required/>
            </span>
        </div>
        <div class="wrapper">
            <label for="renewalFee">Renewal License Fee</label>
            <span *ngIf="!editMode">{{setup.renewalFee | currency}}</span>
            <span *ngIf="editMode">
                $<input maxlength="10" type="text" class="form-control feeInput" name="renewalFee" id="renewalFee"
                      [(ngModel)]="setup.renewalFee" [disabled]="!!setup.enrollmentCode" required/>
            </span> per year
        </div>
        <div *ngIf="setup.contractFees.DOCUMENT_BUILDER_DOCUMENT_FEE.contractFeeTiers.length <= 1 && !showPricingTiers"
                    class="wrapper">
            <label for="documentFee">Document Fee</label>
            <span *ngIf="!editMode">{{setup.contractFees.DOCUMENT_BUILDER_DOCUMENT_FEE.contractFeeTiers[0].feeAmount | currency}}
            </span>
            <span *ngIf="editMode">
                $<input maxlength="10" type="text" class="form-control feeInput" name="documentFee" id="documentFee"
                        [disabled]="!!setup.enrollmentCode"
                        required [(ngModel)]="setup.contractFees.DOCUMENT_BUILDER_DOCUMENT_FEE.contractFeeTiers[0].feeAmount">
            </span> per document
            <button type="button" *ngIf="editMode && isSuperUser && !showPricingTiers" [disabled]="!!setup.enrollmentCode"
                    class="btn btn-secondary btn-small addPricingMsg" (click)="showPricingTiers = !showPricingTiers">
                Add Pricing Tiers
            </button>
        </div>
        <div *ngIf="setup.contractFees.DOCUMENT_BUILDER_DOCUMENT_FEE.contractFeeTiers.length > 1 || showPricingTiers" class="wrapper">
            <label style="vertical-align: top">Document Fee</label>
            <sf-contract-pricing-tiers [orgId]="orgID" [contractFee]="setup.contractFees.DOCUMENT_BUILDER_DOCUMENT_FEE"
                    [readOnly]="!editMode || !!setup.enrollmentCode" productId="SUBMITTER_SIGNING"
                    (update)="updateContractFee($event)" (removeTiers)="removeTiers()"></sf-contract-pricing-tiers>
        </div>

        <div class="wrapper">
            <label for="freeSubmissions">Free Documents</label>
            <span *ngIf="!editMode">{{setup.freeSubmissions}} Documents Remaining</span>
            <span *ngIf="editMode">
                <input maxlength="6" type="text" class="form-control feeInput" name="freeSubmissions"
                        id="freeSubmissions" [(ngModel)]="setup.freeSubmissions"
                        [disabled]="!!setup.enrollmentCode" required/>
            </span>
        </div>

        <!-- other stuff -->
        <div class="wrapper">
            <label for="bulkBase">{{paymentConfig['Templates and E-Sign'].bulk_release_base_fee.label}}</label>
            <span *ngIf="!editMode">{{paymentConfig['Templates and E-Sign'].bulk_release_base_fee.value | currency}}</span>
            <span *ngIf="editMode">
                $<input id="bulkBase" type="text" maxlength="6" class="form-control feeInput" name="bulkBase"
                        [(ngModel)]="paymentConfig['Templates and E-Sign'].bulk_release_base_fee.value"/>
            </span>
        </div>
        <div class="wrapper">
            <label for="bulkRef">{{paymentConfig['Templates and E-Sign'].bulk_release_per_ref_fee.label}}</label>
            <span *ngIf="!editMode">{{paymentConfig['Templates and E-Sign'].bulk_release_per_ref_fee.value | currency}}</span>
            <span *ngIf="editMode">
                $<input id="bulkRef" type="text" maxlength="6" class="form-control feeInput" name="bulkRef"
                        [(ngModel)]="paymentConfig['Templates and E-Sign'].bulk_release_per_ref_fee.value"/>
            </span>
        </div>

        <!-- LICENSE TERMS -->
        <div class="legend">License Terms</div>
        <div class="wrapper" *ngIf="selectableBillingCycles">
            <label for="billingCycleSelector">Billing Cycle</label>
            <span *ngIf="!editMode">{{displayableBillingCycle}}</span>
            <sf-select *ngIf="editMode" id="billingCycleSelector"
                    [selectedOption]="selectedBillingCycle.id"
                    [options]="selectableBillingCycles"
                    (select)="selectBillingCycle($event)"
                    trackBy="id" labelBy="label"
                    [isSingleSelect]="true" placeholder="Select a billing cycle..."
                    [required]="true"
                    [isDisabled]="!!setup.enrollmentCode">
            </sf-select>
        </div>
        <div class="wrapper" *ngIf="selectablePaymentTerms">
            <label>Payment Terms</label>
            <span *ngIf="!editMode">{{displayablePaymentTerms}}</span>
            <sf-select *ngIf="editMode"
                    [selectedOption]="selectedPaymentTerm.id"
                    [options]="selectablePaymentTerms"
                    (select)="selectPaymentTerm($event)"
                    trackBy="id" labelBy="label"
                    [isSingleSelect]="true" placeholder="Select a payment term..."
                    [required]="true"
                    [isDisabled]="!!setup.enrollmentCode">
            </sf-select>
        </div>

        <!-- LICENSE DOCUMENT -->
        <div class="legend">License Document</div>
        <div class="wrapper">
            <label *ngIf="msa">Service Addendum</label>
            <label *ngIf="!msa">License Document</label>
            <a target="_blank" *ngIf="setup.agreementUrl" [href]="setup.agreementUrl">
                <fa-icon [fixedWidth]="true" icon="file-pdf" class="tooltip-interaction icon-interaction"></fa-icon>
                <span>View</span>
            </a>
            <fa-icon *ngIf="setup.agreementMissing" [fixedWidth]="true" icon="unlink" class="tooltip-interaction icon-alert"
                    ngbTooltip="Document is missing"></fa-icon>
            <fa-icon *ngIf="setup.agreementLoading" [spin]="true" icon="spinner"></fa-icon>
            <button type="button" *ngIf="userCanEdit && !editMode"
                    class="btn btn-link sf-btn-link upload-icon" (click)="showUpload()">
                <fa-icon class="tooltip-interaction" icon="file-upload"></fa-icon>
                <span> Upload</span>
            </button>
        </div>
        <div *ngIf="isSuperUser && (msa || setup)" class="wrapper">
            <button type="button" class="btn btn-link sf-btn-link" (click)="showLicenseDocuments()">
                View All License Documents
            </button>
        </div>

        <!-- FOOTER -->
        <div *ngIf="!editMode && userCanEdit" class="form-footer">
            <button type="button" *ngIf="setup.contractID" (click)="beginEdit()"
                    class="btn btn-secondary" [disabled]="isProcessing">
                <span>Edit License</span>
            </button>
        </div>
        <div *ngIf="editMode" class="form-footer">
            <button type="button" (click)="save()" class="btn btn-primary"
                    [disabled]="isProcessing">
                <span>Save</span>
            </button>
            <button type="button" (click)="reload()" class="btn btn-secondary"
                    [disabled]="isProcessing">
                <span>Cancel</span>
            </button>
        </div>
    </form>

    <sf-notification *ngIf="missingTemplate" [noClose]="true">
        A valid Master Service Agreement Template does not exist on this server!
    </sf-notification>
</div>
