import { Component, OnInit } from "@angular/core";
import { UserOrgService } from "@sf/userorg/common";
import { GrowlService, SessionService } from "@sf/common";
import { ActivatedRoute, Router } from "@angular/router";
import { LoginService } from "@sf/login";

// prettier-ignore
@Component({
    selector: "sf-new-terms",
    templateUrl: "./new-terms.component.html",
    styleUrls: ["./new-terms.component.scss"]
})
export class NewTermsComponent implements OnInit {
    currentTermsVersion = "7.18.2018";

    constructor(
            private _sessionService: SessionService,
            private _userOrgService: UserOrgService,
            private loginService: LoginService,
            private router: Router,
            private route: ActivatedRoute,
            private _growlService: GrowlService
    ) {
    }

    ngOnInit() {
    }

    /*
    getTermsDate() {
        this.loginService.getTermsOfUseRevisionDate()
            .subscribe((date: string) => {
                this.currentTermsVersion = date;
            });
    }
    */

    _getHomePageURL(): string {
        let url: string = this._sessionService.getHomePageUrl();
        if (url.startsWith("/sf/ui")) url = url.substring(6);
        return url;
    }

    accept() {
        this._userOrgService.userAcceptsTerms()
            .subscribe((result: any) => {
                let url: string = this._getHomePageURL();
                this.router.navigate([url]);
            });
    }

    logout() {
        let newUrl = "/login";
        this.router.navigate([newUrl], { relativeTo: this.route });
    }
}
