import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SubmitterDocTypeSelectorComponent } from "./components/submitter-doc-type-selector/submitter-doc-type-selector.component";
import { DateAndTimeModule, SelectModule, SfCommonModule } from "@sf/common";
import { DocTypesDialogComponent } from "./dialogs/doc-types-dialog/doc-types-dialog.component";
import { PrintScaleDialogComponent } from "./dialogs/print-scale-dialog/print-scale-dialog.component";
import { ModalsModule } from "@sf/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { NgbButtonsModule } from "@sf/common";
import { SubmitterStatusPipe } from "./pipes/submitter-status.pipe";
import { DocumentStatusDialogComponent } from "./dialogs/document-status-dialog/document-status-dialog.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { CommonPipesModule } from "@sf/common";
import { SubmitterDefaultFilenameDialogComponent } from "./dialogs/submitter-default-filename-dialog/submitter-default-filename-dialog.component";
import { PackageHistoryComponent } from "./components/package-history/package-history.component";
import { AgGridModule } from "@ag-grid-community/angular";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { SubmitterRecipientSelectorComponent } from "./components/recipient-selector/submitter-recipient-selector.component";
import { RecipientRegistrationDialogComponent } from "./components/recipient-selector/dialogs/recipient-registration-dialog/recipient-registration-dialog.component";
import { OrganizationHistoryComponent } from "./components/organization-history/organization-history.component";
import { DownloadOptionsDialogComponent } from "./dialogs/download-options-dialog/download-options-dialog.component";
import { SubmitterQuickRegisterDialogComponent } from "./dialogs/submitter-quick-register-dialog/submitter-quick-register-dialog.component";
import { SubmitterGlobalSearchComponent } from "./components/submitter-global-search/submitter-global-search.component";
import { SubmitterReceiptDataDialogComponent } from "./dialogs/submitter-receipt-data-dialog/submitter-receipt-data-dialog.component";
import { OrganizationSignatureAuthorizationComponent } from "./dialogs/organization-signature-authorization/organization-signature-authorization.component";
import { CustomFormModule } from "@sf/custom-form";
import { RegistrationOrPaperDialogComponent } from "./components/recipient-selector/dialogs/registration-or-paper-dialog/registration-or-paper-dialog.component";
import { SubmitterPackageStatusRejectedDialogComponent } from "./dialogs/submitter-package-status-rejected-dialog/submitter-package-status-rejected-dialog.component";
import { RouterModule } from "@angular/router";
import { ErecordModule } from "@sf/erecord";
import { SecureCorrectionsWarningDialogComponent } from "./dialogs/secure-corrections-warning-dialog/secure-corrections-warning-dialog.component";
import { SubmitterBankingExportReportBuilderDialogComponent } from "./dialogs/submitter-banking-export-report-builder-dialog/submitter-banking-export-report-builder-dialog.component";
import { SubmitterCustomBankingExportReportBuilderDialogComponent } from "./dialogs/submitter-custom-banking-export-report-builder-dialog/submitter-custom-banking-export-report-builder-dialog.component";
import { CdkDrag, CdkDropList } from "@angular/cdk/drag-drop";

@NgModule({
    imports: [
        CommonModule,
        SfCommonModule,
        SelectModule,
        FormsModule,
        ReactiveFormsModule,
        ModalsModule,
        FontAwesomeModule,
        CommonPipesModule,
        NgbTooltipModule,
        AgGridModule,
        ScrollingModule,
        NgbButtonsModule,
        DateAndTimeModule,
        NgbModule,
        CustomFormModule,
        RouterModule,
        ErecordModule,
        CdkDropList,
        CdkDrag
    ],
    providers: [SubmitterStatusPipe],
    declarations: [
        SubmitterDocTypeSelectorComponent,
        DocTypesDialogComponent,
        PrintScaleDialogComponent,
        SubmitterStatusPipe,
        DocumentStatusDialogComponent,
        SubmitterDefaultFilenameDialogComponent,
        PackageHistoryComponent,
        SubmitterRecipientSelectorComponent,
        RecipientRegistrationDialogComponent,
        OrganizationHistoryComponent,
        DownloadOptionsDialogComponent,
        SubmitterQuickRegisterDialogComponent,
        SubmitterGlobalSearchComponent,
        SubmitterReceiptDataDialogComponent,
        OrganizationSignatureAuthorizationComponent,
        RegistrationOrPaperDialogComponent,
        SubmitterPackageStatusRejectedDialogComponent,
        SecureCorrectionsWarningDialogComponent,
        SubmitterBankingExportReportBuilderDialogComponent,
        SubmitterCustomBankingExportReportBuilderDialogComponent
    ],
    exports: [
        SubmitterDocTypeSelectorComponent,
        DocTypesDialogComponent,
        PrintScaleDialogComponent,
        SubmitterStatusPipe,
        SubmitterDefaultFilenameDialogComponent,
        PackageHistoryComponent,
        SubmitterRecipientSelectorComponent,
        OrganizationHistoryComponent,
        SubmitterQuickRegisterDialogComponent,
        SubmitterGlobalSearchComponent,
        SubmitterReceiptDataDialogComponent
    ]
})
export class SubmitterCommonModule {}
