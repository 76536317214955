import {
    Component,
    OnInit,
    ViewChild,
    Input,
    ElementRef,
    TemplateRef
} from "@angular/core";
import { ModalButton } from "../../interfaces/modal-button";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

@Component({
    selector: "sf-dialog-modal",
    templateUrl: "./dialog-modal.component.html",
    styleUrls: ["./dialog-modal.component.scss"],
    host: { "[class.sf-unmask]": "unmaskModal" }
})
export class DialogModalComponent<TemplateContextType = any> implements OnInit {
    @ViewChild("d") dialogElement: ElementRef;

    @Input()
    title: string;
    @Input()
    message: string;
    @Input()
    template: TemplateRef<TemplateContextType>;
    @Input()
    primary: ModalButton;
    @Input()
    secondary: ModalButton;
    @Input()
    tertiary: ModalButton;
    @Input()
    titleIcon?: IconProp;
    @Input()
    templateContext?: TemplateContextType;
    @Input()
    bodyStyles: {
        [p: string]: any;
    } | null = null;
    @Input()
    showDismiss = true;
    @Input()
    unmaskModal?: boolean = false;

    working: boolean;

    constructor() {}

    ngOnInit(): void {
        if (!this.message && !this.template) {
            throw Error("Either message or template MUST be defined");
        } else if (this.message && this.template) {
            throw Error(
                "Only ONE content variable can be defined.  Only set message OR template, not both."
            );
        }

        if (!this.primary && !this.secondary) {
            throw Error("At least one button MUST be configured!");
        }

        (this.templateContext as any) = {
            $implicit: this.templateContext
        };
    }

    dismiss(): void {
        this.dialogElement.nativeElement.close();
    }
}
