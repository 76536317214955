import {
    AfterContentInit,
    Component,
    ElementRef,
    Injector,
    OnDestroy,
    Renderer2,
    ViewChild,
    ViewContainerRef
} from "@angular/core";
import {
    ActivatedRoute,
    NavigationCancel,
    NavigationEnd,
    NavigationStart,
    Params,
    PRIMARY_OUTLET,
    ResolveEnd,
    ResolveStart,
    RouteConfigLoadEnd,
    RouteConfigLoadStart,
    Router
} from "@angular/router";

import { filter, map, take } from "rxjs/operators";
import { Observable, Subscription } from "rxjs";
import { Title } from "@angular/platform-browser";
import {
    getAllRouteDataFromSnapshot,
    GrowlDisplayService,
    startCase
} from "@sf/common";
import { LoaderService, HotKeysService, TooltipConfig } from "@sf/common";
import { NotificationBannerService } from "@sf/userorg/main";
import { RightContentLeftNavComponent } from "./layout/right-content-left-nav/right-content-left-nav.component";
import { NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { NgbTooltipConfig } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "sf-root",
    templateUrl: "app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent implements AfterContentInit, OnDestroy {
    private titleSubscription: Subscription;
    @ViewChild("tourstepcontainer", { read: ViewContainerRef, static: true })
    entry: ViewContainerRef;
    public isShowingRouteLoadIndicator: boolean;
    private isFirstLoad: boolean;
    public skipLinkPath: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private renderer: Renderer2,
        private title: Title,
        private growlDisplayService: GrowlDisplayService, // Need growls to be rendered at the app level
        private _loader: LoaderService,
        private _hotkeys: HotKeysService,
        private _injector: Injector,
        private _el: ElementRef,
        private _modalConfig: NgbModalConfig,
        private _tooltipConfig: NgbTooltipConfig,
        private _sfTooltipConfig: TooltipConfig
    ) {
        this.renderer.addClass(document.documentElement, "sf-angular");
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                // set page progress bar loading to start on NavigationStart event router
                this._loader.start();
            }
            if (event instanceof RouteConfigLoadStart) {
                this._loader.increment(25);
            }
            if (event instanceof RouteConfigLoadEnd) {
                this._loader.increment(50);
            }
            if (event instanceof ResolveStart) {
                this._loader.increment(65);
            }
            if (event instanceof ResolveEnd) {
                this._loader.increment(80);

                if (
                    getAllRouteDataFromSnapshot(event.state.root).layout
                        ?.name === RightContentLeftNavComponent.name
                ) {
                    this.skipLinkPath = `/sf/ui${
                        event.urlAfterRedirects.match(/[^#]*/)[0]
                    }#main`;
                } else {
                    this.skipLinkPath = undefined;
                }
            }
            if (
                event instanceof NavigationEnd ||
                event instanceof NavigationCancel
            ) {
                // set page progress bar loading to end on NavigationEnd event router
                this._loader.complete();
                this._el.nativeElement.focus();
            }
        });

        this.activatedRoute.queryParams.subscribe((queryParams) => {
            if (Object.keys(queryParams)) {
                const qp: string = Object.keys(queryParams)
                    .map(
                        (k) =>
                            encodeURIComponent(k) +
                            "=" +
                            encodeURIComponent(queryParams[k])
                    )
                    .join("&");
                if (this.skipLinkPath?.indexOf(qp) === -1) {
                    const [href, hash] = this.skipLinkPath.split("#");
                    this.skipLinkPath = `${href}?${qp}`;
                    if (hash) {
                        this.skipLinkPath += `#${hash}`;
                    }
                }
            }
        });

        this._modalConfig.ariaDescribedBy = "modal-message";
        this._modalConfig.ariaLabelledBy = "modal-basic-title";
        this._sfTooltipConfig.openDelay = this._tooltipConfig.openDelay = 500;
    }

    ngAfterContentInit() {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                take(1)
            )
            .subscribe(() => {
                this.isFirstLoad = false;
                this.hidePageLoading();
                this._injector.get(NotificationBannerService);
            });

        this.titleSubscription = this._getRouteAfterNavigation().subscribe(
            ({ route, title }) => {
                this.title.setTitle(title);
            }
        );

        (window as any).angularInitialized = true;
    }

    ngOnDestroy(): void {
        if (this.titleSubscription) {
            this.titleSubscription.unsubscribe();
        }
    }

    private hidePageLoading() {
        // Hide page loading spinner contained in the Angular JS ui-view div
        let spinner = document.getElementById("sf-page-loading-spinner");
        if (spinner) {
            // spinner.remove();
            spinner.parentNode.removeChild(spinner); // This works in IE11 also
        }
    }

    private _getTitlePart(part: string, params: Params): string {
        if (!part || part.startsWith(":")) {
            return "";
            // return params[part.substr(1)];
        }

        return startCase(part.split("-").join(" "));
    }

    private _getRouteAfterNavigation(): Observable<{
        route: ActivatedRoute;
        title: string;
    }> {
        return this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            // Traverse over the state tree to find the last activated route, and then return it to the stream
            map((route) => {
                let title = "Simplifile";
                while (route.firstChild) {
                    route = route.firstChild;
                    let partTitle = this._getRoutePartTitle(route);
                    if (partTitle !== "") {
                        title = partTitle + " | " + title;
                    }
                }
                return { route, title };
            }),
            // Filter for sink route (**)
            filter(
                ({ route }) =>
                    route.outlet === PRIMARY_OUTLET &&
                    route.routeConfig.path !== "**"
            )
        );
    }

    private _getRoutePartTitle(route: ActivatedRoute) {
        let partTitle = route.snapshot.data.pageTitle;
        if (!partTitle) {
            let snapshot = route.snapshot;
            let params = snapshot.params;
            let pathParts = snapshot.routeConfig.path.split("/");
            if (pathParts[0] === "app") {
                pathParts.shift();
            }
            partTitle = this._getTitlePart(pathParts.shift(), params);
            for (let part of pathParts) {
                let subPartTitle = this._getTitlePart(part, params);
                if (subPartTitle !== "" && partTitle !== "") {
                    partTitle =
                        this._getTitlePart(part, params) + " - " + partTitle;
                } else if (subPartTitle !== "" && partTitle === "") {
                    partTitle = subPartTitle;
                }
            }
        }

        return partTitle;
    }

    stop() {
        this._loader.complete();
        this._increment = 0;
    }

    start() {
        this._loader.start();
        this._increment = 0;
    }

    private _increment = 0;

    increment() {
        this._increment = this._increment + 10;
        this._loader.increment(this._increment);
    }
}
